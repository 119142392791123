import React from "react";
import "../../component-styles/Artykuly.scss";

function MetodyEfektywnegoUczeniaSie() {
  return (
    <div className="metody-efektywnego-uczenia-sie artykul">
      <header className="welcome-metody-efektywnego-uczenia-sie welcome-artykul">Metody efektywnego uczenia się</header>
      <p>
        <strong>Jak się uczyć?</strong> Matematyka, choć często uważana za
        trudną i nieprzystępną, wcale nie musi taka być. Odkrywając poprawną dla
        nas metodę uczenia się nie będzie stanowić żadego problemu, a nawet może
        się okazać wciągająca i satysfakcjonująca. Ten artykuł poświęcony jest
        technikom, które mogą być bardzo pomocne podczas codziennej nauki,
        skupiając się nie tylko na wynikach, lecz także na głębszym zrozumieniu
        tematu.
      </p>
      <strong>1. Rozwiązywanie matematycznych zagadek</strong>: Rozwiązywanie
      łamigłówek uczy nas kreatywnego myślenia oraz rozwija umiejętności
      zastosowania konkretnej teorii w praktyce. Jest to nie tylko rozrywka, ale
      i zajęcie rozwijające logiczne myślenie. Do łamigłówek tego typu zaliczamy
      krzyżówki, sudoku, układanie kostki Rubika, szachy, gry strategiczne.
      <br />
      <strong>2. Wizualizacja za pomocą grafik i ilustracji</strong>:
      Zobrazowanie abstrakcyjnych pojęć, początkowo oderwanych od
      rzeczywistości, w postaci wykresów, schematów i ilustracji pomaga w
      lepszym zrozumieniu danego konceptu. W ten sposób zagadnienia stają się
      bardziej dostępne i atrakcyjniejsze. W działach w których jest dużo teorii
      i "pamięciówki" również pomocne może być tworzenie tzw. map myśli
      <br />
      <strong>3. Wykorzystanie technologii w nauce</strong>: Korzystanie z
      aplikacji i programów pozwala nam na zapoznanie się z tematem w
      interaktywny sposób. Strony typu GeoGebra nie tylko pozwalają na
      przećwiczenie poznanych tematów w przyjazny sposób, ale też zawierają
      wiele przydatnych funkcji jak np. zaawansowany kalkulator, który pomoże
      nam w narysowaniu wykresu.
      <br />
      <strong>4. Nauczanie poprzez nauczanie innych</strong>: Przyjmowanie roli
      nauczyciela i objaśnianie pojęć innym wymaga od nas znajomości materiału
      natomiast jest to jedna z najlepszych metod na utrwalenie informacji. Ta
      czynność podświadomie wymusza na nas dogłębne zapoznanie się z
      zagadnieniem, a następnie przedstawienie go w jak najbardziej przystępny
      sposób dla nas i innych.
      <br />
      <strong>5. Praca zespołowa</strong>: Wspólne rozwiązywanie zadań często
      prowadzi do bardziej kreatywnych odpowiedzi. Poznajemy różne spojrzenia na
      problem i różne sposoby rozwiązywania go. Nie tylko jest to produktywnie
      spędzony czas ze znajomymi, ale także zajęcie umożliwiające nam
      prowadzenie dyskusji o opracowywanych tematach oraz zaczęrpnięcie różnych
      perspektyw.
      <br />
      <strong>Podsumowując</strong>, mimo że pozornie jedyną drogą ku opanowaniu
      materiału jest żmudne rozwiązywanie zadań, tak naprawdę metod jest dużo
      więcej. Każdy z nas jest inny i poszczgólne sposoby mogą do nas przemawiać
      lepiej lub gorzej. Poprzez testowanie nowych metod możemy poznać
      matematykę od o wiele ciekawszej strony niż nam się pierwotnie wydawało!
    </div>
  );
}

export default MetodyEfektywnegoUczeniaSie;
