import React, { useState } from "react";
import { useAtom } from "jotai";
import { WindowWidth } from "../../App.tsx";
import tematy from "./tematy.ts";
function ContentPicker(props: any) {
  const [showContentPicker, setShowContentPicker] = useState<boolean>(true);
  const [showKanaly, setShowKanaly] = useState<boolean>(false);
  const [width] = useAtom(WindowWidth);

  const toggleShowContentPicker = () => {
    const trueOrFalse = showContentPicker === true ? false : true;
    setShowContentPicker(trueOrFalse);
    setShowKanaly(false);
  };

  const ileKanalow = (x: string) => {
    let wynik = 0;
    for (let i = 0; i < props.filmyFiltered.length; i++) {
      if (props.filmyFiltered[i][6] === x) {
        wynik++;
      }
    }
    return wynik;
  };

  const changeKanaly = (event: any) => {
    if (event.target.checked) {
      props.setKanaly([...props.kanaly, event.target.name]);
    } else {
      props.setKanaly(
        props.kanaly.filter((x: string) => x !== event.target.name)
      );
    }
  };

  const zaznaczWszystkieKanaly = () => {
    props.setKanaly(props.kanalyNow);
  };
  const odznaczWszystkieKanaly = () => {
    props.setKanaly([]);
  };
  return (
    <div
      className={`content-picker ${
        width > 767 ? "wide-content-picker" : "thin-content-picker"
      } ${showContentPicker ? "show-content-picker" : ""}`}
    >
      {width <= 767 ? (
        <button
          className={`show-content-picker-button ${
            showContentPicker ? "show" : "dont-show"
          }`}
          onClick={toggleShowContentPicker}
        >
          <i className="fa-solid fa-chevron-down"></i>
        </button>
      ) : null}
      <button
        className="losowe-zadanie content-picker-select"
        onClick={() => {
          setShowContentPicker(false);
          props.losoweZadanie();
        }}
      >
        Losowe zadanie maturalne
      </button>
      <select
        value={props.klasa}
        className="content-picker-select"
        onChange={(event) => {
          props.setKlasa(event.target.value);
          props.setTeoriaOpened(false);
          props.setTeoriaIloscStron(1);
          props.setZadaniaMaturalneOpened(false);
          props.setZadaniaMaturalneIloscStron(1);
        }}
      >
        <option value="" disabled>
          Klasa
        </option>
        <option value="1">Klasa 1</option>
        <option value="2">Klasa 2</option>
        <option value="3">Klasa 3</option>
        <option value="4">Klasa 4</option>
      </select>
      <select
        value={props.zakres}
        className="content-picker-select"
        onChange={(event) => {
          props.setZakres(event.target.value);
          props.setDzial("wszystkie");
          props.setTemat("wszystkie");
          props.setTeoriaOpened(false);
          props.setZadaniaMaturalneOpened(false);
        }}
        disabled={props.klasa === "" ? true : false}
      >
        <option value="" disabled>
          Zakres
        </option>
        <option value="podstawa">Podstawa</option>
        <option value="rozszerzenie">Rozszerzenie</option>
      </select>

      <select
        value={props.dzial}
        className="content-picker-select"
        onChange={(event) => {
          props.setDzial(event.target.value);
          props.setTeoriaOpened(false);
          props.setTeoriaIloscStron(1);
          props.setZadaniaMaturalneOpened(false);
          props.setZadaniaMaturalneIloscStron(1);
        }}
        disabled={props.klasa === "" || props.zakres === "" ? true : false}
      >
        <option value="wszystkie" disabled>
          Dział
        </option>
        <option value="wszystkie" className="wszystkie">
          Wszystkie działy
        </option>
        {props.klasa === "1" && props.zakres === "podstawa" ? (
          <>
            <option value="1">Zbiory liczbowe. Liczby rzeczywiste</option>
            <option value="2">Wyrażenia algebraiczne</option>
            <option value="3">Funkcja i jej własności</option>
            <option value="4">Funkcja liniowa</option>
            <option value="5">
              Układy równań liniowych z dwiema niewiadomymi
            </option>
            <option value="6">Podstawowe własności wybranych funkcji</option>
            <option value="7">
              Geometria płaska - pojęcia wstępne. Trójkąty
            </option>
            <option value="8">Trygonometria kąta ostrego</option>
          </>
        ) : props.klasa === "1" && props.zakres === "rozszerzenie" ? (
          <>
            <option value="1">Zbiory liczbowe. Liczby rzeczywiste</option>
            <option value="2">Wyrażenia algebraiczne</option>
            <option value="3">Funkcja i jej własności</option>
            <option value="4">Funkcja linowa</option>
            <option value="5">
              Układy równań liniowych z dwiema niewiadomymi
            </option>
            <option value="6">Podstawowe własności wybranych funkcji</option>
            <option value="7">
              Geometria płaska - pojęcia wstępne. Trójkąty
            </option>
            <option value="8">Trygonometria kąta ostrego</option>
          </>
        ) : props.klasa === "2" && props.zakres === "podstawa" ? (
          <>
            <option value="1">Przekształcenia wykresów funkcji</option>
            <option value="2">
              Równania i nierówności z wartością bezwzględną
            </option>
            <option value="3">Funkcja kwadratowa</option>
            <option value="4">Geometria płaska - okręgi i koła</option>
            <option value="5">Trygonometria</option>
            <option value="6">Geometria analityczna</option>
            <option value="7">
              Geometria płaska - rozwiązywanie trójkątów
            </option>
            <option value="8">Wielomiany</option>
          </>
        ) : props.klasa === "2" && props.zakres === "rozszerzenie" ? (
          <>
            <option value="1">Przekształcenia wykresów funkcji</option>
            <option value="2">
              Równania i nierówności z wartością bezwzględną
            </option>
            <option value="3">Funkcja kwadratowa</option>
            <option value="4">Geometria płaska - okręgi i koła</option>
            <option value="5">Trygonometria</option>
            <option value="6">Geometria analityczna</option>
            <option value="7">
              Geometria płaska - rozwiązywanie trójkątów
            </option>
            <option value="8">Wielomiany</option>
          </>
        ) : props.klasa === "3" && props.zakres === "podstawa" ? (
          <>
            <option value="1">Ułamki algebraiczne. Równania wymierne</option>
            <option value="2">Ciągi</option>
            <option value="3">Kombinatoryka</option>
            <option value="4">Czworokąty</option>
            <option value="5">Geometria płaska - pole czworokąta</option>
            <option value="6">Geometria analityczna</option>
          </>
        ) : props.klasa === "3" && props.zakres === "rozszerzenie" ? (
          <>
            <option value="1">Ułamki algebraiczne. Równania wymierne</option>
            <option value="2">Ciągi</option>
            <option value="3">Kombinatoryka</option>
            <option value="4">Geometria płaska - czworokąty</option>
            <option value="5">Geometria płaska - pole czworokąta</option>
            <option value="6">Elementy analizy matematycznej</option>
            <option value="7">Trygonometria</option>
            <option value="8">Geometria analityczna</option>
          </>
        ) : props.klasa === "4" && props.zakres === "podstawa" ? (
          <>
            <option value="1">Funkcja wykładnicza</option>
            <option value="2">Funkcja logarytmiczna</option>
            <option value="3">Elementy statystyki</option>
            <option value="4">Rachunek prawdopodobieństwa</option>
            <option value="5">Geometria przestrzenna. Wielościany</option>
            <option value="6">Geometria przestrzenna. Bryły obrotowe</option>
          </>
        ) : props.klasa === "4" && props.zakres === "rozszerzenie" ? (
          <>
            <option value="1">Funkcja wykładnicza</option>
            <option value="2">Funkcja logarytmiczna</option>
            <option value="3">Elementy statystyki</option>
            <option value="4">Rachunek prawdopodobieństwa</option>
            <option value="5">Geometria przestrzenna. Wielościany</option>
            <option value="6">Geometria przestrzenna. Bryły obrotowe</option>
          </>
        ) : null}
      </select>
      <select
        value={props.temat}
        className="content-picker-select"
        onChange={(event) => {
          props.setTemat(event.target.value);
          props.setTeoriaOpened(false);
          props.setTeoriaIloscStron(1);
          props.setZadaniaMaturalneOpened(false);
          props.setZadaniaMaturalneIloscStron(1);
        }}
        disabled={
          props.dzial === "" || props.dzial === "wszystkie" ? true : false
        }
      >
        <option value="wszystkie" disabled>
          Temat
        </option>
        <option value="wszystkie" className="wszystkie">
          Wszystkie tematy
        </option>
        {props.dzial !== "wszystkie" && props.dzial !== "" ? (
          <>
            {Object.values(tematy[props.klasa][props.zakres][props.dzial])
              .filter((x) => typeof x === "object" && !(x instanceof Array))
              .map((x: any, index: number) => (
                <option
                  key={((x.name as string) + index) as string}
                  value={(index + 1) as number}
                >
                  {x.name as string}
                </option>
              ))}
          </>
        ) : null}
      </select>
      {props.klasa && props.zakres && (
        <div className="kanaly-picker">
          <button onClick={() => setShowKanaly(!showKanaly)}>Kanały</button>
          {showKanaly && (
            <div className="kanaly-form">
              <div className="zaznacz-kanaly">
                <button onClick={zaznaczWszystkieKanaly}>
                  Zaznacz wszystko
                </button>
                <button onClick={odznaczWszystkieKanaly}>
                  Odznacz wszystko
                </button>
              </div>
              {props.kanalyNow.map((x: string, index: string) => (
                <>
                  <input
                    type="checkbox"
                    id={index}
                    key={x + index}
                    name={x}
                    checked={props.kanaly.includes(x)}
                    onChange={changeKanaly}
                  />
                  <label htmlFor={index}>
                    {x}
                    <div>{ileKanalow(x)}</div>
                  </label>
                  <br />
                </>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
export default ContentPicker;
