import React from "react";
import "../component-styles/Kontakt.scss";
import ClipboardJS from "clipboard";

function Kontakt() {
  const clipboard = new ClipboardJS(".copy");
  clipboard.on("success", (e) => {
    e.clearSelection();
  });
  return (
    <div id="kontakt-segment">
      <header className="welcome-kontakt">
        <span className="line">
          Skontaktuj się z <span className="purple-text">nami</span>!
        </span>
      </header>
      <main className="kontakt-info">
        <div className="matzone-kontakt kontakt-div">
          <h1>E-mail matzone:</h1>
          <div className="info">
            Mail:{" "}
            <span
              className="copy"
              data-clipboard-text="matzone.kontakt@gmail.com"
            >
              matzone.kontakt@gmail.com
            </span>
          </div>
        </div>
        <div className="korepetycje-template kontakt-div">
          <h1>Zgłoszenia korepetycji:</h1>
          <div className="info">
            <span className="zgloszenia-start">
              <p>Jesteś korepetytorem i chcesz pojawić się na stronie? Napisz do
              nas wiadomość, a za darmo umieścimy cię w zakładce "Korepetycje"!</p>
              We wiadomości powinny znajdować się twoje dane, takie jak:
            </span>
            <p className="zgloszenia-info">
              <ul>
                <li>
                  <b>Imię i nazwisko</b>,
                </li>
                <li>
                  <b>Miejscowość</b>,
                </li>
                <li>
                  <b>Numer telefonu</b>,
                </li>
                <li>
                  <b>E-mail</b>,
                </li>
                <li>
                  <b>Zakres, którego uczysz</b>,
                </li>
                <li>
                  <b>Forma lekcji</b>,
                </li>
              </ul>
              Wiadomość urozmaicić możesz również pisząc trochę o sobie.
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Kontakt;
