import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { WindowWidth } from "../../App.tsx";
import ContentPicker from "./ContentPicker.tsx";
import "../../component-styles/Materialy.scss";
import filmyYtLinki, { filmy } from "./filmyYTLinki.ts";
import tematy from "./tematy.ts";
function Materialy() {
  const [width] = useAtom(WindowWidth);

  const [klasa, setKlasa] = useState<string>("");
  const [zakres, setZakres] = useState<string>("");
  const [dzial, setDzial] = useState<string>("wszystkie");
  const [temat, setTemat] = useState<string>("wszystkie");
  const [kanaly, setKanaly] = useState<string[]>([]);
  const [kanalyNow, setKanalyNow] = useState<string[]>([]);

  const [filmyFiltered, setFilmyFiltered] = useState<filmy[]>([]);
  const [showFilmy, setShowFilmy] = useState<boolean>(false);

  const [filmCount, setFlimCount] = useState<number>(0);
  const [maxFilmCount, setMaxFilmCount] = useState<number>(0);

  const [teoriaOpened, setTeoriaOpened] = useState<boolean>(false);
  const [teoriaHovered, setTeoriaHovered] = useState<boolean>(false);
  const [teoriaTekst, setTeoriaTekst] = useState<string>("");
  const [teoriaZIndex, setTeoriaZIndex] = useState<number>(2);
  const [teoriaIloscStron, setTeoriaIloscStron] = useState<number>(1);

  const [zadaniaMaturalneOpened, setZadaniaMaturalneOpened] =
    useState<boolean>(false);
  const [zadaniaMaturalneHovered, setZadaniaMaturalneHovered] =
    useState<boolean>(false);
  const [zadaniaMaturalneTekst, setZadaniaMaturalneTekst] =
    useState<string>("");
  const [zadaniaMaturalneZIndex, setZadaniaMaturalneZIndex] =
    useState<number>(2);
  const [zadaniaMaturalneIloscStron, setZadaniaMaturalneIloscStron] =
    useState<number>(1);
  const [zadaniaMaturalneWybor, setZadaniaMaturalneWybor] =
    useState<string>("");
  const [zadaniaMaturalnePokazOdpowiedz, setZadaniaMaturalnePokazOdpowiedz] =
    useState<boolean>(false);

  const [losoweZadanieOpened, setLosoweZadanieOpened] =
    useState<boolean>(false);

  useEffect(() => {
    if (width && width > 767) {
      setFlimCount(12);
    } else {
      setFlimCount(6);
    }
  }, [width, dzial, temat]);

  useEffect(() => {
    filterFilmy();
  }, [klasa, zakres, dzial, temat]);

  useEffect(() => {
    kanalyNowChange();
  }, [filmyFiltered]);

  function kanalyNowChange() {
    let kanalyHere: string[] = [];
    for (let i = 0; i < filmyFiltered.length; i++) {
      if (!kanalyHere.includes(filmyFiltered[i][6])) {
        kanalyHere.push(filmyFiltered[i][6]);
      }
    }
    setKanalyNow(kanalyHere);
    setKanaly(kanalyHere);
  }
  function filterFilmy() {
    const filmyArr: filmy[] = filmyYtLinki.filter(
      (x) =>
        x[1].indexOf("film-" + zakres + klasa) !== -1 &&
        (dzial !== "wszystkie" ? x[3] === dzial : true) &&
        (x[5] && temat !== "wszystkie"
          ? x[5].indexOf(tematy[klasa][zakres][dzial][temat].name) !== -1
          : true)
    );
    setFilmyFiltered(filmyArr);
    setMaxFilmCount(filmyArr.length);
    setShowFilmy(true);
  }
  function teoriaToggle(text: string) {
    setTeoriaOpened(!teoriaOpened);
    setZadaniaMaturalneOpened(false);
    setTeoriaTekst("");
    if (!teoriaOpened) {
      setZadaniaMaturalneZIndex(0);
      setTeoriaZIndex(2);
      const letters = text.split("");
      letters.forEach((letter, index) => {
        setTimeout(() => {
          setTeoriaTekst((prevTekst) => prevTekst + letter);
        }, 60 * index);
      });
    } else {
      setTeoriaTekst("");
    }
  }
  function teoriaHover() {
    setTeoriaHovered(true);
    setTeoriaTekst("eoria");
  }
  function teoriaUnHover() {
    setTeoriaHovered(false);
    !teoriaOpened && setTeoriaTekst("");
  }
  function zadaniaMaturalneToggle(text: string) {
    setZadaniaMaturalneOpened(!zadaniaMaturalneOpened);
    setTeoriaOpened(false);
    setZadaniaMaturalneTekst("");
    if (!zadaniaMaturalneOpened) {
      setTeoriaZIndex(0);
      setZadaniaMaturalneZIndex(2);
      const letters = text.split("");
      letters.forEach((letter, index) => {
        setTimeout(() => {
          setZadaniaMaturalneTekst((prevTekst) => prevTekst + letter);
        }, 60 * index);
      });
    } else {
      setZadaniaMaturalneTekst("");
    }
  }
  function zadaniaMaturalneHover() {
    setZadaniaMaturalneHovered(true);
    setZadaniaMaturalneTekst("atura");
  }
  function zadaniaMaturalneUnHover() {
    setZadaniaMaturalneHovered(false);
    !zadaniaMaturalneOpened && setZadaniaMaturalneTekst("");
  }
  function zadaniaMaturalneWyborToggle(odpowiedz: string) {
    setZadaniaMaturalneWybor(odpowiedz);
  }
  function getSrcTeoriaFile() {
    let nowyDzial = dzial;
    let nowyTemat = temat;
    if (nowyDzial === "wszystkie") {
      nowyDzial = "1";
    }
    if (nowyTemat === "wszystkie") {
      nowyTemat = "1";
    }
    return `images/Teorie/${klasa},${nowyDzial},${nowyTemat},${teoriaIloscStron}.png`;
  }

  function getSrcZadanieMaturalneFile() {
    let nowyDzial = dzial;
    if (nowyDzial === "wszystkie") {
      nowyDzial = "1";
    }
    return `images/ZadaniaMaturalne/${klasa},${nowyDzial},${zadaniaMaturalneIloscStron}.jpg`;
  }

  function kolejnaStronaTeorii() {
    if (temat !== "wszystkie") {
      if (
        teoriaIloscStron < tematy[klasa][zakres][dzial][temat].liczbaStronTeorii
      ) {
        setTeoriaIloscStron(teoriaIloscStron + 1);
      }
    } else {
      if (teoriaIloscStron < tematy[klasa][zakres][dzial].liczbaStronTeorii) {
        setTeoriaIloscStron(teoriaIloscStron + 1);
      }
    }
  }
  function poprzedniaStronaTeorii() {
    if (teoriaIloscStron > 1) {
      setTeoriaIloscStron(teoriaIloscStron - 1);
    }
  }
  function kolejnaStronaZadanMaturalnych() {
    if (
      zadaniaMaturalneIloscStron <
      tematy[klasa].podstawa[dzial].zadanieMaturalneSpecyfikacje.length
    ) {
      setZadaniaMaturalneIloscStron(zadaniaMaturalneIloscStron + 1);
    }
    setZadaniaMaturalneWybor("");
    setZadaniaMaturalnePokazOdpowiedz(false);
  }
  function poprzedniaStronaZadanMaturalnych() {
    if (zadaniaMaturalneIloscStron > 1) {
      setZadaniaMaturalneIloscStron(zadaniaMaturalneIloscStron - 1);
    }
    setZadaniaMaturalneWybor("");
    setZadaniaMaturalnePokazOdpowiedz(false);
  }

  const losoweZadanie = () => {
    let fakeKlasa: string, fakeDzial: string;
    do {
      fakeKlasa = (Math.floor(Math.random() * 4) + 1).toString();
      fakeDzial = (
        Math.floor(
          Math.random() * Object.keys(tematy[fakeKlasa].podstawa).length
        ) + 1
      ).toString();
    } while (
      !tematy[fakeKlasa].podstawa[fakeDzial].hasOwnProperty(
        "zadanieMaturalneSpecyfikacje"
      )
    );
    let maxzadaniaMaturalneIloscStron: number =
      tematy[fakeKlasa].podstawa[fakeDzial].zadanieMaturalneSpecyfikacje.length;
    let fakeZadaniaMaturalneIloscStron: number =
      Math.floor(Math.random() * maxzadaniaMaturalneIloscStron) + 1;

    setKlasa(fakeKlasa);
    setZakres("podstawa");
    setDzial(fakeDzial);
    setZadaniaMaturalneIloscStron(fakeZadaniaMaturalneIloscStron);
    setZadaniaMaturalneTekst("atura");
    setZadaniaMaturalneOpened(true);
  };

  return (
    <div id="materialy">
      <div className="materialy">
        <ContentPicker
          klasa={klasa}
          setKlasa={setKlasa}
          zakres={zakres}
          setZakres={setZakres}
          dzial={dzial}
          setDzial={setDzial}
          temat={temat}
          setTemat={setTemat}
          kanaly={kanaly}
          setKanaly={setKanaly}
          kanalyNow={kanalyNow}
          setKanalyNow={setKanalyNow}
          filmyFiltered={filmyFiltered}
          filmCount={filmCount}
          setFlimCount={setFlimCount}
          setTeoriaOpened={setTeoriaOpened}
          setTeoriaIloscStron={setTeoriaIloscStron}
          setZadaniaMaturalneOpened={setZadaniaMaturalneOpened}
          setZadaniaMaturalneIloscStron={setZadaniaMaturalneIloscStron}
          losoweZadanie={losoweZadanie}
        />
        {klasa !== "" && zakres !== "" ? (
          <>
            {dzial !== "wszystkie" &&
            ((temat !== "wszystkie" &&
              tematy[klasa][zakres][dzial][temat].liczbaStronTeorii !== 0) ||
              (temat === "wszystkie" &&
                tematy[klasa][zakres][dzial].liczbaStronTeorii)) ? (
              <button
                className={`teoria-button materialy-button ${
                  teoriaOpened || teoriaHovered ? "opened" : "closed"
                }`}
                onClick={() => teoriaToggle("eoria")}
                onMouseEnter={() => width >= 767 && teoriaHover()}
                onMouseLeave={() => width >= 767 && teoriaUnHover()}
                style={{ zIndex: teoriaZIndex }}
              >
                T
                <span style={{ fontSize: "0.9em" }}>
                  {teoriaOpened || teoriaHovered ? teoriaTekst : ""}
                </span>
              </button>
            ) : null}
            {teoriaOpened ? (
              <div className="teoria-div materialy-button-div">
                {dzial !== "wszystkie" &&
                (temat !== "wszystkie" ||
                  (temat === "wszystkie" &&
                    tematy[klasa][zakres][dzial].liczbaStronTeorii)) ? (
                  <>
                    <img
                      src={
                        temat !== "wszystkie"
                          ? tematy[klasa][zakres][dzial][temat].teoriaCustom
                            ? process.env.PUBLIC_URL +
                              `images/Teorie/${
                                tematy[klasa][zakres][dzial][temat]
                                  .teoriaCustom[teoriaIloscStron - 1]
                              }.png`
                            : process.env.PUBLIC_URL + getSrcTeoriaFile()
                          : tematy[klasa][zakres][dzial].teoriaCustom
                          ? process.env.PUBLIC_URL +
                            `images/Teorie/${
                              tematy[klasa][zakres][dzial].teoriaCustom[
                                teoriaIloscStron - 1
                              ]
                            }.png`
                          : process.env.PUBLIC_URL + getSrcTeoriaFile()
                      }
                      alt="Teorii do tego tematu jeszcze nie ma"
                    />

                    <div
                      className="change-strona-buttons"
                      strona-teraz={teoriaIloscStron}
                      strona-max={
                        temat !== "wszystkie"
                          ? tematy[klasa][zakres][dzial][temat]
                              .liczbaStronTeorii
                          : tematy[klasa][zakres][dzial]
                          ? tematy[klasa][zakres][dzial].liczbaStronTeorii
                          : 1
                      }
                    >
                      {teoriaIloscStron > 1 ? (
                        <button
                          className="poprzednia-strona-button"
                          onClick={() => poprzedniaStronaTeorii()}
                        >
                          <i className="fa-solid fa-chevron-left"></i>
                        </button>
                      ) : null}

                      {(temat !== "wszystkie" &&
                        tematy[klasa][zakres][dzial][temat].liczbaStronTeorii &&
                        tematy[klasa][zakres][dzial][temat].liczbaStronTeorii >
                          teoriaIloscStron) ||
                      (temat === "wszystkie" &&
                        tematy[klasa][zakres][dzial].liczbaStronTeorii &&
                        tematy[klasa][zakres][dzial].liczbaStronTeorii >
                          teoriaIloscStron) ? (
                        <button
                          className="nastepna-strona-button"
                          onClick={() => kolejnaStronaTeorii()}
                        >
                          <i className="fa-solid fa-chevron-right"></i>
                        </button>
                      ) : null}
                    </div>
                  </>
                ) : (
                  "Wybierz dział oraz temat, aby zobaczyć teorię!"
                )}
              </div>
            ) : (
              ""
            )}
            {(klasa === "3" && dzial < "7") || klasa !== "3"
              ? dzial !== "wszystkie" &&
                tematy[klasa].podstawa[dzial].zadanieMaturalneSpecyfikacje && (
                  <button
                    className={`zadania-maturalne-button materialy-button ${
                      zadaniaMaturalneOpened || zadaniaMaturalneHovered
                        ? "opened"
                        : "closed"
                    } ${zadaniaMaturalneOpened && "openedfr"}
                `}
                    onClick={() => zadaniaMaturalneToggle("atura")}
                    onMouseEnter={() => width >= 767 && zadaniaMaturalneHover()}
                    onMouseLeave={() =>
                      width >= 767 && zadaniaMaturalneUnHover()
                    }
                    style={{ zIndex: zadaniaMaturalneZIndex }}
                  >
                    M
                    <span style={{ fontSize: "0.9em" }}>
                      {zadaniaMaturalneOpened || zadaniaMaturalneHovered
                        ? zadaniaMaturalneTekst
                        : ""}
                    </span>
                  </button>
                )
              : null}
            {zadaniaMaturalneOpened || losoweZadanieOpened ? (
              <div
                className="zadania-maturalne-div materialy-button-div"
                matura={`Matura ${
                  tematy[klasa].podstawa[dzial].zadanieMaturalneSpecyfikacje[
                    zadaniaMaturalneIloscStron - 1
                  ][3]
                }`}
              >
                <img
                  src={process.env.PUBLIC_URL + getSrcZadanieMaturalneFile()}
                  alt="zadanie się nie wczytuje"
                />
                {width >= 767 ? (
                  <div
                    className={`odpowiedzi-abcd ${
                      /2023|2022/.test(
                        tematy[klasa].podstawa[dzial]
                          .zadanieMaturalneSpecyfikacje[
                          zadaniaMaturalneIloscStron - 1
                        ][3]
                      )
                        ? "ddt"
                        : ""
                    } ${
                      tematy[klasa].podstawa[dzial]
                        .zadanieMaturalneSpecyfikacje[
                        zadaniaMaturalneIloscStron - 1
                      ][4]
                        ? tematy[klasa].podstawa[dzial]
                            .zadanieMaturalneSpecyfikacje[
                            zadaniaMaturalneIloscStron - 1
                          ][4] === "rows2"
                          ? "rows2"
                          : tematy[klasa].podstawa[dzial]
                              .zadanieMaturalneSpecyfikacje[
                              zadaniaMaturalneIloscStron - 1
                            ][4] === "rows4"
                          ? "rows4"
                          : ""
                        : ""
                    }`}
                    style={{
                      gap:
                        tematy[klasa].podstawa[dzial]
                          .zadanieMaturalneSpecyfikacje[
                          zadaniaMaturalneIloscStron - 1
                        ][5] &&
                        tematy[klasa].podstawa[dzial]
                          .zadanieMaturalneSpecyfikacje[
                          zadaniaMaturalneIloscStron - 1
                        ][4] === "rows4"
                          ? `calc(${
                              tematy[klasa].podstawa[dzial]
                                .zadanieMaturalneSpecyfikacje[
                                zadaniaMaturalneIloscStron - 1
                              ][5]
                            }px * ${width < 767 ? "0.3" : "1"})`
                          : `${width < 767 ? "2.5px" : "25px"}`,
                      height:
                        tematy[klasa].podstawa[dzial]
                          .zadanieMaturalneSpecyfikacje[
                          zadaniaMaturalneIloscStron - 1
                        ][4] === "rows4" && width < 767
                          ? `calc(${
                              tematy[klasa].podstawa[dzial]
                                .zadanieMaturalneSpecyfikacje[
                                zadaniaMaturalneIloscStron - 1
                              ][0]
                            }px * 4 * 0.7 * 0.4 + ${
                              tematy[klasa].podstawa[dzial]
                                .zadanieMaturalneSpecyfikacje[
                                zadaniaMaturalneIloscStron - 1
                              ][5]
                                ? tematy[klasa].podstawa[dzial]
                                    .zadanieMaturalneSpecyfikacje[
                                    zadaniaMaturalneIloscStron - 1
                                  ][5]
                                : "0"
                            }px)`
                          : tematy[klasa].podstawa[dzial]
                              .zadanieMaturalneSpecyfikacje[
                              zadaniaMaturalneIloscStron - 1
                            ][4] === "rows2" && width < 767
                          ? `calc(${
                              tematy[klasa].podstawa[dzial]
                                .zadanieMaturalneSpecyfikacje[
                                zadaniaMaturalneIloscStron - 1
                              ][0]
                            }px * 2 * 0.25 + 40px)`
                          : "",
                    }}
                  >
                    <button
                      onClick={() => zadaniaMaturalneWyborToggle("a")}
                      className={
                        zadaniaMaturalneWybor
                          ? tematy[klasa].podstawa[dzial]
                              .zadanieMaturalneSpecyfikacje[
                              zadaniaMaturalneIloscStron - 1
                            ][2] === "a"
                            ? "dobra-odpowiedz"
                            : zadaniaMaturalneWybor === "a"
                            ? "wybrany"
                            : ""
                          : ""
                      }
                      style={{
                        height: `calc(${
                          tematy[klasa].podstawa[dzial]
                            .zadanieMaturalneSpecyfikacje[
                            zadaniaMaturalneIloscStron - 1
                          ][0]
                        }px)`,
                        width: `calc(${
                          tematy[klasa].podstawa[dzial]
                            .zadanieMaturalneSpecyfikacje[
                            zadaniaMaturalneIloscStron - 1
                          ][1]
                        }px)`,
                        bottom:
                          tematy[klasa].podstawa[dzial]
                            .zadanieMaturalneSpecyfikacje[
                            zadaniaMaturalneIloscStron - 1
                          ][5] &&
                          tematy[klasa].podstawa[dzial]
                            .zadanieMaturalneSpecyfikacje[
                            zadaniaMaturalneIloscStron - 1
                          ][4] === "rows2" &&
                          width >= 767
                            ? tematy[klasa].podstawa[dzial]
                                .zadanieMaturalneSpecyfikacje[
                                zadaniaMaturalneIloscStron - 1
                              ][5] + "px"
                            : "",
                        top:
                          tematy[klasa].podstawa[dzial]
                            .zadanieMaturalneSpecyfikacje[
                            zadaniaMaturalneIloscStron - 1
                          ][4] === "rows2" && width < 767
                            ? tematy[klasa].podstawa[dzial]
                                .zadanieMaturalneSpecyfikacje[
                                zadaniaMaturalneIloscStron - 1
                              ][0] <= 50
                              ? "0px"
                              : "-10px"
                            : "",
                      }}
                    />
                    <button
                      onClick={() => zadaniaMaturalneWyborToggle("b")}
                      className={
                        zadaniaMaturalneWybor
                          ? tematy[klasa].podstawa[dzial]
                              .zadanieMaturalneSpecyfikacje[
                              zadaniaMaturalneIloscStron - 1
                            ][2] === "b"
                            ? "dobra-odpowiedz"
                            : zadaniaMaturalneWybor === "b"
                            ? "wybrany"
                            : ""
                          : ""
                      }
                      style={{
                        height: `calc(${
                          tematy[klasa].podstawa[dzial]
                            .zadanieMaturalneSpecyfikacje[
                            zadaniaMaturalneIloscStron - 1
                          ][0]
                        }px)`,
                        width: `calc(${
                          tematy[klasa].podstawa[dzial]
                            .zadanieMaturalneSpecyfikacje[
                            zadaniaMaturalneIloscStron - 1
                          ][1]
                        }px)`,
                        bottom:
                          tematy[klasa].podstawa[dzial]
                            .zadanieMaturalneSpecyfikacje[
                            zadaniaMaturalneIloscStron - 1
                          ][5] &&
                          tematy[klasa].podstawa[dzial]
                            .zadanieMaturalneSpecyfikacje[
                            zadaniaMaturalneIloscStron - 1
                          ][4] === "rows2" &&
                          width >= 767
                            ? tematy[klasa].podstawa[dzial]
                                .zadanieMaturalneSpecyfikacje[
                                zadaniaMaturalneIloscStron - 1
                              ][5] + "px"
                            : "",
                        top:
                          tematy[klasa].podstawa[dzial]
                            .zadanieMaturalneSpecyfikacje[
                            zadaniaMaturalneIloscStron - 1
                          ][4] === "rows2" && width < 767
                            ? tematy[klasa].podstawa[dzial]
                                .zadanieMaturalneSpecyfikacje[
                                zadaniaMaturalneIloscStron - 1
                              ][0] <= 50
                              ? "0px"
                              : "-10px"
                            : "",
                      }}
                    />

                    <button
                      onClick={() => zadaniaMaturalneWyborToggle("c")}
                      className={
                        zadaniaMaturalneWybor
                          ? tematy[klasa].podstawa[dzial]
                              .zadanieMaturalneSpecyfikacje[
                              zadaniaMaturalneIloscStron - 1
                            ][2] === "c"
                            ? "dobra-odpowiedz"
                            : zadaniaMaturalneWybor === "c"
                            ? "wybrany"
                            : ""
                          : ""
                      }
                      style={{
                        height: `calc(${
                          tematy[klasa].podstawa[dzial]
                            .zadanieMaturalneSpecyfikacje[
                            zadaniaMaturalneIloscStron - 1
                          ][0]
                        }px)`,
                        width: `calc(${
                          tematy[klasa].podstawa[dzial]
                            .zadanieMaturalneSpecyfikacje[
                            zadaniaMaturalneIloscStron - 1
                          ][1]
                        }px)`,
                      }}
                    />
                    <button
                      onClick={() => zadaniaMaturalneWyborToggle("d")}
                      className={
                        zadaniaMaturalneWybor
                          ? tematy[klasa].podstawa[dzial]
                              .zadanieMaturalneSpecyfikacje[
                              zadaniaMaturalneIloscStron - 1
                            ][2] === "d"
                            ? "dobra-odpowiedz"
                            : zadaniaMaturalneWybor === "d"
                            ? "wybrany"
                            : ""
                          : ""
                      }
                      style={{
                        height: `calc(${
                          tematy[klasa].podstawa[dzial]
                            .zadanieMaturalneSpecyfikacje[
                            zadaniaMaturalneIloscStron - 1
                          ][0]
                        }px)`,
                        width: `calc(${
                          tematy[klasa].podstawa[dzial]
                            .zadanieMaturalneSpecyfikacje[
                            zadaniaMaturalneIloscStron - 1
                          ][1]
                        }px)`,
                      }}
                    />
                  </div>
                ) : (
                  <div className="pokaz-odpowiedz">
                    <button
                      onClick={() =>
                        setZadaniaMaturalnePokazOdpowiedz(
                          !zadaniaMaturalnePokazOdpowiedz
                        )
                      }
                    >
                      Pokaż odpowiedź
                    </button>
                    <div>
                      {zadaniaMaturalnePokazOdpowiedz &&
                        tematy[klasa].podstawa[dzial]
                          .zadanieMaturalneSpecyfikacje[
                          zadaniaMaturalneIloscStron - 1
                        ][2]}
                    </div>
                  </div>
                )}
                <div
                  className="change-strona-buttons"
                  strona-teraz={zadaniaMaturalneIloscStron}
                  strona-max={
                    tematy[klasa].podstawa[dzial].zadanieMaturalneSpecyfikacje
                      ? tematy[klasa].podstawa[dzial]
                          .zadanieMaturalneSpecyfikacje.length
                      : 1
                  }
                >
                  {zadaniaMaturalneIloscStron > 1 ? (
                    <button
                      className="poprzednia-strona-button"
                      onClick={() => poprzedniaStronaZadanMaturalnych()}
                    >
                      <i className="fa-solid fa-chevron-left"></i>
                    </button>
                  ) : null}

                  {tematy[klasa].podstawa[dzial].zadanieMaturalneSpecyfikacje &&
                  tematy[klasa].podstawa[dzial].zadanieMaturalneSpecyfikacje
                    .length > zadaniaMaturalneIloscStron ? (
                    <button
                      className="nastepna-strona-button"
                      onClick={() => kolejnaStronaZadanMaturalnych()}
                    >
                      <i className="fa-solid fa-chevron-right"></i>
                    </button>
                  ) : null}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="filmy">
              {showFilmy &&
                filmyFiltered
                  .slice(0, filmCount)
                  .filter((x) => kanaly.includes(x[6]))
                  .map((x) => (
                    <div className="filmy-div">
                      <iframe
                        key={x + "-film"}
                        className={x.join(" ")}
                        src={x[0]}
                        title={x[2]}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                      <div>{x[2]}</div>
                    </div>
                  ))}
            </div>
            {filmCount < maxFilmCount ? (
              <div className="moreFilms-div">
                <button
                  onClick={() => {
                    if (width > 767) {
                      setFlimCount(filmCount + 12);
                    } else {
                      setFlimCount(filmCount + 6);
                    }
                  }}
                >
                  Pokaż więcej
                </button>
              </div>
            ) : null}
          </>
        ) : (
          <div className="materialy-welcome">
            <div className="wybierz-poziom">
              <img
                className="first-arrow"
                src={process.env.PUBLIC_URL + "/images/StrzalkaSzara.png"}
                alt=""
              />
              <span>
                Wybierz
                <br /> poziom
              </span>
            </div>
            <img
              className="materialy-img"
              src={process.env.PUBLIC_URL + "/images/MaterialyStart.png"}
              alt=""
            />
            <div className="wybierz-zagadnienia">
              <img
                className="second-arrow"
                src={process.env.PUBLIC_URL + "/images/StrzalkaSzara.png"}
                alt=""
              />
              <span>
                Wybierz
                <br /> zagadnienia
              </span>
            </div>
          </div>
        )}
        <div className="kanaly-matematyczne">
          <hr />
          <span className="kanaly-matematyczne-start">Kanały matematyczne</span>
          <div className="kanaly-matematyczne-content">
            {[
              "pistacjamatematyka",
              "ezmatma",
              "wzorowanie",
              "pizzamath8681",
              "AjkaMat",
              "patomatma",
              "matspot1088",
              "miedzianyfsor",
              "matematykazwujkiemmarkiem",
              "matzadanie",
              "matkwadrat",
              "khanacademy",
              "moja-matplaneta",
              "pawemalarczyk9577",
              "MatematykaNaPlus1",
              "babkazmajcy1394",
              "akademiamatematykipiotraciupaka",
              "Noijuz",
              "maturazlewusem",
              "cinematma3034",
              "andrzejwinnickihul4193",
              "krzysztofzelner9129",
              "matematykazangel9968",
              "statystykaludzkimgosem9949",
              "karolinaduda3699",
              "Datacademypl",
              "takpoprostu5514",
              "Lernado",
              "mathdream_ms",
              "MatmaZPasja",
              "themathteacher1593",
              "aitra4539",
              "smiemwatpic",
              "mathacademy6798",
              "edudamarek",
              "ojosverdespl",
              "user-ex5lm1cg9p",
              "zacnyumysl",
              "matematykadasielubic",
              "krautka",
              "PoprostuLicz",
              "ynmun",
              "matematykamartatrojak1777",
              "deyko7542",
              "asjonat5683",
              "PoliczymySieZtaMatma",
              "matwujek",
              "planetaedu",
              "Ematematyka",
              "dziae-learningupolitechnik3935",
              "MarekMaolepszy",
              "matmadlahumanow7179",
              "zaliczone_na_5",
              "PiotrSzlagor",
            ].map((x: string, index: number) => (
              <div className="kanal-matematyczny">
                <img
                  alt={x}
                  src={
                    process.env.PUBLIC_URL +
                    `/images/KanalyMatematyczne/${x}.png`
                  }
                  onClick={() => {
                    window.open(`https://www.youtube.com/@${x}`, "_blank");
                  }}
                  key={x + index + 1}
                />
                <div className="nazwa-kanalu">@{x}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Materialy;
