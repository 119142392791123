import React from "react";
import { Link } from "react-router-dom";
import ClipboardJS from "clipboard";
import korepetytorzy from "./korepetytorzy.ts";
import "../../component-styles/Korepetycje.scss";
import "@fortawesome/fontawesome-free/css/all.css";
function Korepetycje() {
  const clipboard = new ClipboardJS(".copy");

  clipboard.on("success", (e) => {
    e.clearSelection();
  });

  return (
    <div id="korepetycje">
      <div className="korepetycje-segment">
        <nav className="kontakt-korepetycji">
          Chcesz pojawić się na stronie?{" "}
          <Link to="/kontakt" className="link">
            Napisz do nas!
          </Link>
        </nav>
        <div className="welcome-korepetycje">
          znajdź swojego
          <br />
          <span className="purple-text">korepetytora!</span>
        </div>
        <div className="korepetytorzy">
          {korepetytorzy.map((x) => (
            <div className="korepetytor-div" key={x + "-korepetytor"}>
              <span className="imie-i-nazwisko">
                <strong>{x.imie + " " + x.nazwisko}</strong>
              </span>
              {x.miejscowosc !== "" ? (
                <>
                  <br />
                  <strong>- Miejscowość:</strong>
                  <span className="miejscowosc">{x.miejscowosc}</span>
                </>
              ) : (
                ""
              )}
              <br />
              <strong>- Numer telefonu:</strong>{" "}
              <span
                className="numerTelefonu copy "
                data-clipboard-text={x.numerTelefonu}
              >
                {x.numerTelefonu}
              </span>
              <br />
              <strong>- Email:</strong>{" "}
              <span className="mail copy " data-clipboard-text={x.mail}>
                {x.mail}
              </span>
              <br />
              <strong>- Zakres:</strong>{" "}
              <span className="zakres">{x.zakres}</span>
              <br />
              <strong>- Opis:</strong>{" "}
              <span className="zakres">{x.formaLekcji}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default Korepetycje;
