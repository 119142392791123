import React from "react";
import "../../component-styles/Matura.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { MotywStrony } from "../../App.tsx";

export const ZakresArkusze = atom<string>("podstawa");

function Matura() {
  const setZakresArkusze = useSetAtom(ZakresArkusze);

  const motyw = useAtomValue(MotywStrony);
  const navigate = useNavigate();

  return (
    <div className="matura-segment">
      <div className="welcome-matura">
        <div className="img-and-start">
          <img
            src={
              motyw === "light"
                ? process.env.PUBLIC_URL + "/images/MaturaWelcome.png"
                : process.env.PUBLIC_URL + "/images/MaturaWelcomeDarkMode.png"
            }
            alt="matura logo"
          />

          <div className="welcome-matura-start">
            Jak dobrze zdać <span className="purple-text">maturę</span>?
          </div>
        </div>
        <p className="welcome-matura-content">
          Odpowiedź to <strong>zadania!</strong>
          <br />
          Mimo, że istnieje wiele porad i sposobów jak przygotować się do
          matury, czyli tak zwanego egzaminu dojrzałości, to z pewnością jednym
          z podstawowych kroków jakie powinien podjąć każdy maturzysta jest
          przerobienie arkuszy z poprzednich lat.
          <br />W związku z tym zebraliśmy dla was
          <strong> matury z ubiegłych lat</strong> oraz przygotowaliśmy ich{" "}
          <strong>rozwiązania wraz z objaśnieniami!</strong>
        </p>
        <ScrollLink to="wybierz-poziom" spy={true} smooth={true} duration={600}>
          <div className="arrows-div">
            <div className="arrows">
              <i className="fa-solid fa-chevron-down first" />
              <i className="fa-solid fa-chevron-down second" />
              <i className="fa-solid fa-chevron-down third" />
              <i className="fa-solid fa-chevron-down fourth" />
            </div>
          </div>
        </ScrollLink>
      </div>
      <div id="wybierz-poziom" className="wybierz-poziom">
        <div className="wybierz-swoj-poziom wybierz">Wybierz</div>
        <div className="strzalka1">
          <img
            src={
              motyw === "light"
                ? process.env.PUBLIC_URL + "/images/Strzalka.png"
                : process.env.PUBLIC_URL + "/images/StrzalkaDarkMode.png"
            }
            alt=""
          />
        </div>
        <div className="wybierz-poziom-buttons">
          <button
            onClick={() => {
              navigate("/matura/lista-arkuszy");
              setZakresArkusze("podstawa");
            }}
          >
            <div className="matematyka">Matematyka</div>
            <div className="poziom">Poziom podstawowy</div>
          </button>
          <button
            onClick={() => {
              navigate("/matura/lista-arkuszy");
              setZakresArkusze("rozszerzenie");
            }}
          >
            <div className="matematyka">Matematyka</div>
            <div className="poziom">Poziom rozszerzony</div>
          </button>
        </div>
        <div className="strzalka2">
          <img
            src={
              motyw === "light"
                ? process.env.PUBLIC_URL + "/images/Strzalka.png"
                : process.env.PUBLIC_URL + "/images/StrzalkaDarkMode.png"
            }
            alt=""
          />
        </div>
        <div className="wybierz-swoj-poziom swoj-poziom">swój poziom</div>
      </div>
      <hr className="zagadnienia-maturalne-hr" />
      <div className="zagadnienia-maturalne-div">
        <div className="zagadnienia-maturalne-start">Zagadnienia maturalne</div>
        <p className="zagadnienia-maturalne-content">
          W tej zakładce znajdziesz całą podstawę programową na maturę z
          matematyki podstawowej oraz rozszerzonej. Zobaczyć też tu możesz
          informację o najnowszych zmianach maturalnych. Wejdź i bądź na
          bieżąco!
        </p>
        <NavLink
          to="zagadnienia-maturalne"
          className="zagadnienia-maturalne-link"
        >
          Kliknij, by przejść do zagadnień maturalnych
        </NavLink>
        <img
          src={process.env.PUBLIC_URL + "/images/ZagadnieniaMaturalne.png"}
          alt="zmiany maturalne"
        />
      </div>
      <div className="kursy-maturalne">
        <div className="welcome-kursy-maturalne"><span className="line">Polecane kursy maturalne</span></div>
        <div className="wzorowanie-kurs kurs-div">
          <img
            src={process.env.PUBLIC_URL + "/images/WzorowanieKurs.jpg"}
            alt="Wzorowanie link"
            onClick={() => {
              window.open("https://www.wzorowanie.pl/", "_blank");
            }}
          />
          <div className="wzorowanie-kurs-content kurs-content">
            Chcesz przygotować się do{" "}
            <span className="purple">matury z matematyki</span>? Oto{" "}
            <span className="purple">kurs maturalny</span>, który ci w tym
            pomoże! A jeśli chcesz przygotować się na najbliższy{" "}
            <span className="purple">sprawdzian</span>, sięgnij po{" "}
            <span className="purple">bezpłatne</span> materiały z kanału{" "}
            <span className="green">Wzorowanie</span>, które znajdziesz{" "}
            <Link
              to="https://www.youtube.com/@wzorowanie/videos"
              target="_blank"
              className="link"
            >
              tutaj
            </Link>
          </div>
        </div>
        <div className="ezmatma-kurs kurs-div">
          <img
            src={process.env.PUBLIC_URL + "/images/EzmatmaKurs.jpg"}
            alt="Ezmatma link"
            onClick={() => {
              window.open("https://www.ezkursy.pl/", "_blank");
            }}
          />
          <div className="ezmatma-kurs-content kurs-content">
            Nowoczesny kurs maturalny{" "}
            <span className="purple">Ezmatura 2024</span>, w którym uczysz się
            za pomocą <span className="purple">interaktywnych modułów</span>.
            Sprawdzaj swój <span className="purple">postęp</span> wykonując
            zadania na stronie, a także oglądając{" "}
            <span className="purple">edukacyjne filmy</span>. Sprawdź także
            limitowaną <span className="green">darmową</span> wersję kursu.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Matura;
