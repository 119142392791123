import { shuffle } from "../Materialy/filmyYTLinki.ts";

export interface Korepetytor {
  imie: string;
  nazwisko: string;
  miejscowosc: string;
  numerTelefonu: string;
  mail: string;
  zakres: string;
  formaLekcji: string;
}

let korepetytorzy: Korepetytor[] = [
  {
    imie: "Dominika",
    nazwisko: "z kanału Matematyka Na Plus",
    miejscowosc: "",
    numerTelefonu: "515081284",
    mail: "matnaplus@gmail.com",
    zakres: "studia - dowolny przedmiot",
    formaLekcji: "Lekcje online. Zajęcia indywidualne i grupowe",
  },
];
korepetytorzy = shuffle(korepetytorzy);
export default korepetytorzy;
