export type filmy = [string, string[], string, string, string, string[],string];

let filmyYtLinki:filmy[] = [
["https://www.youtube.com/embed/KPOFrG8HOwo",["film-podstawa2","film-rozszerzenie2"],"WEKTOR w układzie współrzędnych","1","youtube",["Wektor w układzie współrzędnych","Wektor w układzie współrzędnych - podstawowe informacje"],"Wzorowanie"],
["https://www.youtube.com/embed/LqBrQp-f2I0",["film-podstawa2","film-rozszerzenie2"],"Wektory - Wprowadzenie Odc.1 (na szybko bo wychodzę za 5 min)","1","youtube",["Wektor na płaszczyźnie"],"PATOMATMA"],
["https://www.youtube.com/embed/WCOChZ-BaCM",["film-rozszerzenie2"],"Wektory - wprowadzenie - najważniejsze informacje","1","youtube",["Wektor na płaszczyźnie"],"Matemaks"],
["https://www.youtube.com/embed/9agT9Lu6pJQ",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa I. Wektor w układzie współrzędnych","1","youtube",["Wektor w układzie współrzędnych","Wektor w układzie współrzędnych - podstawowe informacje"],"Pizza Math"],
["https://www.youtube.com/embed/KPOFrG8HOwo",["film-podstawa2","film-rozszerzenie2"],"WEKTOR W UKŁADZIE WSPÓŁRZĘDNYCH - musisz to wiedzieć","1","youtube",["Wektor w układzie współrzędnych","Wektor w układzie współrzędnych - podstawowe informacje"],"Wzorowanie"],
["https://www.youtube.com/embed/wQeAzgttOUM",["film-rozszerzenie2"],"WEKTORY NA PŁASZCZYŹNIE [#59]","1","youtube",["Wektor na płaszczyźnie"],"Wzorowanie"],
["https://www.youtube.com/embed/C3RlRjnBE0c",["film-podstawa2","film-rozszerzenie2"],"Przesuwanie wykresów funkcji wzdłuż osi X i Y oraz o wektor","1","youtube",["Przesunięcie równoległe. Przesunięcie równoległe wzdłuż osi OX","Przesunięcie równoległe wzdłuż osi OY"],"matspot"],
["https://www.youtube.com/embed/sD4mC5Ux6pM",["film-podstawa2","film-rozszerzenie2"],"Przesuwanie wykresu funkcji wzdłuż osi OX i OY","1","youtube",["Przesunięcie równoległe. Przesunięcie równoległe wzdłuż osi OX","Przesunięcie równoległe wzdłuż osi OY"],"MARTAmatics PL"],
["https://www.youtube.com/embed/7xxIkATsBeo",["film-podstawa2","film-rozszerzenie2"],"Przesunięcie wykresu funkcji wzdłuż osi układu współrzędnych cz.2 MATEMATYKA LEKCJE Z FSOREM","1","youtube",["Przesunięcie równoległe. Przesunięcie równoległe wzdłuż osi OX","Przesunięcie równoległe wzdłuż osi OY"],"MiedzianyFsor"],
["https://www.youtube.com/embed/nxuZvjTxMOk",["film-podstawa2","film-rozszerzenie2"],"Matematyka - Przesunięcie funkcji wzdłuż osi OX","1","youtube",["Przesunięcie równoległe. Przesunięcie równoległe wzdłuż osi OX"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/WMVe1NZBrsE",["film-podstawa2","film-rozszerzenie2"],"Przesuwanie funkcji w górę, w dół, w prawo, w lewo | MatFiz24.PL","1","youtube",["Przesunięcie równoległe. Przesunięcie równoległe wzdłuż osi OX","Przesunięcie równoległe wzdłuż osi OY"],"Marek Duda"],
["https://www.youtube.com/embed/yLRIkacEAy0",["film-podstawa2","film-rozszerzenie2"],"Przekształcanie wykresu funkcji - symetria względem osi OX i OY","1","youtube",["Symetria osiowa. Symetria osiowa względem osi OX i OY"],"matspot"],
["https://www.youtube.com/embed/Q-0ylc-dqn8",["film-podstawa2","film-rozszerzenie2"],"Symetria osiowa względem osi OX i OY - definicje, przykłady","1","youtube",["Symetria osiowa. Symetria osiowa względem osi OX i OY"],"matzadanie"],
["https://www.youtube.com/embed/m48o2yHJoDA",["film-podstawa2","film-rozszerzenie2"],"Symetria osiowa w układzie współrzędnych #3 [ Symetria ]","1","youtube",["Symetria osiowa. Symetria osiowa względem osi OX i OY"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/H9i1y9Hdtl4",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa I. Symetria środkowa i osiowa w układzie współrzędnych","1","youtube",["Symetria osiowa. Symetria osiowa względem osi OX i OY"],"Pizza Math"],
["https://www.youtube.com/embed/dPZVFCuQCvI",["film-podstawa2","film-rozszerzenie2"],"Symetria środkowa. Symetria środkowa względem punktu (0,0) - definicje, przykłady","1","youtube",["Symetria środkowa. Symetria środkowa względem punktu (0, 0)"],"matzadanie"],
["https://www.youtube.com/embed/qCqHk3kFGZo",["film-podstawa2","film-rozszerzenie2"],"Symetria środkowa względem punktu 0(0,0)","1","youtube",["Symetria środkowa. Symetria środkowa względem punktu (0, 0)"],"Paweł Malarczyk"],
["https://www.youtube.com/embed/4S0vLLSkcBs",["film-podstawa2","film-rozszerzenie2"],"Symetria środkowa w układzie współrzędnych #6 [ Symetria ]","1","youtube",["Symetria środkowa. Symetria środkowa względem punktu (0, 0)"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/2nDHxsXrebw",["film-rozszerzenie2"],"WYKRESY FUNKCJI |f(x)| i f(|x|)","1","youtube",["Wykres funkcji y = |f(x)| oraz y = f(|x|)"],"Wzorowanie"],
["https://www.youtube.com/embed/s7-qOjZ4ewc",["film-rozszerzenie2"],"Liceum. Klasa I. Wykres funkcji y =|f(x)| oraz y = f(|x|)","1","youtube",["Wykres funkcji y = |f(x)| oraz y = f(|x|)"],"Pizza Math"],
["https://www.youtube.com/embed/PMUfNmqtXoI",["film-rozszerzenie2"],"POWINOWACTWO PROSTOKĄTNE ","1","youtube",["Wykres funkcji y = k * f(x) oraz y = f(k * x), gdzie k ≠ 0"],"Wzorowanie"],
["https://www.youtube.com/embed/CSUrW9hoZig",["film-rozszerzenie2"],"Liceum. Klasa I. Powinowactwo prostokątne o osi OX i osi OY","1","youtube",["Wykres funkcji y = k * f(x) oraz y = f(k * x), gdzie k ≠ 0"],"Pizza Math"],
["https://www.youtube.com/embed/5yvD8JU6E8s",["film-rozszerzenie2"],"Liceum. Klasa I. Powinowactwo prostokątne","1","youtube",["Wykres funkcji y = k * f(x) oraz y = f(k * x), gdzie k ≠ 0"],"mat kwadrat"],
["https://www.youtube.com/embed/_-JGp4xiHiA",["film-rozszerzenie2"],"Liceum. Klasa I. Szkicowanie wykresów funkcji ","1","youtube",["Szkicowanie wykresów wybranych funkcji"],"Pizza Math"],
["https://www.youtube.com/embed/Z1eLKnrDP1o",["film-rozszerzenie2"],"Przekształcanie wykresów funkcji - kurs","1","youtube",["Szkicowanie wykresów wybranych funkcji"],"MiedzianyFsor"],
["https://www.youtube.com/embed/KPOFrG8HOwo",["film-podstawa2","film-rozszerzenie2"],"Zastosowanje wykresów funkcji do rozwiązywania równań i nierówności Zadanie 1","1","youtube",["Zastosowanie wykresów funkcji do rozwiązywania równań i nierówności"],"Wzorowanie"],
["https://www.youtube.com/embed/iSzPGTqsio0",["film-podstawa2","film-rozszerzenie2"],"Zastosowanje wykresów funkcji do rozwiązywania równań i nierówności Zadanie 2","1","youtube",["Zastosowanie wykresów funkcji do rozwiązywania równań i nierówności"],"matzadanie"],
["https://www.youtube.com/embed/GA_uZ8UnKaA",["film-podstawa2","film-rozszerzenie2"],"Zastosowanje wykresów funkcji do rozwiązywania równań i nierówności Zadanie 3","1","youtube",["Zastosowanie wykresów funkcji do rozwiązywania równań i nierówności"],"matzadanie"],
["https://www.youtube.com/embed/dDxdyB9bgFM",["film-rozszerzenie2"],"Nierówności z wartością bezwzględną #1 - podstawy","2","youtube",["Nierówności z wartością bezwzględną"],"matspot"],
["https://www.youtube.com/embed/_toUaYoZ6do",["film-rozszerzenie2"],"Nierówności z wartością bezwzględną #2","2","youtube",["Nierówności z wartością bezwzględną"],"matspot"],
["https://www.youtube.com/embed/UBqWHi5p8IQ",["film-rozszerzenie2"],"Nierówności z wartością bezwzględną - rozszerzenie","2","youtube",["Nierówności z wartością bezwzględną"],"matspot"],
["https://www.youtube.com/embed/tIQ3q_oB-3A",["film-rozszerzenie2"],"Nierówności z wartością bezwzględną - metoda algebraiczna #4 [ Wartość bezwzględna ]","2","youtube",["Nierówności z wartością bezwzględną"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/cPq-9EyJBMs",["film-rozszerzenie2"],"Liceum. Klasa II. Funkcja liniowa. Nierówności z wartością bezwzględną","2","youtube",["Nierówności z wartością bezwzględną"],"Pizza Math"],
["https://www.youtube.com/embed/EBlbgeaEzjo",["film-rozszerzenie2"],"Równania i nierówności z wartością bezwzględną MATURA KURS ROZSZERZENIE Liczby rzeczywiste cz.2","2","youtube",["Nierówności z wartością bezwzględną"],"MiedzianyFsor"],
["https://www.youtube.com/embed/4n0w5A-474A",["film-rozszerzenie2"],"Równania i nierówności z wartością bezwzględną KURS MATURA PODSTAWA Równania i nierówności cz.2","2","youtube",["Nierówności z wartością bezwzględną"],"MiedzianyFsor"],
["https://www.youtube.com/embed/ycRaxHIuBMo",["film-rozszerzenie2"],"RÓWNANIA LINIOWE Z PARAMETREM","2","youtube",["Równanie liniowe z parametrem"],"Wzorowanie"],
["https://www.youtube.com/embed/cxv5Kf2J1X8",["film-rozszerzenie2"],"Liceum. Klasa II. Funkcja liniowa. Równania i nierówności liniowe","2","youtube",["Równanie liniowe z parametrem"],"Pizza Math"],
["https://www.youtube.com/embed/ycRaxHIuBMo",["film-rozszerzenie2"],"RÓWNANIA LINIOWE Z PARAMETREM","2","youtube",["Równanie liniowe z parametrem","Nierówność liniowa z parametrem"],"Wzorowanie"],
["https://www.youtube.com/embed/YMPX9DZYurY",["film-rozszerzenie2"],"Bezwzględne równanie z parametrem MATURA 2022 KURS ROZSZERZENIE Liczby rzeczywiste cz.3","2","youtube",["Równanie liniowe z parametrem"],"MiedzianyFsor"],
["https://www.youtube.com/embed/6Fy6uYq__Fw",["film-rozszerzenie2"],"Funkcja liniowa na poziomie rozszerzonym","2","youtube",["Równanie liniowe z parametrem"],"AjkaMat"],
["https://www.youtube.com/embed/ycRaxHIuBMo",["film-rozszerzenie2"],"RÓWNANIA LINIOWE Z PARAMETREM","2","youtube",["Równanie liniowe z parametrem"],"Wzorowanie"],
["https://www.youtube.com/embed/Viz-6jzKM5A",["film-rozszerzenie2"],"Nierówność liniowa z parametrem","2","youtube",["Nierówność liniowa z parametrem"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/llJ9JDPLWdE",["film-rozszerzenie2"],"Nierówność liniowa z parametrem","2","youtube",["Nierówność liniowa z parametrem"],"mat kwadrat"],
["https://www.youtube.com/embed/ZHfNEkBcmPA",["film-rozszerzenie2"],"Układy równań - metoda podstawiania","2","youtube",["Układy równań pierwszego stopnia z dwiema niewiadomymi z parametrem"],"matspot"],
["https://www.youtube.com/embed/sjQkMGTJeuQ",["film-rozszerzenie2"],"Układy równań metoda przeciwnych współczynników","2","youtube",["Układy równań pierwszego stopnia z dwiema niewiadomymi z parametrem"],"matspot"],
["https://www.youtube.com/embed/Fciu9Z4zaBg",["film-rozszerzenie2","film-podstawa2"],"Wartość bezwzględna liczby rzeczywistej - definicja, przykłady","2","youtube",["Wartość bezwzględna liczby rzeczywistej"],"matzadanie"],
["https://www.youtube.com/embed/Yf5xVafSdZE",["film-rozszerzenie2","film-podstawa2"],"Wartość bezwzględna - w prostych słowach","2","youtube",["Wartość bezwzględna liczby rzeczywistej"],"PATOMATMA"],
["https://www.youtube.com/embed/JR-QyonxHEk",["film-rozszerzenie2","film-podstawa2"],"WARTOŚĆ BEZWZGLĘDNA LEKCJE Z FSOREM #4","2","youtube",["Wartość bezwzględna liczby rzeczywistej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/akA5JqF5H5M",["film-rozszerzenie2","film-podstawa2"],"Odległość między liczbami na osi liczbowej. Geometryczna interpretacja wartości bezwzględnej na osi liczbowej - definicja, przykłady","2","youtube",["Odległość między liczbami na osi liczbowej. Geometryczna interpretacja wartości bezwzględnej na osi liczbowej"],"matzadanie"],
["https://www.youtube.com/embed/_ssCGvQ--4w",["film-rozszerzenie2","film-podstawa2"],"Wartość bezwzględna liczby, odległość dwóch ","2","youtube",["Odległość między liczbami na osi liczbowej. Geometryczna interpretacja wartości bezwzględnej na osi liczbowej"],"mat kwadrat"],
["https://www.youtube.com/embed/K23mfBpdBak",["film-rozszerzenie2","film-podstawa2"],"Geometryczna interpretacja wartości bezwględnej na osi liczbowej Zadanie 1","2","youtube",["Odległość między liczbami na osi liczbowej. Geometryczna interpretacja wartości bezwzględnej na osi liczbowej"],"matzadanie"],
["https://www.youtube.com/embed/z0X4oIwVkCw",["film-rozszerzenie2","film-podstawa2"],"Proste równania z wartością bezwzględną LEKCJE Z FSOREM #80","2","youtube",["Proste równania z wartością bezwzględną"],"MiedzianyFsor"],
["https://www.youtube.com/embed/o79kk14Xays",["film-rozszerzenie2","film-podstawa2"],"Proste równania z wartością bezwzględną - definicja, przykłady","2","youtube",["Proste równania z wartością bezwzględną"],"matzadanie"],
["https://www.youtube.com/embed/oXB1dUTSdnQ",["film-rozszerzenie2","film-podstawa2"],"Proste równania z wartością bezwzględną Zadanie 1, przykłady","2","youtube",["Proste równania z wartością bezwzględną"],"matzadanie"],
["https://www.youtube.com/embed/wEOW8VdyRX4",["film-rozszerzenie2","film-podstawa2"],"Proste równania z wartością bezwzględną Zadanie 2, przykłady","2","youtube",["Proste równania z wartością bezwzględną"],"matzadanie"],
["https://www.youtube.com/embed/Sgd0N_PHo-E",["film-rozszerzenie2","film-podstawa2"],"Proste równania z wartością bezwzględną Zadanie 3, przykłady","2","youtube",["Proste równania z wartością bezwzględną"],"matzadanie"],
["https://www.youtube.com/embed/Hiyoh-YYwiI",["film-rozszerzenie2","film-podstawa2"],"Proste nierówności z wartością bezwzględną LEKCJE Z FSOREM #81","2","youtube",["Proste nierówności z wartością bezwzględną"],"MiedzianyFsor"],
["https://www.youtube.com/embed/dDxdyB9bgFM",["film-rozszerzenie2","film-podstawa2"],"Nierówności z wartością bezwzględną #1 - podstawy","2","youtube",["Proste nierówności z wartością bezwzględną"],"matspot"],
["https://www.youtube.com/embed/MI9A3bx7_0k",["film-rozszerzenie2","film-podstawa2"],"Proste nierówności z wartością bezwzględną - definicje, przykłady","2","youtube",["Proste nierówności z wartością bezwzględną"],"matzadanie"],
["https://www.youtube.com/embed/hjVaF8wLjBo",["film-rozszerzenie2","film-podstawa2"],"Proste nierówności z wartością bezwzględną Zadanie 1","2","youtube",["Proste nierówności z wartością bezwzględną"],"matzadanie"],
["https://www.youtube.com/embed/uCU58J2aVoU",["film-rozszerzenie2","film-podstawa2"],"Proste nierówności z wartością bezwzględną Zadanie 2","2","youtube",["Proste nierówności z wartością bezwzględną"],"matzadanie"],
["https://www.youtube.com/embed/uMRFEP8yQTg",["film-rozszerzenie2"],"Własności wartości bezwzględnej LEKCJE Z FSOREM #82 ROZSZERZENIE","2","youtube",["Własności wartości bezwzględnej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/ByV7VITIEzA",["film-rozszerzenie2"],"Matematyka - Własności wartości bezwzględnej","2","youtube",["Własności wartości bezwzględnej"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/R3um24WEGvU",["film-rozszerzenie2"],"Wartość bezwzględna na ROZSZERZENIU: Równania i nierówności z JEDNYM modułem","2","youtube",["Równania z wartością bezwzględną","Nierówności z wartością bezwzględną"],"A po co MI ta MATMA?"],
["https://www.youtube.com/embed/JWYCRqjReDo",["film-rozszerzenie2"],"Wartość bezwzględna na ROZSZERZENIU: Równania i nierówności z DWOMA modułami","2","youtube",["Równania z wartością bezwzględną","Nierówności z wartością bezwzględną"],"A po co MI ta MATMA?"],
["https://www.youtube.com/embed/SvhWh9DeHI4",["film-podstawa1","film-rozszerzenie1"],"Działania na zbiorach","1","youtube",["Zbiór. Działania na zbiorach"],"matspot"],
["https://www.youtube.com/embed/Z7qMR5ay8Zg",["film-podstawa1","film-rozszerzenie1"],"Szybko i na temat. Zbiory + Zadania.","1","youtube",["Zbiór. Działania na zbiorach"],"PATOMATMA"],
["https://www.youtube.com/embed/g0Rk_OTvb_o",["film-podstawa1","film-rozszerzenie1"],"ZBIORY LICZBOWE I OŚ LICZBOWA - wszystko co musisz wiedzieć.","1","youtube",["Zbiory liczbowe. Oś liczbowa"],"Wzorowanie"],
["https://www.youtube.com/embed/QE7QM00MSVw",["film-podstawa1","film-rozszerzenie1"],"Liceum.Klasa 1.Zbiory liczbowe.","1","youtube",["Zbiory liczbowe. Oś liczbowa"],"Pizza Math"],
["https://www.youtube.com/embed/oC0k1mE5wIU",["film-podstawa1","film-rozszerzenie1"],"PRAWA DZIAŁAŃ W ZBIORZE LICZB RZECZYWISTYCH LEKCJE Z FSOREM #5","1","youtube",["Prawa działań w zbiorze liczb rzeczywistych"],"MiedzianyFsor"],
["https://www.youtube.com/embed/s_LHjcTJAZk",["film-podstawa1","film-rozszerzenie1"],"JAKIE SĄ PRAWA W ZBIORZE LICZB RZECZYWISTYCH?","1","youtube",["Prawa działań w zbiorze liczb rzeczywistych"],"Wzorowanie"],
["https://www.youtube.com/embed/d47BJ23E8no",["film-podstawa1","film-rozszerzenie1"],"Liceum.Klasa1.Prawa działań","1","youtube",["Prawa działań w zbiorze liczb rzeczywistych"],"Pizza Math"],
["https://www.youtube.com/embed/Hux9kRFSY4Q",["film-podstawa1","film-rozszerzenie1"],"PRZEDZIAŁY","1","youtube",["Przedziały"],"MatWujek"],
["https://www.youtube.com/embed/BfTA083UR_E",["film-podstawa1","film-rozszerzenie1"],"Liceum. Klasa 1. Przedziały","1","youtube",["Przedziały"],"Pizza Math"],
["https://www.youtube.com/embed/LYSa0o1Tvhg",["film-podstawa1","film-rozszerzenie1"],"Przedziały liczbowe","1","youtube",["Przedziały"],"matspot"],
["https://www.youtube.com/embed/UQWZZ4ZlKjU",["film-podstawa1","film-rozszerzenie1"],"Działania na przedziałach","1","youtube",["Przedziały"],"matspot"],
["https://www.youtube.com/embed/pIDE6w9E2wE",["film-podstawa1","film-rozszerzenie1"],"PRZEDZIAŁY #4","1","youtube",["Przedziały"],"Wzorowanie"],
["https://www.youtube.com/embed/YxcohFIKyxA",["film-podstawa1","film-rozszerzenie1"],"ZBIÓR LICZB CAŁKOWITYCH I NATURALNYCH(przyda Ci się)","1","youtube",["Zbiór liczb naturalnych i zbiór liczb całkowitych"],"Wzorowanie"],
["https://www.youtube.com/embed/D77eRaqo6p8",["film-podstawa1","film-rozszerzenie1"],"Zbiory liczb naturalnych i całkowitych #3","1","youtube",["Zbiór liczb naturalnych i zbiór liczb całkowitych"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/y0_Z8GVtFmc",["film-podstawa1","film-rozszerzenie1"],"Działania na zbiorach liczbowych","1","youtube",["Zbiór liczb naturalnych i całkowitych"],"AjkaMat"],
["https://www.youtube.com/embed/CbirayNz4Bk",["film-podstawa1","film-rozszerzenie1"],"rozwiązywanie równań poziom 1","1","youtube",["Przypomnienie i uzupełnienie wiadomości o równaniach","Rozwiązywanie równań metodą równań równoważnych"],"matspot"],
["https://www.youtube.com/embed/S-IhtWiBbTw",["film-podstawa1","film-rozszerzenie1"],"Równania sprzeczne i tożsamościowe","1","youtube",["Przypomnienie i uzupełnienie wiadomości o równaniach","Rozwiązywanie równań metodą równań równoważnych"],"matspot"],
["https://www.youtube.com/embed/myxNBzuu1lw",["film-podstawa1","film-rozszerzenie1"],"rozwiązywanie równań poziom 3- przykłady z ułamkami","1","youtube",["Przypomnienie i uzupełnienie wiadomości o równaniach","Rozwiązywanie równań metodą równań równoważnych"],"matspot"],
["https://www.youtube.com/embed/1mdGC342mHk",["film-podstawa1","film-rozszerzenie1"],"Obliczanie NWW i NWD + zadania tekstowe","1","youtube",["Przypomnienie i uzupełnienie wiadomości o równaniach"],"matspot"],
["https://www.youtube.com/embed/-MtRHUwIw5w",["film-podstawa1","film-rozszerzenie1"],"JAK ROZWIĄZAĆ RÓWNANIA (liniowe)","1","youtube",["Przypomnienie i uzupełnienie wiadomości o równaniach","Rozwiązywanie równań metodą równań równoważnych"],"Wzorowanie"],
["https://www.youtube.com/embed/wbOAr-QIVbI",["film-podstawa1","film-rozszerzenie1"],"JAK ROZWIĄZYWAĆ RÓWNANIA RÓWNOWAŻNE?","1","youtube",["Rozwiązywanie równań metodą równań równoważnych"],"Wzorowanie"],
["https://www.youtube.com/embed/JWmei9ad1WM",["film-podstawa1","film-rozszerzenie1"],"ROZWIĄZYWANIE NIERÓWNOŚCI Z JEDNĄ NIEWIADOMĄ","1","youtube",["Nierówność z jedną niewiadomą. Rozwiązywanie nierówności metodą nierówności równoważnych"],"Wzorowanie"],
["https://www.youtube.com/embed/dKZkTnEXVyA",["film-podstawa1","film-rozszerzenie1"],"Niezbędnik Maturzysty #4 Nierówności","1","youtube",["Nierówność z jedną niewiadomą. Rozwiązywanie nierówności metodą nierówności równoważnych"],"matspot"],
["https://www.youtube.com/embed/z07xfp-Yd8s",["film-podstawa1","film-rozszerzenie1"],"Matura 2020 zadanie 5. Nierówność ale nie ta społeczna","1","youtube",["Nierówność z jedną niewiadomą. Rozwiązywanie nierówności metodą nierówności równoważnych"],"PATOMATMA"],
["https://www.youtube.com/embed/Iag3pNZkfXI",["film-podstawa1","film-rozszerzenie1"],"Równania, nierówności, działania na zbiorach i przedziałach","1","youtube",["Nierówność z jedną niewiadomą. Rozwiązywanie nierówności metodą nierówności równoważnych"],"AjkaMat"],
["https://www.youtube.com/embed/s1ciAwE2FwE",["film-podstawa1","film-rozszerzenie1"],"Obliczenia procentowe","1","youtube",["Procenty"],"matspot"],
["https://www.youtube.com/embed/2XCJdr5LvHw",["film-podstawa1","film-rozszerzenie1"],"Procenty na przykładzie procentów","1","youtube",["Procenty"],"PATOMATMA"],
["https://www.youtube.com/embed/decCqM9MqE0",["film-podstawa1","film-rozszerzenie1"],"PROCENTY","1","youtube",["Procenty"],"Wzorowanie"],
["https://www.youtube.com/embed/FzP1CMS-4LE",["film-podstawa1","film-rozszerzenie1"],"Zadanie maturalne- procenty a punkty procentowe","1","youtube",["Punkty procentowe"],"matspot"],
["https://www.youtube.com/embed/Y4F4ScNJ9Qo",["film-podstawa1","film-rozszerzenie1"],"Liceum.Klasa I. Procenty i punkty procentowe","1","youtube",["Punkty procentowe"],"Pizza Math"],
["https://www.youtube.com/embed/oqb0yu3OCW4",["film-podstawa1","film-rozszerzenie1"],"PUNKTY PROCENTOWE - co musisz wiedzieć","1","youtube",["Punkty procentowe"],"Wzorowanie"],
["https://www.youtube.com/embed/yZnqypkUqDA",["film-podstawa1","film-rozszerzenie1"],"Liceum.Klasa I. Błąd względny i bezwzględny","1","youtube",["Przybliżenia, błąd bezwzględny i błąd względny, szacowanie"],"Pizza Math"],
["https://www.youtube.com/embed/i9FT3VyeOBc",["film-podstawa1","film-rozszerzenie1"],"PRZYBLIŻENIE I SZACOWANIE","1","youtube",["Przybliżenia, błąd bezwzględny i błąd względny, szacowanie"],"Wzorowanie"],
["https://www.youtube.com/embed/ByLFgCWT5po",["film-podstawa1","film-rozszerzenie1"],"POTĘGA O WYKŁADNIKU NATURALNYM","2","youtube",["Potęga o wykładniku naturalnym"],"Wzorowanie"],
["https://www.youtube.com/embed/cKoLn_EELrM",["film-podstawa1","film-rozszerzenie1"],"Pojęcie potęgi #1 [ Potęgi o wykładniku naturalnym ]","2","youtube",["Potęga o wykładniku naturalnym"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/j8ioOqkaVn4",["film-podstawa1","film-rozszerzenie1"]," Potęgowanie ułamków #2 [ Potęgi o wykładniku naturalnym ]","2","youtube",["Potęga o wykładniku naturalnym"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/J0r7extoQN0",["film-podstawa1","film-rozszerzenie1"],"Potęga potęgi #6 [ Potęgi o wykładniku naturalnym ]","2","youtube",["Potęga o wykładniku naturalnym"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/XxYHD8va23o",["film-podstawa1","film-rozszerzenie1"]," Działania na potęgach o wykładniku naturalnym #12 [ Potęgi o wykładniku naturalnym ]","2","youtube",["Potęga o wykładniku naturalnym"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/RLvWy3p_-7k",["film-podstawa1","film-rozszerzenie1"],"Potęga o wykładniku naturalnym część 1 WSTĘP LEKCJE Z FSOREM #14","2","youtube",["Potęga o wykładniku naturalnym"],"MiedzianyFsor"],
["https://www.youtube.com/embed/HNKoD6txmXM",["film-podstawa1","film-rozszerzenie1"]," Potęga o wykładniku naturalnym część 2 ZADANKA LEKCJE Z FSOREM #15","2","youtube",["Potęga o wykładniku naturalnym"],"MiedzianyFsor"],
["https://www.youtube.com/embed/WonRDbTDyKo",["film-podstawa1","film-rozszerzenie1"],"DZIAŁANIA NA PIERWIASTKACH [#14]","2","youtube",["Pierwiastek arytmetyczny. Pierwiastek stopnia nieparzystego z liczby ujemnej"],"Wzorowanie"],
["https://www.youtube.com/embed/ct_T7suAO8E",["film-podstawa1","film-rozszerzenie1"],"własności pierwiastków powtórzenie część 1","2","youtube",["Pierwiastek arytmetyczny. Pierwiastek stopnia nieparzystego z liczby ujemnej"],"matspot"],
["https://www.youtube.com/embed/czlNDea2iWE",["film-podstawa1","film-rozszerzenie1"],"własności pierwiastków część 2","2","youtube",["Pierwiastek arytmetyczny. Pierwiastek stopnia nieparzystego z liczby ujemnej"],"matspot"],
["https://www.youtube.com/embed/7TEcnG-E28A",["film-podstawa1","film-rozszerzenie1"],"Działania na pierwiastkach wyższych stopni #2 [Potęgi i pierwiastki]","2","youtube",["Pierwiastek arytmetyczny. Pierwiastek stopnia nieparzystego z liczby ujemnej"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/gWV-PMujzI4",["film-podstawa1","film-rozszerzenie1"],"Liceum.Klasa.I.Potęgi i pierwiastki","2","youtube",["Pierwiastek arytmetyczny. Pierwiastek stopnia nieparzystego z liczby ujemnej"],"Pizza Math"],
["https://www.youtube.com/embed/Cq09pCwAa1Q",["film-podstawa1","film-rozszerzenie1"],"Pierwiastek arytmetyczny stopnia parzystego i nieparzystego LEKCJE Z FSOREM #16","2","youtube",["Pierwiastek arytmetyczny. Pierwiastek stopnia nieparzystego z liczby ujemnej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/oT8Dkkj_Duc",["film-podstawa1","film-rozszerzenie1"],"DZIAŁANIA NA WYRAŻENIACH ALGEBRAICZNYCH #14","2","youtube",["Działania na wyrażeniach algebraicznych"],"Wzorowanie"],
["https://www.youtube.com/embed/Vm4FK_O1OV4",["film-podstawa1","film-rozszerzenie1"],"Wyrażenia algebraiczne - wprowadzenie #1 [ Wyrażenia algebraiczne ]","2","youtube",["Działania na wyrażeniach algebraicznych"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/rQ3eHU0OxuQ",["film-podstawa1","film-rozszerzenie1"],"Wyrażenia algebraiczne - suma różnica, iloczyn, iloraz #2 [ Wyrażenia algebraiczne ]","2","youtube",["Działania na wyrażeniach algebraicznych"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/nysijB3-IgA",["film-podstawa1","film-rozszerzenie1"],"Wyrażenia algebraiczne - nazywanie i odczytywanie #3 [ Wyrażenia algebraiczne ]","2","youtube",["Działania na wyrażeniach algebraicznych"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/IPgy5oW7Ztw",["film-podstawa1","film-rozszerzenie1"],"Wyrażenia algebraiczne- część I","2","youtube",["Działania na wyrażeniach algebraicznych"],"AjkaMat"],
["https://www.youtube.com/embed/VHMnzpMaahk",["film-podstawa1","film-rozszerzenie1"],"WZORY SKRÓCONEGO MNOŻENIA [#15]","2","youtube",["Wzory skróconego mnożenia stopnia 2"],"Wzorowanie"],
["https://www.youtube.com/embed/l2dUrcYVofs",["film-podstawa1","film-rozszerzenie1"],"wzory skróconego mnożenia #1","2","youtube",["Wzory skróconego mnożenia stopnia 2"],"matspot"],
["https://www.youtube.com/embed/luDwfx-24UQ",["film-podstawa1","film-rozszerzenie1"],"wzory skróconego mnożenia #3","2","youtube",["Wzory skróconego mnożenia stopnia 2"],"matspot"],
["https://www.youtube.com/embed/NZ4eldiDuFY",["film-podstawa1","film-rozszerzenie1"],"wzory skróconego mnożenia #4","2","youtube",["Wzory skróconego mnożenia stopnia 2"],"matspot"],
["https://www.youtube.com/embed/QXMyt0HMYds",["film-podstawa1","film-rozszerzenie1"],"POTĘGA O WYKŁADNIKU CAŁKOWITYM UJEMNYM [#16]","2","youtube",["Potęga o wykładniku całkowitym ujemnym"],"Wzorowanie"],
["https://www.youtube.com/embed/IHfclHCfZ-I",["film-podstawa1","film-rozszerzenie1"],"Potęga o wykładniku całkowitym ujemnym","2","youtube",["Potęga o wykładniku całkowitym ujemnym"],"matspot"],
["https://www.youtube.com/embed/E4N4tk-JegI",["film-podstawa1","film-rozszerzenie1"],"Potęga o wykładniku całkowitym ujemnym ZADANKA Z FSOREM #21","2","youtube",["Potęga o wykładniku całkowitym ujemnym"],"MiedzianyFsor"],
["https://www.youtube.com/embed/XNRpiSxOP_4",["film-podstawa1","film-rozszerzenie1"],"POTĘGA O WYKŁADNIKU WYMIERNYM [#17]","2","youtube",["Potęga o wykładniku wymiernym"],"Wzorowanie"],
["https://www.youtube.com/embed/-kc52o2gD4Y",["film-podstawa1","film-rozszerzenie1"],"Potęga o wykładniku wymiernym","2","youtube",["Potęga o wykładniku wymiernym"],"matspot"],
["https://www.youtube.com/embed/MI4DFB6yw7M",["film-podstawa1","film-rozszerzenie1"],"Potęga o wykładniku wymiernym LEKCJE Z FSOREM #22","2","youtube",["Potęga o wykładniku wymiernym"],"MiedzianyFsor"],
["https://www.youtube.com/embed/rbQZJV736XE",["film-podstawa1","film-rozszerzenie1"],"Działania na potęgach o wykładniku wymiernym #4 [Potęgi i pierwiastki]","2","youtube",["Potęga o wykładniku wymiernym"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/YhrxtN1cpro",["film-podstawa1","film-rozszerzenie1"],"POTĘGA RZECZYWISTA - to musisz wiedzieć","2","youtube",["Potęga o wykładniku rzeczywistym"],"Wzorowanie"],
["https://www.youtube.com/embed/ClKPtUxFybY",["film-podstawa1","film-rozszerzenie1"],"OBLICZANIE LOGARYTMÓW #19","2","youtube",["Określenie logarytmu","Zastosowanie logarytmów"],"Wzorowanie"],
["https://www.youtube.com/embed/K3OkbCo-Xew",["film-podstawa1","film-rozszerzenie1"],"Obliczanie logarytmów- podstawy","2","youtube",["Określenie logarytmu","Zastosowanie logarytmów"],"matspot"],
["https://www.youtube.com/embed/BvMBskQrRqs",["film-podstawa1","film-rozszerzenie1"],"Obliczanie logarytmów #2","2","youtube",["Określenie logarytmu","Zastosowanie logarytmów"],"matspot"],
["https://www.youtube.com/embed/bUPcSqLBRMQ",["film-podstawa1","film-rozszerzenie1"],"Własności logarytmów","2","youtube",["Określenie logarytmu","Zastosowanie logarytmów"],"matspot"],
["https://www.youtube.com/embed/LkjNsFxt9us",["film-podstawa1","film-rozszerzenie1"],"Własności logarytmów #2","2","youtube",["Określenie logarytmu","Zastosowanie logarytmów"],"matspot"],
["https://www.youtube.com/embed/dekaP1GmmQE",["film-podstawa1","film-rozszerzenie1"],"Definicja logarytmu #1 [Logarytmy]","2","youtube",["Określenie logarytmu","Zastosowanie logarytmów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/HtXEd0OWCFo",["film-podstawa1","film-rozszerzenie1"],"Dodawanie i odejmowanie logarytmów #2 [Logarytmy]","2","youtube",["Określenie logarytmu","Zastosowanie logarytmów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/gXvrjyM0mpU",["film-podstawa1","film-rozszerzenie1"],"Logarytm potęgi #3 [Logarytmy]","2","youtube",["Określenie logarytmu","Zastosowanie logarytmów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/xfkjvB66Ex0",["film-podstawa1","film-rozszerzenie1"],"Logarytm w wykładniku potęgi #4 [Logarytmy]","2","youtube",["Określenie logarytmu","Zastosowanie logarytmów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/ACrRHluVm_o",["film-podstawa1","film-rozszerzenie1"],"Zmiana podstawy logarytmu #5 [Logarytmy]","2","youtube",["Określenie logarytmu","Zastosowanie logarytmów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/VANUPgaNSPA",["film-podstawa1","film-rozszerzenie1"],"Działania na logarytmach #6 [Logarytmy]","2","youtube",["Określenie logarytmu","Zastosowanie logarytmów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/0Yb67WnLd1I",["film-podstawa1","film-rozszerzenie1"],"Proste równania logarytmiczne #7 [Logarytmy]","2","youtube",["Określenie logarytmu","Zastosowanie logarytmów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/1euY8qqQB0Q",["film-podstawa1","film-rozszerzenie1"],"Liceum.Klasa.I.Logarytmy","2","youtube",["Określenie logarytmu","Zastosowanie logarytmów"],"Pizza Math"],
["https://www.youtube.com/embed/uojszTHc-Ug",["film-podstawa1","film-rozszerzenie1"],"Logarytmy prawie od zera odc.1 Zadania (Udostępnij na grupie klasowej)","2","youtube",["Określenie logarytmu","Zastosowanie logarytmów"],"PATOMATMA"],
["https://www.youtube.com/embed/3doMk6eSaNw",["film-podstawa1","film-rozszerzenie1"],"ZDANIA LOGICZNE W MATEMATYCE #20","2","youtube",["Zdanie. Zaprzeczenie zdania"],"Wzorowanie"],
["https://www.youtube.com/embed/eXJD9cHcgD4",["film-podstawa1","film-rozszerzenie1"],"Liceum. Klasa I. Zdania","2","youtube",["Zdanie. Zaprzeczenie zdania"],"Pizza Math"],
["https://www.youtube.com/embed/vyjnc4fHtUI",["film-podstawa1","film-rozszerzenie1"],"Liceum. Klasa I. Kwantyfikatory","2","youtube",["Zdanie. Zaprzeczenie zdania"],"Pizza Math"],
["https://www.youtube.com/embed/NnqeawOqGlI",["film-podstawa1","film-rozszerzenie1"],"ZDANIA ZŁOŻONE Z MATEMATYKI [#21]","2","youtube",["Zdania złożone. Zaprzeczenia zdań złożonych"],"Wzorowanie"],
["https://www.youtube.com/embed/1-l5V8STyjE",["film-podstawa1","film-rozszerzenie1"],"Liceum.Klasa I. Zdania złożone","2","youtube",["Zdania złożone. Zaprzeczenia zdań złożonych"],"Pizza Math"],
["https://www.youtube.com/embed/N35gKqBdsRA",["film-podstawa1","film-rozszerzenie1"],"DOWODY I TWIERDZENIA W MATEMATYCE","2","youtube",["Definicja. Twierdzenie. Dowód twierdzenia"],"Wzorowanie"],
["https://www.youtube.com/embed/o7bAaI1Fhxo",["film-podstawa1","film-rozszerzenie1"],"Dowodzenie nierówności #1","2","youtube",["Definicja. Twierdzenie. Dowód twierdzenia"],"matspot"],
["https://www.youtube.com/embed/nHUJSncnPu0",["film-podstawa1","film-rozszerzenie1"],"Dowodzenie nierówności #2","2","youtube",["Definicja. Twierdzenie. Dowód twierdzenia"],"matspot"],
["https://www.youtube.com/embed/SVEYxWjSOQU",["film-podstawa1","film-rozszerzenie1"],"Dowodzenie nierówności #3","2","youtube",["Definicja. Twierdzenie. Dowód twierdzenia"],"matspot"],
["https://www.youtube.com/embed/LeH8NfKbzLw",["film-podstawa1","film-rozszerzenie1"],"Dowodzenie nierówności #4","2","youtube",["Definicja. Twierdzenie. Dowód twierdzenia"],"matspot"],
["https://www.youtube.com/embed/uP-fC_2zY8U",["film-podstawa1","film-rozszerzenie1"],"MATURA MATEMATYKA Dowody algebraiczne Zadania PEWNIAK","2","youtube",["Definicja. Twierdzenie. Dowód twierdzenia"],"MiedzianyFsor"],
["https://www.youtube.com/embed/IYZwcMqXajU",["film-podstawa1","film-rozszerzenie1"],"PRZEKSZTAŁCANIE WZORÓW [#23]","2","youtube",["Przekształcanie wzorów"],"Wzorowanie"],
["https://www.youtube.com/embed/zXk4QJGImQs",["film-podstawa1","film-rozszerzenie1"],"przekształcanie wzorów część 1","2","youtube",["Przekształcanie wzorów"],"matspot"],
["https://www.youtube.com/embed/tMN6aEsOLDo",["film-podstawa1","film-rozszerzenie1"],"przekształcanie wzorów część 2","2","youtube",["Przekształcanie wzorów"],"matspot"],
["https://www.youtube.com/embed/qaCVyvlWn5I",["film-podstawa1","film-rozszerzenie1"],"Przekształcanie wzorów #9 [ Równania ]","2","youtube",["Przekształcanie wzorów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/XjC_rvfVjy4",["film-podstawa1","film-rozszerzenie1"],"Przekształcanie wzorów LEKCJE Z FSOREM #25","2","youtube",["Przekształcanie wzorów"],"MiedzianyFsor"],
["https://www.youtube.com/embed/Fy3GWyJzjJU",["film-podstawa1","film-rozszerzenie1"],"ŚREDNIE W MATEMATYCE [#24]","2","youtube",["Średnie"],"Wzorowanie"],
["https://www.youtube.com/embed/8r9Vnk1yyBI",["film-podstawa1","film-rozszerzenie1"],"CZYM SĄ FUNKCJE W MATEMATYCE? [#25]","3","youtube",["Pojęcie funkcji. Funkcja liczbowa. Sposoby opisywania funkcji"],"Wzorowanie"],
["https://www.youtube.com/embed/9JDTeWzOdXs",["film-podstawa1","film-rozszerzenie1"],"funkcje powtórzenie","3","youtube",["Pojęcie funkcji. Funkcja liczbowa. Sposoby opisywania funkcji"],"matspot"],
["https://www.youtube.com/embed/wGKEoOVUMw4",["film-podstawa1","film-rozszerzenie1"],"Funkcje w 6 minut","3","youtube",["Pojęcie funkcji. Funkcja liczbowa. Sposoby opisywania funkcji","Wykres funkcji"],"EZMATMA"],
["https://www.youtube.com/embed/PY6KCFwVJ0E",["film-podstawa1","film-rozszerzenie1"],"Co to jest funkcja #1 [ FUNKCJA ]","3","youtube",["Pojęcie funkcji. Funkcja liczbowa. Sposoby opisywania funkcji"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/U2cq3ajIZro",["film-podstawa1","film-rozszerzenie1"],"Liceum. Klasa I. Wstęp do funkcji","3","youtube",["Pojęcie funkcji. Funkcja liczbowa. Sposoby opisywania funkcji"],"Pizza Math"],
["https://www.youtube.com/embed/fheOaJ8YfyE",["film-podstawa1","film-rozszerzenie1"],"Funkcja co to takiego? Wstęp, definicja, podstawowe pojęcia LEKCJA Z FSOREM #29","3","youtube",["Pojęcie funkcji. Funkcja liczbowa. Sposoby opisywania funkcji"],"MiedzianyFsor"],
["https://www.youtube.com/embed/mG8BVf7Xu18",["film-podstawa1","film-rozszerzenie1"],"Wykresy funkcji #5 [ Własności funkcji ]","3","youtube",["Wykres funkcji"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/4WQfTf83qY8",["film-podstawa1","film-rozszerzenie1"],"Wykres funkcji LEKCJE Z FSOREM #30","3","youtube",["Wykres funkcji"],"MiedzianyFsor"],
["https://www.youtube.com/embed/807jY8q-YKc",["film-podstawa1","film-rozszerzenie1"],"JAK WYZNACZYĆ DZIEDZINĘ FUNKCJI? [#27]","3","youtube",["Dziedzina funkcji liczbowej"],"Wzorowanie"],
["https://www.youtube.com/embed/aMA2oFxiqsE",["film-podstawa1","film-rozszerzenie1"],"Dziedzina i zbiór wartości funkcji #2 [Własności funkcji]","3","youtube",["Dziedzina funkcji liczbowej","Zbiór wartości funkcji liczbowej. Najmniejsza i największa wartość funkcji"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/RhzTgd97aRs",["film-podstawa1","film-rozszerzenie1"],"Liceum. Klasa I. Dziedzina i zbiór wartości funkcji","3","youtube",["Dziedzina funkcji liczbowej","Zbiór wartości funkcji liczbowej. Najmniejsza i największa wartość funkcji"],"Pizza Math"],
["https://www.youtube.com/embed/2uq1GAgfY1Y",["film-podstawa1","film-rozszerzenie1"],"Odczytywanie dziedziny z wykresu funkcji LEKCJE Z FSOREM #31","3","youtube",["Dziedzina funkcji liczbowej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/yyrF7aE5Kx0",["film-podstawa1","film-rozszerzenie1"],"Wyznaczanie dziedziny na podstawie wzoru funkcji LEKCJE Z FSOREM #32","3","youtube",["Dziedzina funkcji liczbowej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/H0hVYA73E1g",["film-podstawa1","film-rozszerzenie1"],"JAK WYZNACZYĆ ZBIÓR WARTOŚCI FUNKCJI [#28]","3","youtube",["Zbiór wartości funkcji liczbowej. Najmniejsza i największa wartość funkcji"],"Wzorowanie"],
["https://www.youtube.com/embed/ynfC7LkJHE8",["film-podstawa1","film-rozszerzenie1"],"Zbiór wartości funkcji liczbowej LEKCJE Z FSOREM #32","3","youtube",["Zbiór wartości funkcji liczbowej. Najmniejsza i największa wartość funkcji"],"MiedzianyFsor"],
["https://www.youtube.com/embed/U4xtSyfkT_o",["film-podstawa1","film-rozszerzenie1"],"Czym jest dziedzina i zbiór wartości funkcji? Czym jest w ogóle funkcja?","3","youtube",["Dziedzina funkcji liczbowej","Zbiór wartości funkcji liczbowej. Najmniejsza i największa wartość funkcji"],"PATOMATMA"],
["https://www.youtube.com/embed/wWDdh1nNoyk",["film-podstawa1","film-rozszerzenie1"],"Najmniejsza i największa wartość funkcji LEKCJE Z FSOREM #33","3","youtube",["Zbiór wartości funkcji liczbowej. Najmniejsza i największa wartość funkcji"],"MiedzianyFsor"],
["https://www.youtube.com/embed/YZU_Y5SWgBg",["film-podstawa1","film-rozszerzenie1"],"MIEJSCE ZEROWE FUNKCJI [#29]","3","youtube",["Miejsce zerowe funkcji"],"Wzorowanie"],
["https://www.youtube.com/embed/h8vC-Bkr368",["film-podstawa1","film-rozszerzenie1"],"Miejsce zerowe funkcji, wartości dodatnie i ujemne","3","youtube",["Miejsce zerowe funkcji"],"matspot"],
["https://www.youtube.com/embed/o4iYN7ekEEQ",["film-podstawa1","film-rozszerzenie1"],"Miejsce zerowe funkcji #3 [ Własność funkcji ]","3","youtube",["Miejsce zerowe funkcji"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/54woS0NgRS0",["film-rozszerzenie1"],"FUNKCJE RÓWNE [#30]","3","youtube",["Funkcje równe"],"Wzorowanie"],
["https://www.youtube.com/embed/cKXVyrCjOWg",["film-rozszerzenie1"],"Funkcje równe LEKCJE Z FSOREM #35 ROZSZERZENIE","3","youtube",["Funkcje równe"],"MiedzianyFsor"],
["https://www.youtube.com/embed/7VI2ZTZJkg8",["film-podstawa1","film-rozszerzenie1"],"MONOTONICZNOŚĆ FUNKCJI [#31]","3","youtube",["Monotoniczność funkcji"],"Wzorowanie"],
["https://www.youtube.com/embed/S15hChUxw18",["film-podstawa1","film-rozszerzenie1"],"Liceum. Klasa I. Monotoniczność funkcji","3","youtube",["Monotoniczność funkcji"],"Pizza Math"],
["https://www.youtube.com/embed/R4Ix5oLTjG8",["film-podstawa1","film-rozszerzenie1"],"FUNKCJE RÓŻNOWARTOŚCIOWE [#32]","3","youtube",["Funkcje różnowartościowe"],"Wzorowanie"],
["https://www.youtube.com/embed/-gjuSX6N7t8",["film-podstawa1","film-rozszerzenie1"],"Liceum. Klasa I. Funkcje różnowartościowe","3","youtube",["Funkcje różnowartościowe"],"Pizza Math"],
["https://www.youtube.com/embed/LK9rnClslKY",["film-rozszerzenie1"],"FUNKCJA PARZYSTA I NIEPARZYSTA [#33]","3","youtube",["Funkcje różnowartościowe"],"Wzorowanie"],
["https://www.youtube.com/embed/UNOJDN8VT6k",["film-rozszerzenie1"],"Liceum. Klasa I. Funkcja parzysta i nieparzysta","3","youtube",["Funkcje różnowartościowe"],"Pizza Math"],
["https://www.youtube.com/embed/sxI58fIUI4g",["film-podstawa1"],"ODCZYTYWANIE WŁASNOŚCI FUNKCJI [#34]","3","youtube",["Odczytywanie własności funkcji o zadanych własnościach."],"Wzorowanie"],
["https://www.youtube.com/embed/sxI58fIUI4g",["film-rozszerzenie1"],"ODCZYTYWANIE WŁASNOŚCI FUNKCJI [#34]","3","youtube",["Odczytywanie własności funkcji na podstawie jej wykresu. Szkicowanie wykresów funkcji o zadanych własnościach"],"Wzorowanie"],
["https://www.youtube.com/embed/DjWwDQZbfk0",["film-rozszerzenie1"],"JAK SZKICOWAĆ WYKRES FUNKCJI? [#26]","3","youtube",["Odczytywanie własności funkcji na podstawie jej wykresu. Szkicowanie wykresów funkcji o zadanych własnościach"],"Wzorowanie"],
["https://www.youtube.com/embed/URIQNSYSzc4",["film-rozszerzenie1"],"Wykres funkcji - rysowanie #1","3","youtube",["Odczytywanie własności funkcji na podstawie jej wykresu. Szkicowanie wykresów funkcji o zadanych własnościach"],"matspot"],
["https://www.youtube.com/embed/WxV-uymfqbs",["film-rozszerzenie1"],"Wykres funkcji - rysowanie #2","3","youtube",["Odczytywanie własności funkcji na podstawie jej wykresu. Szkicowanie wykresów funkcji o zadanych własnościach"],"matspot"],
["https://www.youtube.com/embed/RqyH_N7sUYA",["film-podstawa1"],"Własności funkcji #1 - odczytywanie z wykresu","3","youtube",["Odczytywanie własności funkcji na podstawie jej wykresu."],"matspot"],
["https://www.youtube.com/embed/RqyH_N7sUYA",["film-rozszerzenie1"],"Własności funkcji #1 - odczytywanie z wykresu","3","youtube",["Odczytywanie własności funkcji na podstawie jej wykresu. Szkicowanie wykresów funkcji o zadanych własnościach"],"matspot"],
["https://www.youtube.com/embed/72SWOd-Vnk4",["film-podstawa1","film-rozszerzenie1"],"Zastosowanie funkcji w życiu codziennym - temperatura #3 [ Funkcje ]","3","youtube",["Zastosowanie wiadomości o funkcjach do opisywania, interpretowania i przetwarzania informacji wyrażonych w postaci wykresu funkcji"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/hjUeZEcwj2E",["film-podstawa1","film-rozszerzenie1"],"Zastosowanie funkcji w życiu codziennym - prędkość, droga i czas #4 [ Funkcje ]","3","youtube",["Zastosowanie wiadomości o funkcjach do opisywania, interpretowania i przetwarzania informacji wyrażonych w postaci wykresu funkcji"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/6ilAjoAp-0M",["film-podstawa1","film-rozszerzenie1"],"Zastosowanie funkcji w życiu codziennym - gospodarka  #5 [ Funkcje ]","3","youtube",["Zastosowanie wiadomości o funkcjach do opisywania, interpretowania i przetwarzania informacji wyrażonych w postaci wykresu funkcji"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/2XoKQM_pLf8",["film-podstawa1","film-rozszerzenie1"],"PROPORCJONALNOŚĆ PROSTA [#35]","4","youtube",["Proporcjonalność prosta"],"Wzorowanie"],
["https://www.youtube.com/embed/0EhXqrzL9TU",["film-podstawa1","film-rozszerzenie1"],"wielkości wprost i odwrotnie proporcjonalne","4","youtube",["Proporcjonalność prosta"],"matspot"],
["https://www.youtube.com/embed/kFdL5cpup04",["film-podstawa1","film-rozszerzenie1"],"Proporcjonalność prosta #1 [ Funkcja liniowa ]","4","youtube",["Proporcjonalność prosta"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/pBqkCRu91UE",["film-podstawa1","film-rozszerzenie1"],"FUNKCJA LINIOWA - WYKRES I MIEJSCE ZEROWE [#36]","4","youtube",["Funkcja liniowa. Wykres i miejsce zerowe funkcji liniowej"],"Wzorowanie"],
["https://www.youtube.com/embed/YZvkJd3WSjk",["film-podstawa1","film-rozszerzenie1"],"Zadania maturalne - funkcja liniowa.","4","youtube",["Funkcja liniowa. Wykres i miejsce zerowe funkcji liniowej"],"matspot"],
["https://www.youtube.com/embed/gCsVjSYq3O4",["film-podstawa1","film-rozszerzenie1"],"Liceum. Klasa II. Funkcja liniowa. Wykres funkcji liniowej","4","youtube",["Funkcja liniowa. Wykres i miejsce zerowe funkcji liniowej"],"Pizza Math"],
["https://www.youtube.com/embed/LdEqRL__k6g",["film-podstawa1","film-rozszerzenie1"],"Funkcja liniowa - wprowadzenie #2 [ Funkcja liniowa ]","4","youtube",["Funkcja liniowa. Wykres i miejsce zerowe funkcji liniowej"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/pnxtKkI7ppw",["film-podstawa1","film-rozszerzenie1"],"Funkcja liniowa podstawowe informacje LEKCJE Z FSOREM #38","4","youtube",["Funkcja liniowa. Wykres i miejsce zerowe funkcji liniowej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/BSkDKWhUqRo",["film-podstawa1","film-rozszerzenie1"],"WSPÓŁCZYNNIK KIERUNKOWY - FUNKCJA LINIOWA [#37]","4","youtube",["Znaczenie współczynnika kierunkowego we wzorze funkcji liniowej"],"Wzorowanie"],
["https://www.youtube.com/embed/S7l_CcWWtgE",["film-podstawa1","film-rozszerzenie1"],"Współczynnik funkcji liniowej  y = ax + b #3 [ Funkcja liniowa ]","4","youtube",["Znaczenie współczynnika kierunkowego we wzorze funkcji liniowej"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/PuQOC5rR3KE",["film-podstawa1","film-rozszerzenie1"],"Matura 2020 zadanie 7. Znalezienie współczynnika kierunkowego","4","youtube",["Znaczenie współczynnika kierunkowego we wzorze funkcji liniowej"],"PATOMATMA"],
["https://www.youtube.com/embed/Y38AoUwFepw",["film-podstawa1","film-rozszerzenie1"],"Znaczenie współczynników we wzorze funkcji liniowej","4","youtube",["Znaczenie współczynnika kierunkowego we wzorze funkcji liniowej"],"AjkaMat"],
["https://www.youtube.com/embed/2QGnzvrbSYg",["film-podstawa1","film-rozszerzenie1"],"FUNKCJA LINIOWA - WŁASNOŚCI [#38]","4","youtube",["Własności funkcji liniowej - zadania różne"],"Wzorowanie"],
["https://www.youtube.com/embed/NddBd7Sn1JQ",["film-podstawa1","film-rozszerzenie1"],"Funkcja liniowa własności - część 1","4","youtube",["Własności funkcji liniowej - zadania różne"],"matspot"],
["https://www.youtube.com/embed/ceP8WCeHpQk",["film-podstawa1","film-rozszerzenie1"],"Funkcja liniowa własności - część 2","4","youtube",["Własności funkcji liniowej - zadania różne"],"matspot"],
["https://www.youtube.com/embed/jQcm74HIesc",["film-podstawa1","film-rozszerzenie1"],"Funkcja liniowa podstawowe zadania LEKCJE Z FSOREM #39","4","youtube",["Własności funkcji liniowej - zadania różne"],"MiedzianyFsor"],
["https://www.youtube.com/embed/IOkcKLGpAGE",["film-podstawa1","film-rozszerzenie1"],"ZASTOSOWANIE FUNKCJI LINIOWEJ [#39]","4","youtube",["Zastosowanie własności funkcji liniowej w zadaniach praktycznych"],"Wzorowanie"],
["https://www.youtube.com/embed/QikFSnIAr54",["film-rozszerzenie1"],"WYKRESY WYBRANYCH FUNKCJI SIGNUM, MAKSIMUM I INNYCH [#40]","4","youtube",["Wykresy wybranych funkcji"],"Wzorowanie"],
["https://www.youtube.com/embed/dDxdyB9bgFM",["film-rozszerzenie2"],"Nierówności z wartością bezwzględną #1 - podstawy","2","youtube",["Nierówności z wartością bezwzględną"],"matspot"],
["https://www.youtube.com/embed/_toUaYoZ6do",["film-rozszerzenie2"],"Nierówności z wartością bezwzględną #2","2","youtube",["Nierówności z wartością bezwzględną"],"matspot"],
["https://www.youtube.com/embed/UBqWHi5p8IQ",["film-rozszerzenie2"],"Nierówności z wartością bezwzględną - rozszerzenie","2","youtube",["Nierówności z wartością bezwzględną"],"matspot"],
["https://www.youtube.com/embed/tIQ3q_oB-3A",["film-rozszerzenie2"],"Nierówności z wartością bezwzględną - metoda algebraiczna #4 [ Wartość bezwzględna ]","2","youtube",["Nierówności z wartością bezwzględną"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/cPq-9EyJBMs",["film-rozszerzenie2"],"Liceum. Klasa II. Funkcja liniowa. Nierówności z wartością bezwzględną","2","youtube",["Nierówności z wartością bezwzględną"],"Pizza Math"],
["https://www.youtube.com/embed/EBlbgeaEzjo",["film-rozszerzenie2"],"Równania i nierówności z wartością bezwzględną MATURA KURS ROZSZERZENIE Liczby rzeczywiste cz.2","2","youtube",["Nierówności z wartością bezwzględną"],"MiedzianyFsor"],
["https://www.youtube.com/embed/4n0w5A-474A",["film-rozszerzenie2"],"Równania i nierówności z wartością bezwzględną KURS MATURA PODSTAWA Równania i nierówności cz.2","2","youtube",["Nierówności z wartością bezwzględną"],"MiedzianyFsor"],
["https://www.youtube.com/embed/cxv5Kf2J1X8",["film-rozszerzenie2"],"Liceum. Klasa II. Funkcja liniowa. Równania i nierówności liniowe","2","youtube",["Równanie liniowe z parametrem"],"Pizza Math"],
["https://www.youtube.com/embed/YMPX9DZYurY",["film-rozszerzenie2"],"Bezwzględne równanie z parametrem MATURA 2022 KURS ROZSZERZENIE Liczby rzeczywiste cz.3","2","youtube",["Równanie liniowe z parametrem"],"MiedzianyFsor"],
["https://www.youtube.com/embed/6Fy6uYq__Fw",["film-rozszerzenie2"],"Funkcja liniowa na poziomie rozszerzonym","2","youtube",["Równanie liniowe z parametrem"],"AjkaMat"],
["https://www.youtube.com/embed/zcbL65jMctA",["film-rozszerzenie2"],"Liceum. Klasa II. Funkcja liniowa. Układ równań z dwiema niewiadomymi z parametrem","2","youtube",["Układy równań pierwszego stopnia z dwiema niewiadomymi z parametrem"],"Pizza Math"],
["https://www.youtube.com/embed/-12p6LMh9dQ",["film-rozszerzenie2"],"Układy równań pierwszego stopnia z dwiema niewiadomymi LEKCJE Z FSOREM","2","youtube",["Układy równań pierwszego stopnia z dwiema niewiadomymi z parametrem"],"MiedzianyFsor"],
["https://www.youtube.com/embed/8EAMk8PUfrU",["film-podstawa2","film-rozszerzenie2"],"Funkcja kwadratowa #1 - postać ogólna i kanoniczna, obliczanie delty","3","youtube",["Przypomnienie wiadomości o funkcji kwadratowej z klasy 1.","Związek między wzorem funkcji kwadratowej w postaci ogólnej, a wzorem funkcji kwadratowej w postaci kanonicznej"],"matspot"],
["https://www.youtube.com/embed/cUwhdXz-rzE",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa II. Funkcja kwadratowa. Wstęp do funkcji kwadratowej","3","youtube",["Przypomnienie wiadomości o funkcji kwadratowej z klasy 1."],"Pizza Math"],
["https://www.youtube.com/embed/z26ShaBSpIs",["film-podstawa2","film-rozszerzenie2"],"Co to funkcja kwadratowa? odc.1 Wprowadzenie (Udostępnij na Grupce Klasowej)","3","youtube",["Przypomnienie wiadomości o funkcji kwadratowej z klasy 1."],"PATOMATMA"],
["https://www.youtube.com/embed/w2wk746ce0Q",["film-podstawa2","film-rozszerzenie2"],"Zamiana postaci kanonicznej na ogólną i odwrotnie #7 [ Funkcja kwadratowa - postać ogólna i kanonicz","3","youtube",["Związek między wzorem funkcji kwadratowej w postaci ogólnej, a wzorem funkcji kwadratowej w postaci kanonicznej"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/pSfm5R_sAt4",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa II. Funkcja kwadratowa. Postać ogólna a kanoniczna funkcji kwadratowej","3","youtube",["Związek między wzorem funkcji kwadratowej w postaci ogólnej, a wzorem funkcji kwadratowej w postaci kanonicznej"],"Pizza Math"],
["https://www.youtube.com/embed/hgcQqvS5N5M",["film-podstawa2","film-rozszerzenie2"],"Co to funkcja kwadratowa? odc.4 Zadania Postać Kanoniczna. (Udostępnij na Grupce Klasowej)","3","youtube",["Związek między wzorem funkcji kwadratowej w postaci ogólnej, a wzorem funkcji kwadratowej w postaci kanonicznej"],"PATOMATMA"],
["https://www.youtube.com/embed/wzz-Io-xVt4",["film-podstawa2","film-rozszerzenie2"],"Co to FUNKCJA KWADRATOWA W POSTACI OGÓLNEJ I KANONICZNEJ","3","youtube",["Związek między wzorem funkcji kwadratowej w postaci ogólnej, a wzorem funkcji kwadratowej w postaci kanonicznej"],"Wzorowanie"],
["https://www.youtube.com/embed/S_IeVQxw698",["film-podstawa1","film-rozszerzenie1"],"JAK ROZWĄZAĆ UKŁAD RÓWNAŃ [#41]","5","youtube",["Równania pierwszego stopnia z dwiema niewiadomymi"],"Wzorowanie"],
["https://www.youtube.com/embed/YATtPZN4RM8",["film-podstawa1","film-rozszerzenie1"],"Układy równań - przykłady z wzorami skróconego mnożenia","5","youtube",["Równania pierwszego stopnia z dwiema niewiadomymi"],"matspot"],
["https://www.youtube.com/embed/-12p6LMh9dQ",["film-podstawa1","film-rozszerzenie1"],"Układy równań pierwszego stopnia z dwiema niewiadomymi LEKCJE Z FSOREM","5","youtube",["Równania pierwszego stopnia z dwiema niewiadomymi"],"MiedzianyFsor"],
["https://www.youtube.com/embed/n74BQHhpyIg",["film-podstawa1","film-rozszerzenie1"],"JAK ROZWĄZAĆ UKŁAD RÓWNAŃ (metoda graficzna) [#42]","5","youtube",["Układy równań pierwszego stopnia z dwiema niewiadomymi. Graficzne rozwiązywanie układów równań"],"Wzorowanie"],
["https://www.youtube.com/embed/oG9Ees3yyTs",["film-podstawa1","film-rozszerzenie1"],"Interpretacja geometryczna układu równań liniowych","5","youtube",["Układy równań pierwszego stopnia z dwiema niewiadomymi. Graficzne rozwiązywanie układów równań"],"matspot"],
["https://www.youtube.com/embed/yyGY84k6zvI",["film-podstawa1","film-rozszerzenie1"],"Rozwiązywanie układów równań metodą graficzną #2 [ Układy równań - metoda graficzna ]","5","youtube",["Układy równań pierwszego stopnia z dwiema niewiadomymi. Graficzne rozwiązywanie układów równań"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/Z1qmBcqCROQ",["film-podstawa1","film-rozszerzenie1"],"Układy równań liniowych i interpretacja geometryczna MATURA PODSTAWA równania i nierówności cz.2","5","youtube",["Układy równań pierwszego stopnia z dwiema niewiadomymi. Graficzne rozwiązywanie układów równań"],"MiedzianyFsor"],
["https://www.youtube.com/embed/ECmK1tGRzV8",["film-podstawa1","film-rozszerzenie1"],"JAK ROZWIĄZAĆ UKŁAD RÓWNAŃ? (metoda podstawiania) [#43]","5","youtube",["Rozwiązywanie układów równań pierwszego stopnia z dwiema niewiadomymi metodą podstawiania"],"Wzorowanie"],
["https://www.youtube.com/embed/ZHfNEkBcmPA",["film-podstawa1","film-rozszerzenie1"],"Układy równań - metoda podstawiania","5","youtube",["Rozwiązywanie układów równań pierwszego stopnia z dwiema niewiadomymi metodą podstawiania"],"matspot"],
["https://www.youtube.com/embed/3bk2fdNZmB8",["film-podstawa1","film-rozszerzenie1"],"Układy równań - metoda podstawiania - poziom 2","5","youtube",["Rozwiązywanie układów równań pierwszego stopnia z dwiema niewiadomymi metodą podstawiania"],"matspot"],
["https://www.youtube.com/embed/TTWaWRxLW5k",["film-podstawa1","film-rozszerzenie1"],"Rozwiązywanie układów równań metodą podstawiania #3 [ Układy równań ]","5","youtube",["Rozwiązywanie układów równań pierwszego stopnia z dwiema niewiadomymi metodą podstawiania"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/_F-5S1UMib8",["film-podstawa1","film-rozszerzenie1"],"Metoda podstawiania - zadania #4 [ Układy równań ]","5","youtube",["Rozwiązywanie układów równań pierwszego stopnia z dwiema niewiadomymi metodą podstawiania"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/e_r5_Zj7OlM",["film-podstawa1","film-rozszerzenie1"],"JAK ROZWIĄZAĆ UKŁAD RÓWNAŃ? (metoda przeciwnych współczynników) [#44]","5","youtube",["Rozwiązywanie układów równań pierwszego stopnia z dwiema niewiadomymi metodą przeciwnych współczynników"],"Wzorowanie"],
["https://www.youtube.com/embed/sjQkMGTJeuQ",["film-podstawa1","film-rozszerzenie1"],"Układy równań metoda przeciwnych współczynników","5","youtube",["Rozwiązywanie układów równań pierwszego stopnia z dwiema niewiadomymi metodą przeciwnych współczynników"],"matspot"],
["https://www.youtube.com/embed/wsiQtSNwvbk",["film-podstawa1","film-rozszerzenie1"],"Metoda przeciwnych współczynników - zadania #6 [ Układy równań ]","5","youtube",["Rozwiązywanie układów równań pierwszego stopnia z dwiema niewiadomymi metodą przeciwnych współczynników"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/lXO51O-xVME",["film-podstawa1","film-rozszerzenie1"],"Układy równań - zadania tekstowe - liczba dwucyfrowa/trzycyfrowa","5","youtube",["Zastosowanie układów równań do rozwiązywania zadań"],"matspot"],
["https://www.youtube.com/embed/wb4qbSDkk9E",["film-podstawa1","film-rozszerzenie1"],"Układy równań - zadania tekstowe #1 [ Układy równań - zadania ]","5","youtube",["Zastosowanie układów równań do rozwiązywania zadań"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/xYsu9ikqBk0",["film-podstawa1","film-rozszerzenie1"],"Zadania o liczbach #2 [ Układy równań - zadania ]","5","youtube",["Zastosowanie układów równań do rozwiązywania zadań"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/icpyz8fFFRs",["film-podstawa1","film-rozszerzenie1"],"Zadania o wieku ludzi #3 [ Układy równań - zadania ]","5","youtube",["Zastosowanie układów równań do rozwiązywania zadań"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/PtzAnTC58DM",["film-podstawa1","film-rozszerzenie1"],"Zadania geometryczne #4 [ Układy równań - zadania ]","5","youtube",["Zastosowanie układów równań do rozwiązywania zadań"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/Z1R0QDBGsgU",["film-podstawa1","film-rozszerzenie1"],"Zadania procentowe #5 [ Układy równań - zadania ]","5","youtube",["Zastosowanie układów równań do rozwiązywania zadań"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/3Y5h61EYVs8",["film-podstawa1","film-rozszerzenie1"],"Zadania wykorzystujące układy równań #6 [ Układy równań - zadania ]","5","youtube",["Zastosowanie układów równań do rozwiązywania zadań"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/ZYyebgrrUno",["film-podstawa1","film-rozszerzenie1"],"FUNKCJA KWADRATOWA [#45]","6","youtube",["Funkcja kwadratowa"],"Wzorowanie"],
["https://www.youtube.com/embed/8EAMk8PUfrU",["film-podstawa1","film-rozszerzenie1"],"Funkcja kwadratowa #1 - postać ogólna i kanoniczna, obliczanie delty","6","youtube",["Funkcja kwadratowa"],"matspot"],
["https://www.youtube.com/embed/lBnepB1Pnoo",["film-podstawa1","film-rozszerzenie1"],"Funkcja kwadratowa #2 - wierzchołek paraboli, wartość największa, oś symetrii, zbiór wartości","6","youtube",["Funkcja kwadratowa"],"matspot"],
["https://www.youtube.com/embed/P2uuU_k0JxI",["film-podstawa1","film-rozszerzenie1"]," Wykres i własności funkcji x do kwadratu #1 [ Funkcja kwadratowa - postać ogólna i kanoniczna ]","6","youtube",["Funkcja kwadratowa"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/MlKVKOI4IEw",["film-podstawa1","film-rozszerzenie1"]," Wykres i własności funkcji minus x do kwadratu #2 [ Funkcja kwadratowa - postać ogólna i kanoniczna ]","6","youtube",["Funkcja kwadratowa"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/8N9U7DB0XQc",["film-podstawa1","film-rozszerzenie1"],"Postać kanoniczna - wprowadzenie #4 [ Funkcja kwadratowa - postać ogólna i kanoniczna ]","6","youtube",["Funkcja kwadratowa"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/aR_rOdHbqGE",["film-podstawa1","film-rozszerzenie1"],"Postać kanoniczna - własności funkcji #5 [ Funkcja kwadratowa - postać ogólna i kanoniczna ]","6","youtube",["Funkcja kwadratowa"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/cUwhdXz-rzE",["film-podstawa1","film-rozszerzenie1"],"Liceum. Klasa II. Funkcja kwadratowa. Wstęp do funkcji kwadratowej","6","youtube",["Funkcja kwadratowa"],"Pizza Math"],
["https://www.youtube.com/embed/VKsaJ73TC1I",["film-podstawa1","film-rozszerzenie1"],"Liceum. Klasa II. Funkcja kwadratowa. Postać kanoniczna funkcji kwadratowej","6","youtube",["Funkcja kwadratowa"],"Pizza Math"],
["https://www.youtube.com/embed/z26ShaBSpIs",["film-podstawa1","film-rozszerzenie1"],"Co to funkcja kwadratowa? odc.1 Wprowadzenie (Udostępnij na Grupce Klasowej)","6","youtube",["Funkcja kwadratowa"],"PATOMATMA"],
["https://www.youtube.com/embed/2D-s3h2o1n8",["film-podstawa1","film-rozszerzenie1"],"MATURA MATEMATYKA Funkcja kwadratowa Teoria PEWNIAK","6","youtube",["Funkcja kwadratowa"],"MiedzianyFsor"],
["https://www.youtube.com/embed/zpSo9Lug4fs",["film-podstawa1","film-rozszerzenie1"],"Funkcja kwadratowa","6","youtube",["Funkcja kwadratowa"],"AjkaMat"],
["https://www.youtube.com/embed/hzsGT-H5l28",["film-podstawa1","film-rozszerzenie1"],"Funkcja kwadratowa - zadania #1","6","youtube",["Funkcja kwadratowa - zastosowania"],"matspot"],
["https://www.youtube.com/embed/1I1B5IAPoi0",["film-podstawa1","film-rozszerzenie1"],"Zadanie maturalne - funkcja kwadratowa.","6","youtube",["Funkcja kwadratowa - zastosowania"],"matspot"],
["https://www.youtube.com/embed/EYTXSmqR1GI",["film-podstawa1","film-rozszerzenie1"],"eZadanie maturalne - funkcja kwadratowa.","6","youtube",["Funkcja kwadratowa - zastosowania"],"matspot"],
["https://www.youtube.com/embed/d-a1ji3KATM",["film-podstawa1","film-rozszerzenie1"],"Postać kanoniczna - zadania #6 [ Funkcja kwadratowa - postać ogólna i kanoniczna]","6","youtube",["Funkcja kwadratowa - zastosowania"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/8UnJsmkbQhQ",["film-podstawa1","film-rozszerzenie1"],"PROPORCJONALNOŚĆ ODWROTNA [#46]","6","youtube",["Proporcjonalność odwrotna"],"Wzorowanie"],
["https://www.youtube.com/embed/eRFesoidVbk",["film-podstawa1","film-rozszerzenie1"],"Proporcja odwrotna #3 [ Proporcje ]","6","youtube",["Proporcjonalność odwrotna"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/vT2DSNsvLCs",["film-podstawa1","film-rozszerzenie1"],"FUNKCJA WYKŁADNICZA [#47]","6","youtube",["Funkcja wykładnicza"],"Wzorowanie"],
["https://www.youtube.com/embed/-U6xBu8PfZY",["film-podstawa1","film-rozszerzenie1"],"Zadanie maturalne - wartości funkcji wykładniczej.","6","youtube",["Funkcja wykładnicza"],"matspot"],
["https://www.youtube.com/embed/zOxq_iNX7ao",["film-rozszerzenie1"],"Funkcja wykładnicza na poziomie rozszerzonym","6","youtube",["Funkcja wykładnicza"],"AjkaMat"],
["https://www.youtube.com/embed/TA1sBb5tCHE",["film-podstawa1","film-rozszerzenie1"],"FUNKCJA LOGARYTMICZNA - co to jest?","6","youtube",["Funkcja logarytmiczna"],"Wzorowanie"],
["https://www.youtube.com/embed/zb5X7xqpydY",["film-rozszerzenie1"],"Logarytmy, funkcja wykładnicza i logarytmiczna - A co było na maturze rozszerzonej?","6","youtube",["Funkcja logarytmiczna"],"AjkaMat"],
["https://www.youtube.com/embed/xnNHHTu9EVg",["film-podstawa1","film-rozszerzenie1"],"GEOMETRIA - WSTĘP [#49]","7","youtube",["Punkt, prosta, odcinek, półprosta, kąt, figura wypukła, figura ograniczona"],"Wzorowanie"],
["https://www.youtube.com/embed/75Ci9E3vHv8",["film-podstawa1","film-rozszerzenie1"],"Punkt, prosta, odcinek, półprosta, kąt, figura wypukła, figura ograniczona - definicje, przykłady","7","youtube",["Punkt, prosta, odcinek, półprosta, kąt, figura wypukła, figura ograniczona"],"matzadanie"],
["https://www.youtube.com/embed/_bYXHZUcpkE",["film-podstawa1","film-rozszerzenie1"],"Punkt, odcinek, prosta, półprosta #1 [ Proste i odcinki ]","7","youtube",["Punkt, prosta, odcinek, półprosta, kąt, figura wypukła, figura ograniczona"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/XF0liQiZtWo",["film-podstawa1","film-rozszerzenie1"],"Pojęcia w geometrii #1 [ Wstęp do geometrii ]","7","youtube",["Punkt, prosta, odcinek, półprosta, kąt, figura wypukła, figura ograniczona"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/xLq6KC55dSw",["film-podstawa1","film-rozszerzenie1"],"Liceum. Klasa I. Wstęp do geometrii","7","youtube",["Punkt, prosta, odcinek, półprosta, kąt, figura wypukła, figura ograniczona"],"Pizza Math"],
["https://www.youtube.com/embed/0E86jZhoAsY",["film-podstawa1","film-rozszerzenie1"],"Liceum. Klasa I. Proste i odcinki","7","youtube",["Wzajemne położenie prostych na płaszczyźnie, odległość punktu od prostej, odległość między prostymi równoległymi, symetralna odcinka, dwusieczna kąta"],"Pizza Math"],
["https://www.youtube.com/embed/8VnWxwCxVjI",["film-podstawa1","film-rozszerzenie1"],"POŁOŻENIE PROSTYCH NA PŁASZCZYŹNIE [#50]","7","youtube",["Wzajemne położenie prostych na płaszczyźnie, odległość punktu od prostej, odległość między prostymi równoległymi, symetralna odcinka, dwusieczna kąta"],"Wzorowanie"],
["https://www.youtube.com/embed/cLrDkWZ64N8",["film-podstawa1","film-rozszerzenie1"],"Wzajemne położenie prostych na płaszczyźnie, symetralna, dwusieczna kąta - definicje, przykłady","7","youtube",["Wzajemne położenie prostych na płaszczyźnie, odległość punktu od prostej, odległość między prostymi równoległymi, symetralna odcinka, dwusieczna kąta"],"matzadanie"],
["https://www.youtube.com/embed/KYhvhcW9M8g",["film-podstawa1","film-rozszerzenie1"],"Odległość punktu od prostej #5 [ Proste i odcinki ]","7","youtube",["Wzajemne położenie prostych na płaszczyźnie, odległość punktu od prostej, odległość między prostymi równoległymi, symetralna odcinka, dwusieczna kąta"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/4HRorhGgvVg",["film-podstawa1","film-rozszerzenie1"],"Liceum. Klasa I. Proste i kąty","7","youtube",["Dwie proste przecięte trzecią prostą. Suma kątów w trójkącie","Wielokąt. Wielokąt foremny. Suma kątów w trójkącie"],"Pizza Math"],
["https://www.youtube.com/embed/-JRIzwxajYQ",["film-podstawa1","film-rozszerzenie1"],"MIARY KĄTÓW MIĘDZY PROSTYMI PRZECIĘTYMI TRZECIĄ PROSTĄ [#51]","7","youtube",["Dwie proste przecięte trzecią prostą. Suma kątów w trójkącie"],"Wzorowanie"],
["https://www.youtube.com/embed/pxz5zBHPXho",["film-podstawa1","film-rozszerzenie1"],"Dwie proste przecięte trzecią prostą, suma kątów w trójkącie - definicje, przykłady","7","youtube",["Dwie proste przecięte trzecią prostą. Suma kątów w trójkącie"],"matzadanie"],
["https://www.youtube.com/embed/b8jLJ4y-_74",["film-podstawa1","film-rozszerzenie1"],"Proste równoległe przecięte trzecią prostą - kąty #3 [ Kąty powstałe w wyniku przecięcia prostych ]","7","youtube",["Dwie proste przecięte trzecią prostą. Suma kątów w trójkącie"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/MoNAiOAUDtc",["film-podstawa1","film-rozszerzenie1"],"Liceum. Klasa I. Wstęp do geometrii 2","7","youtube",["Wielokąt. Wielokąt foremny. Suma kątów w wielokącie"],"Pizza Math"],
["https://www.youtube.com/embed/qdzYSaQhsQ0",["film-podstawa1","film-rozszerzenie1"],"WIELOKĄTY FOREMNE [#52]","7","youtube",["Wielokąt. Wielokąt foremny. Suma kątów w wielokącie"],"Wzorowanie"],
["https://www.youtube.com/embed/T1JNutBGF88",["film-podstawa1","film-rozszerzenie1"],"kąt wewnętrzny wielokąta foremnego","7","youtube",["Wielokąt. Wielokąt foremny. Suma kątów w wielokącie"],"matspot"],
["https://www.youtube.com/embed/2XkPXgkURXE",["film-podstawa1","film-rozszerzenie1"],"Wielokąty foremne #1 [ Wielokąty foremne ]","7","youtube",["Wielokąt. Wielokąt foremny. Suma kątów w wielokącie"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/857XiKa5i1Q",["film-podstawa1","film-rozszerzenie1"],"Kąt wewnętrzny wielokąta foremnego #2 [ Wielokąty foremne ]","7","youtube",["Wielokąt. Wielokąt foremny. Suma kątów w wielokącie"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/wG1yJ4dzmf4",["film-podstawa1","film-rozszerzenie1"],"Liczba przekątnych wielokąta foremnego #3 [ Wielokąty foremne ]","7","youtube",["Wielokąt. Wielokąt foremny. Suma kątów w wielokącie"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/lKhqN840Rqo",["film-podstawa1","film-rozszerzenie1"],"TWIERDZENIE TALESA [#53]","7","youtube",["Twierdzenie Talesa"],"Wzorowanie"],
["https://www.youtube.com/embed/gzCrBCUlrxE",["film-podstawa1","film-rozszerzenie1"],"Twierdzenie Talesa i twierdzenie odwrotne","7","youtube",["Twierdzenie Talesa"],"matspot"],
["https://www.youtube.com/embed/4nas5h7A9u4",["film-podstawa1","film-rozszerzenie1"],"Twierdzenie Talesa #5 [ Podobieństwo trójkątów ]","7","youtube",["Twierdzenie Talesa"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/c7mSym-BW1k",["film-podstawa1","film-rozszerzenie1"],"Liceum. Klasa I. Twierdzenie Talesa","7","youtube",["Twierdzenie Talesa"],"Pizza Math"],
["https://www.youtube.com/embed/YIs-uIaKvHQ",["film-podstawa1","film-rozszerzenie1"],"Twierdzenie Talesa Zupełnie od Zera (Udostępnij na Grupce Klasowej)","7","youtube",["Twierdzenie Talesa"],"PATOMATMA"],
["https://www.youtube.com/embed/-yL-MbFcMaI",["film-podstawa1","film-rozszerzenie1"],"WŁASNOŚCI TRÓJKĄTÓW [#54]","7","youtube",["Podział trójkątów. Nierówność trójkąta. Odcinek łączący środki boków w trójkącie"],"Wzorowanie"],
["https://www.youtube.com/embed/YkC9JZQgENk",["film-podstawa1","film-rozszerzenie1"]," Podział trójkątów ze względu na kąty i boki #2 [ Trójkąty - wprowadzenie ]","7","youtube",["Podział trójkątów. Nierówność trójkąta. Odcinek łączący środki boków w trójkącie"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/O7luA3oiHA4",["film-podstawa1","film-rozszerzenie1"]," Liceum. Klasa I. Trójkąty","7","youtube",["Podział trójkątów. Nierówność trójkąta. Odcinek łączący środki boków w trójkącie"],"Pizza Math"],
["https://www.youtube.com/embed/ZoQjeTq6-jo",["film-podstawa1","film-rozszerzenie1"]," TWIERDZENIE PITAGORASA [#55]","7","youtube",["Twierdzenie Pitagorasa. Twierdzenie odwrotne do twierdzenia Pitagorasa"],"Wzorowanie"],
["https://www.youtube.com/embed/_WAX_an-aC4",["film-podstawa1","film-rozszerzenie1"],"Twierdzenie Pitagorasa - od podstaw","7","youtube",["Twierdzenie Pitagorasa. Twierdzenie odwrotne do twierdzenia Pitagorasa"],"matspot"],
["https://www.youtube.com/embed/Gl_ocz8jzqM",["film-podstawa1","film-rozszerzenie1"],"Twierdzenie Pitagorasa - wprowadzenie #1 [ Twierdzenie Pitagorasa ]","7","youtube",["Twierdzenie Pitagorasa. Twierdzenie odwrotne do twierdzenia Pitagorasa"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/30OKS5t1xcc",["film-podstawa1","film-rozszerzenie1"],"Liceum. Klasa I. Twierdzenie Pitagorasa","7","youtube",["Twierdzenie Pitagorasa. Twierdzenie odwrotne do twierdzenia Pitagorasa"],"Pizza Math"],
["https://www.youtube.com/embed/9khlpVfa0Sc",["film-podstawa1","film-rozszerzenie1"]," Przydatny wzór odc. 1 - tw. Pitagorasa oraz zastosowania","7","youtube",["Twierdzenie Pitagorasa. Twierdzenie odwrotne do twierdzenia Pitagorasa"],"PATOMATMA"],
["https://www.youtube.com/embed/X6m4VXQDlKk",["film-podstawa1","film-rozszerzenie1"]," Przydatny wzór odc. 2 - tw. Pitagorasa oraz zastosowania","7","youtube",["Twierdzenie Pitagorasa. Twierdzenie odwrotne do twierdzenia Pitagorasa"],"PATOMATMA"],
["https://www.youtube.com/embed/VNZWKAXxuGc",["film-podstawa1","film-rozszerzenie1"],"WYSOKOŚCI I ŚRODKOWE W TRÓJKĄCIE [#56]","7","youtube",["Wysokości w trójkącie. Środkowe w trójkącie"],"Wzorowanie"],
["https://www.youtube.com/embed/iobLCK3Oo9k",["film-podstawa1","film-rozszerzenie1"]," wysokość trójkąta równobocznego","7","youtube",["Wysokości w trójkącie. Środkowe w trójkącie"],"matspot"],
["https://www.youtube.com/embed/kDltOagTCDo",["film-podstawa1","film-rozszerzenie1"],"Wysokość trójkąta #3 [ Trójkąty - wprowadzenie ]","7","youtube",["Wysokości w trójkącie. Środkowe w trójkącie"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/IxkJHqWCTQI",["film-podstawa1","film-rozszerzenie1"],"Liceum. Klasa I. Wysokości i środkowe w trójkącie","7","youtube",["Wysokości w trójkącie. Środkowe w trójkącie"],"Pizza Math"],
["https://www.youtube.com/embed/hMw9lH-caUw",["film-podstawa1","film-rozszerzenie1"],"TRÓJKĄTY PRZYSTAJĄCE [#57]","7","youtube",["Przystawanie trójkątów"],"Wzorowanie"],
["https://www.youtube.com/embed/wOmDdn9E4_g",["film-podstawa1","film-rozszerzenie1"],"Trójkąty przystające - cechy przystawania","7","youtube",["Przystawanie trójkątów"],"matspot"],
["https://www.youtube.com/embed/CuWd9I26f5c",["film-podstawa1","film-rozszerzenie1"]," Przystawanie trójkątów #7 [ Trójkąty ]","7","youtube",["Przystawanie trójkątów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/EMkM89YXITk",["film-podstawa1","film-rozszerzenie1"],"Liceum. Klasa I. Przystawanie trójkątów","7","youtube",["Przystawanie trójkątów"],"Pizza Math"],
["https://www.youtube.com/embed/WW4jXZaAcfo",["film-podstawa1","film-rozszerzenie1"],"TRÓJKĄTY PODOBNE [#58]","7","youtube",["Podobieństwo trójkątów","Podobieństwo trójkątów - zastosowanie w zadaniach"],"Wzorowanie"],
["https://www.youtube.com/embed/rlZJuhtXqnI",["film-podstawa1","film-rozszerzenie1"],"Trójkąty podobne - matura 2015","7","youtube",["Podobieństwo trójkątów","Podobieństwo trójkątów - zastosowanie w zadaniach"],"matspot"],
["https://www.youtube.com/embed/qXRxBWku2Fk",["film-podstawa1","film-rozszerzenie1"],"Trójkąty podobne w trójkątach #1 [ Podobieństwo trójkątów ]","7","youtube",["Podobieństwo trójkątów","Podobieństwo trójkątów - zastosowanie w zadaniach"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/pUZMyjl34oA",["film-podstawa1","film-rozszerzenie1"]," Trójkąty podobne w czworokątach #2 [ Podobieństwo trójkątów ]","7","youtube",["Podobieństwo trójkątów","Podobieństwo trójkątów - zastosowanie w zadaniach"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/nEcAi87Oi6A",["film-podstawa1","film-rozszerzenie1"],"Liceum. Klasa I. Podobieństwo trójkątów","7","youtube",["Podobieństwo trójkątów","Podobieństwo trójkątów - zastosowanie w zadaniach"],"Pizza Math"],
["https://www.youtube.com/embed/wQeAzgttOUM",["film-rozszerzenie1"],"WEKTORY NA PŁASZCZYŹNIE [#59]","7","youtube",["Wektor na płaszczyźnie"],"Wzorowanie"],
["https://www.youtube.com/embed/LqBrQp-f2I0",["film-rozszerzenie1"]," Wektory - Wprowadzenie Odc.1 (na szybko bo wychodzę za 5 min) (Udostępnij na Grupce Klasowej)","7","youtube",["Wektor na płaszczyźnie"],"PATOMATMA"],
["https://www.youtube.com/embed/9agT9Lu6pJQ",["film-rozszerzenie1"]," Liceum. Klasa I. Wektor w układzie współrzędnych","7","youtube",["Wektor na płaszczyźnie"],"Pizza Math"],
["https://www.youtube.com/embed/aivTB9ly-tw",["film-podstawa1","film-rozszerzenie1"],"SINUS / COSINUS / TANGENS / COTANGENS - FUNKCJE TRYGONOMETRYCZNE [#60]","8","youtube",["Określenie sinusa, cosinusa, tangensa i cotangensa w trójkącie prostokątnym"],"Wzorowanie"],
["https://www.youtube.com/embed/qX232RNzZ_M",["film-podstawa1","film-rozszerzenie1"],"Trygonometria kąta ostrego - część 1 - podstawy","8","youtube",["Określenie sinusa, cosinusa, tangensa i cotangensa w trójkącie prostokątnym"],"matspot"],
["https://www.youtube.com/embed/tZk4LdG_1rU",["film-podstawa1","film-rozszerzenie1"],"Trygonometria kąta ostrego - część 2","8","youtube",["Określenie sinusa, cosinusa, tangensa i cotangensa w trójkącie prostokątnym"],"matspot"],
["https://www.youtube.com/embed/lGcuKPtOTEk",["film-podstawa1","film-rozszerzenie1"],"Sinus, cosinus i tangens w trójkącie prostokątnym #1 [ Trygonometria ]","8","youtube",["Określenie sinusa, cosinusa, tangensa i cotangensa w trójkącie prostokątnym"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/XYMK7H9712Q",["film-podstawa1","film-rozszerzenie1"],"Wyznaczanie boków trójkąta znając sinus, cosinus lub tangens #2 [ Trygonometria ]","8","youtube",["Określenie sinusa, cosinusa, tangensa i cotangensa w trójkącie prostokątnym"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/TRNiRkB1llA",["film-podstawa1","film-rozszerzenie1"],"Korzystanie z tablic trygonometrycznych #3 [ Trygonometria ]","8","youtube",["Określenie sinusa, cosinusa, tangensa i cotangensa w trójkącie prostokątnym"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/lsmzRf830zE",["film-podstawa1","film-rozszerzenie1"]," Liceum. Klasa I. Wstęp do trygonometrii","8","youtube",["Określenie sinusa, cosinusa, tangensa i cotangensa w trójkącie prostokątnym","Wartości sinusa, cosinusa, tangensa i cotangensa kątów 30°, 45° i 60°"],"Pizza Math"],
["https://www.youtube.com/embed/fBptK4ev4ho",["film-podstawa1","film-rozszerzenie1"]," WARTOŚCI SINUSA / COSIUNA / TANGENSA / COTANGENSA DLA KĄTÓW 30°, 45°, 60° [#61]","8","youtube",["Wartości sinusa, cosinusa, tangensa i cotangensa kątów 30°, 45° i 60°"],"Wzorowanie"],
["https://www.youtube.com/embed/_JhMCrCtw9M",["film-podstawa1","film-rozszerzenie1"],"Wartości sinusa, cosinusa, tangensa dla kątów 30 i 60 stopni #4 [ Trygonometria ]","8","youtube",["Wartości sinusa, cosinusa, tangensa i cotangensa kątów 30°, 45° i 60°"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/9dIGRWSq6AM",["film-podstawa1","film-rozszerzenie1"],"Wartości sinusa, cosinusa, tangensa dla kątów 45 stopni #5 [ Trygonometria ]","8","youtube",["Wartości sinusa, cosinusa, tangensa i cotangensa kątów 30°, 45° i 60°"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/1WSjWvdFCsk",["film-podstawa1","film-rozszerzenie1"],"ZALEŻNOŚCI MIĘDZY FUNKCJAMI TRYGONOMETRYCZNYMI [#62]","8","youtube",["Zależności między funkcjami trygonometrycznymi tego samego kąta ostrego"],"Wzorowanie"],
["https://www.youtube.com/embed/zvZltxwb1hc",["film-podstawa1","film-rozszerzenie1"]," Trygonometria kąta ostrego - część 3","8","youtube",["Zależności między funkcjami trygonometrycznymi tego samego kąta ostrego"],"matspot"],
["https://www.youtube.com/embed/S0nexv9bhKI",["film-podstawa1","film-rozszerzenie1"]," Trygonometria kąta ostrego - część 5","8","youtube",["Zależności między funkcjami trygonometrycznymi tego samego kąta ostrego"],"matspot"],
["https://www.youtube.com/embed/qWv_tcmikcM",["film-podstawa1","film-rozszerzenie1"],"Funkcje Trygonometryczne Wstęp + Zadanie Maturalne (Udostępnij na Grupce Klasowej)","8","youtube",["Zależności między funkcjami trygonometrycznymi tego samego kąta ostrego"],"PATOMATMA"],
["https://www.youtube.com/embed/X__SR4HHDuU",["film-podstawa1","film-rozszerzenie1"],"Trygonometria","8","youtube",["Zależności między funkcjami trygonometrycznymi tego samego kąta ostrego"],"AjkaMat"],
["https://www.youtube.com/embed/-U6xBu8PfZY",["film-podstawa4","film-rozszerzenie4"],"Zadanie maturalne - wartości funkcji wykładniczej.","1","youtube",["Potęga o wykładniku rzeczywistym - powtórzenie"],"matspot"],
["https://www.youtube.com/embed/Ubn1hMYpiCc",["film-podstawa4","film-rozszerzenie4"],"Potęgi, logarytmy i funkcja wykładnicza","1","youtube",["Funkcja wykładnicza i jej właściwości","Przekształcenia wykresów funkcji wykładniczych"],"AjkaMat"],
["https://www.youtube.com/embed/zOxq_iNX7ao",["film-rozszerzenie4"],"Funkcja wykładnicza na poziomie rozszerzonym","1","youtube",["Funkcja wykładnicza i jej właściwości","Nierówności wykładnicze","Zastosowanie własności funkcji wykładniczej w zadaniach"],"AjkaMat"],
["https://www.youtube.com/embed/vT2DSNsvLCs",["film-podstawa4","film-rozszerzenie4"],"FUNKCJA WYKŁADNICZA [#47]","1","youtube",["Funkcja wykładnicza i jej właściwości"],"Wzorowanie"],
["https://www.youtube.com/embed/zzeQjqaoRME",["film-podstawa4","film-rozszerzenie4"],"Działania na potęgach o wykładniku ujemnym #3 [ Notacja wykładnicza ]","1","youtube",["Potęga o wykładniku rzeczywistym - powtórzenie"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/ZYyebgrrUno",["film-podstawa2","film-rozszerzenie2"],"FUNKCJA KWADRATOWA [#45]","3","youtube",["Przypomnienie wiadomości o funkcji kwadratowej z klasy 1."],"Wzorowanie"],
["https://www.youtube.com/embed/P2uuU_k0JxI",["film-podstawa2","film-rozszerzenie2"]," Wykres i własności funkcji x do kwadratu #1 [ Funkcja kwadratowa - postać ogólna i kanoniczna ]","3","youtube",["Przypomnienie wiadomości o funkcji kwadratowej z klasy 1."],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/wzz-Io-xVt4",["film-podstawa2","film-rozszerzenie2"]," FUNKCJA KWADRATOWA W POSTACI OGÓLNEJ I KANONICZNEJ","3","youtube",["Związek między wzorem funkcji kwadratowej w postaci ogólnej, a wzorem funkcji kwadratowej w postaci kanonicznej"],"Wzorowanie"],
["https://www.youtube.com/embed/8EAMk8PUfrU",["film-podstawa2","film-rozszerzenie2"],"Funkcja kwadratowa #1 - postać ogólna i kanoniczna, obliczanie delty","3","youtube",["Związek między wzorem funkcji kwadratowej w postaci ogólnej, a wzorem funkcji kwadratowej w postaci kanonicznej"],"matspot"],
["https://www.youtube.com/embed/w2wk746ce0Q",["film-podstawa2","film-rozszerzenie2"],"Zamiana postaci kanonicznej na ogólną i odwrotnie #7 [ Funkcja kwadratowa - postać ogólna i kanoniczna]","3","youtube",["Związek między wzorem funkcji kwadratowej w postaci ogólnej, a wzorem funkcji kwadratowej w postaci kanonicznej"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/pSfm5R_sAt4",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa II. Funkcja kwadratowa. Postać ogólna a kanoniczna funkcji kwadratowej","3","youtube",["Związek między wzorem funkcji kwadratowej w postaci ogólnej, a wzorem funkcji kwadratowej w postaci kanonicznej"],"Pizza Math"],
["https://www.youtube.com/embed/03PqlV8gh6w",["film-podstawa2","film-rozszerzenie2"],"MIEJSCE ZEROWE FUNKCJI KWADRATOWEJ","3","youtube",["Miejsce zerowe funkcji kwadratowej. Wzór funkcji kwadratowej w postaci iloczynowej"],"Wzorowanie"],
["https://www.youtube.com/embed/iN915GJTS-w",["film-podstawa2","film-rozszerzenie2"],"POSTAĆ ILOCZYNOWA FUNKCJI KWADRATOWEJ","3","youtube",["Miejsce zerowe funkcji kwadratowej. Wzór funkcji kwadratowej w postaci iloczynowej"],"Wzorowanie"],
["https://www.youtube.com/embed/RqOk1zHjQjw",["film-podstawa2","film-rozszerzenie2"],"Postać iloczynowa funkcji kwadratowej.","3","youtube",["Miejsce zerowe funkcji kwadratowej. Wzór funkcji kwadratowej w postaci iloczynowej"],"matspot"],
["https://www.youtube.com/embed/spKuvDAS9uQ",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa II. Funkcja kwadratowa. Miejsca zerowe i postać iloczynowa funkcji kwadratowej","3","youtube",["Miejsce zerowe funkcji kwadratowej. Wzór funkcji kwadratowej w postaci iloczynowej"],"Pizza Math"],
["https://www.youtube.com/embed/RkmY_T3Ta9A",["film-podstawa2","film-rozszerzenie2"],"Postać iloczynowa #7 [ Równania kwadratowe i postać iloczynowa ]","3","youtube",["Miejsce zerowe funkcji kwadratowej. Wzór funkcji kwadratowej w postaci iloczynowej"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/GB4tCEH-80M",["film-podstawa2","film-rozszerzenie2"],"Zamiana postaci ogólnej i kanonicznej na iloczynową #8 [ Równania kwadratowe i postać iloczynowa ]","3","youtube",["Miejsce zerowe funkcji kwadratowej. Wzór funkcji kwadratowej w postaci iloczynowej"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/iVPi-9Uz7eE",["film-podstawa2","film-rozszerzenie2"],"JAK NASZKICOWAĆ WYKRES FUNKCJI KWADRATOWEJ - krok po kroku","3","youtube",["Szkicowanie wykresów funkcji kwadratowej. Odczytywanie własności funkcji kwadratowej na podstawie wykresu"],"Wzorowanie"],
["https://www.youtube.com/embed/TuKQs5YOdbw",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa II. Funkcja kwadratowa. Szkicowanie wykresów funkcji kwadratowej","3","youtube",["Szkicowanie wykresów funkcji kwadratowych. Odczytywanie własności funkcji kwadratowej na podstawie wykresu"],"Pizza Math"],
["https://www.youtube.com/embed/OPQ2SbYJp3E",["film-podstawa2","film-rozszerzenie2"],"JAK WYZNACZYĆ WZÓR FUNKCJI KWADRATOWEJ NA PODSTAWIE JEJ WŁASNOŚCI?","3","youtube",["Wyznaczanie wzoru funkcji kwadratowej na podstawie jej własności"],"Wzorowanie"],
["https://www.youtube.com/embed/-BqskcljHew",["film-podstawa2","film-rozszerzenie2"],"Wyznaczanie wzoru funkcji kwadratowej na podstawie jej własności.","3","youtube",["Wyznaczanie wzoru funkcji kwadratowej na podstawie jej własności"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/tx5RhyT9WUw",["film-podstawa2","film-rozszerzenie2"],"JAK OBLICZYĆ NAJWIĘKSZĄ I NAJMNIEJSZĄ WARTOŚĆ FUNKCJI KWADRATOWEJ","3","youtube",["Najmniejsza oraz największa wartość funkcji kwadratowej w przedziale domkniętym"],"Wzorowanie"],
["https://www.youtube.com/embed/7wUWjLrOoM4",["film-podstawa2","film-rozszerzenie2"],"Wartość najmniejsza i największa funkcji kwadratowej w przedziale domkniętym","3","youtube",["Najmniejsza oraz największa wartość funkcji kwadratowej w przedziale domkniętym"],"matspot"],
["https://www.youtube.com/embed/Hc77QngKUqg",["film-podstawa2","film-rozszerzenie2"],"JAK ROBIĆ ZADANIA OPTYMALIZACYJNE? - badanie funkcji kwadratowej","3","youtube",["Badanie funkcji kwadratowej - zadania optymalizacyjne","Zadania prowadzące do równań i nierówności kwadratowych"],"Wzorowanie"],
["https://www.youtube.com/embed/l-L2d6BOWlE",["film-podstawa2","film-rozszerzenie2"],"Optymalizacja - wprowadzenie #1 [ Optymalizacja ]","3","youtube",["Badanie funkcji kwadratowej - zadania optymalizacyjne","Zadania prowadzące do równań i nierówności kwadratowych"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/3RN2pI--1h8",["film-podstawa2","film-rozszerzenie2"],"Optymalizacja w geometrii #2 [ Optymalizacja ]","3","youtube",["Badanie funkcji kwadratowej - zadania optymalizacyjne","Zadania prowadzące do równań i nierówności kwadratowych"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/ekLQPaINEtc",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa II. Funkcja kwadratowa. Badanie funkcji kwadratowej - zadania optymalizacyjne","3","youtube",["Badanie funkcji kwadratowej - zadania optymalizacyjne","Zadania prowadzące do równań i nierówności kwadratowych"],"Pizza Math"],
["https://www.youtube.com/embed/2quidN5TJMk",["film-podstawa2","film-rozszerzenie2"],"Optymalizacja funkcja kwadratowa MATURA PODSTAWA","3","youtube",["Badanie funkcji kwadratowej - zadania optymalizacyjne","Zadania prowadzące do równań i nierówności kwadratowych"],"MiedzianyFsor"],
["https://www.youtube.com/embed/P_xzxwzyR8I",["film-podstawa2","film-rozszerzenie2"],"Optymalizacja na maturze","3","youtube",["Badanie funkcji kwadratowej - zadania optymalizacyjne","Zadania prowadzące do równań i nierówności kwadratowych"],"AjkaMat"],
["https://www.youtube.com/embed/NjEdRIRzFgM",["film-podstawa2","film-rozszerzenie2"],"Trójmian kwadratowy (zadania optymalizacyjne)","3","youtube",["Badanie funkcji kwadratowej - zadania optymalizacyjne","Zadania prowadzące do równań i nierówności kwadratowych"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/h7ddgW4fiBs",["film-podstawa2","film-rozszerzenie2"],"RÓWNANIE KWADRATOWE - część 1","3","youtube",["Równania kwadratowe"],"Wzorowanie"],
["https://www.youtube.com/embed/C8rIu7txss4",["film-podstawa2","film-rozszerzenie2"],"Równania kwadratowe - zupełne i niezupełne.","3","youtube",["Równania kwadratowe"],"matspot"],
["https://www.youtube.com/embed/fH4HSO4RAdk",["film-podstawa2","film-rozszerzenie2"],"Rozwiązywanie równań kwadratowych korzystając z delty #5 [ Równania kwadratowe i postać iloczynowa ]","3","youtube",["Równania kwadratowe"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/4qy-v7s3nck",["film-podstawa2","film-rozszerzenie2"],"Rozwiązywanie różnych równań kwadratowych - zadania #6 [ Równania kwadratowe i postać iloczynowa ]","3","youtube",["Równania kwadratowe"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/XLqsdvYT3NM",["film-podstawa2","film-rozszerzenie2"],"Funkcja kwadratowa - Szybki sposób rozwiązania","3","youtube",["Równania kwadratowe"],"PATOMATMA"],
["https://www.youtube.com/embed/NQb1Q_I0NGE",["film-podstawa2","film-rozszerzenie2"],"MATURA Równania kwadratowe PEWNIAK równania i nierówności cz.3","3","youtube",["Równania kwadratowe"],"MiedzianyFsor"],
["https://www.youtube.com/embed/7xVD-Eiwg14",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa II. Funkcja kwadratowa. Równania prowadzące do równań kwadratowych","3","youtube",["Równania prowadzące do równań kwadratowych"],"Pizza Math"],
["https://www.youtube.com/embed/eLKR0hl99tM",["film-podstawa2","film-rozszerzenie2"],"Równania prowadzące do równań kwadratowych","3","youtube",["Równania prowadzące do równań kwadratowych"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/fptYYSyi7yI",["film-podstawa2","film-rozszerzenie2"],"Równania prowadzące do równań kwadratowych","3","youtube",["Równania prowadzące do równań kwadratowych"],"matspot"],
["https://www.youtube.com/embed/IguBfv1jhRY",["film-podstawa2","film-rozszerzenie2"],"Niezbędnik Maturzysty #6 Nierówności kwadratowe","3","youtube",["Równania prowadzące do równań kwadratowych"],"matspot"],
["https://www.youtube.com/embed/uFWiEc52Xts",["film-podstawa2","film-rozszerzenie2"]," MATURA MATEMATYKA Nierówności kwadratowe PEWNIAK równania i nierówności cz.4","3","youtube",["Równania prowadzące do równań kwadratowych"],"MiedzianyFsor"],
["https://www.youtube.com/embed/Q-vrMcRevEc",["film-rozszerzenie2"],"Nierówność z niewiadomą pod pierwiastkiem","3","youtube",["Równania i nierówności, w których niewiadoma występuje pod znakiem pierwiastka kwadratowego"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/U10LObnzBbg",["film-rozszerzenie2"],"Równanie z niewiadomą pod pierwiastkiem","3","youtube",["Równania i nierówności, w których niewiadoma występuje pod znakiem pierwiastka kwadratowego"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/KRhcxbWMp4A",["film-rozszerzenie2"],"PROSTE RÓWNANIA Z NIEWIADOMĄ POD PIERWIASTKIEM LEKCJE Z FSOREM #12 ROZSZERZENIE","3","youtube",["Równania i nierówności, w których niewiadoma występuje pod znakiem pierwiastka kwadratowego"],"MiedzianyFsor"],
["https://www.youtube.com/embed/67mr8qIeXxQ",["film-rozszerzenie2"],"Liceum. Klasa II. Funkcja kwadratowa. Wykres funkcji kwadratowej z wartością bezwzględną","3","youtube",["Wykres funkcji kwadratowej z wartością bezwzględną"],"Pizza Math"],
["https://www.youtube.com/embed/V9dfy5hH-rk",["film-rozszerzenie2"],"Wykres funkcji kwadratowej z wartością bezwzględną","3","youtube",["Wykres funkcji kwadratowej z wartością bezwzględną"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/w-og0zGOvAE",["film-rozszerzenie2"],"Liceum. Klasa II. Funkcja kwadratowa. Równania kwadratowe z wartością bezwzględną","3","youtube",["Równania i nierówności kwadratowe z wartością bezwzględną"],"Pizza Math"],
["https://www.youtube.com/embed/KBsG9VSxjs8",["film-rozszerzenie2"]," Równania i nierówności kwadratowe z wartością bezwzględną","3","youtube",["Równania i nierówności kwadratowe z wartością bezwzględną"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/9B5GO5udbYU",["film-rozszerzenie2"],"Reklama wzorów Viete'a","3","youtube",["Wzory Viete'a"],"PATOMATMA"],
["https://www.youtube.com/embed/CknUJcowEQs",["film-rozszerzenie2"],"Liceum. Klasa II. Funkcja kwadratowa. Wzory Vièta","3","youtube",["Wzory Viete'a"],"Pizza Math"],
["https://www.youtube.com/embed/6Pgk_6UPQjw",["film-rozszerzenie2"],"Liceum. Klasa II. Funkcja kwadratowa. Wzory Vièta","3","youtube",["Wzory Viete'a"],"MiedzianyFsor"],
["https://www.youtube.com/embed/DeAiyU1QV3o",["film-rozszerzenie2"],"Wzory Viete'y","3","youtube",["Wzory Viete'a"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/LXLNPZn4ybI",["film-rozszerzenie2"],"Funkcja kwadratowa z parametrem, wzory wjeta (Wzory Viete'a), O co w tym chodzi?","3","youtube",["Równania I nierówności kwadratowe z parametrem"],"PATOMATMA"],
["https://www.youtube.com/embed/FwWYXbEfgsA",["film-rozszerzenie2"],"Liceum. Klasa II. Funkcja kwadratowa. Równania i nierówności kwadratowe z parametrem","3","youtube",["Równania I nierówności kwadratowe z parametrem"],"Pizza Math"],
["https://www.youtube.com/embed/jy8Jlj45cEs",["film-rozszerzenie2"],"Liceum. Klasa II. Funkcja kwadratowa. Równania kwadratowe z wartością bezwzględną i parametrem","3","youtube",["Równania I nierówności kwadratowe z parametrem"],"Pizza Math"],
["https://www.youtube.com/embed/bLKWrY1acS8",["film-rozszerzenie2"],"Równania kwadratowe z parametrem cz 1","3","youtube",["Równania I nierówności kwadratowe z parametrem"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/t1evq_1tRpA",["film-rozszerzenie2"],"Równania kwadratowe z parametrem cz 2","3","youtube",["Równania I nierówności kwadratowe z parametrem"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/j15XP1fl_Y4",["film-rozszerzenie2"],"Równania kwadratowe z parametrem cz 3","3","youtube",["Równania I nierówności kwadratowe z parametrem"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/GfTASVMYOqQ",["film-rozszerzenie2"],"Równania kwadratowe z parametrem cz 4","3","youtube",["Równania I nierówności kwadratowe z parametrem"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/G6mZ0qzcfjE",["film-rozszerzenie2"],"Równania kwadratowe z parametrem cz 5","3","youtube",["Równania I nierówności kwadratowe z parametrem"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/O7luA3oiHA4",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa I. Trójkąty","4","youtube",["Powtórzenie wiadomości z geometrii z klasy 1."],"Pizza Math"],
["https://www.youtube.com/embed/IxkJHqWCTQI",["film-podstawa2","film-rozszerzenie2"]," Liceum. Klasa I. Wysokości i środkowe w trójkącie","4","youtube",["Powtórzenie wiadomości z geometrii z klasy 1."],"Pizza Math"],
["https://www.youtube.com/embed/EMkM89YXITk",["film-podstawa2","film-rozszerzenie2"]," Liceum. Klasa I. Przystawanie trójkątów","4","youtube",["Powtórzenie wiadomości z geometrii z klasy 1."],"Pizza Math"],
["https://www.youtube.com/embed/nEcAi87Oi6A",["film-podstawa2","film-rozszerzenie2"]," Liceum. Klasa I. Podobieństwo trójkątów","4","youtube",["Powtórzenie wiadomości z geometrii z klasy 1."],"Pizza Math"],
["https://www.youtube.com/embed/30OKS5t1xcc",["film-podstawa2","film-rozszerzenie2"]," Liceum. Klasa I. Twierdzenie Pitagorasa","4","youtube",["Powtórzenie wiadomości z geometrii z klasy 1."],"Pizza Math"],
["https://www.youtube.com/embed/c7mSym-BW1k",["film-podstawa2","film-rozszerzenie2"]," Liceum. Klasa I. Twierdzenie Talesa","4","youtube",["Powtórzenie wiadomości z geometrii z klasy 1."],"Pizza Math"],
["https://www.youtube.com/embed/41WFgOhakCA",["film-podstawa2","film-rozszerzenie2"],"styczna do okręgu","4","youtube",["Okrąg. Położenie prostej i okręgu"],"matspot"],
["https://www.youtube.com/embed/mZ-eXc47YkE",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa I. Okręgi i proste","4","youtube",["Okrąg. Położenie prostej i okręgu"],"Pizza Math"],
["https://www.youtube.com/embed/OYZnw3JNYLE",["film-podstawa2","film-rozszerzenie2"],"Wzajemne położenie prostej i okręgu","4","youtube",["Okrąg. Położenie prostej i okręgu"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/1OsWWgVxAE0",["film-podstawa2","film-rozszerzenie2"],"WZAJEMNE POŁOŻNENIE DWÓCH OKRĘGÓW","4","youtube",["Wzajemne położenie dwóch okręgów"],"MatWujek"],
["https://www.youtube.com/embed/yW1xhdUA_3M",["film-podstawa2","film-rozszerzenie2"],"Wzajemne położenie dwóch okręgów","4","youtube",["Wzajemne położenie dwóch okręgów"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/jgz6citS8GE",["film-podstawa2","film-rozszerzenie2"],"Wzajemne położenie okręgów #1 [ Kąty i koła ]","4","youtube",["Wzajemne położenie dwóch okręgów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/QLzuSRiNTFU",["film-podstawa2","film-rozszerzenie2"],"Kąty w kole - matura poprawkowa 2021 zadanie 19","4","youtube",["Koła i kąty"],"matspot"],
["https://www.youtube.com/embed/YhCzuHLb-a4",["film-podstawa2","film-rozszerzenie2"],"Zadanie maturalne - kąty w kole","4","youtube",["Koła i kąty"],"matspot"],
["https://www.youtube.com/embed/c14NaDKbx0E",["film-podstawa2","film-rozszerzenie2"],"Zadanie maturalne - kąty w kole","4","youtube",["Koła i kąty"],"matspot"],
["https://www.youtube.com/embed/Up21HZnqDOw",["film-podstawa2","film-rozszerzenie2"]," Kąt środkowy i kąt wpisany #2 [ Kąty i koła ]","4","youtube",["Koła i kąty"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/lvf2wwz1CZg",["film-podstawa2","film-rozszerzenie2"]," Kąty wpisane oparte na tym samym łuku #5 [ Kąty i koła ]","4","youtube",["Koła i kąty"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/6dCOoLbw8mc",["film-podstawa2","film-rozszerzenie2"],"Kąty w kole","4","youtube",["Koła i kąty"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/r9rbZR6TXLY",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa I. Koło i kąty","4","youtube",["Koła i kąty"],"Pizza Math"],
["https://www.youtube.com/embed/-f_aoXf1-xk",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa I. Twierdzenie o stycznej i siecznej","4","youtube",["Twierdzenie o stycznej i siecznej"],"Pizza Math"],
["https://www.youtube.com/embed/mRPYsr3-9No",["film-podstawa2","film-rozszerzenie2"],"Twierdzenie o stycznej i siecznej","4","youtube",["Twierdzenie o stycznej i siecznej"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/fpp26ovWXXQ",["film-rozszerzenie2"]," Konstruowanie trójkątów #5 [ Trójkąty - wprowadzenie ]","4","youtube",["Wybrane konstrukcje geometryczne"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/WrWJrKPehjo",["film-rozszerzenie2"]," Jak narysować sześciokąt foremny - Konstrukcja - Matfiz24.pl","4","youtube",["Wybrane konstrukcje geometryczne"],"Marek Duda"],
["https://www.youtube.com/embed/s-mFUG3Hc9A",["film-rozszerzenie2"]," Liceum. Klasa I. Okrąg opisany na trójkącie |PRZECZYTAJCIE OPIS!|","4","youtube",["Symetralne boków trójkąta. Okrąg opisany na trójkącie"],"Pizza Math"],
["https://www.youtube.com/embed/qU2rxz-GedM",["film-rozszerzenie2"],"Okrąg opisany na trójkącie","4","youtube",["Symetralne boków trójkąta. Okrąg opisany na trójkącie"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/W-ZzPJfCcZE",["film-rozszerzenie2"],"okrąg opisany na trójkącie","4","youtube",["Symetralne boków trójkąta. Okrąg opisany na trójkącie"],"matspot"],
["https://www.youtube.com/embed/zWV4jlPidGs",["film-rozszerzenie2"],"okrąg wpisany w trójkąt","4","youtube",["Dwusieczne kątów trójkąta. Okrąg wpisany w trójkąt"],"matspot"],
["https://www.youtube.com/embed/wkOTQ97lMAc",["film-rozszerzenie2"],"Twierdzenie o dwusiecznej kąta w trójkącie","4","youtube",["Dwusieczne kątów trójkąta. Okrąg wpisany w trójkąt"],"matspot"],
["https://www.youtube.com/embed/PAQnlTgea7E",["film-rozszerzenie2"],"Liceum. Klasa I. Okrąg wpisany w trójkąt","4","youtube",["Dwusieczne kątów trójkąta. Okrąg wpisany w trójkąt"],"Pizza Math"],
["https://www.youtube.com/embed/ndc_SnBlNGs",["film-rozszerzenie2"],"okrąg wpisany w trójkąt","4","youtube",["Dwusieczne kątów trójkąta. Okrąg wpisany w trójkąt"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/qX232RNzZ_M",["film-podstawa2","film-rozszerzenie2"],"Trygonometria kąta ostrego - część 1 - podstawy","5","youtube",["Trygonometria kąta ostrego - powtórzenie wiadomości z klasy 1."],"matspot"],
["https://www.youtube.com/embed/lsmzRf830zE",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa I. Wstęp do trygonometrii","5","youtube",["Trygonometria kąta ostrego - powtórzenie wiadomości z klasy 1."],"Pizza Math"],
["https://www.youtube.com/embed/1LIP7FLpkyM",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa I. Funkcje trygonometryczne dowolnego kąta","5","youtube",["Sinus, cosinus, tangens i cotangens dowolnego kąta płaskiego"],"Pizza Math"],
["https://www.youtube.com/embed/oAXcwemI4b0",["film-podstawa2","film-rozszerzenie2"],"Sinus, cosinus, tangens dowolnego kąta rozwartego #1 [ Funkcje trygonometryczne kąta rozwartego ]","5","youtube",["Sinus, cosinus, tangens i cotangens dowolnego kąta płaskiego"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/dgwkjPUo0HQ",["film-podstawa2","film-rozszerzenie2"],"Funkcje trygonometryczne dowolnego kąta.","5","youtube",["Sinus, cosinus, tangens i cotangens dowolnego kąta płaskiego"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/jdE4Pzj18pM",["film-podstawa2","film-rozszerzenie2"],"Zadanie maturalne - trygonometria - tożsamości.","5","youtube",["Podstawowe tożsamości trygonometryczne"],"matspot"],
["https://www.youtube.com/embed/1UbiaUF6jEM",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa I. Tożsamości trygonometryczne","5","youtube",["Podstawowe tożsamości trygonometryczne"],"Pizza Math"],
["https://www.youtube.com/embed/uzOmcLMuQLc",["film-podstawa2","film-rozszerzenie2"],"Trygonometria - zadanie z tożsamości trygonometrycznych - Oblicz tangens konta alfa - rozwiązanie","5","youtube",["Podstawowe tożsamości trygonometryczne"],"PATOMATMA"],
["https://www.youtube.com/embed/N-SFtVnHlLc",["film-podstawa2","film-rozszerzenie2"],"Jedynka trygonometryczna #1 [ Tożsamości trygonometryczne ]","5","youtube",["Podstawowe tożsamości trygonometryczne"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/tfarfJ2bbV0",["film-podstawa2","film-rozszerzenie2"],"Tangens kąta #2 [ Tożsamości trygonometryczne ]","5","youtube",["Podstawowe tożsamości trygonometryczne"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/8LDAjXNJ8kg",["film-podstawa2","film-rozszerzenie2"],"Funkcje trygonometryczne kąta ostrego #3 [ Tożsamości trygonometryczne ]","5","youtube",["Podstawowe tożsamości trygonometryczne"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/pwoZmLY2ITU",["film-podstawa2","film-rozszerzenie2"],"Wzory redukcyjne.","5","youtube",["Wybrane wzory redukcyjne"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/kD4ZJ12ZsxI",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa I. Wzory redukcyjne","5","youtube",["Wybrane wzory redukcyjne"],"Pizza Math"],
["https://www.youtube.com/embed/1h1AATqXkaQ",["film-podstawa2","film-rozszerzenie2"],"Korzystanie z wybranych wzorów redukcyjnych #2 [ Funkcje trygonometryczne kąta rozwartego ]","5","youtube",["Wybrane wzory redukcyjne"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/7p21166FOok",["film-rozszerzenie2"]," Matematyka - Miara łukowa kąta (część pierwsza)","5","youtube",["Kąt skierowany. Miara łukowa kąta"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/4mRC2u3cWbU",["film-rozszerzenie2"]," Matematyka - Miara łukowa kąta (część druga)","5","youtube",["Kąt skierowany. Miara łukowa kąta"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/G-9fyr_FPNQ",["film-rozszerzenie2"],"Kąt skierowany","5","youtube",["Kąt skierowany. Miara łukowa kąta"],"mat kwadrat"],
["https://www.youtube.com/embed/WXuqrF5ftSM",["film-rozszerzenie2"],"Miara łukowa kąta","5","youtube",["Kąt skierowany. Miara łukowa kąta"],"mat kwadrat"],
["https://www.youtube.com/embed/Ma_nhh1GQKU",["film-rozszerzenie2"]," Wprowadzenie do miary łukowej - radiany","5","youtube",["Kąt skierowany. Miara łukowa kąta"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/cOVoAkAWdpY",["film-rozszerzenie2"],"Trygonometria - wyznaczanie okresu funkcji trygonometrycznych","5","youtube",["Funkcje trygonometryczne zmiennej rzeczywistej"],"The Mathteacher"],
["https://www.youtube.com/embed/Irh1S9cZLQU",["film-rozszerzenie2"],"Zbadaj parzystość / nieparzystość funkcji cz. 1","5","youtube",["Funkcje trygonometryczne zmiennej rzeczywistej"],"Matematyka Na Plus"],
["https://www.youtube.com/embed/rzACuSgpZPA",["film-rozszerzenie2"],"Zbadaj parzystość / nieparzystość funkcji cz. 2","5","youtube",["Funkcje trygonometryczne zmiennej rzeczywistej"],"Matematyka Na Plus"],
["https://www.youtube.com/embed/th099zUOJ90",["film-rozszerzenie2"],"Wykresy funkcji trygonometrycznych i okresowość","5","youtube",["Wykresy funkcji trygonometrycznych"],"Matemaks"],
["https://www.youtube.com/embed/loITLluuWG8",["film-rozszerzenie2"],"Wykresy funkcji trygonometrycznych","5","youtube",["Wykresy funkcji trygonometrycznych"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/j3aAEgZvUkQ",["film-rozszerzenie2"],"Dziedzina, zbiór wartości i wykres funkcji sinus","5","youtube",["Wykresy funkcji trygonometrycznych"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/-KZsMNL6pS4",["film-rozszerzenie2"],"Wykres funkcji cosinus","5","youtube",["Wykresy funkcji trygonometrycznych"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/t89s-H-QHeA",["film-podstawa2","film-rozszerzenie2"],"Długość odcinka- geometria analityczna.","6","youtube",["Odcinek w układzie współrzędnych"],"matspot"],
["https://www.youtube.com/embed/WLEJMrGIfsg",["film-podstawa2","film-rozszerzenie2"],"Wektor w układzie współrzędnych -definicje, przykłady","6","youtube",["Odcinek w układzie współrzędnych"],"matzadanie"],
["https://www.youtube.com/embed/LKZiXAKia7g",["film-podstawa2","film-rozszerzenie2"],"Środek odcinka- geometria analityczna.","6","youtube",["Odcinek w układzie współrzędnych"],"matspot"],
["https://www.youtube.com/embed/bloPPXEDUK4",["film-podstawa2","film-rozszerzenie2"],"Geometria Analityczna wprowadzenie + zadania","6","youtube",["Odcinek w układzie współrzędnych"],"PATOMATMA"],
["https://www.youtube.com/embed/10OPCP4SnY8",["film-podstawa2","film-rozszerzenie2"],"Równanie kierunkowe prostej.","6","youtube",["Równanie kierunkowe prostej"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/ZeGevXIOVDo",["film-podstawa2","film-rozszerzenie2"],"Równanie ogólne prostej.","6","youtube",["Równanie ogólne prostej"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/fyjUJ758tA0",["film-podstawa2","film-rozszerzenie2"],"Równanie prostej w postaci ogólnej","6","youtube",["Równanie ogólne prostej"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/_q3yeKjr_uI",["film-podstawa2","film-rozszerzenie2"],"Równanie okręgu","6","youtube",["Równanie okręgu"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/1A--OvBIq8s",["film-podstawa2","film-rozszerzenie2"],"Równanie okręgu","6","youtube",["Równanie okręgu"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/lsdkLaMoFBo",["film-podstawa2","film-rozszerzenie2"],"Równanie okręgu w pigułce - jak wygląda w zadaniach, rodzaje równania, na co uważać","6","youtube",["Równanie okręgu"],"Matematyka Na Plus"],
["https://www.youtube.com/embed/NMvofh7ciTo",["film-podstawa2","film-rozszerzenie2"],"Wyznacz punkty przecięcia paraboli i prostej","6","youtube",["Wyznaczanie w układzie współrzędnych punktów wspólnych prostych, okręgów i parabol"],"Matma Z Pasją"],
["https://www.youtube.com/embed/rZB4MLW_Udo",["film-podstawa2","film-rozszerzenie2"],"PROSTA PRZECINA OKRĄG - WYZNACZ PUNKTY","6","youtube",["Wyznaczanie w układzie współrzędnych punktów wspólnych prostych, okręgów i parabol"],"Matura z Lewusem"],
["https://www.youtube.com/embed/bo08Pyt-DGU",["film-podstawa2","film-rozszerzenie2"],"Wyznacz punkty wspólne prostej i paraboli","6","youtube",["Wyznaczanie w układzie współrzędnych punktów wspólnych prostych, okręgów i parabol"],"Ewelina Lis-Jarnuszkiewicz Moja Mat-planeta"],
["https://www.youtube.com/embed/R69V3vmNxS8",["film-podstawa2","film-rozszerzenie2"],"Wszystko co musisz wiedzieć o geometrii analitycznej KURS MATURA PODSTAWOWA cz.1","6","youtube",["Zastosowanie układów równań do rozwiązywania zadań z geometrii analitycznej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/Ig47g8-3X0M",["film-podstawa2","film-rozszerzenie2"],"Wszystko co musisz wiedzieć o geometrii analitycznej KURS MATURA PODSTAWOWA cz.2","6","youtube",["Zastosowanie układów równań do rozwiązywania zadań z geometrii analitycznej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/Om1KCOJ4Ais",["film-podstawa2","film-rozszerzenie2"],"Geometria analityczna 4 zadania","6","youtube",["Zastosowanie układów równań do rozwiązywania zadań z geometrii analitycznej"],"PATOMATMA"],
["https://www.youtube.com/embed/11TaEb4Sctc",["film-podstawa2","film-rozszerzenie2"],"Geometria analityczna - zadania z matury podstawowej","6","youtube",["Zastosowanie układów równań do rozwiązywania zadań z geometrii analitycznej"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/cZJoBpORTzs",["film-podstawa2","film-rozszerzenie2"],"Dowody z wykorzystaniem wzorów skróconego mnożenia #6 [ Sześciany w wyrażeniach algebraicznych ]","8","youtube",["Zastosowanie wzorów skróconego mnożenia w dowodzeniu"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/XJx-b201YMc",["film-podstawa2","film-rozszerzenie2"],"Wzory SKRÓCONEGO MNOŻENIA 3-go stopnia: Jak zapamiętać❓Jakie zastosowania❓","8","youtube",["Wzory skróconego mnożenia stopnia 3. Wzór na aⁿ - bⁿ"],"A po co MI ta MATMA?"],
["https://www.youtube.com/embed/MbCvmA2lG6A",["film-podstawa2","film-rozszerzenie2"],"Matura 2020 zadanie 28. Zastosowanie wzoru skróconego mnożenia w zadaniu dowodowym","8","youtube",["Zastosowanie wzorów skróconego mnożenia w dowodzeniu"],"PATOMATMA"],
["https://www.youtube.com/embed/9FHZTSaTnSE",["film-podstawa2","film-rozszerzenie2"],"Sześcian sumy i sześcian różnicy - zadania obliczeniowe #5 [ Sześciany w wyrażeniach algebraicznych","8","youtube",["Wzory skróconego mnożenia stopnia 3. Wzór na aⁿ - bⁿ","Zastosowanie wzorów skróconego mnożenia w dowodzeniu"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/9Q9PM67818U",["film-podstawa2","film-rozszerzenie2"],"Schemat Hornera","8","youtube",["Dzielenie wielomianu przez dwumian liniowy. Schemat Hornera"],"Akademia Matematyki Piotra Ciupaka"],
["https://www.youtube.com/embed/sJLBPp70IKI",["film-podstawa2","film-rozszerzenie2"],"Schemat Hornera - dzielenie wielomianu przez dwumian liniowy.","8","youtube",["Dzielenie wielomianu przez dwumian liniowy. Schemat Hornera"],"matspot"],
["https://www.youtube.com/embed/7AwN-iChw6s",["film-podstawa2","film-rozszerzenie2"],"Wzory skróconego mnożenia -  suma i różnica sześcianów.","8","youtube",["Wzory skróconego mnożenia stopnia 3. Wzór na aⁿ - bⁿ"],"matspot"],
["https://www.youtube.com/embed/eQTEXfqLQYE",["film-podstawa2","film-rozszerzenie2"],"Wzory skróconego mnożenia - sześcian sumy i różnicy.","8","youtube",["Wzory skróconego mnożenia stopnia 3. Wzór na aⁿ - bⁿ"],"matspot"],
["https://www.youtube.com/embed/Q-lkLAeKtlA",["film-rozszerzenie2"],"Jak dzielić (pisemnie) WIELOMIAN przez DWUMIAN na MATURZE PODSTAWOWEJ❓","8","youtube",["Dzielenie wielomianu przez wielomian stopnia wiekszego od 1"],"A po co MI ta MATMA?"],
["https://www.youtube.com/embed/23n0eEDw4oc",["film-podstawa2","film-rozszerzenie2"],"Wzory skróconego mnożenia st. 3 cz. II","8","youtube",["Wzory skróconego mnożenia stopnia 3. Wzór na aⁿ - bⁿ"],"Matfix"],
["https://www.youtube.com/embed/aGtQDnHRIt4",["film-podstawa2","film-rozszerzenie2"],"SCHEMAT HORNERA","8","youtube",["Dzielenie wielomianu przez dwumian liniowy. Schemat Hornera"],"MatWujek"],
["https://www.youtube.com/embed/kYwrzXyK8DE",["film-rozszerzenie2"],"Liceum. Klasa II. Wielomiany. Dzielenie wielomianów","8","youtube",["Dzielenie wielomianu przez wielomian stopnia wiekszego od 1"],"Pizza Math"],
["https://www.youtube.com/embed/LVIgymkVLhI",["film-podstawa2","film-rozszerzenie2"],"TWIERDZENIE BEZOUT'A","8","youtube",["Pierwiastek wielomianu. Twierdzenie Bezouta"],"MatWujek"],
["https://www.youtube.com/embed/eEqJxQOsZ8U",["film-podstawa2","film-rozszerzenie2"],"Twierdzenie Bezouta","8","youtube",["Pierwiastek wielomianu. Twierdzenie Bezouta"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/3y4h3gAbm68",["film-podstawa2","film-rozszerzenie2"],"Dzielenie wielomianów schematem Hornera #6 [ Wielomiany ]","8","youtube",["Dzielenie wielomianu przez dwumian liniowy. Schemat Hornera"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/1J6YT5z0WzM",["film-podstawa2","film-rozszerzenie2"],"Schemat HORNERA i dzielenie WIELOMIANU przez dwumian | MATURA 2021 ROZSZERZONA (czerwiec) Zad.5","8","youtube",["Dzielenie wielomianu przez dwumian liniowy. Schemat Hornera"],"A po co MI ta MATMA?"],
["https://www.youtube.com/embed/87mgswpLKD8",["film-podstawa2","film-rozszerzenie2"],"Wzory skróconego mnożenia st. 3 cz. I","8","youtube",["Wzory skróconego mnożenia stopnia 3. Wzór na aⁿ - bⁿ"],"Matfix"],
["https://www.youtube.com/embed/FiIGvkmWVt8",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa II. Wielomiany. Równość i podzielność wielomianów","8","youtube",["Podzielność wielomianów"],"Pizza Math"],
["https://www.youtube.com/embed/N7cr9PamOF0",["film-podstawa2","film-rozszerzenie2"],"Twierdzenie Bezouta - wielomiany","8","youtube",["Pierwiastek wielomianu. Twierdzenie Bezouta"],"matspot"],
["https://www.youtube.com/embed/luDwfx-24UQ",["film-podstawa2","film-rozszerzenie2"],"Wzory skróconego mnożenia #3","8","youtube",["Wzory skróconego mnożenia stopnia 3. Wzór na aⁿ - bⁿ"],"matspot"],
["https://www.youtube.com/embed/_7y-T5astBo",["film-podstawa2","film-rozszerzenie2"],"100 dni do matury - Dzień 7 - Schemat Hornera, dzielenie pisemne wielomianów","8","youtube",["Dzielenie wielomianu przez dwumian liniowy. Schemat Hornera"],"Math Dream"],
["https://www.youtube.com/embed/kYwrzXyK8DE",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa II. Wielomiany. Dzielenie wielomianów","8","youtube",["Podzielność wielomianów"],"Pizza Math"],
["https://www.youtube.com/embed/-VnabGFc_Yc",["film-rozszerzenie2"],"Podzielność wielomianów. Klasa2 Zadanie 5 a","8","youtube",["Dzielenie wielomianu przez wielomian stopnia wiekszego od 1"],"Szkoła PitagoLasa"],
["https://www.youtube.com/embed/NLGXntQPTwc",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa II. Wielomiany. Twierdzenie Bezouta","8","youtube",["Pierwiastek wielomianu. Twierdzenie Bezouta"],"Pizza Math"],
["https://www.youtube.com/embed/VZA09ymFcV4",["film-rozszerzenie2"],"Szybko i na temat. Dzielenie Wielomianów Krok po Kroku (Udostępnij na Grupce Klasowej)","8","youtube",["Dzielenie wielomianu przez wielomian stopnia wiekszego od 1"],"PATOMATMA"],
["https://www.youtube.com/embed/VZA09ymFcV4",["film-podstawa2","film-rozszerzenie2"],"Szybko i na temat. Dzielenie Wielomianów Krok po Kroku (Udostępnij na Grupce Klasowej)","8","youtube",["Podzielność wielomianów"],"PATOMATMA"],
["https://www.youtube.com/embed/8C75UZ2vbeA",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa II. Wielomiany. Schemat Hornera","8","youtube",["Dzielenie wielomianu przez dwumian liniowy. Schemat Hornera"],"Pizza Math"],
["https://www.youtube.com/embed/-HrO_Uz0GtU",["film-podstawa2","film-rozszerzenie2"],"Wzory skróconego mnożenia - sześcian sumy","8","youtube",["Wzory skróconego mnożenia stopnia 3. Wzór na aⁿ - bⁿ"],"Planeta EDU"],
["https://www.youtube.com/embed/VCGTknxaWs0",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa II. Wielomiany. Pierwiastek wielomianu","8","youtube",["Pierwiastek wielomianu. Twierdzenie Bezouta"],"Pizza Math"],
["https://www.youtube.com/embed/76mTJXoY-FU",["film-podstawa2","film-rozszerzenie2"],"Twierdzenie Bezouta","8","youtube",["Pierwiastek wielomianu. Twierdzenie Bezouta"],"cinematma"],
["https://www.youtube.com/embed/YYETRAcCWTE",["film-podstawa2","film-rozszerzenie2"],"Sześcian sumy #3 [ Sześciany w wyrażeniach algebraicznych ]","8","youtube",["Wzory skróconego mnożenia stopnia 3. Wzór na aⁿ - bⁿ"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/Af3Ze4Fia4g",["film-podstawa2","film-rozszerzenie2"],"Twierdzenie o reszcie z dzielenia i twierdzenie Bezouta (Udostępnij na Grupce Klasowej)","8","youtube",["Pierwiastek wielomianu. Twierdzenie Bezouta"],"PATOMATMA"],
["https://www.youtube.com/embed/wSOpEhKx_2I",["film-podstawa2","film-rozszerzenie2"],"Dzielenie wielomianów-schemat Hornera","8","youtube",["Dzielenie wielomianu przez dwumian liniowy. Schemat Hornera"],"Matfix"],
["https://www.youtube.com/embed/O9zDQqXvMdI",["film-podstawa2","film-rozszerzenie2"],"Wielomiany - Schemat Hornera i tabelka - Matfiz24.pl","8","youtube",["Dzielenie wielomianu przez dwumian liniowy. Schemat Hornera"],"Marek Duda"],
["https://www.youtube.com/embed/z0wS_WhDNQA",["film-podstawa2","film-rozszerzenie2"],"Dzielenie wielomianów i schemat Hornera","8","youtube",["Dzielenie wielomianu przez dwumian liniowy. Schemat Hornera"],"MATH helperka"],
["https://www.youtube.com/embed/BuKUa6ilLbQ",["film-podstawa2","film-rozszerzenie2"],"Matematyka - Pierwiastki wielomianów jednej zmiennej","8","youtube",["Pierwiastek wielomianu. Twierdzenie Bezouta"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/FiIGvkmWVt8",["film-rozszerzenie2"],"Liceum. Klasa II. Wielomiany. Równość i podzielność wielomianów","8","youtube",["Dzielenie wielomianu przez wielomian stopnia wiekszego od 1"],"Pizza Math"],
["https://www.youtube.com/embed/UY5gj9EPye4",["film-podstawa2","film-rozszerzenie2"],"Pierwiastek wielomianu twierdzenie Bezoute'a","8","youtube",["Pierwiastek wielomianu. Twierdzenie Bezouta"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/ymEw10Soa_k",["film-podstawa2","film-rozszerzenie2"],"Pierwiastek wielomianu. Twierdzenie Bezouta. Klasa2 Zadanie 4 a","8","youtube",["Pierwiastek wielomianu. Twierdzenie Bezouta"],"Szkoła PitagoLasa"],
["https://www.youtube.com/embed/V92tr7yky1E",["film-podstawa2","film-rozszerzenie2"],"Matematyka - Dzielenie wielomianu przed dwumian (Część I)","8","youtube",["Podzielność wielomianów"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/o7dnwhiQ-OE",["film-podstawa2","film-rozszerzenie2"],"Wielomiany: Twierdzenie Bezouta #11","8","youtube",["Pierwiastek wielomianu. Twierdzenie Bezouta"],"Sciart"],
["https://www.youtube.com/embed/V92tr7yky1E",["film-rozszerzenie2"],"Matematyka - Dzielenie wielomianu przed dwumian (Część I)","8","youtube",["Dzielenie wielomianu przez wielomian stopnia wiekszego od 1"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/z63igIJHt-E",["film-podstawa2","film-rozszerzenie2"],"Matematyka da się lubić: Pierwiastek wielomianu - twierdzenie Bezouta #27","8","youtube",["Pierwiastek wielomianu. Twierdzenie Bezouta"],"Matematyka da się lubić"],
["https://www.youtube.com/embed/4a94SNu_JgA",["film-podstawa2","film-rozszerzenie2"],"Pierwiastki wielomianu #2 [ Wielomiany ]","8","youtube",["Pierwiastek wielomianu. Twierdzenie Bezouta"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/CA18VM_sHlU",["film-podstawa2","film-rozszerzenie2"],"Schemat HORNERA - czyli jak SZYBCIEJ dzielić WIELOMIAN przez dwumian x-a❓","8","youtube",["Dzielenie wielomianu przez dwumian liniowy. Schemat Hornera"],"A po co MI ta MATMA?"],
["https://www.youtube.com/embed/6ZvqBbBokL4",["film-podstawa2","film-rozszerzenie2"],"Pierwiastek wielomianu, twierdzenie Bezouta","8","youtube",["Pierwiastek wielomianu. Twierdzenie Bezouta"],"Matfix"],
["https://www.youtube.com/embed/79Acz5sDv40",["film-rozszerzenie2"],"Podzielność wielomianów","8","youtube",["Dzielenie wielomianu przez wielomian stopnia wiekszego od 1"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/xHZXjXBfJBA",["film-podstawa2","film-rozszerzenie2"],"Odc. 7. Twierdzenie Bézouta, pierwiastki wielomianów.","8","youtube",["Pierwiastek wielomianu. Twierdzenie Bezouta"],"FundacjaMaciejko"],
["https://www.youtube.com/embed/Q-lkLAeKtlA",["film-podstawa2","film-rozszerzenie2"],"Jak dzielić (pisemnie) WIELOMIAN przez DWUMIAN na MATURZE PODSTAWOWEJ❓","8","youtube",["Podzielność wielomianów"],"A po co MI ta MATMA?"],
["https://www.youtube.com/embed/9piOqP5jcw4",["film-rozszerzenie2"],"Dzielenie wielomianów #4 [ Wielomiany ]","8","youtube",["Dzielenie wielomianu przez wielomian stopnia wiekszego od 1"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/sqcFGo0zxN0",["film-podstawa2","film-rozszerzenie2"],"Pierwiastek wielomianu i twierdzenie Bezouta","8","youtube",["Pierwiastek wielomianu. Twierdzenie Bezouta"],"FizMat"],
["https://www.youtube.com/embed/9piOqP5jcw4",["film-podstawa2","film-rozszerzenie2"],"Dzielenie wielomianów #4 [ Wielomiany ]","8","youtube",["Podzielność wielomianów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/79Acz5sDv40",["film-podstawa2","film-rozszerzenie2"],"Podzielność wielomianów","8","youtube",["Podzielność wielomianów"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/_0DJ1N7XTC4",["film-podstawa2","film-rozszerzenie2"],"PIERWIASTKI WYMIERNE WIELOMIANU","8","youtube",["Pierwiastki wymierne wielomianu"],"MatWujek"],
["https://www.youtube.com/embed/zGFd569PQlU",["film-rozszerzenie2"],"Pierwiastek wielokrotny wielomianu","8","youtube",["Pierwiastek wielokrotny"],"Jarosław Cywka"],
["https://www.youtube.com/embed/msfZ8_eHIoM",["film-podstawa2","film-rozszerzenie2"],"Pierwiastki całkowite oraz wymierne wielomianu #8 [ Wielomiany ]","8","youtube",["Pierwiastki wymierne wielomianu"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/VZA09ymFcV4",["film-rozszerzenie2"],"Szybko i na temat. Dzielenie Wielomianów Krok po Kroku (Udostępnij na Grupce Klasowej)","8","youtube",["Dzielenie wielomianu przez wielomian stopnia większego niż 1"],"PATOMATMA"],
["https://www.youtube.com/embed/kYwrzXyK8DE",["film-rozszerzenie2"],"Liceum. Klasa II. Wielomiany. Dzielenie wielomianów","8","youtube",["Dzielenie wielomianu przez wielomian stopnia większego niż 1"],"Pizza Math"],
["https://www.youtube.com/embed/TbggjVPb9Fk",["film-podstawa2","film-rozszerzenie2"],"Dzielenie wielomianów z resztą","8","youtube",["Równania wielomianowe"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/aRZcGDSlnb0",["film-podstawa2","film-rozszerzenie2"],"Rozkład wielomianów na czynniki - wzory skróconego mnożenia #2 [ Równania wielomianowe ]","8","youtube",["Rozkładanie wielomianów na czynniki"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/XZChMg_DPEI",["film-rozszerzenie2"],"Matematyka da się lubić: Pierwiastek wielokrotny #29","8","youtube",["Pierwiastek wielokrotny"],"Matematyka da się lubić"],
["https://www.youtube.com/embed/cMmHz8xE_kQ",["film-podstawa2","film-rozszerzenie2"],"Wprowadzenie do równań wielomianowych #1 [ Równania wielomianowe ]","8","youtube",["Równania wielomianowe"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/u1EB4Kwa9cg",["film-podstawa2","film-rozszerzenie2"],"Pierwiastki całkowite i pierwiastki wymierne wielomianu 1","8","youtube",["Pierwiastki wymierne wielomianu"],"cinematma"],
["https://www.youtube.com/embed/Zs7wMf4muAg",["film-podstawa2","film-rozszerzenie2"],"Pierwiastki całkowite i pierwiastki wymierne wielomianu 2","8","youtube",["Równania wielomianowe"],"cinematma"],
["https://www.youtube.com/embed/JYoWnMAhe6E",["film-rozszerzenie2"],"Wykres wielomianu 1","8","youtube",["Funkcje wielomianowe"],"cinematma"],
["https://www.youtube.com/embed/Llo9iEr2eYI",["film-rozszerzenie2"],"Dzielenie wielomianów sposobem pisemnym.","8","youtube",["Dzielenie wielomianu przez wielomian stopnia większego niż 1"],"matspot"],
["https://www.youtube.com/embed/tC4fQXzJKwY",["film-podstawa2","film-rozszerzenie2"],"Rozkład wielomianu na czynniki - poziom 1","8","youtube",["Rozkładanie wielomianów na czynniki"],"matspot"],
["https://www.youtube.com/embed/IGUDlL9Rl8Q",["film-rozszerzenie2"],"Równania wielomianowe z parametrem","8","youtube",["Równania wielomianowe z parametrem"],"mat kwadrat"],
["https://www.youtube.com/embed/yiklSMSczPo",["film-podstawa2","film-rozszerzenie2"],"WIELOMIANY- rozkład wielomianu na czynniki, jak rozłożyć wielomian na czynniki? Postać iloczynowa.","8","youtube",["Rozkładanie wielomianów na czynniki"],"Akademia Matematyki Piotra Ciupaka"],
["https://www.youtube.com/embed/TbggjVPb9Fk",["film-rozszerzenie2"],"Dzielenie wielomianów z resztą","8","youtube",["Dzielenie wielomianu przez wielomian stopnia większego niż 1"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/L2LUb-ecIPI",["film-podstawa2","film-rozszerzenie2"],"Rozkład wielomianu na czynniki","8","youtube",["Rozkładanie wielomianów na czynniki"],"cinematma"],
["https://www.youtube.com/embed/aRZcGDSlnb0",["film-podstawa2","film-rozszerzenie2"],"Rozkład wielomianów na czynniki - wzory skróconego mnożenia #2 [ Równania wielomianowe ]","8","youtube",["Równania wielomianowe"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/KLjji_I3XvA",["film-rozszerzenie2"],"Liceum. Klasa II. Wielomiany. Nierówności wielomianowe","8","youtube",["Nierówności wielomianowe"],"Pizza Math"],
["https://www.youtube.com/embed/1AhYn0jq4t4",["film-podstawa2","film-rozszerzenie2"],"Matematyka da się lubić: Zadania prowadzące do równań wielomianowych #13","8","youtube",["Zadania prowadzące do równań wielomianowych"],"Matematyka da się lubić"],
["https://www.youtube.com/embed/xSTRucqOAPw",["film-rozszerzenie2"],"Nierówności wielomianowe","8","youtube",["Nierówności wielomianowe"],"Uniwersytet Śląski"],
["https://www.youtube.com/embed/Tj9ck-732jE",["film-rozszerzenie2"],"Równania wielomianowe z parametrem 2","8","youtube",["Równania wielomianowe z parametrem"],"mat kwadrat"],
["https://www.youtube.com/embed/f_Kv7-oiMLw",["film-rozszerzenie2"],"WSZYSTKO o: NIERÓWNOŚCI WIELOMIANOWE. Jak rysować wykres wielomianu❓Co to jest krotność pierwiastka❓","8","youtube",["Nierówności wielomianowe"],"A po co MI ta MATMA?"],
["https://www.youtube.com/embed/gD6a3ff3rpk",["film-rozszerzenie2"],"Funkcje wielomianowe.","8","youtube",["Funkcje wielomianowe"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/4zZIg3pWbqM",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa II. Wielomiany. Rozkładanie wielomianów na czynniki","8","youtube",["Rozkładanie wielomianów na czynniki"],"Pizza Math"],
["https://www.youtube.com/embed/YvBAyEhoF7Y",["film-rozszerzenie2"],"Pacz jaki WIELOMIAN z PARAMETREM❗️MATURA ROZSZERZONA ARKUSZ POKAZOWY Zad.4","8","youtube",["Równania wielomianowe z parametrem"],"A po co MI ta MATMA?"],
["https://www.youtube.com/embed/fyGjqCLOsP0",["film-rozszerzenie2"],"Jak dzielić WIELOMIAN przez WIELOMIAN na MATURZE ROZSZERZONEJ❓","8","youtube",["Dzielenie wielomianu przez wielomian stopnia większego niż 1"],"A po co MI ta MATMA?"],
["https://www.youtube.com/embed/8hD1QJCINKI",["film-rozszerzenie2"],"Równania wielomianowe z parametrem cz 1","8","youtube",["Równania wielomianowe z parametrem"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/POTcpOUjRRo",["film-podstawa2","film-rozszerzenie2"],"7 równań wielomianowych, które musisz znać KURS MATURA PODSTAWA  wielomiany cz.4","8","youtube",["Zadania prowadzące do równań wielomianowych"],"MiedzianyFsor"],
["https://www.youtube.com/embed/emW8969R64I",["film-rozszerzenie2"],"Liceum. Klasa II. Wielomiany. Równania wielomianowe z parametrem","8","youtube",["Równania wielomianowe z parametrem"],"Pizza Math"],
["https://www.youtube.com/embed/182OB1YR0TY",["film-podstawa2","film-rozszerzenie2"],"Matematyka - Pierwiastki całkowite i wymierne wielomianu (teoria)","8","youtube",["Pierwiastki wymierne wielomianu"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/jrJZFI8YIOQ",["film-rozszerzenie2"],"Pierwiastek wielokrotny wielomianu - zadania z parametrem cz1","8","youtube",["Pierwiastek wielokrotny"],"Justyna jm"],
["https://www.youtube.com/embed/VCGTknxaWs0",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa II. Wielomiany. Pierwiastek wielomianu","8","youtube",["Pierwiastki wymierne wielomianu"],"Pizza Math"],
["https://www.youtube.com/embed/S5DnjSgHz5A",["film-podstawa2","film-rozszerzenie2"],"Niezbędnik Maturzysty #14 Równania wielomianowe","8","youtube",["Zadania prowadzące do równań wielomianowych"],"matspot"],
["https://www.youtube.com/embed/XpS9WU3gkPo",["film-podstawa2","film-rozszerzenie2"],"Liceum. Klasa II. Wielomiany. Równania wielomianowe","8","youtube",["Zadania prowadzące do równań wielomianowych"],"Pizza Math"],
["https://www.youtube.com/embed/HZUNA8ygOhQ",["film-podstawa2","film-rozszerzenie2"],"Rozkład wielomianu na czynniki KURS MATURA PODSTAWA wielomiany cz.2","8","youtube",["Rozkładanie wielomianów na czynniki"],"MiedzianyFsor"],
["https://www.youtube.com/embed/VBXg0TzUWis",["film-rozszerzenie2"],"Nierówności wielomianowe","8","youtube",["Nierówności wielomianowe"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/iTsnplu3oN4",["film-rozszerzenie2"],"✨Nierówności wielomianowe ✨ wszystko co musisz wiedzieć! 🤩👌 (spis moich lekcji w opisie⤵️)","8","youtube",["Nierówności wielomianowe"],"matematykagryzie pl"],
["https://www.youtube.com/embed/9TPDWpnbzQw",["film-rozszerzenie2"],"Liceum. Klasa II. Wielomiany. Pierwiastek wielokrotny","8","youtube",["Pierwiastek wielokrotny"],"Pizza Math"],
["https://www.youtube.com/embed/6iUps_sfJTw",["film-rozszerzenie2"],"Liceum. Klasa II. Wielomiany. Funkcje wielomianowe","8","youtube",["Funkcje wielomianowe"],"Pizza Math"],
["https://www.youtube.com/embed/Vg3RENrFVnw",["film-rozszerzenie2"],"Krotność pierwiastka wielomianu","8","youtube",["Pierwiastek wielokrotny"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/Zs7wMf4muAg",["film-podstawa2","film-rozszerzenie2"],"Pierwiastki całkowite i pierwiastki wymierne wielomianu 2","8","youtube",["Pierwiastki wymierne wielomianu"],"cinematma"],
["https://www.youtube.com/embed/9PB9wIgDoQo",["film-podstawa2","film-rozszerzenie2"],"Rozkład wielomianów na czynniki − grupowanie wyrazów #3 [ Równania wielomianowe ]","8","youtube",["Rozkładanie wielomianów na czynniki"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/y_p06V3ozhs",["film-rozszerzenie2"],"Pierwiastek wielokrotny wielomianu","8","youtube",["Pierwiastek wielokrotny"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/kYwrzXyK8DE",["film-rozszerzenie2"],"Liceum. Klasa II. Wielomiany. Dzielenie wielomianów","8","youtube",["Dzielenie wielomianu przez wielomian stopnia większego niż 1"],"Pizza Math"],
["https://www.youtube.com/embed/eIN4mjeP8aY",["film-rozszerzenie2"],"NIERÓWNOŚCI WIELOMIANOWE - ROZSZERZENIE","8","youtube",["Nierówności wielomianowe"],"MatWujek"],
["https://www.youtube.com/embed/E2-a1RMu3iw",["film-podstawa2","film-rozszerzenie2"],"Rozkład wielomianu na czynniki","8","youtube",["Rozkładanie wielomianów na czynniki"],"AjkaMat"],
["https://www.youtube.com/embed/a5x4lwnvHM0",["film-rozszerzenie2"],"How To Graph Polynomial Functions Using End Behavior, Multiplicity & Zeros","8","youtube",["Funkcje wielomianowe"],"The Organic Chemistry Tutor"],
["https://www.youtube.com/embed/FqgMCDv6BUA",["film-podstawa2","film-rozszerzenie2"],"Rozłóż na czynniki możliwie najniższego stopnia wielomian | Operon zadanie 5 s.15","8","youtube",["Rozkładanie wielomianów na czynniki"],"Matematyka Na Plus"],
["https://www.youtube.com/embed/5tdBHaLdTNU",["film-rozszerzenie2"],"Pierwiastki wielokrotne 2","8","youtube",["Pierwiastek wielokrotny"],"cinematma"],
["https://www.youtube.com/embed/BvMBskQrRqs",["film-podstawa4","film-rozszerzenie4"],"Obliczanie logarytmów #2","2","youtube",["Logarytm - powtórzenie wiadomości"],"matspot"],
["https://www.youtube.com/embed/bUPcSqLBRMQ",["film-podstawa4","film-rozszerzenie4"],"Własności logarytmów","2","youtube",["Logarytm - powtórzenie wiadomości"],"matspot"],
["https://www.youtube.com/embed/LkjNsFxt9us",["film-podstawa4","film-rozszerzenie4"],"Własności logarytmów #2","2","youtube",["Logarytm - powtórzenie wiadomości"],"matspot"],
["https://www.youtube.com/embed/gRiE3P02qJY",["film-podstawa4","film-rozszerzenie4"],"Logarytmy - własności - zadanie maturalne.","2","youtube",["Logarytm - powtórzenie wiadomości"],"matspot"],
["https://www.youtube.com/embed/agRA42sBOSQ",["film-podstawa4","film-rozszerzenie4"],"Niezbędnik Maturzysty #1 Logarytmy","2","youtube",["Logarytm - powtórzenie wiadomości"],"matspot"],
["https://www.youtube.com/embed/Dl-UG39hhrQ",["film-podstawa4","film-rozszerzenie4"],"Twierdzenie o zmianie podstawy logarytmu LEKCJE Z FSOREM #24 ROZSZERZENIE","2","youtube",["Logarytm - powtórzenie wiadomości"],"MiedzianyFsor"],
["https://www.youtube.com/embed/oev7J-onZA0",["film-podstawa4","film-rozszerzenie4"],"Twierdzenie o zamianie podstawy logarytmu MATURA 2022 KURS ROZSZERZENIE Liczby rzeczywiste cz.1","2","youtube",["Logarytm - powtórzenie wiadomości"],"MiedzianyFsor"],
["https://www.youtube.com/embed/HtXEd0OWCFo",["film-podstawa4","film-rozszerzenie4"],"Dodawanie i odejmowanie logarytmów #2 [ Logarytmy ]","2","youtube",["Logarytm - powtórzenie wiadomości"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/xfkjvB66Ex0",["film-podstawa4","film-rozszerzenie4"],"Logarytm w wykładniku potęgi #4 [ Logarytmy ]","2","youtube",["Logarytm - powtórzenie wiadomości"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/VANUPgaNSPA",["film-podstawa4","film-rozszerzenie4"],"Działania na logarytmach #6 [ Logarytmy ]","2","youtube",["Logarytm - powtórzenie wiadomości"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/ClKPtUxFybY",["film-podstawa4","film-rozszerzenie4"],"OBLICZANIE LOGARYTMÓW [#19]","2","youtube",["Logarytm - powtórzenie wiadomości"],"Wzorowanie"],
["https://www.youtube.com/embed/JtMVR3BssTI",["film-podstawa4","film-rozszerzenie4"],"Logarytmy na poziomie rozszerzonym","2","youtube",["Funckja logarytmiczna - powtórzenie i uzupełnenie wiadomości","Przekształcenia wykresów funkcji logarytmicznych","Równania logarytmiczne"],"AjkaMat"],
["https://www.youtube.com/embed/zb5X7xqpydY",["film-podstawa4","film-rozszerzenie4"],"Logarytmy, funkcja wykładnicza i logarytmiczna - A co było na maturze rozszerzonej?","2","youtube",["Funckja logarytmiczna - powtórzenie i uzupełnenie wiadomości","Przekształcenia wykresów funkcji logarytmicznych"],"AjkaMat"],
["https://www.youtube.com/embed/0Yb67WnLd1I",["film-podstawa4","film-rozszerzenie4"],"Proste równania logarytmiczne #7 [ Logarytmy ]","2","youtube",["Równania logarytmiczne"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/O-2u5gZgm_I",["film-rozszerzenie4"],"Nierówności logarytmiczne","2","youtube",["Nierówności logarytmiczne"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/ns0C8LXT6jQ",["film-rozszerzenie4"],"Równania i nierówności logarytmiczne oraz wykładnicze.","2","youtube",["Równania logarytmiczne","Nierówności logarytmiczne"],"Krzysztof Zelner"],
["https://www.youtube.com/embed/F0gWu0zrRgs",["film-rozszerzenie4"],"Równania i nierówności logarytmiczne z wartością bezwzględną","2","youtube",["Równania logarytmiczne","Nierówności logarytmiczne"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/PUxmEDlDNpg",["film-podstawa4","film-rozszerzenie4"],"Równana logarytmiczne","2","youtube",["Równania logarytmiczne"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/TA1sBb5tCHE",["film-podstawa4","film-rozszerzenie4"],"FUNKCJA LOGARYTMICZNA - co to jest?","2","youtube",["Funckja logarytmiczna - powtórzenie i uzupełnenie wiadomości"],"Wzorowanie"],
["https://www.youtube.com/embed/CUPg7uaF-iI",["film-podstawa4","film-rozszerzenie4"],"Funkcja logarytmiczna i jej własności.","2","youtube",["Funckja logarytmiczna - powtórzenie i uzupełnenie wiadomości"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/P6RErJCMAcQ",["film-rozszerzenie3","film-podstawa3"],"Liceum. Klasa II. Ułamki algebraiczne","1","youtube",["Ułamek algebraiczny. Skracanie i rozszerzanie ułamków algebraicznych","Działania na ułamkach algebraicznych"],"Pizza Math"],
["https://www.youtube.com/embed/aUaYZ8AKOG4",["film-rozszerzenie3","film-podstawa3"],"Ułamek algebraiczny. Skracanie i rozszerzanie ułamków algebraicznych","1","youtube",["Ułamek algebraiczny. Skracanie i rozszerzanie ułamków algebraicznych","Działania na ułamkach algebraicznych"],"Matematyka Marta Trojak"],
["https://www.youtube.com/embed/HaAXA41KMyM",["film-rozszerzenie3","film-podstawa3"],"Wyrażenia wymierne - rozszerzenie ułamka - zadania","1","youtube",["Ułamek algebraiczny. Skracanie i rozszerzanie ułamków algebraicznych","Działania na ułamkach algebraicznych"],"Matemaks"],
["https://www.youtube.com/embed/vSISxH3GP4U",["film-rozszerzenie3","film-podstawa3"],"Liceum. Klasa II. Ułamki algebraiczne. Działania na ułamkach algebraicznych I","1","youtube",["Dodawanie i odejmowanie ułamków algebraicznych","Działania na ułamkach algebraicznych"],"Pizza Math"],
["https://www.youtube.com/embed/wewQ1H4R4T4",["film-rozszerzenie3","film-podstawa3"],"DODAWANIE I ODEJMOWANIE UŁAMKÓW ALGEBRAICZNYCH","1","youtube",["Dodawanie i odejmowanie ułamków algebraicznych","Działania na ułamkach algebraicznych"],"Matematyka Marta Trojak"],
["https://www.youtube.com/embed/C4N7Nap9MSw",["film-rozszerzenie3","film-podstawa3"],"Dodawanie i odejmowanie ułamków algebraicznych ","1","youtube",["Dodawanie i odejmowanie ułamków algebraicznych","Działania na ułamkach algebraicznych"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/7zbMbeUbHEg",["film-rozszerzenie3","film-podstawa3"],"Mnożenie i dzielenie ułamków algebraicznych ","1","youtube",["Mnożenie i dzielenie ułamków algebraicznych","Działania na ułamkach algebraicznych"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/4ECv8_ddefI",["film-rozszerzenie3","film-podstawa3"],"Mnożenie i dzielenie ułamków algebraicznych ","1","youtube",["Mnożenie i dzielenie ułamków algebraicznych","Działania na ułamkach algebraicznych"],"Andrzej Winnicki Hul"],
["https://www.youtube.com/embed/qUmhEbEduSo",["film-rozszerzenie3","film-podstawa3"],"Liceum. Klasa II. Ułamki algebraiczne. Działania na ułamkach algebraicznych II","1","youtube",["Mnożenie i dzielenie ułamków algebraicznych","Działania na ułamkach algebraicznych"],"Pizza Math"],
["https://www.youtube.com/embed/HfTIOREqg6g",["film-rozszerzenie3","film-podstawa3"],"MATURA MATEMATYKA Równania wymierne PEWNIAK równania i nierówności cz.6","1","youtube",["Równania wymierne"],"MiedzianyFsor"],
["https://www.youtube.com/embed/EfLKjM5grDU",["film-rozszerzenie3","film-podstawa3"],"Równania wymierne","1","youtube",["Równania wymierne"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/e7FiqgrQpSA",["film-rozszerzenie3","film-podstawa3"],"Kurs - matematyka 2023 - wyrażenia i równania wymierne","1","youtube",["Równania wymierne"],"Matemaks"],
["https://www.youtube.com/embed/8KP_58Q5ziU",["film-rozszerzenie3","film-podstawa3"],"Matematyka da się lubić: Zadania prowadzące do równań wymiernych #21","1","youtube",["Zadania tekstowe prowadzące do równań wymiernych"],"Matematyka da się lubić"],
["https://www.youtube.com/embed/99cC989wvqY",["film-rozszerzenie3","film-podstawa3"],"Zadanie 25. Równanie wymierne. Rozwiązanie zadania tekstowego","1","youtube",["Zadania tekstowe prowadzące do równań wymiernych"],"Asjonat"],
["https://www.youtube.com/embed/kd8iYZ6OO84",["film-rozszerzenie3","film-podstawa3"],"Zadanie 24. Równanie wymierne. Rozwiązanie zadania tekstowego","1","youtube",["Zadania tekstowe prowadzące do równań wymiernych"],"Asjonat"],
["https://www.youtube.com/embed/b63UT_4mREY",["film-rozszerzenie3","film-podstawa3"],"Funkcja homograficzna i jej wykres","1","youtube",["Funkcja homograficzna"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/er1bRI9M1ik",["film-rozszerzenie3","film-podstawa3"],"Funkcja homograficzna - zadania","1","youtube",["Funkcja homograficzna"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/Q1DP127juAI",["film-rozszerzenie3","film-podstawa3"],"Funkcja homograficzna 1","1","youtube",["Funkcja homograficzna"],"Urszula Kraujutowicz"],
["https://www.youtube.com/embed/wk3X7Wmw8TA",["film-rozszerzenie3","film-podstawa3"],"Funkcja homograficzna 2","1","youtube",["Funkcja homograficzna"],"Urszula Kraujutowicz"],
["https://www.youtube.com/embed/_3IIW5JzFlQ",["film-rozszerzenie3","film-podstawa3"],"Funkcja homograficzna 3","1","youtube",["Funkcja homograficzna"],"Urszula Kraujutowicz"],
["https://www.youtube.com/embed/kb44A06aGbo",["film-rozszerzenie3"],"Zadaniadowodowe #5 - średnia arytmetyczna i geometryczna","1","youtube",["Zadania na dowodzenie z zastosowaniem średniej arytmetycznej, średniej geometrycznej i średniej kwadratowej kilku liczb"],"Łukasz Grobelczyk"],
["https://www.youtube.com/embed/zwckEOaHM-I",["film-rozszerzenie3"],"Funkcja homograficzna - zadanie z wykresem i parametrem","1","youtube",["Zastosowanie wiadomości o funkcji homograficznej w zadaniach"],"Deyko"],
["https://www.youtube.com/embed/xXrnxJFKQe0",["film-rozszerzenie3"],"Matematyka da się lubić: Funkcja homograficzna w zadaniach #15","1","youtube",["Zastosowanie wiadomości o funkcji homograficznej w zadaniach"],"Matematyka da się lubić"],
["https://www.youtube.com/embed/uP-fC_2zY8U",["film-rozszerzenie3"],"MATURA MATEMATYKA Dowody algebraiczne Zadania PEWNIAK","1","youtube",["Zadania na dowodzenie z zastosowaniem średniej arytmetycznej, średniej geometrycznej i średniej kwadratowej kilku liczb"],"MiedzianyFsor"],
["https://www.youtube.com/embed/MIiCqs09v5E",["film-rozszerzenie3"],"Funkcja Wymierna na przykładzie Domu Schadzek","1","youtube",["Funkcje wymierne"],"PATOMATMA"],
["https://www.youtube.com/embed/C9LANIJi9_4",["film-rozszerzenie3"],"Liceum. Klasa II. Ułamki Algebraiczne. Zadania na dowodzenie","1","youtube",["Zadania na dowodzenie z zastosowaniem średniej arytmetycznej, średniej geometrycznej i średniej kwadratowej kilku liczb"],"Pizza Math"],
["https://www.youtube.com/embed/3axrnrgdR8c",["film-rozszerzenie3"],"Wykres funkcji wymiernej","1","youtube",["Funkcje wymierne"],"cinematma"],
["https://www.youtube.com/embed/er1bRI9M1ik",["film-rozszerzenie3"],"Funkcja homograficzna - zadania.","1","youtube",["Zastosowanie wiadomości o funkcji homograficznej w zadaniach"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/oQ51IAVtysU",["film-rozszerzenie3"],"Funkcja wymierna.","1","youtube",["Funkcje wymierne"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/WkL9TuqQn7w",["film-rozszerzenie3"],"Funkcja wymierna - dziedzina, miejsca zerowe, wykres asymptoty wszystko co musisz wiedzieć w 23 min!","1","youtube",["Funkcje wymierne"],"Akademia Matematyki Piotra Ciupaka"],
["https://www.youtube.com/embed/kueuZ7MVzAc",["film-podstawa3","film-rozszerzenie3"],"Badanie monotoniczności ciągu na podstawie wzoru #5 [ Ciągi - wprowadzenie ]","2","youtube",["Monotoniczność ciągów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/R4upCLttNqY",["film-podstawa3","film-rozszerzenie3"],"Wzór ogólny ciągu arytmetycznego - zadania część 2 #3 [ Ciąg arytmetyczny ]","2","youtube",["Ciąg arytmetyczny"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/mBmOQonqD3I",["film-podstawa3","film-rozszerzenie3"],"Suma początkowych wyrazów ciągu geometrycznego 2","2","youtube",["Suma początkowych wyrazów ciągu geometrycznego"],"cinematma"],
["https://www.youtube.com/embed/NvqyyfWmsaI",["film-rozszerzenie3"],"Obliczanie granic ciągów 2","2","youtube",["Obliczanie granic ciągów zbieżnych","Wybrane własności ciągów zbieżnych","Ciągi rozbieżne do nieskończoności"],"cinematma"],
["https://www.youtube.com/embed/utqRlyipPts",["film-podstawa3","film-rozszerzenie3"],"Ciąg rekurencyjny #7 [ Ciągi - wprowadzenie ]","2","youtube",["Określenie ciągu. Sposoby opisywania ciągów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/3eonHRGDPAU",["film-podstawa3","film-rozszerzenie3"],"LOKATY PIENIĘŻNE - procent prosty i procent składany","2","youtube",["Lokaty pieniężne i kredyty bankowe"],"MatWujek"],
["https://www.youtube.com/embed/GxYcP0_4cbU",["film-podstawa3","film-rozszerzenie3"],"Badanie monotoniczności ciągu na podstawie wzoru - trudniejsze przykłady #6 [ Ciągi - wprowadzenie ]","2","youtube",["Monotoniczność ciągów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/hBxoOpx6kis",["film-podstawa3","film-rozszerzenie3"],"Suma początkowych wyrazów ciągu arytmetycznego","2","youtube",["Suma początkowych wyrazów ciągu arytmetycznego"],"cinematma"],
["https://www.youtube.com/embed/34JS2wD6qGc",["film-podstawa3","film-rozszerzenie3"],"MATURA MATEMATYKA Ciąg arytmetyczny zadania PEWNIAK ciągi cz.2","2","youtube",["Ciąg arytmetyczny"],"MiedzianyFsor"],
["https://www.youtube.com/embed/5wGTo7q3ub4",["film-podstawa3","film-rozszerzenie3"],"Ciąg geometryczny zadania PEWNIAK ciągi cz.4 MATURA PODSTAWA","2","youtube",["Ciąg geometryczny"],"MiedzianyFsor"],
["https://www.youtube.com/embed/7RZFGoTEIwU",["film-podstawa3","film-rozszerzenie3"],"Suma n początkowych wyrazów ciągu arytmetycznego","2","youtube",["Suma początkowych wyrazów ciągu arytmetycznego"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/oe62X3Y41yI",["film-podstawa3","film-rozszerzenie3"],"MATURA MATEMATYKA Ciąg geometryczny, który mógł zaskoczyć PEWNIAK ciągi cz.7","2","youtube",["Ciąg arytmetyczny i ciąg geometryczny - zadania różne"],"MiedzianyFsor"],
["https://www.youtube.com/embed/J2NcYl4HaNU",["film-podstawa3","film-rozszerzenie3"],"Wyznaczanie wyrazów ciągu #2 [ Ciągi - wprowadzenie ]","2","youtube",["Określenie ciągu. Sposoby opisywania ciągów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/vJvlbE2vSkw",["film-podstawa3","film-rozszerzenie3"],"Określenie ciągu. Sposoby opisywania ciągów #1 [ Ciągi - wprowadzenie ]","2","youtube",["Określenie ciągu. Sposoby opisywania ciągów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/w-6e1k29mXw",["film-podstawa3","film-rozszerzenie3"],"Suma początkowych wyrazów ciągu geometrycznego 1","2","youtube",["Suma początkowych wyrazów ciągu geometrycznego"],"cinematma"],
["https://www.youtube.com/embed/vnS-3aCieDo",["film-podstawa3","film-rozszerzenie3"],"Wzór na kolejne wyrazy ciągu #3 [ Ciągi - wprowadzenie ]","2","youtube",["Określenie ciągu. Sposoby opisywania ciągów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/CVseyn411Vw",["film-podstawa3","film-rozszerzenie3"],"Wyprowadzenie wzoru na sumę początkowych wyrazów ciągu geometrycznego #4 [ Ciąg geometryczny ]","2","youtube",["Suma początkowych wyrazów ciągu geometrycznego"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/rsLz8JpY1_4",["film-podstawa3","film-rozszerzenie3"],"MATURA MATEMATYKA Ciąg arytmetyczny teoria PEWNIAK ciągi cz.1","2","youtube",["Ciąg arytmetyczny"],"MiedzianyFsor"],
["https://www.youtube.com/embed/A4UJlEw8XpU",["film-podstawa3","film-rozszerzenie3"],"Suma początkowych wyrazów ciągu geometrycznego 4","2","youtube",["Suma początkowych wyrazów ciągu geometrycznego"],"cinematma"],
["https://www.youtube.com/embed/88Ap63AgaIQ",["film-rozszerzenie3"],"Obliczanie granic ciągów 3","2","youtube",["Obliczanie granic ciągów zbieżnych","Wybrane własności ciągów zbieżnych","Ciągi rozbieżne do nieskończoności"],"cinematma"],
["https://www.youtube.com/embed/CbNVbi45DCU",["film-podstawa3","film-rozszerzenie3"],"Szybko i na temat. Ciąg Geometryczny Wstęp (Udostępnij na Grupce Klasowej)","2","youtube",["Ciąg geometryczny"],"PATOMATMA"],
["https://www.youtube.com/embed/L6wgFZ-mZVI",["film-podstawa3","film-rozszerzenie3"],"Ciąg arytmetyczny i geometryczny w jednym zadaniu PEWNIAK ciągi cz.6 MATURA PODSTAWA","2","youtube",["Ciąg arytmetyczny i ciąg geometryczny - zadania różne"],"MiedzianyFsor"],
["https://www.youtube.com/embed/f2pTGHSj89U",["film-podstawa3","film-rozszerzenie3"],"Suma wyrazów ciągu arytmetycznego - zadania #6 [ Ciąg arytmetyczny ]","2","youtube",["Suma początkowych wyrazów ciągu arytmetycznego"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/y3Q4yHnIQcg",["film-rozszerzenie3"],"Obliczanie granic ciągów liczbowych w postaci funkcji wymiernej","2","youtube",["Obliczanie granic ciągów zbieżnych","Wybrane własności ciągów zbieżnych","Ciągi rozbieżne do nieskończoności"],"AjkaMat"],
["https://www.youtube.com/embed/tQ3WJx04OhM",["film-podstawa3","film-rozszerzenie3"],"MATURA  Ciąg geometryczny teoria PEWNIAK ciągi cz.3 MATEMATYKA PODSTAWA","2","youtube",["Ciąg geometryczny"],"MiedzianyFsor"],
["https://www.youtube.com/embed/nUCnros3ImY",["film-podstawa3","film-rozszerzenie3"],"Szybko i na temat  Monotoniczność ciągu geometrycznego (Udostępnij na Grupce Klasowej)","2","youtube",["Monotoniczność ciągów"],"PATOMATMA"],
["https://www.youtube.com/embed/NZx8axJbg_M",["film-rozszerzenie3"],"Szereg geometryczny","2","youtube",["Szereg geometryczny"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/iv4G2pTc3mM",["film-podstawa3","film-rozszerzenie3"],"Zad 3. Procent składany. Lokata bankowa.","2","youtube",["Lokaty pieniężne i kredyty bankowe"],"Policzymy Się Z tą Matmą "],
["https://www.youtube.com/embed/u9OEM3d4oQA",["film-podstawa3","film-rozszerzenie3"],"Lokaty bankowe #6 [ Procenty - zastosowania ]","2","youtube",["Lokaty pieniężne i kredyty bankowe"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/SdrAtUiqwI4",["film-rozszerzenie3"],"Granice ciągów (co to w ogóle jest)?","2","youtube",["Granica ciągu liczbowego"],"Matura z Lewusem"],
["https://www.youtube.com/embed/eKfbHVw1nCk",["film-rozszerzenie3"],"Szereg geometryczny","2","youtube",["Szereg geometryczny"],"AjkaMat"],
["https://www.youtube.com/embed/eG7Ayobm61c",["film-podstawa3","film-rozszerzenie3"],"Wzór ogólny ciągu arytmetycznego - zadania część 1 #2 [ Ciąg arytmetyczny ]","2","youtube",["Ciąg arytmetyczny"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/nUCnros3ImY",["film-podstawa3","film-rozszerzenie3"],"Szybko i na temat  Monotoniczność ciągu geometrycznego (Udostępnij na Grupce Klasowej)","2","youtube",["Ciąg geometryczny"],"PATOMATMA"],
["https://www.youtube.com/embed/GMGwR4ixTGQ",["film-podstawa3","film-rozszerzenie3"],"Suma początkowych wyrazów ciągu geometrycznego 3","2","youtube",["Suma początkowych wyrazów ciągu geometrycznego"],"cinematma"],
["https://www.youtube.com/embed/xhSWv7tSLEI",["film-podstawa3","film-rozszerzenie3"],"Szybko i na temat. Ciąg Arytmetyczny Wstęp (Udostępnij na Grupce Klasowej)","2","youtube",["Ciąg arytmetyczny"],"PATOMATMA"],
["https://www.youtube.com/embed/JRGVHBQKYIE",["film-rozszerzenie3"],"Intuicja stojąca za granicami jednostronnymi [krok po kroku]","2","youtube",["Granica ciągu liczbowego"],"PATOMATMA"],
["https://www.youtube.com/embed/zJdRAhUW_6Q",["film-rozszerzenie3"],"Obliczanie granic ciągu","2","youtube",["Obliczanie granic ciągów zbieżnych","Wybrane własności ciągów zbieżnych","Ciągi rozbieżne do nieskończoności"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/R0vIqnShIvw",["film-rozszerzenie3"],"Obliczanie granic ciągów zbudowanych z pierwiastków","2","youtube",["Obliczanie granic ciągów zbieżnych","Wybrane własności ciągów zbieżnych","Ciągi rozbieżne do nieskończoności"],"AjkaMat"],
["https://www.youtube.com/embed/d2p91f7nDaE",["film-rozszerzenie3"],"Twierdzenie o trzech ciągach","2","youtube",["Obliczanie granic ciągów zbieżnych","Wybrane własności ciągów zbieżnych","Ciągi rozbieżne do nieskończoności"],"AjkaMat"],
["https://www.youtube.com/embed/rfv6Hhu-UF4",["film-podstawa3","film-rozszerzenie3"],"CIĄG ARYTMETYCZNY I GEOMETRYCZNY - typowe zadania na sprawdzian","2","youtube",["Ciąg arytmetyczny i ciąg geometryczny - zadania różne"],"MatWujek"],
["https://www.youtube.com/embed/gQdzymhGsoI",["film-podstawa3","film-rozszerzenie3"],"Monotoniczność ciągu - wprowadzenie #4 [ Ciągi - wprowadzenie ]","2","youtube",["Monotoniczność ciągów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/aO8d8gjUHvQ",["film-rozszerzenie3"],"Obliczanie granic ciągów","2","youtube",["Obliczanie granic ciągów zbieżnych","Wybrane własności ciągów zbieżnych","Ciągi rozbieżne do nieskończoności"],"cinematma"],
["https://www.youtube.com/embed/nZbDwTvywAk",["film-podstawa3","film-rozszerzenie3"],"Trzy wyrazy ciągu geometrycznego #3 [ Ciąg geometryczny ]","2","youtube",["Ciąg geometryczny"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/p0YKs3AXMlM",["film-rozszerzenie3"],"Obliczanie granic ciągów 4","2","youtube",["Obliczanie granic ciągów zbieżnych","Wybrane własności ciągów zbieżnych","Ciągi rozbieżne do nieskończoności"],"cinematma"],
["https://www.youtube.com/embed/Vozb3642PG4",["film-podstawa3","film-rozszerzenie3"],"100 dni do matury Dzień 20 Ciąg arytmetyczny i geometryczny zadania różne MATURA ROZSZERZONA 2023","2","youtube",["Ciąg arytmetyczny i ciąg geometryczny - zadania różne"],"Math Dream"],
["https://www.youtube.com/embed/xzG3ACbhV2o",["film-podstawa3","film-rozszerzenie3"],"Ciąg arytmetyczny - zadania z matur #7 [ Ciąg arytmetyczny ]","2","youtube",["Suma początkowych wyrazów ciągu arytmetycznego"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/oKWfOILVa1s",["film-podstawa3","film-rozszerzenie3"],"Co to jest ciąg arytmetyczny? #1 [ Ciąg arytmetyczny ]","2","youtube",["Ciąg arytmetyczny"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/rpUeGV8GU_o",["film-rozszerzenie3"],"Obliczanie granic ciągów 5","2","youtube",["Obliczanie granic ciągów zbieżnych","Wybrane własności ciągów zbieżnych","Ciągi rozbieżne do nieskończoności"],"cinematma"],
["https://www.youtube.com/embed/oJuW1BsAn4E",["film-podstawa3","film-rozszerzenie3"],"Określenie ciągu, sposoby opisywania ciągów.","2","youtube",["Określenie ciągu. Sposoby opisywania ciągów"],"Matfix"],
["https://www.youtube.com/embed/0wvLepl3V3I",["film-podstawa3","film-rozszerzenie3"],"Wzór ogólny ciągu geometrycznego - zadania #2 [ Ciąg geometryczny ]","2","youtube",["Ciąg geometryczny"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/2IPbm2Z_28I",["film-rozszerzenie3"],"Szereg geometryczny","2","youtube",["Szereg geometryczny"],"AjkaMat"],
["https://www.youtube.com/embed/Fs-K46kBTtU",["film-rozszerzenie3"],"Definicja granicy ciągu","2","youtube",["Granica ciągu liczbowego"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/BfUqpsyC0So",["film-podstawa3","film-rozszerzenie3"],"Ciąg arytmetyczny i geometryczny w zadaniach","2","youtube",["Ciąg arytmetyczny i ciąg geometryczny - zadania różne"],"AjkaMat"],
["https://www.youtube.com/embed/1pwk-Ln9Zog",["film-podstawa3","film-rozszerzenie3"],"Ciąg geometryczny - wprowadzenie #1 [ Ciąg geometryczny ]","2","youtube",["Ciąg geometryczny"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/jq_zLp01aSU",["film-podstawa3","film-rozszerzenie3"],"Lokaty pieniężne i kredyty bankowe 2","2","youtube",["Lokaty pieniężne i kredyty bankowe"],"Paweł Malarczyk"],
["https://www.youtube.com/embed/R9s1xFRQ8NU",["film-podstawa3","film-rozszerzenie3"],"Lokaty pieniężne i kredyty bankowe 3","2","youtube",["Lokaty pieniężne i kredyty bankowe"],"Paweł Malarczyk"],
["https://www.youtube.com/embed/pLIgTwhfjdM",["film-podstawa3","film-rozszerzenie3"],"Lokaty pieniężne i kredyty bankowe 1","2","youtube",["Lokaty pieniężne i kredyty bankowe"],"Paweł Malarczyk"],
["https://www.youtube.com/embed/zfN1nHzWKrw",["film-podstawa3","film-rozszerzenie3"],"Prawdopodobieństwo: permutacje i wariacje bez powtórzeń","3","youtube",["Wariacje","Permutacje"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/6G9FvCsy2GY",["film-podstawa3","film-rozszerzenie3"],"Kombinatoryka reguła mnożenia i dodawania Matura 2022","3","youtube",["Reguła mnożenia i reguła dodawania"],"E Matematyka"],
["https://www.youtube.com/embed/vNptXPPO84s",["film-podstawa3","film-rozszerzenie3"],"Kombinacje","3","youtube",["Kombinacje"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/QlZbGPbfj70",["film-podstawa3","film-rozszerzenie3"],"Kombinatoryka  - Kombinacje  cz 1.","3","youtube",["Kombinacje"],"Babka z Majcy"],
["https://www.youtube.com/embed/lM0jI0TRtf8",["film-rozszerzenie3"],"Dwumian Newtona i trójkąt Pascala","3","youtube",["Symbol Newtona. Wzór Newtona. Trójkąt Pascala"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/wZt3CrmW4jo",["film-rozszerzenie3"],"Kombinatoryka na poziomie rozszerzonym","3","youtube",["Kombinatoryka - zadania różne"],"AjkaMat"],
["https://www.youtube.com/embed/ZoweiOxNm10",["film-rozszerzenie3"],"Trójkąt Pascala","3","youtube",["Symbol Newtona. Wzór Newtona. Trójkąt Pascala"],"mat kwadrat"],
["https://www.youtube.com/embed/kqZmvX5RKaQ",["film-podstawa3","film-rozszerzenie3"],"Wariacje i permutacje","3","youtube",["Wariacje","Permutacje"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/fwjxYmKSTeM",["film-rozszerzenie3"],"Szybko i na temat. Prawdopodobieństwo i Kombinatoryka (Udostępnij na Grupce Klasowej)","3","youtube",["Kombinatoryka - zadania różne"],"PATOMATMA"],
["https://www.youtube.com/embed/Lly7BrWbI0U",["film-podstawa3","film-rozszerzenie3"],"Kombinatoryka - wariacje z powtórzeniami i bez powtórzeń","3","youtube",["Wariacje"],"The Mathteacher"],
["https://www.youtube.com/embed/XUDsaYGbph8",["film-podstawa3","film-rozszerzenie3"],"Kombinatoryka - Wariacje cz 1.","3","youtube",["Wariacje"],"Babka z Majcy"],
["https://www.youtube.com/embed/hc41EaqQtPg",["film-rozszerzenie3"],"Kombinatoryka zadania różne.","3","youtube",["Kombinatoryka - zadania różne"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/nooxlPJBQs4",["film-rozszerzenie3"],"Kombinatoryka - Silnia i Symbol Newtona.","3","youtube",["Symbol Newtona. Wzór Newtona. Trójkąt Pascala"],"Babka z Majcy"],
["https://www.youtube.com/embed/QWXmVLn-cWw",["film-rozszerzenie3"],"Silnia  Symbol Newtona","3","youtube",["Symbol Newtona. Wzór Newtona. Trójkąt Pascala"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/hjzdQu4cYO8",["film-rozszerzenie3"],"Zadania z kombinatoryki na poziomie podstawowym","3","youtube",["Kombinatoryka - zadania różne"],"AjkaMat"],
["https://www.youtube.com/embed/qJp-UBYrLIA",["film-podstawa3","film-rozszerzenie3"],"Reguła mnożenia - kombinatoryka.","3","youtube",["Reguła mnożenia i reguła dodawania"],"matspot"],
["https://www.youtube.com/embed/s6Q4QaIq59U",["film-rozszerzenie3"],"Trójkąta Pascala, a wzory skróconego mnożenia","3","youtube",["Symbol Newtona. Wzór Newtona. Trójkąt Pascala"],"mat kwadrat"],
["https://www.youtube.com/embed/sF4ZKHIfcMg",["film-podstawa3","film-rozszerzenie3"],"Reguła mnożenia i reguła dodawania","3","youtube",["Reguła mnożenia i reguła dodawania"],"AjkaMat"],
["https://www.youtube.com/embed/rYwIniIe7PY",["film-podstawa3","film-rozszerzenie3"],"Kombinatoryka - Permutacje cz 1.","3","youtube",["Permutacje"],"Babka z Majcy"],
["https://www.youtube.com/embed/tXcmzPUK9ng",["film-rozszerzenie3"],"Pewna własność symbolu Newtona","3","youtube",["Symbol Newtona. Wzór Newtona. Trójkąt Pascala"],"mat kwadrat"],
["https://www.youtube.com/embed/YXgnivzLVnU",["film-podstawa3","film-rozszerzenie3"],"Kombinacje: Na ile sposobów można wybrać drużynę ...","3","youtube",["Kombinacje"],"Planeta EDU"],
["https://www.youtube.com/embed/lj9FhBd5LYI",["film-podstawa3","film-rozszerzenie3"],"Równoległobok 2","4","youtube",["Równoległoboki"],"Paweł Malarczyk"],
["https://www.youtube.com/embed/cvyu8RUWxcY",["film-podstawa3","film-rozszerzenie3"],"Liceum. Klasa II. Geometria płaska. Podział czworokątów","4","youtube",["Podział czworokątów. Trapezoidy"],"Pizza Math"],
["https://www.youtube.com/embed/30EUaoaJx7A",["film-rozszerzenie3"],"Matematyka - Okrąg opisany na czworokącie","4","youtube",["Okrąg opisany na czworokącie","Okrąg opisany na czworokącie, okrąg wpisany w czworokąt - zadania na dowodzenie"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/IZPd4ZsN5iI",["film-podstawa3","film-rozszerzenie3"],"Liceum. Klasa II. Geometria płaska. Podobieństwo. Figury podobne","4","youtube",["Podobieństwo. Czworokąty podobne"],"Pizza Math"],
["https://www.youtube.com/embed/chQOTvvy5WE",["film-podstawa3","film-rozszerzenie3"],"Równoległobok 1","4","youtube",["Równoległoboki"],"Paweł Malarczyk"],
["https://www.youtube.com/embed/mPWpcWy7bk8",["film-podstawa3","film-rozszerzenie3"],"Trapezoidy","4","youtube",["Podział czworokątów. Trapezoidy"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/zHBnGC9vlq8",["film-podstawa3","film-rozszerzenie3"],"Geometria czworokąta - trapezy","4","youtube",["Trapezy"],"AjkaMat"],
["https://www.youtube.com/embed/K-c2Q6pNcJ0",["film-podstawa3","film-rozszerzenie3"],"Podział czworokątów  Trapezoidy 2","4","youtube",["Podział czworokątów. Trapezoidy"],"Andrzej Winnicki Hul"],
["https://www.youtube.com/embed/8wzW7_KOKGk",["film-podstawa3","film-rozszerzenie3"],"Trapez - wprowadzenie #3 [ Wielokąty ]","4","youtube",["Trapezy"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/rTbdj_Nxg5A",["film-podstawa3","film-rozszerzenie3"],"Podział czworokątów  Trapezoidy 1","4","youtube",["Podział czworokątów. Trapezoidy"],"Andrzej Winnicki Hul"],
["https://www.youtube.com/embed/04vEEe8ZCZg",["film-podstawa3","film-rozszerzenie3"],"Podobieństwo czworokątów","4","youtube",["Podobieństwo. Czworokąty podobne"],"Paweł Malarczyk"],
["https://www.youtube.com/embed/LcZ2xnbMctw",["film-podstawa3","film-rozszerzenie3"],"Romb - wprowadzenie #2 [ Wielokąty ]","4","youtube",["Równoległoboki"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/ePcYJN1804E",["film-podstawa3","film-rozszerzenie3"],"Równoległobok - wprowadzenie #1 [ Wielokąty ]","4","youtube",["Równoległoboki"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/Le8llqQUSSw",["film-podstawa3","film-rozszerzenie3"],"Trapezy.","4","youtube",["Trapezy"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/KIK0cokqULg",["film-rozszerzenie3"],"Okrąg wpisany w czworokąt","4","youtube",["Okrąg wpisany w czworokąt","Okrąg opisany na czworokącie, okrąg wpisany w czworokąt - zadania na dowodzenie"],"Matematyka z Angel"],
["https://www.youtube.com/embed/REHkrtGyE94",["film-rozszerzenie3"],"Liceum. Klasa II. Geometria płaska. Okrąg opisany na czworokącie","4","youtube",["Okrąg opisany na czworokącie","Okrąg opisany na czworokącie, okrąg wpisany w czworokąt - zadania na dowodzenie"],"Pizza Math"],
["https://www.youtube.com/embed/3lsv-sTkvW0",["film-podstawa3","film-rozszerzenie3"],"Równoległoboki.","4","youtube",["Równoległoboki"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/WEPzDW-5mYI",["film-rozszerzenie3"],"Okrąg wpisany w czworokąt.","4","youtube",["Okrąg wpisany w czworokąt","Okrąg opisany na czworokącie, okrąg wpisany w czworokąt - zadania na dowodzenie"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/Jw3ebxYxj8w",["film-rozszerzenie3"],"Okrąg opisany na czworokącie.","4","youtube",["Okrąg opisany na czworokącie","Okrąg opisany na czworokącie, okrąg wpisany w czworokąt - zadania na dowodzenie"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/Ie2XBb1NJxY",["film-podstawa3","film-rozszerzenie3"],"Podział czworokątów. Trapezoidy","4","youtube",["Podział czworokątów. Trapezoidy"],"Paweł Malarczyk"],
["https://www.youtube.com/embed/92zh2FTOoWs",["film-rozszerzenie3"],"Okrąg wpisany w czworokąt","4","youtube",["Okrąg wpisany w czworokąt","Okrąg opisany na czworokącie, okrąg wpisany w czworokąt - zadania na dowodzenie"],"Agata Szubert"],
["https://www.youtube.com/embed/8McWwaz7l2I",["film-rozszerzenie3"],"Liceum. Klasa II. Geometria płaska. Okrąg wpisany w czworokąt","4","youtube",["Okrąg wpisany w czworokąt","Okrąg opisany na czworokącie, okrąg wpisany w czworokąt - zadania na dowodzenie"],"Pizza Math"],
["https://www.youtube.com/embed/MVlZTfHIpf4",["film-podstawa3","film-rozszerzenie3"],"Pole prostokąta","5","youtube",["Pole prostokąta. Pole kwadratu"],"Paweł Malarczyk"],
["https://www.youtube.com/embed/H8ewzpgVujc",["film-podstawa3","film-rozszerzenie3"],"Stosunek pól wielokątów podobnych #6 [ Podobieństwo wielokątów ]","5","youtube",["Pola figur podobnych"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/mThqQ7a-DFE",["film-podstawa3","film-rozszerzenie3"],"Skala mapy - Oblicz rzeczywistą odległość.","5","youtube",["Mapa. Skala mapy"],"Babka z Majcy"],
["https://www.youtube.com/embed/2rNJHer4tno",["film-podstawa3","film-rozszerzenie3"],"Skala na planach i mapach #4 [ Skala - wprowadzenie ]","5","youtube",["Mapa. Skala mapy"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/7YjoggY4-V8",["film-podstawa3","film-rozszerzenie3"],"Skala mapy - Jak policzyć skalę mapy?","5","youtube",["Mapa. Skala mapy"],"Babka z Majcy"],
["https://www.youtube.com/embed/0YTt4QhV8Ew",["film-podstawa3","film-rozszerzenie3"],"Pole równoległoboku i rombu #6 [ Pola figur ]","5","youtube",["Pole równoległoboku. Pole rombu"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/yVG2uLeJwNc",["film-podstawa3","film-rozszerzenie3"],"Pole kwadratu i prostokąta - wprowadzenie #1 [ Pola figur - wprowadzenie / Prostokąty i kwadraty ]","5","youtube",["Pole prostokąta. Pole kwadratu"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/V1VCKTWKJTE",["film-podstawa3","film-rozszerzenie3"],"Pole trapezu - zadania #9 [ Pola figur ]","5","youtube",["Pole trapezu"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/R7tTb3eCx94",["film-podstawa3","film-rozszerzenie3"],"Pole czworokąta część II.","5","youtube",["Pole czworokąta - zadania różne"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/UheywcAY7mU",["film-podstawa3","film-rozszerzenie3"],"Pole czworokąta część I.","5","youtube",["Pole czworokąta - zadania różne"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/hUgrDsCNkfw",["film-podstawa3","film-rozszerzenie3"],"Pole równoległoboku i rombu - zadania #7 [ Pola figur ]","5","youtube",["Pole równoległoboku. Pole rombu"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/IBBEv2tUhyc",["film-podstawa3","film-rozszerzenie3"],"Oblicz pole trapezu z Twierdzenia Pitagorasa - Zadanie - Matfiz24.pl","5","youtube",["Pole trapezu"],"Marek Duda"],
["https://www.youtube.com/embed/PETgzgD1MDQ",["film-podstawa3","film-rozszerzenie3"],"Pole kwadratu i prostokąta - obliczenia praktyczne #2 [ Pola figur - wprowadzenie / Prostokąty i kwa","5","youtube",["Pole prostokąta. Pole kwadratu"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/DYfI9R4XmQw",["film-podstawa3","film-rozszerzenie3"],"Pole czworokąta - zadania różne","5","youtube",["Pole czworokąta - zadania różne"],"Paweł Malarczyk"],
["https://www.youtube.com/embed/Xi1pYb3qz48",["film-podstawa3","film-rozszerzenie3"],"Pola figur podobnych 1","5","youtube",["Pola figur podobnych"],"Paweł Malarczyk"],
["https://www.youtube.com/embed/fsn3kcEdsPw",["film-podstawa3","film-rozszerzenie3"],"pole prostokąta i kwadratu","5","youtube",["Pole prostokąta. Pole kwadratu"],"matspot"],
["https://www.youtube.com/embed/lM9fOBDNtLQ",["film-podstawa3","film-rozszerzenie3"],"Pola figur podobnych 2","5","youtube",["Pola figur podobnych"],"Paweł Malarczyk"],
["https://www.youtube.com/embed/BEAEa9ogLp8",["film-podstawa3","film-rozszerzenie3"],"Pole czworokąta. Zadania różne. Klasa3 Zadanie 1","5","youtube",["Pole czworokąta - zadania różne"],"Szkoła PitagoLasa"],
["https://www.youtube.com/embed/ziuRvOWnEUM",["film-podstawa3","film-rozszerzenie3"],"Jak obliczyć obwód i pole trapezu z kątami przy podstawie 45? | Matfiz24.pl","5","youtube",["Pole trapezu"],"Marek Duda"],
["https://www.youtube.com/embed/3nJNX0hRPMA",["film-podstawa3","film-rozszerzenie3"],"pole trapezu","5","youtube",["Pole trapezu"],"matspot"],
["https://www.youtube.com/embed/OILdeFa6f9s",["film-podstawa3","film-rozszerzenie3"],"Pola figur podobnych 3","5","youtube",["Pola figur podobnych"],"Paweł Malarczyk"],
["https://www.youtube.com/embed/VlnDctTNB_Q",["film-podstawa3","film-rozszerzenie3"],"Skala mapy - Zamiana skali liczbowej na mianowaną.","5","youtube",["Mapa. Skala mapy"],"Babka z Majcy"],
["https://www.youtube.com/embed/Ijm1lwqVSNo",["film-podstawa3","film-rozszerzenie3"],"pole rombu","5","youtube",["Pole równoległoboku. Pole rombu"],"matspot"],
["https://www.youtube.com/embed/3lmJFYtO42U",["film-podstawa3","film-rozszerzenie3"],"Zad 24. Planimetria. Pole trapezu.","5","youtube",["Pole trapezu"],"Policzymy Się Z tą Matmą "],
["https://www.youtube.com/embed/UfwSbU2iiBU",["film-podstawa3","film-rozszerzenie3"],"pole równoległoboku","5","youtube",["Pole równoległoboku. Pole rombu"],"matspot"],
["https://www.youtube.com/embed/1o8Fv7zE_b8",["film-podstawa3","film-rozszerzenie3"],"Pola figur podobnych.","5","youtube",["Pola figur podobnych"],"No i już"],
["https://www.youtube.com/embed/JnFXAbWvU24",["film-rozszerzenie3"],"Matematyka - Granica funkcji w punkcie","6","youtube",["Granica funkcji w punkcie","Obliczanie granicy funkcji w punkcie"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/7UccbM22pEk",["film-rozszerzenie3"],"Granice niewłaściwe 2","6","youtube",["Granica niewłaściwa funkcji"],"cinematma"],
["https://www.youtube.com/embed/RtNf0ew-6D8",["film-rozszerzenie3"],"Granice niewłaściwe 1","6","youtube",["Granica niewłaściwa funkcji"],"cinematma"],
["https://www.youtube.com/embed/Lm2zCW7wAvg",["film-rozszerzenie3"],"Ciągłość funkcji cz.1 Zbadaj czy funkcja jest ciągła?","6","youtube",["Ciągłość funkcji w punkcie","Ciągłość funkcji w zbiorze"],"Matematyka Na Plus"],
["https://www.youtube.com/embed/yO2uII6I2Rc",["film-rozszerzenie3"],"Granice jednostronne funkcji w punkcie","6","youtube",["Granice jednostronne funkcji w punkcie"],"AjkaMat"],
["https://www.youtube.com/embed/4sNIZwkoN_o",["film-rozszerzenie3"],"Asymptoty funkcji cz.3 Asymptota pozioma, asymptota pionowa, asymptota ukośna","6","youtube",["Asymptoty wykresu funkcji"],"Matematyka Na Plus"],
["https://www.youtube.com/embed/W3X14myj42A",["film-rozszerzenie3"],"Ciągłość funkcji","6","youtube",["Ciągłość funkcji w punkcie","Ciągłość funkcji w zbiorze"],"AjkaMat"],
["https://www.youtube.com/embed/GNNMW7A2rZc",["film-rozszerzenie3"],"Matematyka - Granice niewłaściwe funkcji","6","youtube",["Granica niewłaściwa funkcji"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/p7CUXh8SQnI",["film-rozszerzenie3"],"Asymptota pionowa, pozioma i ukośna","6","youtube",["Asymptoty wykresu funkcji"],"AjkaMat"],
["https://www.youtube.com/embed/M9-Tue--Ipo",["film-rozszerzenie3"],"Granica funkcji w nieskończoności","6","youtube",["Granica funkcji w nieskończoności"],"EllipsoidTV"],
["https://www.youtube.com/embed/jJgHfLpSZSk",["film-rozszerzenie3"],"Ciągłość funkcji w punkcie","6","youtube",["Ciągłość funkcji w punkcie"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/WO8w34-ch_E",["film-rozszerzenie3"],"Asymptoty funkcji cz.2","6","youtube",["Asymptoty wykresu funkcji"],"Matematyka Na Plus"],
["https://www.youtube.com/embed/_g1Wb-MxN40",["film-rozszerzenie3"],"Obliczanie pochodnych funkcji - wstęp","6","youtube",["Funkcja pochodna"],"AjkaMat"],
["https://www.youtube.com/embed/p7CUXh8SQnI",["film-rozszerzenie3"],"Asymptota pionowa, pozioma i ukośna","6","youtube",["Asymptoty wykresu funkcji"],"AjkaMat"],
["https://www.youtube.com/embed/NRSpkW4eak8",["film-rozszerzenie3"],"Co to pochodna i całka nieoznaczona? (feat. TestoWiron)","6","youtube",["Funkcja pochodna"],"PATOMATMA"],
["https://www.youtube.com/embed/R5avEDGOkzU",["film-rozszerzenie3"],"Granice funkcji w punkcie","6","youtube",["Granica funkcji w punkcie","Obliczanie granicy funkcji w punkcie"],"AjkaMat"],
["https://www.youtube.com/embed/xp767EjNkyM",["film-rozszerzenie3"],"Asymptoty funkcji cz.6 Asymptota pozioma, asymptota pionowa, asymptota ukośna | Grzymkowski z. 9.16","6","youtube",["Asymptoty wykresu funkcji"],"Matematyka Na Plus"],
["https://www.youtube.com/embed/UVrE267Es9c",["film-rozszerzenie3"],"Ciągłość funkcji cz.2 Wyznacz k tak, aby funkcja była ciągła","6","youtube",["Ciągłość funkcji w punkcie","Ciągłość funkcji w zbiorze"],"Matematyka Na Plus"],
["https://www.youtube.com/embed/t5e8Xe2IOCU",["film-rozszerzenie3"],"Wprowadzenie do obliczania granicy funkcji w punkcie","6","youtube",["Granica funkcji w punkcie","Obliczanie granicy funkcji w punkcie"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/ce098PpBe1c",["film-rozszerzenie3"],"Pochodna funkcji, Różniczka, Infinityzmalna zmiana - Wyprowadzenie definicji [krok po kroku]","6","youtube",["Pochodna funkcji w punkcie","Funkcja pochodna"],"PATOMATMA"],
["https://www.youtube.com/embed/eSN8WiFNnK4",["film-rozszerzenie3"],"Pochodna funkcji a jej monotoniczność","6","youtube",["Pochodna funkcji a monotoniczność funkcji"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/1a2_yr_8Y1w",["film-rozszerzenie3"],"Monotoniczność funkcji wyznaczana pochodnymi","6","youtube",["Pochodna funkcji a monotoniczność funkcji"],"AjkaMat"],
["https://www.youtube.com/embed/J0aYi6CMGaM",["film-rozszerzenie3"],"Uważaj na GRANICE w PUNKCIE na maturze rozszerzonej❗️","6","youtube",["Granica funkcji w punkcie","Obliczanie granicy funkcji w punkcie"],"A po co MI ta MATMA?"],
["https://www.youtube.com/embed/uz8cheUXEmU",["film-rozszerzenie3"],"Badanie funkcji - monotoniczność i ekstrema lokalne funkcji, wielomian","6","youtube",["Ekstrema lokalne funkcji"],"Marek Małolepszy"],
["https://www.youtube.com/embed/reBT1pRXck8",["film-rozszerzenie3"],"Pochodne funkcji złożonych - część II","6","youtube",["Funkcja złożona. Pochodna funkcji złożonej"],"AjkaMat"],
["https://www.youtube.com/embed/HQlxF7oaQYU",["film-rozszerzenie3"],"Matematyka - Granica funkcji w nieskończoności i asymptoty poziome","6","youtube",["Granica funkcji w nieskończoności"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/ElXOaino13U",["film-rozszerzenie3"],"Pochodna funkcji złożonej","6","youtube",["Funkcja złożona. Pochodna funkcji złożonej"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/GuEJy1ChmsU",["film-rozszerzenie3"],"Jak znaleźć styczną do wykresu funkcji?","6","youtube",["Styczna do wykresu funkcji"],"Babka z Majcy"],
["https://www.youtube.com/embed/x6yRWD_Ei7o",["film-rozszerzenie3"],"Optymalizacja w geometrii analitycznej","6","youtube",["Zadania optymalizacyjne"],"AjkaMat"],
["https://www.youtube.com/embed/d4s7ljinMvM",["film-rozszerzenie3"],"Styczna do wykresu funkcji","6","youtube",["Styczna do wykresu funkcji"],"Tomasz Kokot"],
["https://www.youtube.com/embed/O0MM9qEDFCU",["film-rozszerzenie3"],"Matematyka - Granice jednostronne funkcji","6","youtube",["Granice jednostronne funkcji w punkcie"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/2qo3ALAHSGI",["film-rozszerzenie3"],"Ekstrema lokalne funkcji","6","youtube",["Ekstrema lokalne funkcji"],"AjkaMat"],
["https://www.youtube.com/embed/HoDoOOybZIg",["film-rozszerzenie3"],"Pochodne funkcji złożonych - część I","6","youtube",["Funkcja złożona. Pochodna funkcji złożonej"],"AjkaMat"],
["https://www.youtube.com/embed/okk-Fs23vx8",["film-rozszerzenie3"],"Pochodna funkcji w danym punkcie","6","youtube",["Pochodna funkcji w punkcie"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/qd7qr06RtLs",["film-rozszerzenie3"],"Ekstremum funkcji","6","youtube",["Ekstrema lokalne funkcji"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/lfjs5ewphRw",["film-rozszerzenie3"],"odc. 9  Granice jednostronne funkcji","6","youtube",["Granice jednostronne funkcji w punkcie"],"Sekcja E-learningu Politechniki Wrocławskiej"],
["https://www.youtube.com/embed/z_5Hy6h8t-U",["film-rozszerzenie3"],"Asymptoty wykresu funkcji","6","youtube",["Asymptoty wykresu funkcji"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/hAWn2m7i9P4",["film-rozszerzenie3"],"Asymptoty funkcji cz.4 Asymptota pozioma, asymptota pionowa, asymptota ukośna","6","youtube",["Asymptoty wykresu funkcji"],"Matematyka Na Plus"],
["https://www.youtube.com/embed/iVIEClHxMok",["film-rozszerzenie3"],"Asymptoty funkcji cz.5 Asymptota pozioma, asymptota pionowa, asymptota ukośna","6","youtube",["Asymptoty wykresu funkcji"],"Matematyka Na Plus"],
["https://www.youtube.com/embed/aCpFMfCH7_k",["film-rozszerzenie3"],"Ciągłość funkcji cz.3 Czy mozna dobrac wartosc a tak by funkcja byla ciagla","6","youtube",["Ciągłość funkcji w punkcie","Ciągłość funkcji w zbiorze"],"Matematyka Na Plus"],
["https://www.youtube.com/embed/zz3zggfNO3o",["film-rozszerzenie3"],"Wartość największa i najmniejsza funkcji, a pochodna.","6","youtube",["Największa i najmniejsza wartość funkcji w przedziale"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/VPejbh3Bvvg",["film-rozszerzenie3"],"Pochodna funkcji w punkcie z definicji","6","youtube",["Pochodna funkcji w punkcie"],"The Mathteacher"],
["https://www.youtube.com/embed/h-dVTh9TIMI",["film-rozszerzenie3"],"Najmniejsza i największa wartość funkcji w przedziale przedziały monotoniczności analiza matematyczn","6","youtube",["Największa i najmniejsza wartość funkcji w przedziale"],"Math Dream"],
["https://www.youtube.com/embed/Gk3X8hq-PKI",["film-rozszerzenie3"],"Styczna do wykresu funkcji w punkcie","6","youtube",["Styczna do wykresu funkcji"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/ZCEHbnBz72s",["film-rozszerzenie3"],"Granica funkcji w nieskończoności","6","youtube",["Granica funkcji w nieskończoności"],"AjkaMat"],
["https://www.youtube.com/embed/FBPdHJwhhCY",["film-rozszerzenie3"],"Pochodna funkcji a monotoniczność funkcji","6","youtube",["Pochodna funkcji a monotoniczność funkcji"],"The Mathteacher"],
["https://www.youtube.com/embed/6TF3FkuEwz0",["film-rozszerzenie3"],"Równanie stycznej do wykresu funkcji","6","youtube",["Styczna do wykresu funkcji"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/X__SR4HHDuU",["film-rozszerzenie3"],"Trygonometria","7","youtube",["Funkcje trygonometryczne zmiennej rzeczywistej - powtórzenie wiadomości z klasy 2."],"AjkaMat"],
["https://www.youtube.com/embed/pwcgekELd5U",["film-rozszerzenie3"],"Matematyka - Przekształcenia wykresów funkcji trygonometrycznych (cześć druga)","7","youtube",["Przekształcenia wykresów funkcji trygonometrycznych"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/e_v-lx7DGLg",["film-rozszerzenie3"],"Sinus, cosinus i tangens podwojonego kąta (sin(2x), cos(2x), tg(2x))","7","youtube",["Funkcje trygonometryczne wielokrotności kąta"],"mat kwadrat"],
["https://www.youtube.com/embed/1LIP7FLpkyM",["film-rozszerzenie3"],"Liceum. Klasa I. Funkcje trygonometryczne dowolnego kąta","7","youtube",["Funkcje trygonometryczne zmiennej rzeczywistej - powtórzenie wiadomości z klasy 2."],"Pizza Math"],
["https://www.youtube.com/embed/pwoZmLY2ITU",["film-rozszerzenie3"],"Wzory redukcyjne.","7","youtube",["Funkcje trygonometryczne zmiennej rzeczywistej - powtórzenie wiadomości z klasy 2."],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/xh74Tvm9VEQ",["film-rozszerzenie3"],"Wyznacz wartości funkcji trygonometrycznych dla kątów. Wielokrotność kąta 360 stopni","7","youtube",["Funkcje trygonometryczne wielokrotności kąta"],"Matematyka Na Plus"],
["https://www.youtube.com/embed/G54F3PBUio4",["film-rozszerzenie3"],"Matematyka - Przekształcenia wykresów funkcji trygonometrycznych (cześć pierwsza)","7","youtube",["Przekształcenia wykresów funkcji trygonometrycznych"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/uMNIFLCwz5s",["film-rozszerzenie3"],"Równania trygonometryczne cz. 2","7","youtube",["Równania trygonometryczne, cz. 1.","Równania trygonometryczne, cz. 2."],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/msz4Of8ezsY",["film-rozszerzenie3"],"Matematyka - Funkcje trygonometryczne sumy i różnicy kątów (część II)","7","youtube",["Funkcje trygonometryczne sumy i różnicy"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/BnkCicZDHyI",["film-rozszerzenie3"],"Funkcje trygonometryczne sumy i różnicy kątów","7","youtube",["Funkcje trygonometryczne sumy i różnicy"],"cinematma"],
["https://www.youtube.com/embed/aZXXHaIkQck",["film-rozszerzenie3"],"Matematyka - Równania trygonometryczne (część druga)","7","youtube",["Równania trygonometryczne, cz. 1.","Równania trygonometryczne, cz. 2."],"Zaliczone na 5!"],
["https://www.youtube.com/embed/90wN3-sMg0A",["film-rozszerzenie3"],"Równania trygonometryczna cz 1","7","youtube",["Równania trygonometryczne, cz. 1.","Równania trygonometryczne, cz. 2."],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/6po3hJMmP1o",["film-rozszerzenie3"],"Trygonometria na poziomie rozszerzonym","7","youtube",["Funkcje trygonometryczne wielokrotności kąta"],"AjkaMat"],
["https://www.youtube.com/embed/gCt24sDFuJI",["film-rozszerzenie3"],"Liceum. Klasa I. Funkcje okresowe","7","youtube",["Funkcje trygonometryczne zmiennej rzeczywistej - powtórzenie wiadomości z klasy 2."],"Pizza Math"],
["https://www.youtube.com/embed/1UbiaUF6jEM",["film-rozszerzenie3"],"Liceum. Klasa I. Tożsamości trygonometryczne","7","youtube",["Funkcje trygonometryczne zmiennej rzeczywistej - powtórzenie wiadomości z klasy 2."],"Pizza Math"],
["https://www.youtube.com/embed/GFHcnIA2g4Q",["film-rozszerzenie3"],"Pochodne funkcji trygonometrycznych: Zadanie 1","7","youtube",["Pochodne funkcji trygonometrycznych"],"Piotr Szlagor"],
["https://www.youtube.com/embed/cklfoqlSzK8",["film-rozszerzenie3"],"Matematyka - Funkcje trygonometryczne sumy i różnicy kątów (część I)","7","youtube",["Funkcje trygonometryczne sumy i różnicy"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/PGUwlCUMAMY",["film-rozszerzenie3"],"Przekształcanie wykresów funkcji trygonometrycznych 2","7","youtube",["Przekształcenia wykresów funkcji trygonometrycznych"],"cinematma"],
["https://www.youtube.com/embed/xzFS_RbfK_Y",["film-rozszerzenie3"],"MATURA 2022 2023 Równanie trygonometryczne zadanie za 8% PEWNIAK MATEMATYKA ROZSZERZONA","7","youtube",["Równania trygonometryczne, cz. 1.","Równania trygonometryczne, cz. 2."],"MiedzianyFsor"],
["https://www.youtube.com/embed/2nJMgxMqNvw",["film-rozszerzenie3"],"Równania trygonometryczne cz.3","7","youtube",["Równania trygonometryczne, cz. 1.","Równania trygonometryczne, cz. 2."],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/xi2n2osw3Hc",["film-rozszerzenie3"],"Przekształcanie wykresów funkcji trygonometrycznych","7","youtube",["Przekształcenia wykresów funkcji trygonometrycznych"],"cinematma"],
["https://www.youtube.com/embed/kMLxI2gLymM",["film-rozszerzenie3"],"Nierówności trygonometryczne","7","youtube",["Nierówności trygonometryczne"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/Lc7hB9njsAI",["film-rozszerzenie3"],"Równania trygonometryczne","7","youtube",["Równania trygonometryczne, cz. 1.","Równania trygonometryczne, cz. 2."],"cinematma"],
["https://www.youtube.com/embed/FzNRkQEOjes",["film-rozszerzenie3"],"Pochodne funkcji trygonometrycznych: Zadanie 2","7","youtube",["Pochodne funkcji trygonometrycznych"],"Piotr Szlagor"],
["https://www.youtube.com/embed/jDoW6bFZsnY",["film-rozszerzenie3"],"Matematyka - Nierówności trygonometryczne","7","youtube",["Nierówności trygonometryczne"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/7PKzlTaQNzY",["film-rozszerzenie3"],"Pochodne funkcji trygonometrycznych: Zadanie 3","7","youtube",["Pochodne funkcji trygonometrycznych"],"Piotr Szlagor"],
["https://www.youtube.com/embed/_MtW6RiJnTI",["film-rozszerzenie3"],"Trygonometria   sumy i różnice funkcji trygonometrycznych","7","youtube",["Sumy i różnice funkcji trygonometrycznych"],"The Mathteacher"],
["https://www.youtube.com/embed/OM_roXzVf9I",["film-rozszerzenie3"],"Nierówności trygonometryczne   część 1","7","youtube",["Nierówności trygonometryczne"],"Andrzej Winnicki Hul"],
["https://www.youtube.com/embed/nyFxbJ8nfqI",["film-rozszerzenie3"],"Sumy i różnice funkcji trygonometrycznych","7","youtube",["Sumy i różnice funkcji trygonometrycznych"],"Andrzej Winnicki Hul"],
["https://www.youtube.com/embed/zbBD6_cyXq4",["film-rozszerzenie3"],"Trygonometria - nierówności trygonometryczne","7","youtube",["Nierówności trygonometryczne"],"The Mathteacher"],
["https://www.youtube.com/embed/VQRdg6Nhy7w",["film-rozszerzenie3"],"Nierówności trygonometryczne   część 2","7","youtube",["Nierówności trygonometryczne"],"Andrzej Winnicki Hul"],
["https://www.youtube.com/embed/ekezHHmMsfg",["film-rozszerzenie3"],"Granice funkcji trygonometrycznych","7","youtube",["Pochodne funkcji trygonometrycznych"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/H9i1y9Hdtl4",["film-rozszerzenie3"],"Liceum. Klasa I. Symetria środkowa i osiowa w układzie współrzędnych","8","youtube",["Wybrane przekształcenia geometryczne w układzie współrzędnych"],"Pizza Math"],
["https://www.youtube.com/embed/56-8f3TLJSg",["film-rozszerzenie3"],"Geometria Analityczna - Zadanie i rozwiązaniem - Obliczanie punktu przecięcia się przekątnych kwadr.","8","youtube",["Zadania różne z geometrii analitycznej"],"PATOMATMA"],
["https://www.youtube.com/embed/RcNPFGInLHY",["film-rozszerzenie3"],"Równoległość i prostopadłość prostych w układzie współrzędnych","8","youtube",["Proste w układzie współrzędnych"],"AjkaMat"],
["https://www.youtube.com/embed/OYZnw3JNYLE",["film-rozszerzenie3"],"Wzajemne położenie prostej i okręgu","8","youtube",["Równanie okręgu. Wzajemne położenie prostej i okręgu"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/D8DRevu5PzU",["film-rozszerzenie3"],"Analiza matematyczna - A co było na maturze rozszerzonej?","8","youtube",["Zastosowanie analizy matematycznej w rozwiązywaniu zadań z geometrii analitycznej"],"AjkaMat"],
["https://www.youtube.com/embed/mJZCjfrXQFY",["film-rozszerzenie3"],"Zastosowanie analizy matematycznej w geometrii analitycznej - zadanie maturalne [ROZSZERZENIE]","8","youtube",["Zastosowanie analizy matematycznej w rozwiązywaniu zadań z geometrii analitycznej"],"Lernado"],
["https://www.youtube.com/embed/jzFMDYyP8A8",["film-rozszerzenie3"],"Matematyka - Odległość punktu od prostej","8","youtube",["Odległość punktu od prostej. Odległość między dwiema prostymi równoległymi"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/Om1KCOJ4Ais",["film-rozszerzenie3"],"Geometria analityczna 4 zadania","8","youtube",["Zadania różne z geometrii analitycznej"],"PATOMATMA"],
["https://www.youtube.com/embed/phfZTzT6Lsk",["film-rozszerzenie3"],"Pole trójkąta w geometrii analitycznej","8","youtube",["Pole trójkąta. Pole wielokąta"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/4y9vO98B344",["film-rozszerzenie3"],"Geometria analityczna - Jednokładność","8","youtube",["Wybrane przekształcenia geometryczne w układzie współrzędnych"],"The Mathteacher"],
["https://www.youtube.com/embed/TKWHnMFMEMg",["film-rozszerzenie3"],"Matematyka - Wzajemne położenie dwóch okręgów (teoria)","8","youtube",["Wzajemne położenie dwóch okręgów"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/FB1CYzlZmzk",["film-rozszerzenie3"],"Geometria analityczna - A co było na maturze rozszerzonej?","8","youtube",["Zastosowanie analizy matematycznej w rozwiązywaniu zadań z geometrii analitycznej"],"AjkaMat"],
["https://www.youtube.com/embed/1A--OvBIq8s",["film-rozszerzenie3"],"Równanie okręgu","8","youtube",["Równanie okręgu. Wzajemne położenie prostej i okręgu"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/9agT9Lu6pJQ",["film-rozszerzenie3"],"Liceum. Klasa I. Wektor w układzie współrzędnych","8","youtube",["Wektor w układzie współrzędnych. Podział odcinka"],"Pizza Math"],
["https://www.youtube.com/embed/9EpzSOGqV4U",["film-rozszerzenie3"],"A co było na maturze? - Geometria analityczna","8","youtube",["Zadania różne z geometrii analitycznej"],"AjkaMat"],
["https://www.youtube.com/embed/hd32Lp51yvA",["film-rozszerzenie3"],"Liceum. Klasa I. Przesunięcie równoległe o wektor","8","youtube",["Wybrane przekształcenia geometryczne w układzie współrzędnych"],"Pizza Math"],
["https://www.youtube.com/embed/Ig47g8-3X0M",["film-rozszerzenie3"],"Zadania, które każdy może zrobić MATURA KURS PODSTAWA Geometria analityczna cz.2","8","youtube",["Zadania różne z geometrii analitycznej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/46FNKY8PW6Q",["film-rozszerzenie3"],"Iloczyn skalarny wektorów","8","youtube",["Kąt między niezerowymi wektorami"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/kR-VCB6wH6E",["film-rozszerzenie3"],"Wektor w układzie współrzędnych.","8","youtube",["Wektor w układzie współrzędnych. Podział odcinka"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/0L5KX0SFoKw",["film-rozszerzenie3"],"Geometria analityczna - Kąt między wektorami, wektory równoległe i prostopadłe","8","youtube",["Kąt między niezerowymi wektorami"],"The Mathteacher"],
["https://www.youtube.com/embed/_q3yeKjr_uI",["film-rozszerzenie3"],"Równanie okręgu","8","youtube",["Równanie okręgu. Wzajemne położenie prostej i okręgu"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/P63HIJGAi4s",["film-rozszerzenie3"],"Iloczyn skalarny cz.5 Korzystając z iloczynu skalarnego oblicz kąt między wektorami","8","youtube",["Kąt między niezerowymi wektorami"],"Matematyka Na Plus"],
["https://www.youtube.com/embed/l7CGOwXlw-g",["film-rozszerzenie3"],"Odległość punktu od prostej","8","youtube",["Odległość punktu od prostej. Odległość między dwiema prostymi równoległymi"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/sgzEleRU4zk",["film-rozszerzenie3"],"Matematyka - Wzajemne położenie dwóch okręgów (zadania część I)","8","youtube",["Wzajemne położenie dwóch okręgów"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/2dX2knZ5oOo",["film-rozszerzenie3"],"MATURA MATEMATYKA zadanie za 10 % PEWNIAK geometria analityczna cz.5","8","youtube",["Zadania różne z geometrii analitycznej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/fvUK5y2L9BY",["film-rozszerzenie3"],"Równanie okręgu 1","8","youtube",["Równanie okręgu. Wzajemne położenie prostej i okręgu"],"cinematma"],
["https://www.youtube.com/embed/Lusq76o7DOA",["film-rozszerzenie3"],"Wektor w układzie współrzędnych. Zadanie 2","8","youtube",["Wektor w układzie współrzędnych. Podział odcinka"],"matzadanie"],
["https://www.youtube.com/embed/JxUlDbEKHC8",["film-rozszerzenie3"],"Najbardziej sadystyczne zadanie MATURA 2023 2022 KURS PODSTAWA Geometria analityczna cz.8","8","youtube",["Zadania różne z geometrii analitycznej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/MHLXWkZ8vMg",["film-rozszerzenie3"],"Odległość punktu od prostej","8","youtube",["Odległość punktu od prostej. Odległość między dwiema prostymi równoległymi"],"cinematma"],
["https://www.youtube.com/embed/tCQ3JEMGuZo",["film-rozszerzenie3"],"MATURA MATEMATYKA zadanie za 4 punkty z analitycznej PEWNIAK geometria analityczna cz.7","8","youtube",["Zadania różne z geometrii analitycznej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/10OPCP4SnY8",["film-rozszerzenie3"],"Równanie kierunkowe prostej.","8","youtube",["Proste w układzie współrzędnych"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/KisYpZ9jPeg",["film-rozszerzenie3"],"Wektor w układzie współrzędnych. Zadanie 1","8","youtube",["Wektor w układzie współrzędnych. Podział odcinka"],"matzadanie"],
["https://www.youtube.com/embed/yBdQ7m5aHks",["film-rozszerzenie3"],"Matematyka - Wzajemne położenie okręgu i prostej","8","youtube",["Równanie okręgu. Wzajemne położenie prostej i okręgu"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/N1C4PW3Szbw",["film-rozszerzenie3"],"Zadania za 2% i 4 % MATURA 2023 2015 KURS PODSTAWA Geometria analityczna cz.3","8","youtube",["Zadania różne z geometrii analitycznej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/vxeuRHYBz6Q",["film-rozszerzenie3"],"MATURA MATEMATYKA jak złapać 4% nie wiedząc co robić PEWNIAK geometria analityczna cz.4","8","youtube",["Zadania różne z geometrii analitycznej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/LLXwQ1DKNuY",["film-rozszerzenie3"],"Geometria analityczna- p. podstawowy [3 najważniejsze zadania z #5]","8","youtube",["Zadania różne z geometrii analitycznej"],"Szkoła maturzystów Łukasza Jarosińskiego"],
["https://www.youtube.com/embed/11TaEb4Sctc",["film-rozszerzenie3"],"Geometria analityczna  - zadania z matury podstawowej.","8","youtube",["Zadania różne z geometrii analitycznej"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/T2ws9b74rx4",["film-rozszerzenie3"],"MATURA MATEMATYKA warto zrobić to zadanie PEWNIAK geometria analityczna cz.6","8","youtube",["Zadania różne z geometrii analitycznej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/KPOFrG8HOwo",["film-rozszerzenie3"],"WEKTOR W UKŁADZIE WSPÓŁRZĘDNYCH - musisz to wiedzieć","8","youtube",["Wektor w układzie współrzędnych. Podział odcinka"],"Wzorowanie"],
["https://www.youtube.com/embed/P9nSmCV5Bm8",["film-rozszerzenie3"],"Ćwiczenie - wielokąty w układzie współrzędnych","8","youtube",["Pole trójkąta. Pole wielokąta"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/yW1xhdUA_3M",["film-rozszerzenie3"],"Wzajemne położenie dwóch okręgów","8","youtube",["Wzajemne położenie dwóch okręgów"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/gY3eK_t-xG0",["film-rozszerzenie3"],"Równanie prostej przechodzącej przez dwa punkty","8","youtube",["Proste w układzie współrzędnych"],"cinematma"],
["https://www.youtube.com/embed/LqBrQp-f2I0",["film-rozszerzenie3"],"Wektory - Wprowadzenie Odc.1 (na szybko bo wychodzę za 5 min) (Udostępnij na Grupce Klasowej)","8","youtube",["Wektor w układzie współrzędnych. Podział odcinka"],"PATOMATMA"],
["https://www.youtube.com/embed/xGMyXunKjUI",["film-rozszerzenie3"],"Przekształcenia izometryczne","8","youtube",["Wybrane przekształcenia geometryczne w układzie współrzędnych"],"MatematykaPiszPl"],
["https://www.youtube.com/embed/0MLhCM7R3fI",["film-rozszerzenie3"],"Geometria analityczna na poziomie rozszerzonym","8","youtube",["Zadania różne z geometrii analitycznej"],"AjkaMat"],
["https://www.youtube.com/embed/HryoCXRJAHA",["film-rozszerzenie3"],"Geometria analityczna - Odległość między prostymi równoległymi","8","youtube",["Odległość punktu od prostej. Odległość między dwiema prostymi równoległymi"],"The Mathteacher"],
["https://www.youtube.com/embed/2wtRwiUi7jk",["film-rozszerzenie3"],"Matematyka - Wzajemne położenie dwóch okręgów (zadania część II)","8","youtube",["Wzajemne położenie dwóch okręgów"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/7_OWlKVwL4s",["film-rozszerzenie3"],"Równanie okręgu 2","8","youtube",["Równanie okręgu. Wzajemne położenie prostej i okręgu"],"cinematma"],
["https://www.youtube.com/embed/WLEJMrGIfsg",["film-rozszerzenie3"],"Wektor w układzie współrzędnych - definicje, przykłady","8","youtube",["Wektor w układzie współrzędnych. Podział odcinka"],"matzadanie"],
["https://www.youtube.com/embed/tY11zfQu0XQ",["film-rozszerzenie3"],"Odwzorowywanie punktów przez jednokładność","8","youtube",["Wybrane przekształcenia geometryczne w układzie współrzędnych"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/o54IeP6Ofx8",["film-rozszerzenie3"],"Geometria analityczna - Pole trójkąta w układzie współrzędnych","8","youtube",["Pole trójkąta. Pole wielokąta"],"The Mathteacher"],
["https://www.youtube.com/embed/ZeGevXIOVDo",["film-rozszerzenie3"],"Równanie ogólne prostej","8","youtube",["Proste w układzie współrzędnych"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/7f1tGI-qB3g",["film-rozszerzenie3"],"Matematyka - Odległość między prostymi równoległymi","8","youtube",["Odległość punktu od prostej. Odległość między dwiema prostymi równoległymi"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/9agT9Lu6pJQ",["film-podstawa3"],"Liceum. Klasa I. Wektor w układzie współrzędnych","6","youtube",[" Wektor w układzie współrzędnych. Podział odcinka"],"Pizza Math"],
["https://www.youtube.com/embed/WLEJMrGIfsg",["film-podstawa3"],"Wektor w układzie współrzędnych - definicje, przykłady","6","youtube",[" Wektor w układzie współrzędnych. Podział odcinka"],"matzadanie"],
["https://www.youtube.com/embed/KisYpZ9jPeg",["film-podstawa3"],"Wektor w układzie współrzędnych. Zadanie 1","6","youtube",[" Wektor w układzie współrzędnych. Podział odcinka"],"matzadanie"],
["https://www.youtube.com/embed/Lusq76o7DOA",["film-podstawa3"],"Wektor w układzie współrzędnych. Zadanie 2","6","youtube",[" Wektor w układzie współrzędnych. Podział odcinka"],"matzadanie"],
["https://www.youtube.com/embed/kR-VCB6wH6E",["film-podstawa3"],"Wektor w układzie współrzędnych.","6","youtube",[" Wektor w układzie współrzędnych. Podział odcinka"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/KPOFrG8HOwo",["film-podstawa3"],"WEKTOR W UKŁADZIE WSPÓŁRZĘDNYCH - musisz to wiedzieć","6","youtube",[" Wektor w układzie współrzędnych. Podział odcinka"],"Wzorowanie"],
["https://www.youtube.com/embed/LqBrQp-f2I0",["film-podstawa3"],"Wektory - Wprowadzenie Odc.1 (na szybko bo wychodzę za 5 min) (Udostępnij na Grupce Klasowej)","6","youtube",[" Wektor w układzie współrzędnych. Podział odcinka"],"PATOMATMA"],
["https://www.youtube.com/embed/RcNPFGInLHY",["film-podstawa3"]," Równoległość i prostopadłość prostych w układzie współrzędnych","6","youtube",["Proste w układzie współrzędnych"],"AjkaMat"],
["https://www.youtube.com/embed/10OPCP4SnY8",["film-podstawa3"],"Równanie kierunkowe prostej.","6","youtube",["Proste w układzie współrzędnych"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/ZeGevXIOVDo",["film-podstawa3"],"Równanie ogólne prostej","6","youtube",["Proste w układzie współrzędnych"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/gY3eK_t-xG0",["film-podstawa3"]," Równanie prostej przechodzącej przez dwa punkty","6","youtube",["Proste w układzie współrzędnych"],"cinematma"],
["https://www.youtube.com/embed/MHLXWkZ8vMg",["film-podstawa3"],"Odległość punktu od prostej","6","youtube",["Odległość punktu od prostej. Odległość między dwiema prostymi równoległymi"],"cinematma"],
["https://www.youtube.com/embed/l7CGOwXlw-g",["film-podstawa3"],"Odległość punktu od prostej","6","youtube",["Odległość punktu od prostej. Odległość między dwiema prostymi równoległymi"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/jzFMDYyP8A8",["film-podstawa3"],"Matematyka - Odległość punktu od prostej","6","youtube",["Odległość punktu od prostej. Odległość między dwiema prostymi równoległymi"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/7f1tGI-qB3g",["film-podstawa3"],"Matematyka - Odległość między dwiema prostymi równoległymi","6","youtube",["Odległość punktu od prostej. Odległość między dwiema prostymi równoległymi"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/HryoCXRJAHA",["film-podstawa3"],"Geometria analityczna - Odległość między prostymi równoległymi","6","youtube",["Odległość punktu od prostej. Odległość między dwiema prostymi równoległymi"],"The Mathteacher"],
["https://www.youtube.com/embed/phfZTzT6Lsk",["film-podstawa3"],"Pole trójkąta w geometrii analitycznej","6","youtube",["Pole trójkąta. Pole wielokąta"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/o54IeP6Ofx8",["film-podstawa3"],"Geometria analityczna - Pole trójkąta w układzie współrzędnych","6","youtube",["Pole trójkąta. Pole wielokąta"],"The Mathteacher"],
["https://www.youtube.com/embed/P9nSmCV5Bm8",["film-podstawa3"],"Ćwiczenie - wielokąty w układzie współrzędnych","6","youtube",["Pole trójkąta. Pole wielokąta"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/OYZnw3JNYLE",["film-podstawa3"],"Wzajemne położenie prostej i okręgu","6","youtube",["Równanie okręgu. Wzajemne położenie prostej i okręgu"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/yBdQ7m5aHks",["film-podstawa3"],"Matematyka - Wzajemne położenie okręgu i prostej","6","youtube",["Równanie okręgu. Wzajemne położenie prostej i okręgu"],"Zaliczone na 5!"],
["https://www.youtube.com/embed/_q3yeKjr_uI",["film-podstawa3"],"Równanie okręgu","6","youtube",["Równanie okręgu. Wzajemne położenie prostej i okręgu"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/1A--OvBIq8s",["film-podstawa3"],"Równanie okręgu","6","youtube",["Równanie okręgu. Wzajemne położenie prostej i okręgu"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/fvUK5y2L9BY",["film-podstawa3"],"Równanie okręgu 1","6","youtube",["Równanie okręgu. Wzajemne położenie prostej i okręgu"],"cinematma"],
["https://www.youtube.com/embed/7_OWlKVwL4s",["film-podstawa3"],"Równanie okręgu 2","6","youtube",["Równanie okręgu. Wzajemne położenie prostej i okręgu"],"cinematma"],
["https://www.youtube.com/embed/Om1KCOJ4Ais",["film-podstawa3"],"Geometria analityczna 4 zadania","6","youtube",["Zadania różne z geometrii analitycznej"],"PATOMATMA"],
["https://www.youtube.com/embed/LLXwQ1DKNuY",["film-podstawa3"],"Geometria analityczna- p. podstawowy [3 najważniejsze zadania z #5]","6","youtube",["Zadania różne z geometrii analitycznej"],"Szkoła maturzystów Łukasza Jarosińskiego"],
["https://www.youtube.com/embed/11TaEb4Sctc",["film-podstawa3"],"Geometria analityczna - zadania z matury podstawowej.","6","youtube",["Zadania różne z geometrii analitycznej"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/56-8f3TLJSg",["film-podstawa3"],"Geometria Analityczna - Zadanie i rozwiązaniem - Obliczanie punktu przecięcia się przekątnych kwadr.","6","youtube",["Zadania różne z geometrii analitycznej"],"PATOMATMA"],
["https://www.youtube.com/embed/9EpzSOGqV4U",["film-podstawa3"],"A co było na maturze? - Geometria analityczna","6","youtube",["Zadania różne z geometrii analitycznej"],"AjkaMat"],
["https://www.youtube.com/embed/Ig47g8-3X0M",["film-podstawa3"]," Zadania, które każdy może zrobić MATURA KURS PODSTAWA Geometria analityczna cz.2","6","youtube",["Zadania różne z geometrii analitycznej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/N1C4PW3Szbw",["film-podstawa3"],"Zadania za 2% i 4 % MATURA 2023 2015 KURS PODSTAWA Geometria analityczna cz.3","6","youtube",["Zadania różne z geometrii analitycznej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/vxeuRHYBz6Q",["film-podstawa3"],"MATURA MATEMATYKA jak złapać 4% nie wiedząc co robić PEWNIAK geometria analityczna cz.4","6","youtube",["Zadania różne z geometrii analitycznej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/2dX2knZ5oOo",["film-podstawa3"],"MATURA MATEMATYKA zadanie za 10 % PEWNIAK geometria analityczna cz.5","6","youtube",["Zadania różne z geometrii analitycznej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/T2ws9b74rx4",["film-podstawa3"],"MATURA MATEMATYKA warto zrobić to zadanie PEWNIAK geometria analityczna cz.6","6","youtube",["Zadania różne z geometrii analitycznej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/tCQ3JEMGuZo",["film-podstawa3"],"MATURA MATEMATYKA zadanie za 4 punkty z analitycznej PEWNIAK geometria analityczna cz.7","6","youtube",["Zadania różne z geometrii analitycznej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/JxUlDbEKHC8",["film-podstawa3"],"Najbardziej sadystyczne zadanie MATURA 2023 2022 KURS PODSTAWA Geometria analityczna cz.8","6","youtube",["Zadania różne z geometrii analitycznej"],"MiedzianyFsor"],
["https://www.youtube.com/embed/H9i1y9Hdtl4",["film-podstawa3"],"Liceum. Klasa I. Symetria środkowa i osiowa w układzie współrzędnych","6","youtube",["Wybrane przekształcenia geometryczne w układzie współrzędnych"],"Pizza Math"],
["https://www.youtube.com/embed/xGMyXunKjUI",["film-podstawa3"],"Przekształcenia izometryczne","6","youtube",["Wybrane przekształcenia geometryczne w układzie współrzędnych"],"MatematykaPiszPl"],
["https://www.youtube.com/embed/tY11zfQu0XQ",["film-podstawa3"],"Odwzorowywanie punktów przez jednokładność","6","youtube",["Wybrane przekształcenia geometryczne w układzie współrzędnych"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/4y9vO98B344",["film-podstawa3"],"Geometria analityczna - Jednokładność","6","youtube",["Wybrane przekształcenia geometryczne w układzie współrzędnych"],"The Mathteacher"],
["https://www.youtube.com/embed/hd32Lp51yvA",["film-podstawa3"],"Liceum. Klasa I. Przesunięcie równoległe o wektor","6","youtube",["Wybrane przekształcenia geometryczne w układzie współrzędnych"],"Pizza Math"],
["https://www.youtube.com/embed/tl_oHwg3C20",["film-podstawa4","film-rozszerzenie4"],"Potęga o wykładniku rzeczywistym. Zadanie 3","1","youtube",["Potęga o wykładniku rzeczywistym - powtórzenie"],"matzadanie"],
["https://www.youtube.com/embed/KNz2vzxmaOA",["film-podstawa4","film-rozszerzenie4"],"Potęga o wykładniku rzeczywistym. Zadanie 1","1","youtube",["Potęga o wykładniku rzeczywistym - powtórzenie"],"matzadanie"],
["https://www.youtube.com/embed/1h9DN-soAyg",["film-podstawa4","film-rozszerzenie4"],"Wykres funkcji wykładniczej","1","youtube",["Przekształcenia wykresów funkcji wykładniczej"],"cinematma"],
["https://www.youtube.com/embed/_e3OTrKpWpQ",["film-podstawa4","film-rozszerzenie4"],"Funkcja Wykładnicza - Co to jest  i jakie są jej własności?","1","youtube",["Funkcja wykładnicza i jej własności"],"Babka z Majcy"],
["https://www.youtube.com/embed/bI9z60YGl8E",["film-podstawa4","film-rozszerzenie4"],"Równania wykładnicze - najważniejsza informacja","1","youtube",["Równania wykładnicze"],"Matura z Lewusem"],
["https://www.youtube.com/embed/vSnO_gwiDE0",["film-podstawa4","film-rozszerzenie4"],"Potęga o wykładniku rzeczywistym - definicje, przykłady","1","youtube",["Potęga o wykładniku rzeczywistym - powtórzenie"],"matzadanie"],
["https://www.youtube.com/embed/YhY-piG6wOM",["film-podstawa4","film-rozszerzenie4"],"Potęga o wykładniku rzeczywistym + dużo przykładów","1","youtube",["Potęga o wykładniku rzeczywistym - powtórzenie"],"No i już"],
["https://www.youtube.com/embed/YhrxtN1cpro",["film-podstawa4","film-rozszerzenie4"],"POTĘGA RZECZYWISTA - to musisz wiedzieć","1","youtube",["Potęga o wykładniku rzeczywistym - powtórzenie"],"Wzorowanie"],
["https://www.youtube.com/embed/Tij5OTgerBU",["film-podstawa4","film-rozszerzenie4"],"Funkcja wykładnicza i jej własności","1","youtube",["Funkcja wykładnicza i jej własności"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/JHUVuX2s7Cs",["film-podstawa4","film-rozszerzenie4"],"Wykres funkcji wykładniczej.","1","youtube",["Funkcja wykładnicza i jej własności"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/Vxr8o9jPEvk",["film-podstawa4","film-rozszerzenie4"],"Przekształcanie wykresów funkcji wykładniczych (przykład 2)","1","youtube",["Przekształcenia wykresów funkcji wykładniczej"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/701BW32P71U",["film-podstawa4","film-rozszerzenie4"],"Potęga o wykładniku rzeczywistym. Zadanie 2","1","youtube",["Potęga o wykładniku rzeczywistym - powtórzenie"],"matzadanie"],
["https://www.youtube.com/embed/-TS8Yy4alTw",["film-podstawa4","film-rozszerzenie4"],"Równania wykładnicze","1","youtube",["Równania wykładnicze"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/kWTjMyk9STc",["film-podstawa4","film-rozszerzenie4"],"Przekształcanie wykresów funkcji wykładniczych","1","youtube",["Przekształcenia wykresów funkcji wykładniczej"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/YfAyzgfzIo0",["film-podstawa4","film-rozszerzenie4"],"Nierówność wykładnicza","1","youtube",["Nierówności wykładnicze"],"cinematma"],
["https://www.youtube.com/embed/jGWWUH0D5i8",["film-podstawa4","film-rozszerzenie4"],"Nierówność wykładnicza cz.1 Nierówność z funkcją wykładniczą","1","youtube",["Nierówności wykładnicze"],"Matematyka Na Plus"],
["https://www.youtube.com/embed/PpH5EctbpT4",["film-podstawa4","film-rozszerzenie4"],"Nierówności wykładnicze","1","youtube",["Nierówności wykładnicze"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/-YAsbt11hHA",["film-rozszerzenie4"],"Zadanie tekstowe o funkcji wykładniczej","1","youtube",["Zastosowanie własności funkcji wykładniczej"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/TjTz35IhWIA",["film-podstawa4","film-rozszerzenie4"],"Równania wykładnicze [CAŁOŚCIOWE OMÓWIENIE]","1","youtube",["Równania wykładnicze"],"Lernado"],
["https://www.youtube.com/embed/aYiIcn70e0M",["film-rozszerzenie4"],"Funkcja wykładnicza. Zadanie 3","1","youtube",["Zastosowanie własności funkcji wykładniczej"],"matzadanie"],
["https://www.youtube.com/embed/9nXJIZaezLI",["film-podstawa4","film-rozszerzenie4"],"Nierówność wykładnicza cz.3 Nierówność z funkcją wykładniczą","1","youtube",["Nierówności wykładnicze"],"Matematyka Na Plus"],
["https://www.youtube.com/embed/WNa2GIUszII",["film-rozszerzenie4"],"Funkcja wykładnicza. Zadanie 1","1","youtube",["Zastosowanie własności funkcji wykładniczej"],"matzadanie"],
["https://www.youtube.com/embed/Pp5vU1IhO5s",["film-rozszerzenie4"],"Funkcja wykładnicza. Zadanie 2","1","youtube",["Zastosowanie własności funkcji wykładniczej"],"matzadanie"],
["https://www.youtube.com/embed/NnLOF24k4KA",["film-podstawa4","film-rozszerzenie4"],"Nierówność wykładnicza cz.2 Nierówność z funkcją wykładniczą","1","youtube",["Nierówności wykładnicze"],"Matematyka Na Plus"],
["https://www.youtube.com/embed/0PzqnMQqzv0",["film-rozszerzenie4"],"Funkcja wykładnicza. Zadanie 4","1","youtube",["Zastosowanie własności funkcji wykładniczej"],"matzadanie"],
["https://www.youtube.com/embed/DBXCIzYNBAg",["film-podstawa4","film-rozszerzenie4"],"A co było na maturze? - Funkcja wykładnicza i logarytmy","2","youtube",["Zastosowanie funkcji wykładniczej i funkcji logarytmicznej do rozwiązywania zadań umieszczonych w kontekście praktycznym"],"AjkaMat"],
["https://www.youtube.com/embed/s0q-ykjoRzU",["film-podstawa4","film-rozszerzenie4"],"Funkcja logarytmiczna, wykres, własności, miejsce zerowe, monotoniczność O czym trzeba pamiętać?!","2","youtube",["Funkcja logarytmiczna - powtórzenie i uzupełnienie wiadomości"],"Akademia Matematyki Piotra Ciupaka"],
["https://www.youtube.com/embed/xc4Mot0k_Yg",["film-podstawa4","film-rozszerzenie4"],"Przekształcenia wykresu funkcji logarytmicznej. Wykres funkcji logarytmicznej rozszerzenie. y=-log2x","2","youtube",["Przekształcenia wykresów funkcji logarytmicznych"],"Po prostu Licz"],
["https://www.youtube.com/embed/CUPg7uaF-iI",["film-podstawa4","film-rozszerzenie4"],"Funkcja logarytmiczna i jej własności.","2","youtube",["Funkcja logarytmiczna - powtórzenie i uzupełnienie wiadomości"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/zb5X7xqpydY",["film-podstawa4","film-rozszerzenie4"],"Logarytmy, funkcja wykładnicza  i logarytmiczna - A co było na maturze rozszerzonej?","2","youtube",["Zastosowanie funkcji wykładniczej i funkcji logarytmicznej do rozwiązywania zadań umieszczonych w kontekście praktycznym"],"AjkaMat"],
["https://www.youtube.com/embed/TA1sBb5tCHE",["film-podstawa4","film-rozszerzenie4"],"FUNKCJA LOGARYTMICZNA - co to jest?","2","youtube",["Funkcja logarytmiczna - powtórzenie i uzupełnienie wiadomości"],"Wzorowanie"],
["https://www.youtube.com/embed/LpLQcrY9rGs",["film-podstawa4","film-rozszerzenie4"],"y=log1/3 x Przekształcenia wykresu funkcji logarytmicznej. Wykres funkcji logarytmicznej rozszerze","2","youtube",["Przekształcenia wykresów funkcji logarytmicznych"],"Po prostu Licz"],
["https://www.youtube.com/embed/6PynAQRFVbc",["film-podstawa4","film-rozszerzenie4"],"y=log3 x Przekształcenia wykresu funkcji logarytmicznej. Wykres funkcji logarytmicznej rozszerze","2","youtube",["Przekształcenia wykresów funkcji logarytmicznych"],"Po prostu Licz"],
["https://www.youtube.com/embed/r3bw0xEuV1s",["film-podstawa4","film-rozszerzenie4"],"Rysowanie wykresu funkcji logarytmicznych","2","youtube",["Przekształcenia wykresów funkcji logarytmicznych"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/sRuZua8nqGI",["film-podstawa4","film-rozszerzenie4"],"Przekształcenia wykresów funkcji wykładniczych i logarytmicznych","2","youtube",["Przekształcenia wykresów funkcji logarytmicznych"],"Andrzej Winnicki Hul"],
["https://www.youtube.com/embed/zb5X7xqpydY",["film-rozszerzenie4"],"Logarytmy, funkcja wykładnicza  i logarytmiczna - A co było na maturze rozszerzonej?","2","youtube",["Funkcja logarytmiczna - powtórzenie i uzupełnienie wiadomości"],"AjkaMat"],
["https://www.youtube.com/embed/A7p1KMgq3E8",["film-podstawa4","film-rozszerzenie4"],"y=log1/2 x Przekształcenia wykresu funkcji logarytmicznej. Wykres funkcji logarytmicznej rozszerze","2","youtube",["Przekształcenia wykresów funkcji logarytmicznych"],"Po prostu Licz"],
["https://www.youtube.com/embed/nM4nJNK_xWk",["film-podstawa4","film-rozszerzenie4"],"Mediana #3 [ Średnia i mediana ]","3","youtube",["Mediana z próby i moda z próby. Skala centylowa"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/kHKL9NkKBG4",["film-podstawa4","film-rozszerzenie4"],"Czym jest statystyka? #1 [ Obliczenia praktyczne - tabele i wykresy ]","3","youtube",["Sposoby prezentowania danych zebranych w wyniku obserwacji statystycznej"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/_G_zYiVzXIQ",["film-podstawa4","film-rozszerzenie4"],"Średnia ważona","3","youtube",["Średnia z próby"],"Matematyka z Angel"],
["https://www.youtube.com/embed/6Q7e1c4LQzo",["film-podstawa4","film-rozszerzenie4"],"Statystyka - Wariancja i Odchylenie Standardowe","3","youtube",["Wariancja i odchylenie standardowe"],"DATAcademy"],
["https://www.youtube.com/embed/_3T7nK1KUxM",["film-podstawa4","film-rozszerzenie4"],"Średnia arytmetyczna #1 [ Średnia i mediana ]","3","youtube",["Średnia z próby"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/cldF_jqrKZc",["film-podstawa4","film-rozszerzenie4"],"Znajdowanie średniej, mediany i dominanty","3","youtube",["Mediana z próby i moda z próby. Skala centylowa"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/X4xgvGcAzMk",["film-podstawa4","film-rozszerzenie4"],"Średnia arytmetyczna - zadania #2 [ Średnia i mediana ]","3","youtube",["Średnia z próby"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/xrBoF-t6n4Y",["film-podstawa4","film-rozszerzenie4"],"MATURA MATEMATYKA Statystyka cz.1 PEWNIAK","3","youtube",["Mediana z próby i moda z próby. Skala centylowa"],"MiedzianyFsor"],
["https://www.youtube.com/embed/rLPxu9GoaaQ",["film-podstawa4","film-rozszerzenie4"],"Jak obliczyć odchylenie standardowe?","3","youtube",["Wariancja i odchylenie standardowe"],"Statystyka ludzkim głosem"],
["https://www.youtube.com/embed/74bgsWvdrkk",["film-podstawa4","film-rozszerzenie4"],"Średnia arytmetyczna i mediana","3","youtube",["Mediana z próby i moda z próby. Skala centylowa"],"matspot"],
["https://www.youtube.com/embed/W7RPfLwQKHg",["film-podstawa4","film-rozszerzenie4"],"Wstęp do statystyki - średnia, mediana i dominanta","3","youtube",["Mediana z próby i moda z próby. Skala centylowa"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/g7bLDNaVbVQ",["film-podstawa4","film-rozszerzenie4"],"Statystyka na poziomie podstawowym","3","youtube",["Sposoby prezentowania danych zebranych w wyniku obserwacji statystycznej"],"AjkaMat"],
["https://www.youtube.com/embed/aVDA2r0Cf2Q",["film-podstawa4","film-rozszerzenie4"],"Miary rozrzutu danych: zakres, wariancja i odchylenie standardowe","3","youtube",["Wariancja i odchylenie standardowe"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/PwKxocYfUrI",["film-podstawa4","film-rozszerzenie4"],"Wariancja i odchylenie standardowe","3","youtube",["Wariancja i odchylenie standardowe"],"Ewelina Lis-Jarnuszkiewicz Moja Mat-planeta"],
["https://www.youtube.com/embed/MHTGza604Nw",["film-podstawa4","film-rozszerzenie4"],"Sposoby przedstawiania danych","3","youtube",["Sposoby prezentowania danych zebranych w wyniku obserwacji statystycznej"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/H5pLC05ycoo",["film-podstawa4","film-rozszerzenie4"],"Rodzaje badań statystycznych","3","youtube",["Sposoby prezentowania danych zebranych w wyniku obserwacji statystycznej"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/169sKSotM8c",["film-podstawa4","film-rozszerzenie4"],"Średnia arytmetyczna i ważona","3","youtube",["Średnia z próby"],"Karolina Duda"],
["https://www.youtube.com/embed/br9s5FV-gHI",["film-podstawa4","film-rozszerzenie4"],"Rachunek prawdopodobieństwa","4","youtube",["Zdarzenia. Działania na zdarzeniach"],"AjkaMat"],
["https://www.youtube.com/embed/E8tumNViUW0",["film-podstawa4","film-rozszerzenie4"],"Zadania z rachunku prawdopodobieństwa na poziomie podstawowym","4","youtube",["Zdarzenia. Działania na zdarzeniach"],"AjkaMat"],
["https://www.youtube.com/embed/fwjxYmKSTeM",["film-podstawa4","film-rozszerzenie4"],"Szybko i na temat. Prawdopodobieństwo i Kombinatoryka (Udostępnij na Grupce Klasowej)","4","youtube",["Kombinatoryka - powtórzenie wiadomości"],"PATOMATMA"],
["https://www.youtube.com/embed/wZt3CrmW4jo",["film-rozszerzenie4"],"Kombinatoryka na poziomie rozszerzonym","4","youtube",["Kombinatoryka - powtórzenie wiadomości"],"AjkaMat"],
["https://www.youtube.com/embed/ShHsUfilP_8",["film-podstawa4","film-rozszerzenie4"],"MATURA  Rachunek Prawdopodobieństwa i kombinatoryka  PEWNIAK cz.1 teoria","4","youtube",["Kombinatoryka - powtórzenie wiadomości"],"MiedzianyFsor"],
["https://www.youtube.com/embed/fwjxYmKSTeM",["film-podstawa4","film-rozszerzenie4"],"Szybko i na temat. Prawdopodobieństwo i Kombinatoryka (Udostępnij na Grupce Klasowej)","4","youtube",["Kombinatoryka - powtórzenie wiadomości"],"PATOMATMA"],
["https://www.youtube.com/embed/uaDBlRr5kzc",["film-rozszerzenie4"],"100 dni do matury dzień 56 Twierdzenie o prawdopodobieństwie całkowitym MATURA 2023","4","youtube",["Twierdzenie o prawdopodobieństwie całkowitym. Wzór Bayesa"],"Math Dream"],
["https://www.youtube.com/embed/NYrA77obkHI",["film-podstawa4","film-rozszerzenie4"],"Metoda drzewa a rachunek prawdopodobieństwa","4","youtube",["Doświadczenia losowe wieloetapowe"],"AjkaMat"],
["https://www.youtube.com/embed/Oled4uVa08o",["film-rozszerzenie4"],"Twierdzenie Bayesa, czyli jak znaleźć mordercę! 😎","4","youtube",["Twierdzenie o prawdopodobieństwie całkowitym. Wzór Bayesa"],"Śmiem Wątpić"],
["https://www.youtube.com/embed/_Q7Vv3Rw9So",["film-rozszerzenie4"],"Zdarzenia niezależne - przykład","4","youtube",["Niezależność zdarzeń"],"mat kwadrat"],
["https://www.youtube.com/embed/56phk2DgeMA",["film-podstawa4","film-rozszerzenie4"],"Matura 2020 zadanie 30. Prawdopodobieństwo + milion rzutów kostką","4","youtube",["Określenie prawdopodobieństwa"],"PATOMATMA"],
["https://www.youtube.com/embed/pu-cOqDGSgU",["film-podstawa4","film-rozszerzenie4"],"Zadania z wykorzystaniem klasycznej definicji prawdopodobieństwa","4","youtube",["Prawdopodobieństwo klasyczne"],"AjkaMat"],
["https://www.youtube.com/embed/fNwo2aiJ35A",["film-rozszerzenie4"],"Niezależność zdarzeń (zdarzenia niezależne)","4","youtube",["Niezależność zdarzeń"],"mat kwadrat"],
["https://www.youtube.com/embed/af3LezeRQOM",["film-podstawa4","film-rozszerzenie4"],"100 dni do matury Dzień 54 Doświadczenie wieloetapowe Drzewko stochastyczne MATURA 2023","4","youtube",["Doświadczenia losowe wieloetapowe"],"Math Dream"],
["https://www.youtube.com/embed/Kp64tNvasJ4",["film-podstawa4","film-rozszerzenie4"],"Wartość oczekiwana MATURA 2023","4","youtube",["Zmienna losowa. Wartość oczekiwana zmiennej losowej"],"Ewelina Lis-Jarnuszkiewicz Moja Mat-planeta"],
["https://www.youtube.com/embed/JoPXb8aLGro",["film-rozszerzenie4"],"Łatwe Punkty na Maturze Rozszerzonej -  Schemat Bernoulliego - Powtórka do Matury z Matematyki 2024","4","youtube",["Schemat Bernoulliego"],"Matma Z Pasją"],
["https://www.youtube.com/embed/2GR0uu_MMhg",["film-podstawa4","film-rozszerzenie4"],"Aksjomatyczna definicja prawdopodobieństwa","4","youtube",["Określenie prawdopodobieństwa"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/_klbLJC-w98",["film-podstawa4","film-rozszerzenie4"],"TAK PO PROSTU - Matematyka 3 liceum 2.1 Prawdopodobieństwo. Zdarzenia losowe","4","youtube",["Doświadczenie losowe"],"TakPoProstu"],
["https://www.youtube.com/embed/CNXluhezCis",["film-rozszerzenie4"],"Prawdopodobieństwo warunkowe","4","youtube",["Prawdopodobieństwo warunkowe"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/h_Dv2_YL0RQ",["film-podstawa4","film-rozszerzenie4"],"Rozkład zmiennej losowej.Dystrybuanta, wartość oczekiwana,odchylenie / Random variable distribution.","4","youtube",["Zmienna losowa. Wartość oczekiwana zmiennej losowej"],"Matematyka Na Plus"],
["https://www.youtube.com/embed/2VtXqUPP3y0",["film-podstawa4","film-rozszerzenie4"],"Aksjomatyczna definicja prawdopodobieństwa, zadania na dowodzenie","4","youtube",["Określenie prawdopodobieństwa"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/-EvXqwTEkHU",["film-rozszerzenie4"],"Schemat Bernoulliego","4","youtube",["Schemat Bernoulliego"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/UUzxf5lZ3A4",["film-podstawa4","film-rozszerzenie4"],"Doświadczenie wieloetapowe","4","youtube",["Doświadczenia losowe wieloetapowe"],"AniMat 105"],
["https://www.youtube.com/embed/XcXzM3ObYhA",["film-rozszerzenie4"],"Wzór Bayesa i dokończenie poprzedniego zadania","4","youtube",["Twierdzenie o prawdopodobieństwie całkowitym. Wzór Bayesa"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/ne-rJ_y_myE",["film-podstawa4","film-rozszerzenie4"],"Prawdopodobieństwo klasyczne   winda","4","youtube",["Prawdopodobieństwo klasyczne"],"cinematma"],
["https://www.youtube.com/embed/KxNEQV0VQ54",["film-rozszerzenie4"],"Prawdopodobieństwo - Schemat Bernoulliego","4","youtube",["Schemat Bernoulliego"],"The Mathteacher"],
["https://www.youtube.com/embed/sMhpVcNPDFM",["film-rozszerzenie4"],"Prawdopodobieństwo - zdarzenia niezależne","4","youtube",["Niezależność zdarzeń"],"The Mathteacher"],
["https://www.youtube.com/embed/wzJSKRVnil4",["film-podstawa4","film-rozszerzenie4"],"Co to jest zmienna losowa","4","youtube",["Zmienna losowa. Wartość oczekiwana zmiennej losowej"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/fmdkWPi8em0",["film-podstawa4","film-rozszerzenie4"],"MATURA Rachunek Prawdopodobieństwa i kombinatoryka  PEWNIAK cz.2 zadania","4","youtube",["Kombinatoryka - powtórzenie wiadomości"],"MiedzianyFsor"],
["https://www.youtube.com/embed/mwOH9wjUj5o",["film-podstawa4","film-rozszerzenie4"],"Kl. III  Doświadczenie losowe. Zdarzenie elementarne","4","youtube",["Doświadczenie losowe"],"Mathematicus"],
["https://www.youtube.com/embed/oMgHvmWbkfI",["film-podstawa4","film-rozszerzenie4"],"Doświadczenie wieloetapowe - prawdopodobieństwo warunkowe PR","4","youtube",["Doświadczenia losowe wieloetapowe"],"Ewelina Lis-Jarnuszkiewicz Moja Mat-planeta"],
["https://www.youtube.com/embed/jyA1tkgX1nk",["film-podstawa4","film-rozszerzenie4"],"Prawdopodobieństwo - wieloetapowe zdarzenia losowe","4","youtube",["Doświadczenia losowe wieloetapowe"],"The Mathteacher"],
["https://www.youtube.com/embed/ZDvphZUZ1-0",["film-rozszerzenie4"],"Prawdopodobieństwo   twierdzenie o prawdopodobieństwie całkowitym, twierdzenia Bayesa #8","4","youtube",["Twierdzenie o prawdopodobieństwie całkowitym. Wzór Bayesa"],"Aitra"],
["https://www.youtube.com/embed/drNAD6iUYWY",["film-rozszerzenie4"],"Prawdopodobieństwo całkowite","4","youtube",["Twierdzenie o prawdopodobieństwie całkowitym. Wzór Bayesa"],"The Mathteacher"],
["https://www.youtube.com/embed/NnjOKijHNEw",["film-rozszerzenie4"],"Niezależność zdarzeń","4","youtube",["Niezależność zdarzeń"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/vTJcRn0Mv3w",["film-rozszerzenie4"],"Prawdopodobieństwo warunkowe","4","youtube",["Prawdopodobieństwo warunkowe"],"The Mathteacher"],
["https://www.youtube.com/embed/RuGxm-LarLc",["film-podstawa4","film-rozszerzenie4"],"Doświadczenia losowe","4","youtube",["Doświadczenie losowe"],"Karolina Duda"],
["https://www.youtube.com/embed/PkQf7JAtpx8",["film-podstawa4","film-rozszerzenie4"],"Działania na zdarzeniach - Rachunek prawdopodobieństwa - czerwiec 2016 zadanie 7 PR","4","youtube",["Zdarzenia. Działania na zdarzeniach"],"Math Dream"],
["https://www.youtube.com/embed/qakhLk4BIxw",["film-rozszerzenie4"],"Twierdzenie o Prawdopodobieństwie całkowitym.","4","youtube",["Twierdzenie o prawdopodobieństwie całkowitym. Wzór Bayesa"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/HhgmTQMqVXw",["film-podstawa4","film-rozszerzenie4"],"Prawdopodobieństwo klasyczne [CAŁOŚCIOWE OMÓWIENIE]","4","youtube",["Prawdopodobieństwo klasyczne"],"Lernado"],
["https://www.youtube.com/embed/aMyKXTWrB7U",["film-rozszerzenie4"],"Prawdopodobieństwo warunkowe","4","youtube",["Prawdopodobieństwo warunkowe"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/kjasRU7xSL8",["film-podstawa4","film-rozszerzenie4"],"Rozkład zmiennej losowej - przykład","4","youtube",["Zmienna losowa. Wartość oczekiwana zmiennej losowej"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/7b68qwUFRMA",["film-podstawa4","film-rozszerzenie4"],"Wartość Oczekiwana: Zmienna Dyskretna vs Zmienna Ciągła","4","youtube",["Zmienna losowa. Wartość oczekiwana zmiennej losowej"],"Babka z Majcy"],
["https://www.youtube.com/embed/l2LLuTa0lsA",["film-podstawa4","film-rozszerzenie4"],"Proste w przestrzeni - równoległe, prostopadłe i skośne #1 [ Płaszczyzny, proste i kąty w prz","5","youtube",["Płaszczyzny i proste w przestrzeni. Równoległość prostych i płaszczyzn. Proste skośne"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/VwGRjO13Fgk",["film-podstawa4","film-rozszerzenie4"],"Płaszczyzna w przestrzeni w przykładach cz. 2","5","youtube",["Płaszczyzny i proste w przestrzeni. Równoległość prostych i płaszczyzn. Proste skośne"],"Math Academy"],
["https://www.youtube.com/embed/l2LLuTa0lsA",["film-podstawa4","film-rozszerzenie4"],"Proste w przestrzeni - równoległe, prostopadłe i skośne #1 [ Płaszczyzny, proste i kąty w prz","5","youtube",["Prostopadłość prostych i płaszczyzn w przestrzeni"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/FU05st2A21c",["film-podstawa4","film-rozszerzenie4"],"Płaszczyzna w przestrzeni w przykładach cz. 1","5","youtube",["Płaszczyzny i proste w przestrzeni. Równoległość prostych i płaszczyzn. Proste skośne"],"Math Academy"],
["https://www.youtube.com/embed/ihXQDlK13ds",["film-podstawa4","film-rozszerzenie4"],"[Geometria wykreślna] poprowadzić płaszczyznę równoległą do danych prostych skośnych","5","youtube",["Rzut równoległy na płaszczyznę. Rysowanie figur płaskich w rzucie równoległym na płaszczyznę"],"www.i-kreska.pl"],
["https://www.youtube.com/embed/3w3eqZ6tetM",["film-podstawa4","film-rozszerzenie4"],"Objętość ostrosłupa #2 [ Ostrosłupy ]","5","youtube",["Ostrosłupy"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/2STWtAGiaKI",["film-podstawa4","film-rozszerzenie4"],"Kąt dwuścienny.","5","youtube",["Kąt między prostą a płaszczyzną. Kąt dwuścienny"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/we7Ug7MHA38",["film-podstawa4","film-rozszerzenie4"],"Kąt między prostą i płaszczyzną","5","youtube",["Kąt między prostą a płaszczyzną. Kąt dwuścienny"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/B-KfkSHtuEA",["film-podstawa4","film-rozszerzenie4"],"Rzuty Monge'a. Rzut równoległy prostokątny. Geometria wykreślna krok po kroku. Układ odniesienia.","5","youtube",["Rzut równoległy na płaszczyznę. Rysowanie figur płaskich w rzucie równoległym na płaszczyznę"],"Korki Online"],
["https://www.youtube.com/embed/7ZanhAvZ_34",["film-podstawa4","film-rozszerzenie4"],"Twierdzenie Pitagorasa w graniastosłupach #6 [ Graniastosłupy ]","5","youtube",["Graniastosłupy"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/fr2Ypa3rhwY",["film-rozszerzenie4"],"Rysowanie graniastosłupów","5","youtube",["Przekroje wielościanów - konstrukcje"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/SAC3K9zJXc0",["film-podstawa4","film-rozszerzenie4"],"Objętość prostopadłościanu i sześcianu #6 [ Figury przestrzenne - wprowadzenie ]","5","youtube",["Objętość figury przestrzennej. Objętość wielościanów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/pPZqDtsXHME",["film-podstawa4","film-rozszerzenie4"],"Zadania maturalne z ostrosłupów","5","youtube",["Ostrosłupy"],"AjkaMat"],
["https://www.youtube.com/embed/koqJqjeIKI0",["film-podstawa4","film-rozszerzenie4"],"Kąt między prostą a płaszczyzną - zadania #3 [ Płaszczyzny, proste i kąty w przestrzeni ]","5","youtube",["Kąt między prostą a płaszczyzną. Kąt dwuścienny"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/Tb3hmPJx0Ns",["film-podstawa4","film-rozszerzenie4"],"Rodzaje ostrosłupów #1 [ Ostrosłupy ]","5","youtube",["Ostrosłupy"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/TgTGzCvVpaI",["film-podstawa4","film-rozszerzenie4"],"Graniastosłupy.","5","youtube",["Graniastosłupy"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/w6g3fzh8Pj4",["film-podstawa4","film-rozszerzenie4"],"Ostrosłup prawidłowy czworokątny i sześciokątny - obliczanie długości odcinków #6 [ Ostrosłupy ]","5","youtube",["Ostrosłupy"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/KfyH-aAVGRY",["film-podstawa4","film-rozszerzenie4"],"Siatka i pole powierzchni ostrosłupa #4 [ Ostrosłupy ]","5","youtube",["Siatka wielościanu. Pole powierzchni wielościanu"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/qkO_sGnxCtg",["film-podstawa4","film-rozszerzenie4"],"Równanie płaszczyzny i prostej do niej prostopadłej","5","youtube",["Prostopadłość prostych i płaszczyzn w przestrzeni"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/Jw6nrewG1kM",["film-podstawa4","film-rozszerzenie4"],"Zadania maturalne z graniastosłupów","5","youtube",["Przekroje wielościanów - zadania"],"AjkaMat"],
["https://www.youtube.com/embed/L6pxLnKC6qI",["film-podstawa4","film-rozszerzenie4"],"Kąt między prostą a płaszczyzną #2 [ Płaszczyzny, proste i kąty w przestrzeni ]","5","youtube",["Kąt między prostą a płaszczyzną. Kąt dwuścienny"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/c2oF5ViBJO8",["film-podstawa4","film-rozszerzenie4"],"Objętość i pole całkowite ostrosłupa prawidłowego czworokątnego - Matfiz24.pl","5","youtube",["Ostrosłupy"],"Marek Duda"],
["https://www.youtube.com/embed/nZNbbB9vng8",["film-rozszerzenie4"],"Rysowanie ostrosłupów #3 [ Ostrosłupy ]","5","youtube",["Przekroje wielościanów - konstrukcje"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/Jw6nrewG1kM",["film-podstawa4","film-rozszerzenie4"],"Zadania maturalne z graniastosłupów","5","youtube",["Graniastosłupy"],"AjkaMat"],
["https://www.youtube.com/embed/wuZyhpGXzLg",["film-podstawa4","film-rozszerzenie4"],"Graniastosłupy - zadania na pole powierzchni i objętość #5 [ Graniastosłupy ]","5","youtube",["Graniastosłupy"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/gLXkCCmoNDQ",["film-podstawa4","film-rozszerzenie4"],"Objętość i jednostki objętości - wprowadzenie #5 [ Figury przestrzenne - wprowadzenie ]","5","youtube",["Objętość figury przestrzennej. Objętość wielościanów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/uMt-8kyEPbA",["film-podstawa4","film-rozszerzenie4"],"MATURA  MATEMATYKA graniastosłup zadania PEWNIAK stereometria cz.2","5","youtube",["Przekroje wielościanów - zadania"],"MiedzianyFsor"],
["https://www.youtube.com/embed/3SrUcD87V6w",["film-podstawa4","film-rozszerzenie4"],"Ostrosłup prawidłowy trójkątny - obliczanie długości odcinków #5 [ Ostrosłupy ]","5","youtube",["Ostrosłupy"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/nZNbbB9vng8",["film-podstawa4","film-rozszerzenie4"],"Rysowanie ostrosłupów #3 [ Ostrosłupy ]","5","youtube",["Ostrosłupy"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/zUS6V7FZtVo",["film-podstawa4","film-rozszerzenie4"],"Przekroje wielościanów.","5","youtube",["Przekroje wielościanów - zadania"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/aaC1NwyR1sM",["film-rozszerzenie4"],"MATURA 2022 2023 Zadanie z optymalizacji bryły za 14% PEWNIAK MATEMATYKA ROZSZERZONA","5","youtube",["Przekroje wielościanów - zadania"],"MiedzianyFsor"],
["https://www.youtube.com/embed/3w3eqZ6tetM",["film-podstawa4","film-rozszerzenie4"],"Objętość ostrosłupa #2 [ Ostrosłupy ]","5","youtube",["Objętość figury przestrzennej. Objętość wielościanów"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/Bgf5s8kRplE",["film-podstawa4","film-rozszerzenie4"],"Przekroje ostrosłupów i graniastosłupów","5","youtube",["Przekroje wielościanów - zadania"],"AjkaMat"],
["https://www.youtube.com/embed/TwFseqX_ibQ",["film-podstawa4","film-rozszerzenie4"],"MATURA  MATEMATYKA Stereometria prostopadłościan za 10% PEWNIAK stereometria cz.3","5","youtube",["Przekroje wielościanów - zadania"],"MiedzianyFsor"],
["https://www.youtube.com/embed/ryKhsfvKTAw",["film-rozszerzenie4"],"Przekroje graniastosłupów #4 [ Płaszczyzny, proste i kąty w przestrzeni ]","5","youtube",["Przekroje wielościanów - konstrukcje"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/1qOUOtZdGfQ",["film-podstawa4","film-rozszerzenie4"],"Obliczanie pola powierzchni wielościanu z wykorzystaniem siatki","5","youtube",["Siatka wielościanu. Pole powierzchni wielościanu"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/cbdA_fMZZQw",["film-podstawa4","film-rozszerzenie4"],"Rzut prostokątny (ortogonalny) na k","5","youtube",["Rzut równoległy na płaszczyznę. Rysowanie figur płaskich w rzucie równoległym na płaszczyznę"],"mat kwadrat"],
["https://www.youtube.com/embed/CM-qr8kcTY8",["film-podstawa4","film-rozszerzenie4"],"Rzuty Monge'a - prosta prostopadła do płaszczyzny śladowo przez punkt","5","youtube",["Prostopadłość prostych i płaszczyzn w przestrzeni"],"Ojos Verdes"],
["https://www.youtube.com/embed/6H-vJOvrf4o",["film-podstawa4","film-rozszerzenie4"],"Obliczanie pola powierzchni z użyciem siatki wielościanu","5","youtube",["Siatka wielościanu. Pole powierzchni wielościanu"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/8K_9D58buhA",["film-podstawa4","film-rozszerzenie4"],"Ostrosłupy - zadania #7 [ Ostrosłupy ]","5","youtube",["Ostrosłupy"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/0PD8mYACZCk",["film-podstawa4","film-rozszerzenie4"],"100 dni do matury Dzień 76 Twierdzenie o 3 prostych prostopadłych STEREOMETRIA MATURA 2023","5","youtube",["Twierdzenie o trzech prostych prostopadłych"],"Math Dream"],
["https://www.youtube.com/embed/icOiJuL-6OU",["film-podstawa4","film-rozszerzenie4"],"Siatki wielościanów","5","youtube",["Siatka wielościanu. Pole powierzchni wielościanu"],"KhanAcademyPoPolsku"],
["https://www.youtube.com/embed/aklcrEUBXlY",["film-podstawa4","film-rozszerzenie4"],"Bryły obrotowe #1 [ Walce ]","6","youtube",["Walec"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/EOXdmdP02E0",["film-podstawa4","film-rozszerzenie4"],"Jak obliczyć objętość i pole całkowite walca - Zadanie - MatFiz24.pl","6","youtube",["Walec"],"Marek Duda"],
["https://www.youtube.com/embed/Jhz4FiVTS3s",["film-podstawa4","film-rozszerzenie4"],"Walec, pole powierzchni walca, objętość walca, własności walca, przekrój walca, oś obrotu,","6","youtube",["Walec"],"Akademia Matematyki Piotra Ciupaka"],
["https://www.youtube.com/embed/v3DH7K5zpSg",["film-podstawa4","film-rozszerzenie4"],"Obliczanie pola i objętości stożka","6","youtube",["Stożek"],"Zacny Umysł"],
["https://www.youtube.com/embed/I11rs9TrRjI",["film-podstawa4","film-rozszerzenie4"],"Skala podobieństwa wielokątów #5 [ Podobieństwo wielokątów ]","6","youtube",["Podobieństwo figur w przestrzeni"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/PDRFOulCNdk",["film-podstawa4","film-rozszerzenie4"],"Przekrój osiowy walca #4 [ Walce ]","6","youtube",["Walec"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/SOUPDxk5wAI",["film-podstawa4","film-rozszerzenie4"],"Objętość walca #2 [ Walce ]","6","youtube",["Walec"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/Dz9Bholl4H0",["film-podstawa4","film-rozszerzenie4"],"Kl. III  Bryły obrotowe - zadania.","6","youtube",["Bryły obrotowe - zadania różne"],"Mathematicus"],
["https://www.youtube.com/embed/mJZCjfrXQFY",["film-rozszerzenie4"],"Zastosowanie analizy matematycznej w geometrii analitycznej - zadanie maturalne [ROZSZERZENIE]","6","youtube",["Zastosowanie analizy matematycznej w rozwiązywaniu zadań z geometrii przestrzennej"],"Lernado"],
["https://www.youtube.com/embed/LZuXD-yp2Bw",["film-podstawa4","film-rozszerzenie4"],"Oblicz objętość i pole całkowite stożka - Zadanie - Matfiz24.pl","6","youtube",["Stożek"],"Marek Duda"],
["https://www.youtube.com/embed/z1Kyh2U9w88",["film-rozszerzenie4"],"Bryły obrotowe","6","youtube",["Zastosowanie analizy matematycznej w rozwiązywaniu zadań z geometrii przestrzennej"],"AjkaMat"],
["https://www.youtube.com/embed/Eo26-uV47rY",["film-podstawa4","film-rozszerzenie4"],"Kula i Sfera 1","6","youtube",["Kula i sfera"],"Paweł Malarczyk"],
["https://www.youtube.com/embed/IZPd4ZsN5iI",["film-podstawa4","film-rozszerzenie4"],"Liceum. Klasa II. Geometria płaska. Podobieństwo. Figury podobne","6","youtube",["Podobieństwo figur w przestrzeni"],"Pizza Math"],
["https://www.youtube.com/embed/pQgA4Faj5j8",["film-podstawa4","film-rozszerzenie4"],"Pole powierzchni walca #3 [ Walce ]","6","youtube",["Walec"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/EpxgI9e8b44",["film-podstawa4","film-rozszerzenie4"],"Objętość kuli #1 [ Kule ]","6","youtube",["Kula i sfera"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/_JVh0qovAx0",["film-podstawa4","film-rozszerzenie4"],"Stożek: zadania #4 [ Stożki ]","6","youtube",["Stożek"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/taOEK43p2lU",["film-rozszerzenie4"],"Zastosowanie analizy matematycznej w geometrii przestrzennej.","6","youtube",["Zastosowanie analizy matematycznej w rozwiązywaniu zadań z geometrii przestrzennej"],"Matematyka z wujkiem Markiem"],
["https://www.youtube.com/embed/hz2bAVS3gW8",["film-podstawa4","film-rozszerzenie4"],"Pole powierzchni stożka #2 [ Stożki ]","6","youtube",["Stożek"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/NGl6GKKFPI0",["film-podstawa4","film-rozszerzenie4"],"Walec - zadania #5 [ Walce ]","6","youtube",["Walec"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/fQlxpzm8es0",["film-rozszerzenie4"],"Bryły obrotowe","6","youtube",["Zastosowanie analizy matematycznej w rozwiązywaniu zadań z geometrii przestrzennej"],"AjkaMat"],
["https://www.youtube.com/embed/z1Kyh2U9w88",["film-podstawa4","film-rozszerzenie4"],"Bryły obrotowe","6","youtube",["Bryły obrotowe - zadania różne"],"AjkaMat"],
["https://www.youtube.com/embed/nTyBfYhi9pk",["film-podstawa4","film-rozszerzenie4"],"Podsumowanie brył obrotowych #1 [ Bryły - zadania ]","6","youtube",["Bryły obrotowe - zadania różne"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/YtRicUeX5g0",["film-podstawa4","film-rozszerzenie4"],"Przekrój osiowy kuli #3 [ Kule ]","6","youtube",["Kula i sfera"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/hJ9M_GDjaSY",["film-rozszerzenie4"],"Zastosowanie analizy matematycznej w geometrii przestrzennej - zadanie 1 [ROZSZERZENIE]","6","youtube",["Zastosowanie analizy matematycznej w rozwiązywaniu zadań z geometrii przestrzennej"],"Lernado"],
["https://www.youtube.com/embed/L9uQu6_8hzs",["film-podstawa4","film-rozszerzenie4"],"Niezbędnik Maturzysty #9 Bryły obrotowe","6","youtube",["Bryły obrotowe - zadania różne"],"matspot"],
["https://www.youtube.com/embed/Qaehz4fVGso",["film-podstawa4","film-rozszerzenie4"],"Przekrój osiowy stożka #3 [ Stożki ]","6","youtube",["Stożek"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/JjKaMXmHokA",["film-podstawa4","film-rozszerzenie4"],"czerwiec 2020 zad. 24 (1 pkt) matura poziom podstawowy. Bryły obrotowe.","6","youtube",["Bryły obrotowe - zadania różne"],"Szkoła PitagoLasa"],
["https://www.youtube.com/embed/UdY1URtnxFo",["film-podstawa4","film-rozszerzenie4"],"Objętość stożka #1 [ Stożki ]","6","youtube",["Stożek"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/CCjecvSuGpE",["film-podstawa4","film-rozszerzenie4"],"Pole powierzchni kuli #2 [ Kule ]","6","youtube",["Kula i sfera"],"Pi-stacja Matematyka"],
["https://www.youtube.com/embed/H_NRPVrfMu4",["film-podstawa4","film-rozszerzenie4"],"grudzień 2017 zad. 33 (4 pkt) matura poziom podstawowy. Bryły obrotowe.","6","youtube",["Bryły obrotowe - zadania różne"],"Szkoła PitagoLasa"],
["https://www.youtube.com/embed/fQlxpzm8es0",["film-podstawa4","film-rozszerzenie4"],"Bryły obrotowe","6","youtube",["Bryły obrotowe - zadania różne"],"AjkaMat"],
["https://www.youtube.com/embed/aNFUrvLSqNQ",["film-podstawa4","film-rozszerzenie4"],"Kule są cool, czyli wyprowadznie wzorów na pole koła, sfery i objętość kuli.","6","youtube",["Kula i sfera"],"Phy6"],
["https://www.youtube.com/embed/4uVWk1gCuUg",["film-podstawa2","film-rozszerzenie2"],"zacznij maturę z 4 punktami","3","youtube",["Badanie funkcji kwadratowej - zadania optymalizacyjne"],"EZMATMA"],
["https://www.youtube.com/embed/zwYCCkQhEqw",["film-podstawa2","film-rozszerzenie2"],"Dzielenie Wielomianów - Dlaczego Nie Umiesz?","8","youtube",["Podzielność wielomianów"],"EZMATMA"],
["https://www.youtube.com/embed/tfuxF_Z7380",["film-podstawa2","film-rozszerzenie2"],"nawet ty to rozwiążesz","7","youtube",["Twierdzenie cosinusów","Zastosowanie twierdzenia sinusów i twierdzenia cosinusów do rozwiązywania zadań"],"EZMATMA"],
["https://www.youtube.com/embed/ES2brps3Abw",["film-podstawa1","film-rozszerzenie1"],"Matura 🤴 TRYGONOMETRIA ŁATWA JAK NIGDY","8","youtube",["Zależności między funkcjami trygonometrycznymi tego samego kąta ostrego"],"EZMATMA"],
["https://www.youtube.com/embed/2Xb1WIcGGAQ",["film-podstawa1","film-rozszerzenie1"],"LOGARYTMY NA MATURZE ♨️ (Animacja + darcie ryja)","2","youtube",["Określenie logarytmu, Zastosowanie logarytmów"],"EZMATMA"],
["https://www.youtube.com/embed/ES2brps3Abw",["film-podstawa2","film-rozszerzenie2"],"Matura 🤴 TRYGONOMETRIA ŁATWA JAK NIGDY","5","youtube",["Podstawowe tożsamości trygonometryczne"],"EZMATMA"]];

export function shuffle(array:any) {
  let currentIndex = array.length,
    randomIndex:number;
  while (currentIndex > 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
}
filmyYtLinki = shuffle(filmyYtLinki);
export default filmyYtLinki;
