import React from "react";
import "../component-styles/ONas.scss";

function ONas() {
  return (
    <div id="onas-segment">
      <header className="welcome-onas">
        <span className="line">Poznajmy się!</span>
      </header>
      <div className="onas-content">
        <div className="kim-jestesmy">
          <hr />
          <span className="kim-jestesmy-start">Kim jesteśmy</span>
          <p>
            Cześć! Jesteśmy 4 osobową grupą licealistów z Warszawy, którzy
            wyszli z inicjatywą pomocy uczniom szkół średnich w całej Polsce.
            Około 50% osób wskazało, że najtrudniejszym przedmiotem była i jest
            matematyka, dlatego łącząc nasze zainteresowania i okazując
            zaangażowanie stworzyliśmy MatZone - platforme edukacyjną, która ma
            być dla ciebie, uczniu, pomocą w nauce i innym sposobem na
            opanowanie matematyki!
          </p>
        </div>
        <div className="nasza-historia">
          <hr />
          <span className="nasza-historia-start">Nasza historia</span>
          <div className="timeline">
            <div className="timeline-item">
              <h2>MAJ 2023</h2>
              <p>Pomysł stworzenia projektu edukacyjnego - wstępne plany</p>
            </div>

            <div className="timeline-item">
              <h2>5 WRZEŚNIA 2023</h2>
              <p>Rozpoczęcie działań i początek tworzenia strony MatZone</p>
            </div>

            <div className="timeline-item">
              <h2>WRZESIEŃ - GRUDZIEŃ 2023</h2>
              <p>
                Nawiązywanie współprac i przygotowywanie strony do
                upublicznienia
              </p>
            </div>

            <div className="timeline-item">
              <h2>2 STYCZNIA 2024</h2>
              <p>Oficjalne uruchomienie i upublicznienie platformy MatZone</p>
            </div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <header className="welcome-nasz-zespol">
        <span className="line">Nasz zespół</span>
      </header>
      <div className="nasz-zespol-content">
        <div className="czlonek">
          <img
            src={process.env.PUBLIC_URL + "/images/Avatary/AvatarZofia.png"}
            alt="awatar Zosi"
          />
          <div className="imie-i-nazwisko">Zofia Warzybok</div>
          <div className="funkcja">kierownik projektu</div>
        </div>
        <div className="czlonek">
          <img
            src={process.env.PUBLIC_URL + "/images/Avatary/AvatarAlicja.png"}
            alt="awatar Alicji"
          />
          <div className="imie-i-nazwisko">Alicja Kras</div>
          <div className="funkcja">koordynator projektu</div>
        </div>
        <div className="czlonek">
          <img
            src={process.env.PUBLIC_URL + "/images/Avatary/AvatarJulia.png"}
            alt="awatar Julii"
          />
          <div className="imie-i-nazwisko">Julia Olak</div>
          <div className="funkcja">content specialist</div>
        </div>
        <div className="czlonek">
          <img
            src={process.env.PUBLIC_URL + "/images/Avatary/AvatarKrystian.png"}
            alt="awatar Krystiana"
          />
          <div className="imie-i-nazwisko">Krystian Kras</div>
          <div className="funkcja">programista</div>
        </div>
      </div>
      <header className="welcome-partnerzy">
        <span className="line">Partnerzy</span>
      </header>
      <div className="partnerzy-content">
        <img
          src={process.env.PUBLIC_URL + "/images/Logotypy/LogotypEnea.png"}
          alt="logotyp Enea"
        />
        <img
          src={process.env.PUBLIC_URL + "/images/Logotypy/LogotypING.png"}
          alt="logotyp ING"
        />
        <img
          src={process.env.PUBLIC_URL + "/images/Logotypy/LogotypCapgemini.png"}
          alt="logotyp Capgemini"
        />
      </div>
      <div className="kanaly-matematyczne">
        <hr />
        <span className="kanaly-matematyczne-start">Kanały matematyczne</span>
        <div className="kanaly-matematyczne-content">
          {[
            "pistacjamatematyka",
            "ezmatma",
            "wzorowanie",
            "pizzamath8681",
            "AjkaMat",
            "patomatma",
            "matspot1088",
            "miedzianyfsor",
            "matematykazwujkiemmarkiem",
            "matzadanie",
            "matkwadrat",
            "khanacademy",
            "moja-matplaneta",
            "pawemalarczyk9577",
            "MatematykaNaPlus1",
            "babkazmajcy1394",
            "akademiamatematykipiotraciupaka",
            "Noijuz",
            "maturazlewusem",
            "cinematma3034",
            "andrzejwinnickihul4193",
            "krzysztofzelner9129",
            "matematykazangel9968",
            "statystykaludzkimgosem9949",
            "karolinaduda3699",
            "Datacademypl",
            "takpoprostu5514",
            "Lernado",
            "mathdream_ms",
            "MatmaZPasja",
            "themathteacher1593",
            "aitra4539",
            "smiemwatpic",
            "mathacademy6798",
            "edudamarek",
            "ojosverdespl",
            "user-ex5lm1cg9p",
            "zacnyumysl",
            "matematykadasielubic",
            "krautka",
            "PoprostuLicz",
            "ynmun",
            "matematykamartatrojak1777",
            "deyko7542",
            "asjonat5683",
            "PoliczymySieZtaMatma",
            "matwujek",
            "planetaedu",
            "Ematematyka",
            "dziae-learningupolitechnik3935",
            "MarekMaolepszy",
            "matmadlahumanow7179",
            "zaliczone_na_5",
            "PiotrSzlagor",
            
          ].map((x: string, index: number) => (
            <div className="kanal-matematyczny">
              <img
                alt={x}
                src={
                  process.env.PUBLIC_URL + `/images/KanalyMatematyczne/${x}.png`
                }
                onClick={() => {
                  window.open(`https://www.youtube.com/@${x}`, "_blank");
                }}
                key={x + index}
              />
              <div className="nazwa-kanalu">@{x}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ONas;
