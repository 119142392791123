import React from "react";
import { Link } from "react-router-dom";
import ClipboardJS from "clipboard";
import "../component-styles/FAQ.scss";

function FAQ() {
  const clipboard = new ClipboardJS(".copy");

  clipboard.on("success", (e) => {
    e.clearSelection();
  });

  return (
    <div className="faq">
      <header className="welcome-faq">
        <span className="line">
          Najczęściej zadawane <span className="purple-text">pytania</span>
        </span>
      </header>
      <div className="questions">
        <div className="question">
          <strong></strong>
          <br />
          <h2>Czy korzystanie z matzone.pl jest darmowe?</h2>
          Tak, matzone.pl oferuje wszystkie materiały edukacyjne bezpłatnie.
          Naszym celem jest dostarczenie uczniom dostępu do wysokiej jakości
          zasobów matematycznych zarówno już dostępnych w internecie jak również
          tworzonych przez nas.
          <h2>Czy matzone.pl jest przeznaczone dla maturzystów?</h2>
          Tak, matzone.pl to platforma dla uczniów szkół średnich w tym
          maturzystów. Na naszej stronie w zakładce{" "}
          <Link to="/matura">MATURA</Link> znajdują się wszystkie arkusze
          maturalne CKE jak również popularnych wydawnictw wraz z odpowiedziami
          przygotowywanymi przez nas. Ponadto w{" "}
          <Link to="/materialy">MATERIAŁACH</Link> umieszczamy konkretne
          zadania, które pojawiły się na maturze podzielone działami tak aby
          maturzyści powtarzając sobie teorie, mogli wykorzystać ją w praktyce.
          <h2>Jak moge wesprzeć matzone.pl?</h2>
          Jesteśmy otwarci na wszelką współpracę i wdzięczni za każdą pomoc.
          Najbardziej zależy nam na rozgłosie, dlatego udostępnienie naszej
          platformy jest dla nas szczególnie pomocne. Zachęcamy do dzielenia się
          linkami do naszych materiałów na innych platformach edukacyjnych.
          Więcej informacji znajduje się w zakładce{" "}
          <Link to="/wsparcie">WSPARCIE</Link>.
          <h2>
            Jak mogę skontaktować się z zespołem matzone.pl w razie pytań lub
            sugestii?
          </h2>
          Skontaktuj się z nami poprzez adres e-mailowy:
          <span
            className="copy "
            data-clipboard-text="matzone.kontakt@gmail.com"
          >
            {" "}
            matzone.kontakt@gmail.com
          </span>
          . Jesteśmy otwarci na wszelkie pytania, uwagi i sugestie od
          użytkowników.
        </div>
      </div>
    </div>
  );
}

export default FAQ;
