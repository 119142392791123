import React from "react";
import "../component-styles/HomePage.scss";
import { useAtom } from "jotai";
import { NavLink } from "react-router-dom";
import { Link as ScrollLink} from 'react-scroll';
import { MotywStrony } from "../App.tsx";
function HomePage() {
  const [motyw] = useAtom(MotywStrony);

  return (
    <div id="home-page">
      <header className="home-header">
        <div className="welcome-home-page">
          Cała wiedza w <br />
          <span className="purple-text">jednym miejscu!</span>
        </div>
        <div className="big-buttons">
          <NavLink
            className="big-buttons-button"
            to="/materialy"
            data-content="Materiały"
          >
            <img
              src={
                motyw === "light"
                  ? process.env.PUBLIC_URL + "/images/HomePageLinks/Materialy.png"
                  : process.env.PUBLIC_URL + "/images/HomePageLinks/MaterialyDarkMode.png"
              }
              alt="materialy"
            />
          </NavLink>

          <NavLink
            className="big-buttons-button"
            to="/korepetycje"
            data-content="Korepetycje"
          >
            <img
              src={
                motyw === "light"
                  ? process.env.PUBLIC_URL + "/images/HomePageLinks/Korepetycje.png"
                  : process.env.PUBLIC_URL + "/images/HomePageLinks/KorepetycjeDarkMode.png"
              }
              alt="korepetycje"
            />
          </NavLink>

          <NavLink
            className="big-buttons-button"
            to="/studia"
            data-content="Studia"
          >
            <img
              src={
                motyw === "light"
                  ? process.env.PUBLIC_URL + "/images/HomePageLinks/Studia.png"
                  : process.env.PUBLIC_URL + "/images/HomePageLinks/StudiaDarkMode.png"
              }
              alt="studia"
            />
          </NavLink>

          <NavLink
            className="big-buttons-button"
            to="/matura"
            data-content="Matura"
          >
            <img
              src={
                motyw === "light"
                  ? process.env.PUBLIC_URL + "/images/HomePageLinks/Matura.png"
                  : process.env.PUBLIC_URL + "/images/HomePageLinks/MaturaDarkMode.png"
              }
              alt="matura"
            />
          </NavLink>
        </div>
        <ScrollLink
          to="o-stronie-div"
          spy={true} smooth={true} duration={600}
        >
          <div className="arrows-div">
            <div className="arrows">
              <i className="fa-solid fa-chevron-down first" />
              <i className="fa-solid fa-chevron-down second" />
              <i className="fa-solid fa-chevron-down third" />
              <i className="fa-solid fa-chevron-down fourth" />
            </div>
          </div>
        </ScrollLink>
      </header>
      <div id="o-stronie-div" className="o-stronie-div">
        <header className="o-stronie-welcome">
          <span className="line">
            Co wyróżnia{" "}
            <span className={`mat ${motyw === "light" ? "purple-text" : ""}`}>
              Mat
            </span>
            <span className={`zone ${motyw === "light" ? "" : "purple-text"}`}>
              Zone
            </span>
            ?
          </span>
        </header>

        <nav className="o-stronie-nav">
          <div
            className="o-stronie-nav-item materialy-video"
            data-content="Na naszej stronie znajdziesz wiele wartościowych filmików dopasowanych do konkretnych zagadnień, które z pewnością pomogą Ci opanować materiał szkolny. "
          >
            <img
              src={process.env.PUBLIC_URL + "/images/MaterialyVideo.png"}
              alt=""
            />
            <div>Materiały video</div>
          </div>
          <div
            className="o-stronie-nav-item rozwiazania-matur"
            data-content="Chcesz jak najlepiej przygotować się do matury? Przerób arkusze z poprzednich lat. Na naszej stronie masz je wszystkie w jednym miejscu wraz z odpowiedziami i wyjaśnieniami zadań."
          >
            <img
              src={process.env.PUBLIC_URL + "/images/RozwiazaniaMatur.png"}
              alt=""
            />
            <div>Rozwiązania matur</div>
          </div>
          <div
            className="o-stronie-nav-item teoria-i-artykuly"
            data-content="Oprócz materiałów video znajdziesz u nas też całą potrzebną teorię i wzory do każdego tematu (nawet te których nie ma w karcie wzorów), co więcej możesz przeczytać wiele ciekawych i wartościowych artykułów edukacyjnych i nowinkowych takich jak na przykład najnowsze zmiany w wymaganiach maturalnych."
          >
            <img
              src={process.env.PUBLIC_URL + "/images/TeoriaIArtykuly.png"}
              alt=""
            />
            <div>Teoria i artykuły</div>
          </div>
        </nav>
        <section className="dodatki">
          <hr />
          <div className="dodatki-div artykuly">
            <div className="artykuly-start">Artykuły</div>
            <div className="artykuly-divs">
              <NavLink
                to="artykul/metody-efektywnego-uczenia-sie"
                className="artykuly-link metody-efektywnego-uczenia-sie-div"
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/Artykuly/MetodyEfektywnegoUczeniaSie.png"}
                  alt="metody efektywnego uczenia się"
                />
                <span>Metody efektywnego uczenia się</span>
              </NavLink>
              <NavLink
                to="artykul/przygotowanie-do-matury"
                className="artykuly-link przygotowanie-do-matury-div"
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/Artykuly/PrzygotowanieDoMatury.png"}
                  alt="przygotowanie do matury"
                />
                <span>Przygotowanie do matury</span>
              </NavLink>
              <NavLink
                to="artykul/motywacja-do-systematycznej-nauki"
                className="artykuly-link przygotowanie-do-matury-div"
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/Artykuly/MotywacjaDoSystematycznejNauki.png"}
                  alt="motywacja do systematycznej nauki"
                />
                <span>Motywacja do systematycznej nauki</span>
              </NavLink>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default HomePage;
