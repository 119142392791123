import React from "react";
import { Link, NavLink } from "react-router-dom";
import "../component-styles/About.scss";
import { useAtom } from "jotai";
import { MotywStrony } from "../App.tsx";
function About() {
  const [motyw] = useAtom(MotywStrony);
  return (
    <footer id="about-segment">
      <div className="social-media">
        <div className="social-media-welcome">Nasze social media</div>
        <div className="social-media-links">
          <Link to="https://www.instagram.com/matzone.pl" className="instagram">
            <>
              <img
                src={process.env.PUBLIC_URL + "/images/About/Instagram.png"}
                alt="instagram logo"
              />
            </>
            <h1>Instagram</h1>
          </Link>
          <Link to="https://www.tiktok.com/@matzonepl" className="tiktok">
            <>
              <img
                src={process.env.PUBLIC_URL + "/images/About/TikTok.png"}
                alt="tiktok logo"
              />
            </>
            <h1>TikTok</h1>
          </Link>
          <Link to="https://www.facebook.com/profile.php?id=61557282751595" className="facebook">
            <>
              <img
                src={process.env.PUBLIC_URL + "/images/About/Facebook.png"}
                alt="facebook logo"
              />
            </>
            <h1>Facebook</h1>
          </Link>
        </div>
      </div>
      <div className="inne">
        <nav className="inne-div">Inne</nav>
        <NavLink to="kontakt" className="inne-button">
          Kontakt
        </NavLink>
        <Link to="https://pomagam.pl/3dacgb" className="inne-button">
          Wsparcie
        </Link>
        <NavLink to="o-nas" className="inne-button">
          O nas
        </NavLink>
        <NavLink to="faq" className="inne-button">
          FAQ
        </NavLink>
      </div>
      <div className="projekt-w-ramach-zwolnieni">
        <h1>Projekt w ramach</h1>
        <>
          <img
            src={
              motyw === "light"
                ? process.env.PUBLIC_URL + "/images/About/ZwolnieniZTeorii.png"
                : process.env.PUBLIC_URL +
                  "/images/About/ZwolnieniZTeoriiDarkMode.png"
            }
            alt="zwolnieni z teorii"
          />
        </>
      </div>
    </footer>
  );
}
export default About;
