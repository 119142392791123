import React from "react";
import "../component-styles/ZagadnieniaMaturalne.scss";
import { Link as ScrollLink } from "react-scroll";
function ZagadnieniaMaturalne() {
  return (
    <div className="zagadnienia-maturalne">
      <header className="welcome-zagadnienia-maturalne">
        <span className="line">
          Zagadnienia <span className="purple-text">maturalne</span>
        </span>
      </header>
      <div className="zagadnienia-maturalne-lista">
        <nav className="spis-tresci">
          <h1 className="spis-tresci-start">Spis treści</h1>
          <ul>
            <li>
              <ScrollLink
                spy={true}
                smooth={true}
                duration={1000}
                to="liczby-rzeczywiste"
              >
                Liczby rzeczywiste
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                spy={true}
                smooth={true}
                duration={1000}
                to="wyrazenia-algebraiczne"
              >
                Wyrażenia algebraiczne
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                spy={true}
                smooth={true}
                duration={1000}
                to="rownania-i-nierownosci"
              >
                Równania i nierówności
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="uklady-rownan"
                spy={true}
                smooth={true}
                duration={1000}
              >
                Układy równań
              </ScrollLink>
            </li>
            <li>
              <ScrollLink spy={true} smooth={true} duration={1000} to="funkcje">
                Funkcje
              </ScrollLink>
            </li>
            <li>
              <ScrollLink spy={true} smooth={true} duration={1000} to="ciagi">
                Ciągi
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                spy={true}
                smooth={true}
                duration={1000}
                to="trygonometria"
              >
                Trygonometria
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                spy={true}
                smooth={true}
                duration={1000}
                to="planimetria"
              >
                Planimetria
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                spy={true}
                smooth={true}
                duration={1000}
                to="geometria-analityczna-na-plaszczyznie-kartezjanskiej"
              >
                Geometria analityczna na płaszczyźnie kartezjańskiej
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                spy={true}
                smooth={true}
                duration={1000}
                to="stereometria"
              >
                Stereometria
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                spy={true}
                smooth={true}
                duration={1000}
                to="kombinatoryka"
              >
                Kombinatoryka
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                spy={true}
                smooth={true}
                duration={1000}
                to="rachunek-prawdopodobienstwa-i-statystyka"
              >
                Rachunek prawdopodobieństwa i statystyka
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                spy={true}
                smooth={true}
                duration={1000}
                to="optymalizacja-i-rachunek-rozniczkowy"
              >
                Optymalizacja i rachunek różniczkowy
              </ScrollLink>
            </li>
          </ul>
        </nav>
        <h1 id="liczby-rzeczywiste">I. Liczby rzeczywiste</h1>
        <h2>Zakres podstawowy</h2>
        <p>Uczeń:</p>
        <ol>
          <li>
            wykonuje działania (dodawanie, odejmowanie, mnożenie, dzielenie,
            potęgowanie, pierwiastkowanie, logarytmowanie) w zbiorze liczb
            rzeczywistych;
          </li>
          <li>
            przeprowadza proste dowody dotyczące podzielności liczb całkowitych
            i reszt z dzielenia nie trudniejsze niż:
            <ol type="a">
              <li>
                dowód podzielności przez 24 iloczynu czterech kolejnych liczb
                naturalnych,
              </li>
              <li>
                dowód własności: jeśli liczba przy dzieleniu przez 5 daje resztę
                3, to jej trzecia potęga przy dzieleniu przez 5 daje resztę 2;
              </li>
            </ol>
          </li>
          <li>
            stosuje własności pierwiastków dowolnego stopnia, w tym pierwiastków
            stopnia nieparzystego z liczb ujemnych;
          </li>
          <li>
            stosuje związek pierwiastkowania z potęgowaniem oraz prawa działań
            na potęgach i pierwiastkach;
          </li>
          <li>
            stosuje własności monotoniczności potęgowania, w szczególności
            własności: jeśli x &lt; y oraz a &gt; 1, to a<sup>x</sup> &lt; a
            <sup>y</sup>, zaś gdy x &lt; y i 0 &lt; a &lt; 1, to a<sup>x</sup>{" "}
            &gt; a<sup>y</sup>;
          </li>
          <li>
            posługuje się pojęciem przedziału liczbowego, zaznacza przedziały na
            osi liczbowej;
          </li>
          <li>
            stosuje interpretację geometryczną i algebraiczną wartości
            bezwzględnej, rozwiązuje równania i nierówności typu: |x + 4| = 5,
            |x - 2| &lt; 3, |x+3| &ge; 4;
          </li>
          <li>
            wykorzystuje własności potęgowania i pierwiastkowania w sytuacjach
            praktycznych, w tym do obliczania procentów składanych, zysków z
            lokat i kosztów kredytów;
          </li>
          <li>
            stosuje związek logarytmowania z potęgowaniem, posługuje się wzorami
            na logarytm iloczynu, logarytm ilorazu i logarytm potęgi.
          </li>
        </ol>
        <h2>Zakres rozszerzony</h2>
        <p>
          Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto
          stosuje wzór na zamianę podstawy logarytmu.
        </p>
        <hr id="wyrazenia-algebraiczne" />

        <h1>II. Wyrażenia algebraiczne</h1>
        <h2>Zakres podstawowy</h2>
        <p>Uczeń:</p>
        <ol>
          <li>
            stosuje wzory skróconego mnożenia na: (a + b)<sup>2</sup>, (a - b)
            <sup>2</sup>, a<sup>2</sup> - b<sup>2</sup>, (a + b)<sup>3</sup>, (a
            - b)
            <sup>3</sup>, a<sup>3</sup> - b<sup>3</sup>, a<sup>n</sup> - b
            <sup>n</sup>,
          </li>
          <li>dodaje, odejmuje i mnoży wielomiany jednej i wielu zmiennych;</li>
          <li>wyłącza poza nawias jednomian z sumy algebraicznej;</li>
          <li>
            rozkłada wielomiany na czynniki metodą wyłączania wspólnego czynnika
            przed nawias oraz metodą grupowania wyrazów, w przypadkach nie
            trudniejszych niż rozkład wielomianu W(x) = 2x<sup>3</sup> - √3x
            <sup>2</sup>+ 4x - 2√3;
          </li>
          <li>
            znajduje pierwiastki całkowite wielomianu o współczynnikach
            całkowitych;
          </li>
          <li>
            dzieli wielomian jednej zmiennej W(x) przez dwumian postaci x - a;
          </li>
          <li>mnoży i dzieli wyrażenia wymierne;</li>
          <li>
            dodaje i odejmuje wyrażenia wymierne, w przypadkach nie
            trudniejszych niż:{" "}
            <div className="ulamek">
              <div className="licznik">1</div>
              <div className="kreska-ulamkowa" />
              <div className="mianownik">x + 1</div>
            </div>{" "}
            -{" "}
            <div className="ulamek">
              <div className="licznik">1</div>
              <div className="kreska-ulamkowa" />
              <div className="mianownik">x</div>
            </div>
            ,{" "}
            <div className="ulamek">
              <div className="licznik">1</div>
              <div className="kreska-ulamkowa" />
              <div className="mianownik">x + 1</div>
            </div>{" "}
            +{" "}
            <div className="ulamek">
              <div className="licznik">1</div>
              <div className="kreska-ulamkowa" />
              <div className="mianownik">
                x<sup>2</sup>
              </div>
            </div>{" "}
            +{" "}
            <div className="ulamek">
              <div className="licznik">1</div>
              <div className="kreska-ulamkowa" />
              <div className="mianownik">
                x<sup>3</sup>
              </div>
            </div>
            ,{" "}
            <div className="ulamek">
              <div className="licznik">x + 1</div>
              <div className="kreska-ulamkowa" />
              <div className="mianownik">x + 2</div>
            </div>{" "}
            +{" "}
            <div className="ulamek">
              <div className="licznik">x - 1</div>
              <div className="kreska-ulamkowa" />
              <div className="mianownik">x + 1</div>
            </div>
            ,{" "}
          </li>
        </ol>
        <h2>Zakres rozszerzony</h2>
        <p>
          Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
        </p>
        <ol>
          <li>
            znajduje pierwiastki całkowite i wymierne wielomianu o
            współczynnikach całkowitych;
          </li>
          <li>
            stosuje podstawowe własności trójkąta Pascala oraz następujące
            własności współczynnika dwumianowego (symbolu Newtona):
            <br />{" "}
            <div className="symbol-newtona">
              <span className="nawias1">(</span>
              <span className="values">
                <span className="value">n</span>
                <span className="value">0</span>
              </span>
              <span className="nawias2">)</span>
            </div>{" "}
            = 1,{" "}
            <div className="symbol-newtona">
              <span className="nawias1">(</span>
              <span className="values">
                <span className="value">n</span>
                <span className="value">1</span>
              </span>
              <span className="nawias2">)</span>
            </div>{" "}
            = n,{" "}
            <div className="symbol-newtona">
              <span className="nawias1">(</span>
              <span className="values">
                <span className="value">n</span>
                <span className="value">n - 1</span>
              </span>
              <span className="nawias2">)</span>
            </div>{" "}
            = n,{" "}
            <div className="symbol-newtona">
              <span className="nawias1">(</span>
              <span className="values">
                <span className="value">n</span>
                <span className="value">k</span>
              </span>
              <span className="nawias2">)</span>
            </div>{" "}
            ={" "}
            <div className="symbol-newtona">
              <span className="nawias1">(</span>
              <span className="values">
                <span className="value">n</span>
                <span className="value">n - k</span>
              </span>
              <span className="nawias2">)</span>
            </div>
            ,{" "}
            <div className="symbol-newtona">
              <span className="nawias1">(</span>
              <span className="values">
                <span className="value">n</span>
                <span className="value">k</span>
              </span>
              <span className="nawias2">)</span>
            </div>{" "}
            +{" "}
            <div className="symbol-newtona">
              <span className="nawias1">(</span>
              <span className="values">
                <span className="value">n</span>
                <span className="value">k + 1</span>
              </span>
              <span className="nawias2">)</span>
            </div>{" "}
            ={" "}
            <div className="symbol-newtona">
              <span className="nawias1">(</span>
              <span className="values">
                <span className="value">n + 1</span>
                <span className="value">k + 1</span>
              </span>
              <span className="nawias2">)</span>;
            </div>
          </li>
          <li>
            <div className="dluzsza-przerwa" />
            korzysta ze wzorów na: a<sup>3</sup> + b<sup>3</sup>, (a + b)
            <sup>n</sup> i (a - b)<sup>n</sup>.
          </li>
        </ol>
        <hr id="rownania-i-nierownosci" />

        <h1>III. Równania i nierówności</h1>
        <h2>Zakres podstawowy</h2>
        <p>Uczeń:</p>
        <ol>
          <li>przekształca równania i nierówności w sposób równoważny;</li>
          <li>
            interpretuje równania i nierówności sprzeczne oraz tożsamościowe;
          </li>
          <li>rozwiązuje nierówności liniowe z jedną niewiadomą;</li>
          <li>rozwiązuje równania i nierówności kwadratowe;</li>
          <li>
            rozwiązuje równania wielomianowe, które dają się doprowadzić do
            równania kwadratowego, w szczególności równania dwukwadratowe;
          </li>
          <li>
            rozwiązuje równania wielomianowe postaci W(x) = 0 dla wielomianów
            doprowadzonych do postaci iloczynowej lub takich, które dają się
            doprowadzić do postaci iloczynowej metodą wyłączania wspólnego
            czynnika przed nawias lub metodą grupowania;
          </li>
          <li>
            <div className="dluzsza-przerwa" />
            rozwiązuje równania wymierne postaci{" "}
            <div className="ulamek">
              <div className="licznik">V(x)</div>
              <div className="kreska-ulamkowa" />
              <div className="mianownik">W(x)</div>
            </div>{" "}
            , gdzie wielomiany V(x) i W(x) są zapisane w postaci iloczynowej;
          </li>
        </ol>
        <h2>Zakres rozszerzony</h2>
        <p>
          Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
        </p>
        <ol>
          <li>
            rozwiązuje nierówności wielomianowe typu: W(x) &gt; 0, W(x) ≥ 0,
            W(x) &lt; 0, W(x) ≤ 0 dla wielomianów doprowadzonych do postaci
            iloczynowej lub takich, które dają się doprowadzić do postaci
            iloczynowej metodą wyłączania wspólnego czynnika przed nawias lub
            metodą grupowania;
          </li>
          <li>
            <div className="dluzsza-przerwa" />
            rozwiązuje równania i nierówności wymierne nie trudniejsze niż{" "}
            <div className="ulamek">
              <div className="licznik">x + 1</div>
              <div className="kreska-ulamkowa" />
              <div className="mianownik">x(x + 1)</div>
            </div>{" "}
            +{" "}
            <div className="ulamek">
              <div className="licznik">1</div>
              <div className="kreska-ulamkowa" />
              <div className="mianownik">x + 1</div>
            </div>{" "}
            &ge;{" "}
            <div className="ulamek">
              <div className="licznik">2x</div>
              <div className="kreska-ulamkowa" />
              <div className="mianownik">(x-1)(x+1)</div>
            </div>
          </li>
          <li>stosuje wzory Viète'a dla równań kwadratowych;</li>
          <li>
            rozwiązuje równania i nierówności z wartością bezwzględną, o stopniu
            trudności nie większym niż: 2|x + 3| + 3|x - 1| = 13, |x + 2| + 2|x
            - 3| &lt; 11;
          </li>
          <li>
            analizuje równania i nierówności liniowe z parametrami oraz równania
            i nierówności kwadratowe z parametrami, w szczególności wyznacza
            liczbę rozwiązań w zależności od parametrów, podaje warunki, przy
            których rozwiązania mają żądaną własność, i wyznacza rozwiązania w
            zależności od parametrów.
          </li>
        </ol>
        <hr id="uklady-rownan" />

        <h1>IV. Układy równań</h1>
        <h2>Zakres podstawowy</h2>
        <p>Uczeń:</p>
        <ol>
          <li>
            rozwiązuje układy równań liniowych z dwiema niewiadomymi, podaje
            interpretację geometryczną układów oznaczonych, nieoznaczonych i
            sprzecznych;
          </li>
          <li>stosuje układy równań do rozwiązywania zadań tekstowych;</li>
          <li>
            rozwiązuje metodą podstawiania układy równań, z których jedno jest
            liniowe, a drugie kwadratowe, postaci:{" "}
            <div className="uklad-rownan">
              <div className="nawias">{"{"}</div>
              <div className="dzialania">
                <div className="dzialanie">
                  <span className="lewa-strona">ax + by</span>
                  <span className="rowna-sie">=</span>
                  <span className="prawa-strona">e</span>
                </div>
                <div className="dzialanie">
                  <span className="lewa-strona">
                    x<sup>2</sup> + y<sup>2</sup> + cx + dy
                  </span>
                  <span className="rowna-sie">=</span>
                  <span className="prawa-strona">f</span>
                </div>
              </div>
            </div>{" "}
            lub{" "}
            <div className="uklad-rownan">
              <div className="nawias">{"{"}</div>
              <div className="dzialania">
                <div className="dzialanie">
                  <span className="lewa-strona">ax + by</span>
                  <span className="rowna-sie">=</span>
                  <span className="prawa-strona">e</span>
                </div>
                <div className="dzialanie">
                  <span className="lewa-strona">y</span>
                  <span className="rowna-sie">=</span>
                  <span className="prawa-strona">
                    cx<sup>2</sup> + dx + f .
                  </span>
                </div>
              </div>
            </div>
          </li>
        </ol>
        <h2>Zakres rozszerzony.</h2>
        <p>
          Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto
          rozwiązuje układy równań kwadratowych postaci{" "}
          <div className="uklad-rownan">
            <div className="nawias">{"{"}</div>
            <div className="dzialania">
              <div className="dzialanie">
                <span className="lewa-strona">
                  x<sup>2</sup> + y<sup>2</sup> + ax + by
                </span>
                <span className="rowna-sie">=</span>
                <span className="prawa-strona">c</span>
              </div>
              <div className="dzialanie">
                <span className="lewa-strona">
                  x<sup>2</sup> + y<sup>2</sup> + dx + ey
                </span>
                <span className="rowna-sie">=</span>
                <span className="prawa-strona">f .</span>
              </div>
            </div>
          </div>
        </p>
        <hr id="funkcje" />

        <h1>V. Funkcje</h1>
        <h2>Zakres podstawowy</h2>
        <p>Uczeń:</p>
        <ol>
          <li>
            określa funkcje jako jednoznaczne przyporządkowanie za pomocą opisu
            słownego, tabeli, wykresu, wzoru (także różnymi wzorami na różnych
            przedziałach);
          </li>
          <li>oblicza wartość funkcji zadanej wzorem algebraicznym;</li>
          <li>
            odczytuje i interpretuje wartości funkcji określonych za pomocą
            tabel, wykresów, wzorów itp., również w sytuacjach wielokrotnego
            użycia tego samego źródła informacji lub kilku źródeł jednocześnie;
          </li>
          <li>
            odczytuje z wykresu funkcji: dziedzinę, zbiór wartości, miejsca
            zerowe, przedziały monotoniczności, przedziały, w których funkcja
            przyjmuje wartości większe (nie mniejsze) lub mniejsze (nie większe)
            od danej liczby, największe i najmniejsze wartości funkcji (o ile
            istnieją) w danym przedziale domkniętym oraz argumenty, dla których
            wartości największe i najmniejsze są przez funkcję przyjmowane;
          </li>
          <li>
            interpretuje współczynniki występujące we wzorze funkcji liniowej;
          </li>
          <li>
            wyznacza wzór funkcji liniowej na podstawie informacji o jej
            wykresie lub o jej własnościach;
          </li>
          <li>szkicuje wykres funkcji kwadratowej zadanej wzorem;</li>
          <li>
            interpretuje współczynniki występujące we wzorze funkcji kwadratowej
            w postaci ogólnej, kanonicznej i iloczynowej (jeśli istnieje);
          </li>
          <li>
            wyznacza wzór funkcji kwadratowej na podstawie informacji o tej
            funkcji lub o jej wykresie;
          </li>
          <li>
            wyznacza największą i najmniejszą wartość funkcji kwadratowej w
            przedziale domkniętym;
          </li>
          <li>
            wykorzystuje własności funkcji liniowej i kwadratowej do
            interpretacji zagadnień geometrycznych, fizycznych itp., także
            osadzonych w kontekście praktycznym;
          </li>
          <li>
            na podstawie wykresu funkcji y = f(x) rysuje wykresy funkcji y = f(x
            - a), y = f(x) + b, y = - f(x), y = f(-x);
          </li>
          <li>
            <div className="dluzsza-przerwa" />
            posługuje się funkcją f(x) ={" "}
            <div className="ulamek">
              <div className="licznik">a</div>
              <div className="kreska-ulamkowa" />
              <div className="mianownik">x</div>
            </div>
            , w tym jej wykresem, do opisu i interpretacji zagadnień związanych
            z wielkościami odwrotnie proporcjonalnymi, również w zastosowaniach
            praktycznych;
          </li>
          <li>
            posługuje się funkcjami wykładniczą i logarytmiczną, w tym ich
            wykresami, do opisu i interpretacji zagadnień związanych z
            zastosowaniami praktycznymi.
          </li>
        </ol>
        <h2>Zakres rozszerzony</h2>
        <p>
          Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
        </p>
        <ol>
          <li>
            na podstawie wykresu funkcji y = f(x) rysuje wykres funkcji y =
            |f(x)|;
          </li>
          <li>posługuje się złożeniami funkcji;</li>
          <li>
            dowodzi monotoniczności funkcji zadanej wzorem, jak w przykładzie:
            wykaż, że funkcja f(x) ={" "}
            <div className="ulamek">
              <div className="licznik">x - 1</div>
              <div className="kreska-ulamkowa" />
              <div className="mianownik">x + 2</div>
            </div>{" "}
            jest monotoniczna w przedziale (-∞, -2).
          </li>
        </ol>
        <hr id="ciagi" />

        <h1>VI. Ciągi</h1>
        <h2>Zakres podstawowy</h2>
        <p>Uczeń:</p>
        <ol>
          <li>oblicza wyrazy ciągu określonego wzorem ogólnym;</li>
          <li>
            oblicza początkowe wyrazy ciągów określonych rekurencyjnie, jak w
            przykładach:
            <ol type="a">
              <li>
                <div className="uklad-rownan">
                  <div className="nawias">{"{"}</div>
                  <div className="dzialania">
                    <div className="dzialanie">
                      <span className="lewa-strona">
                        a<sub>1</sub>
                      </span>
                      <span className="rowna-sie">=</span>
                      <span className="prawa-strona">0,001</span>
                    </div>
                    <div className="dzialanie">
                      <span className="lewa-strona">
                        a<sub>n+1</sub>
                      </span>
                      <span className="rowna-sie">=</span>
                      <span className="prawa-strona">
                        a<sub>n</sub> +{" "}
                        <div className="ulamek">
                          <div className="licznik">1</div>
                          <div className="kreska-ulamkowa" />
                          <div className="mianownik">2</div>
                        </div>{" "}
                        a<sub>n</sub>(1 - a<sub>n</sub>),
                      </span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="dluzsza-przerwa" />
                <div className="uklad-rownan">
                  <div
                    className="nawias"
                    style={{ fontSize: "5em", marginBottom: "15px" }}
                  >
                    {"{"}
                  </div>
                  <div className="dzialania">
                    <div className="dzialanie">
                      <span className="lewa-strona">
                        a<sub>1</sub>
                      </span>
                      <span className="rowna-sie">=</span>
                      <span className="prawa-strona">1</span>
                    </div>
                    <div className="dzialanie">
                      <span className="lewa-strona">
                        a<sub>2</sub>
                      </span>
                      <span className="rowna-sie">=</span>
                      <span className="prawa-strona">1</span>
                    </div>
                    <div className="dzialanie">
                      <span className="lewa-strona">
                        a<sub>n+2</sub>
                      </span>
                      <span className="rowna-sie">=</span>
                      <span className="prawa-strona">
                        a<sub>n+1</sub> + a<sub>n</sub>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="dluzsza-przerwa" />
              </li>
            </ol>
          </li>
          <li>
            w prostych przypadkach bada, czy ciąg jest rosnący, czy malejący;
          </li>
          <li>sprawdza, czy dany ciąg jest arytmetyczny lub geometryczny;</li>
          <li>
            stosuje wzór na n-ty wyraz i na sumę n początkowych wyrazów ciągu
            arytmetycznego;
          </li>
          <li>
            stosuje wzór na n-ty wyraz i na sumę n początkowych wyrazów ciągu
            geometrycznego;&#8730;
          </li>
          <li>
            wykorzystuje własności ciągów, w tym arytmetycznych i
            geometrycznych, do rozwiązywania zadań, również osadzonych w
            kontekście praktycznym.
          </li>
        </ol>
        <h2>Zakres rozszerzony</h2>
        <p>
          Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
        </p>
        <ol>
          <li>
            oblicza granice ciągów, korzystając z granic ciągów typu{" "}
            <div className="ulamek">
              <div className="licznik">1</div>
              <div className="kreska-ulamkowa" />
              <div className="mianownik">n</div>
            </div>
            , <sup className="stopien-pierwiastka">3</sup>√a oraz twierdzeń o
            granicach sumy, różnicy, iloczynu i ilorazu ciągów zbieżnych, a
            także twierdzenia o trzech ciągach;
            <div className="dluzsza-przerwa" />
          </li>
          <li>rozpoznaje zbieżne szeregi geometryczne i oblicza ich sumę.</li>
        </ol>
        <hr id="trygonometria" />

        <h1>VII. Trygonometria</h1>
        <h2>Zakres podstawowy</h2>
        <p>Uczeń:</p>
        <ol>
          <li>
            wykorzystuje definicje funkcji: sinus, cosinus i tangens dla kątów
            od 0° do 180°, w szczególności wyznacza wartości funkcji
            trygonometrycznych dla kątów 30°, 45°, 60°;
          </li>
          <li>
            znajduje przybliżone wartości funkcji trygonometrycznych,
            korzystając z tablic lub kalkulatora;
          </li>
          <li>
            znajduje za pomocą tablic lub kalkulatora przybliżoną wartość kąta,
            jeśli dana jest wartość funkcji trygonometrycznej;
          </li>
          <li>
            <div className="dluzsza-przerwa" />
            korzysta z wzorów sin<sup>2</sup> &#945; + cos<sup>2</sup> &#945; =
            1, tg &#945; ={" "}
            <div className="ulamek">
              <div className="licznik">sin &#945;</div>
              <div className="kreska-ulamkowa" />
              <div className="mianownik">cos &#945;</div>
            </div>
            ;<div className="dluzsza-przerwa" />
          </li>
          <li>
            stosuje twierdzenia sinusów i cosinusów oraz wzór na pole trójkąta P
            ={" "}
            <div className="ulamek">
              <div className="licznik">1</div>
              <div className="kreska-ulamkowa" />
              <div className="mianownik">2</div>
            </div>{" "}
            &#183; a &#183; b &#183; sin &#947;;
            <div className="dluzsza-przerwa" />
          </li>
          <li>
            oblicza kąty trójkąta i długości jego boków przy odpowiednich danych
            (rozwiązuje trójkąty).
          </li>
        </ol>
        <h2>Zakres rozszerzony</h2>
        <p>
          Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
        </p>
        <ol>
          <li>
            stosuje miarę łukową, zamienia miarę łukową kąta na stopniową i
            odwrotnie;
          </li>
          <li>
            posługuje się wykresami funkcji trygonometrycznych: sinus, cosinus,
            tangens;
          </li>
          <li>wykorzystuje okresowość funkcji trygonometrycznych;</li>
          <li>stosuje wzory redukcyjne dla funkcji trygonometrycznych;</li>
          <li>
            korzysta z wzorów na sinus, cosinus i tangens sumy i różnicy kątów,
            a także na funkcje trygonometryczne kątów podwojonych;
          </li>
          <li>
            rozwiązuje równania i nierówności trygonometryczne o stopniu
            trudności nie większym niż w przykładach: 4(cos 2x) (cos 5x) = 2(cos
            7x) + 1, 2sin<sup>2</sup> x &le; 1.
          </li>
        </ol>
        <hr id="planimetria" />

        <h1>VIII. Planimetria</h1>
        <h2>Zakres podstawowy</h2>
        <p>Uczeń:</p>
        <ol>
          <li>
            wyznacza promienie i średnice okręgów, długości cięciw okręgów oraz
            odcinków stycznych, w tym z wykorzystaniem twierdzenia Pitagorasa;
          </li>
          <li>
            rozpoznaje trójkąty ostrokątne, prostokątne i rozwartokątne przy
            danych długościach boków (m.in. stosuje twierdzenie odwrotne do
            twierdzenia Pitagorasa i twierdzenie cosinusów); stosuje
            twierdzenie: w trójkącie naprzeciw większego kąta wewnętrznego leży
            dłuższy bok;
          </li>
          <li>
            rozpoznaje wielokąty foremne i korzysta z ich podstawowych
            własności;
          </li>
          <li>
            korzysta z własności kątów i przekątnych w prostokątach,
            równoległobokach, rombach i trapezach;
          </li>
          <li>stosuje własności kątów wpisanych i środkowych;</li>
          <li>stosuje wzory na pole wycinka koła i długość łuku okręgu;</li>
          <li>
            stosuje twierdzenia: Talesa, odwrotne do twierdzenia Talesa, o
            dwusiecznej kąta oraz o kącie między styczną a cięciwą;
          </li>
          <li>korzysta z cech podobieństwa trójkątów;</li>
          <li>
            wykorzystuje zależności między obwodami oraz między polami figur
            podobnych;
          </li>
          <li>
            wskazuje podstawowe punkty szczególne w trójkącie: środek okręgu
            wpisanego w trójkąt, środek okręgu opisanego na trójkącie,
            ortocentrum, środek ciężkości oraz korzysta z ich własności;
          </li>
          <li>
            stosuje funkcje trygonometryczne do wyznaczania długości odcinków w
            figurach płaskich oraz obliczania pól figur;
          </li>
          <li>przeprowadza dowody geometryczne.</li>
        </ol>
        <h2>Zakres rozszerzony</h2>
        <p>
          Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto
          stosuje własności czworokątów wpisanych w okrąg i opisanych na okręgu.
        </p>
        <hr id="geometria-analityczna-na-plaszczyznie-kartezjanskiej" />

        <h1>IX. Geometria analityczna na płaszczyźnie kartezjańskiej</h1>
        <h2>Zakres podstawowy</h2>
        <p>Uczeń:</p>
        <ol>
          <li>
            rozpoznaje wzajemne położenie prostych na płaszczyźnie na podstawie
            ich równań, w tym znajduje wspólny punkt dwóch prostych, jeśli taki
            istnieje;
          </li>
          <li>
            posługuje się równaniami prostych na płaszczyźnie, w postaci
            kierunkowej i ogólnej, w tym wyznacza równanie prostej o zadanych
            własnościach (takich jak na przykład przechodzenie przez dwa dane
            punkty, znany współczynnik kierunkowy, równoległość lub
            prostopadłość do innej prostej, styczność do okręgu);
          </li>
          <li>oblicza odległość dwóch punktów w układzie współrzędnych;</li>
          <li>
            posługuje się równaniem okręgu (x - a)<sup>2</sup> + (y - b)
            <sup>2</sup> = r<sup>2</sup>;
          </li>
          <li>oblicza odległość punktu od prostej;</li>
          <li>
            znajduje punkty wspólne prostej i okręgu oraz prostej i paraboli
            będącej wykresem funkcji kwadratowej;
          </li>
          <li>
            wyznacza obrazy okręgów i wielokątów w symetriach osiowych względem
            osi układu współrzędnych, symetrii środkowej (o środku w początku
            układu współrzędnych).
          </li>
        </ol>
        <h2>Zakres rozszerzony</h2>
        <p>
          Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
        </p>
        <ol>
          <li>stosuje równanie okręgu w postaci ogólnej;</li>
          <li>znajduje punkty wspólne dwóch okręgów;</li>
          <li>
            zna pojęcie wektora i oblicza jego współrzędne oraz długość, dodaje
            wektory i mnoży wektor przez liczbę, oba te działania wykonuje
            zarówno analitycznie, jak i geometrycznie.
          </li>
        </ol>
        <hr id="stereometria" />

        <h1>X. Stereometria</h1>
        <h2>Zakres podstawowy</h2>
        <p>Uczeń:</p>
        <ol>
          <li>
            rozpoznaje wzajemne położenie prostych w przestrzeni, w
            szczególności proste prostopadłe nieprzecinające się;
          </li>
          <li>
            posługuje się pojęciem kąta między prostą a płaszczyzną oraz
            pojęciem kąta dwuściennego między półpłaszczyznami;
          </li>
          <li>
            rozpoznaje w graniastosłupach i ostrosłupach kąty między odcinkami
            (np. krawędziami, krawędziami i przekątnymi) oraz kąty między
            ścianami, oblicza miary tych kątów;
          </li>
          <li>
            rozpoznaje w walcach i w stożkach kąt między odcinkami oraz kąt
            między odcinkami i płaszczyznami (np. kąt rozwarcia stożka, kąt
            między tworzącą a podstawą), oblicza miary tych kątów;
          </li>
          <li>
            określa, jaką figurą jest dany przekrój prostopadłościanu
            płaszczyzną;
          </li>
          <li>
            oblicza objętości i pola powierzchni graniastosłupów, ostrosłupów,
            walca, stożka i kuli, również z wykorzystaniem trygonometrii i
            poznanych twierdzeń;
          </li>
          <li>wykorzystuje zależność między objętościami brył podobnych.</li>
        </ol>
        <h2>Zakres rozszerzony</h2>
        <p>
          Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
        </p>
        <ol>
          <li>
            zna i stosuje twierdzenie o prostej prostopadłej do płaszczyzny i o
            trzech prostopadłych;
          </li>
          <li>
            wyznacza przekroje sześcianu i ostrosłupów prawidłowych oraz oblicza
            ich pola, także z wykorzystaniem trygonometrii.
          </li>
        </ol>
        <hr id="kombinatoryka" />

        <h1>XI. Kombinatoryka</h1>
        <h2>Zakres podstawowy</h2>
        <p>Uczeń:</p>
        <ol>
          <li>zlicza obiekty w prostych sytuacjach kombinatorycznych;</li>
          <li>
            zlicza obiekty, stosując reguły mnożenia i dodawania (także łącznie)
            dla dowolnej liczby czynności w sytuacjach nie trudniejszych niż:
            <ol type="a">
              <li>
                obliczenie, ile jest czterocyfrowych nieparzystych liczb
                całkowitych dodatnich takich, że w ich zapisie dziesiętnym
                występuje dokładnie jedna cyfra 1 i dokładnie jedna cyfra 2,
              </li>
              <li>
                obliczenie, ile jest czterocyfrowych parzystych liczb
                całkowitych dodatnich takich, że w ich zapisie dziesiętnym
                występuje dokładnie jedna cyfra 0 i dokładnie jedna cyfra 1.
              </li>
            </ol>
          </li>
        </ol>
        <h2>Zakres rozszerzony</h2>
        <p>
          Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
        </p>
        <ol>
          <li>
            oblicza liczbę możliwych sytuacji, spełniających określone kryteria,
            z wykorzystaniem reguły mnożenia i dodawania (także łącznie) oraz
            wzorów na liczbę: permutacji, kombinacji i wariacji, również w
            przypadkach wymagających rozważenia złożonego modelu zliczania
            elementów;
          </li>
          <li>
            stosuje współczynnik dwumianowy (symbol Newtona) i jego własności
            przy rozwiązywaniu problemów kombinatorycznych.
          </li>
        </ol>
        <hr id="rachunek-prawdopodobienstwa-i-statystyka" />

        <h1>XII. Rachunek prawdopodobieństwa i statystyka</h1>
        <h2>Zakres podstawowy</h2>
        <p>Uczeń:</p>
        <ol>
          <li>oblicza prawdopodobieństwo w modelu klasycznym;</li>
          <li>stosuje skalę centylową;</li>
          <li>
            oblicza średnią arytmetyczną i średnią ważoną, znajduje medianę i
            dominantę;
          </li>
          <li>
            oblicza odchylenie standardowe zestawu danych (także w przypadku
            danych odpowiednio pogrupowanych), interpretuje ten parametr dla
            danych empirycznych;
          </li>
          <li>
            oblicza wartość oczekiwaną, np. przy ustalaniu wysokości wygranej w
            prostych grach losowych i loteriach.
          </li>
        </ol>
        <h2>Zakres rozszerzony</h2>
        <p>
          Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
        </p>
        <ol>
          <li>
            oblicza prawdopodobieństwo warunkowe i stosuje wzór Bayesa, stosuje
            twierdzenie o prawdopodobieństwie całkowitym;
          </li>
          <li>stosuje schemat Bernoulliego.</li>
        </ol>
        <hr id="optymalizacja-i-rachunek-rozniczkowy" />

        <h1>XIII. Optymalizacja i rachunek różniczkowy</h1>
        <h2>Zakres podstawowy</h2>
        <p>
          Uczeń rozwiązuje zadania optymalizacyjne w sytuacjach dających się
          opisać funkcją kwadratową.
        </p>
        <h2>Zakres rozszerzony</h2>
        <p>
          Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
        </p>
        <ol>
          <li>oblicza granice funkcji (w tym jednostronne);</li>
          <li>
            stosuje własność Darboux do uzasadniania istnienia miejsca zerowego
            funkcji i znajdowania przybliżonej wartości miejsca zerowego;
          </li>
          <li>
            stosuje definicję pochodnej funkcji, podaje interpretację
            geometryczną i fizyczną pochodnej;
          </li>
          <li>
            oblicza pochodną funkcji potęgowej o wykładniku rzeczywistym oraz
            oblicza pochodną, korzystając z twierdzeń o pochodnej sumy, różnicy,
            iloczynu, ilorazu i funkcji złożonej;
          </li>
          <li>stosuje pochodną do badania monotoniczności funkcji;</li>
          <li>rozwiązuje zadania optymalizacyjne z zastosowaniem pochodnej.</li>
        </ol>
        <hr />
        <footer>
          Źródło: <span className="purple-text">CKE</span>
        </footer>
      </div>
    </div>
  );
}

export default ZagadnieniaMaturalne;
