import React from "react";
import { Link } from "react-router-dom";
import "../../component-styles/Artykuly.scss";

function PrzygotowanieDoMatury() {
  return (
    <div className="przygotowanie-do-matury artykul">
      <header className="welcome-przygotowanie-do-matury welcome-artykul">
        Przygotowanie do Matury: kluczowe aspekty i wskazówki dla uczniów liceum
      </header>
      <p>
        <strong>Egzamin maturalny</strong> to jedno z najważniejszych wydarzeń w
        życiu ucznia liceum. Wymaga solidnej wiedzy, a nie rzadko też dużego
        zaangażowania. Często już na samym początku wiele osób czuje się
        przytłoczonych ilością materiału oraz presją nałożoną przez siebie i
        środowisko. Przygotowania narażają maturzystów przedewszystkim na stres
        i inne trudne emocje. Aby temu zapobiec warto już na samym początku
        ułożyć plan nauki.
      </p>
      <strong>1. Odpowiednia organizacja:</strong> Najskuteczniejszym sposobem
      na zminimalizowanie często towarzyszącego stresu jest solidne
      rozplanowanie nauki całego materiału. Ze świadomością, że na przyswojenie
      wszystkich zagadnień nadejdzie właściwa pora będziesz dużo spokojniejszy
      podczas przygotowań i nie wpadniesz w nagły natłok zadań. Tworząc dobrany
      pod siebie harmonogram wiesz kiedy i czym konkretnie się zająć. W
      organizacji czasu możesz skorzystać ze specjalnego planera lub kalendarza.
      Zreflektuj się, na które partie potrzebujesz więcej czasu. Podczas
      planowania ważna jest systematyczność przyswajania materiału, ale także
      istotne są przerwy. Pamiętaj, że zagadnienia maturalne znajdziesz na
      stronie CKE w zakładce "Informatory", natomiast zmiany w wymaganiach
      znajdziesz w przyszłości na stronie głównej matzone.pl
      <br />
      <strong>2. Dobrze dobrana metoda nauki:</strong> Istotnym jest by znaleźć
      odpowiedni sposób na naukę. Korzystając z metod, które sprawiają ci
      przyjemność poszczególne tematy będą dużo bardziej przystępne, a samo
      dokształcanie się przestanie być przykrym obowiązkiem. Może być to krok ku
      zaczęciu postrzegania nauki jako ciekawszej i ekscytującej. O skutecznych
      metodach nauki również przeczytasz na naszej stronie:{" "}
      <Link to="/metody-efektywnego-uczenia-sie">
        matzone.pl/metody-efektywnego-uczenia-sie
      </Link>
      <br />
      <strong>3. Zdrowy tryb życia:</strong> Często pomijanym, a kluczowym
      czynnikiem tego jak wydajny jesteś jest twój tryb życia. Nacisk kładź na
      dobrze zbilansowaną dietę składająca się z: białek, węglowodanów,
      tłuszczy, najlepiej bogatych w kwasy Omega-3 i Omega-6, błonnika,
      składników mineralnych i witamin. Nie powinieneś zapominać by urozmaicić
      swoje żywienie o rośliny strączkowe, niskotłuszczowe produkty mleczne,
      orzechy i nasiona, ryby oraz produkty zbożowe z pełnego ziarna. Aby
      pozytywnie wpłynąć na pamięć i koncentrację powinieneś również dbać o
      nawodnienie organizmu, wystarczającą ilość snu, a także zaleca się
      uprawianie aktywności fizycznej.
      <br />
      <strong>4. Odpoczynek:</strong> Staraj się utrzymać równowagę między nauką
      a relaksem, dbając o swoje ciało i umysł. Mimo że przed tobą tzw. "egzamin
      dojrzałości" wciąż jesteś człowiekiem, nie tylko uczniem. Znajdź czas na
      swoje hobby, spotkania ze znajomymi i realizację swoich własnych celów.
      Odpręż się oglądając serial, czytając książkę lub medytując.
      <br />
      Ostatnie o czym warto wspomnieć to pozytywne nastawienie. Końcowo nawet
      Matura to wciąż tylko egzamin. Nie definiuje on twojej wartości. "Dobre"
      wyniki nie gwarantują powodzenia na ścieżce kariery, "złe" natomiast nie
      przekreślają cię jako osoby usatysfakcjonowanej i szczęśliwej z życia, a
      do egzaminu możesz podejść wielokrotnie.
    </div>
  );
}
export default PrzygotowanieDoMatury;
