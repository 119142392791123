interface Tematy {
  [key: number]: {
    [key: string]: {
      [key: number]: {
        liczbaStronTeorii?: number;
        teoriaCustom?: string[];
        zadanieMaturalneSpecyfikacje?: string[][];
        [key: number]: {
          name: string;
          liczbaStronTeorii: number;
          teoriaCustom?: string[];
        };
      };
    };
  };
}

const tematy: Tematy = {
  1: {
    podstawa: {
      1: {
        liczbaStronTeorii: 13,
        teoriaCustom: [
          "1,1,1,1",
          "1,1,1,2",
          "1,1,1,3",
          "1,1,2,1",
          "1,1,2,2",
          "1,1,3,1",
          "1,1,4,1",
          "1,1,5,1",
          "1,1,6,1",
          "1,1,7,1",
          "1,1,8,1",
          "1,1,9,1",
          "1,1,10,1",
        ],
        zadanieMaturalneSpecyfikacje: [
          ["40", "220", "c", "maj 2023"],
          ["40", "145", "d", "maj 2023"],
        ],
        1: {
          name: "Zbiór. Działania na zbiorach",
          liczbaStronTeorii: 3,
        },
        2: {
          name: "Zbiory liczbowe. Oś liczbowa",
          liczbaStronTeorii: 2,
        },
        3: {
          name: "Prawa działań w zbiorze liczb rzeczywistych",
          liczbaStronTeorii: 1,
        },
        4: {
          name: "Przedziały",
          liczbaStronTeorii: 1,
        },
        5: {
          name: "Zbiór liczb naturalnych i zbiór liczb całkowitych",
          liczbaStronTeorii: 1,
        },
        6: {
          name: "Przypomnienie i uzupełnienie wiadomości o równaniach",
          liczbaStronTeorii: 1,
        },
        7: {
          name: "Rozwiązywanie równań metodą równań równoważnych",
          liczbaStronTeorii: 1,
        },
        8: {
          name: "Nierówność z jedną niewiadomą. Rozwiązywanie nierówności metodą nierówności równoważnych",
          liczbaStronTeorii: 1,
        },
        9: {
          name: "Procenty",
          liczbaStronTeorii: 1,
        },
        10: {
          name: "Punkty procentowe",
          liczbaStronTeorii: 1,
        },
      },
      2: {
        liczbaStronTeorii: 14,
        teoriaCustom: [
          "1,2,1,1",
          "1,2,2,1",
          "1,2,3,1",
          "1,2,4,1",
          "1,2,5,1",
          "1,2,6,1",
          "1,2,7,1",
          "1,2,8,1",
          "1,2,9,1",
          "1,2,10,1",
          "1,2,11,1",
          "1,2,12,1",
          "1,2,13,1",
          "1,2,14,1",
        ],
        zadanieMaturalneSpecyfikacje: [
          ["35", "140", "d", "maj 2023"],
          ["35", "270", "a", "maj 2023"],
          ["45", "760", "a", "maj 2023", "rows4", "20"],
          ["75", "150", "a", "czerwiec 2023"],
          ["90", "190", "c", "próbna grudzień 2023"],
          ["70", "170", "b", "próbna grudzień 2023"],
          ["42.5", "150", "a", "próbna grudzień 2023"],
          ["35", "140", "a", "próbna wrzesień 2022"],
          ["70", "200", "b", "próbna wrzesień 2022"],
          ["45", "160", "b", "próbna marzec 2022"],
          //["35", "125", "b", "czerwiec 2014"],
          //["45", "185", "c", "maj 2014"],
          //["40", "130", "c", "czerwiec 2013"],
          //["40", "210", "b", "maj 2013"],
          //["40", "200", "d", "czerwiec 2012"],
          //["40", "225", "a", "maj 2012"],
          //["35", "125", "b", "maj 2012"],
          //["95", "160", "b", "czerwiec 2011"],
          //["35", "125", "b", "maj 2010"],
        ],
        1: {
          name: "Potęga o wykładniku naturalnym",
          liczbaStronTeorii: 1,
        },
        2: {
          name: "Pierwiastek arytmetyczny. Pierwiastek stopnia nieparzystego z liczby ujemnej",
          liczbaStronTeorii: 1,
        },
        3: {
          name: "Działania na wyrażeniach algebraicznych",
          liczbaStronTeorii: 1,
        },
        4: {
          name: "Wzory skróconego mnożenia stopnia 2",
          liczbaStronTeorii: 1,
        },
        5: {
          name: "Potęga o wykładniku całkowitym ujemnym",
          liczbaStronTeorii: 1,
        },
        6: {
          name: "Potęga o wykładniku wymiernym",
          liczbaStronTeorii: 1,
        },
        7: {
          name: "Potęga o wykładniku rzeczywistym",
          liczbaStronTeorii: 1,
        },
        8: {
          name: "Określenie logarytmu",
          liczbaStronTeorii: 1,
        },
        9: {
          name: "Zastosowanie logarytmów",
          liczbaStronTeorii: 1,
        },
        10: {
          name: "Zdanie. Zaprzeczenie zdania",
          liczbaStronTeorii: 1,
        },
        11: {
          name: "Zdania złożone. Zaprzeczenia zdań złożonych",
          liczbaStronTeorii: 1,
        },
        12: {
          name: "Definicja. Twierdzenie. Dowód twierdzenia",
          liczbaStronTeorii: 1,
        },
        13: {
          name: "Przekształcanie wzorów",
          liczbaStronTeorii: 1,
        },
        14: {
          name: "Średnie",
          liczbaStronTeorii: 1,
        },
      },
      3: {
        liczbaStronTeorii: 8,
        teoriaCustom: [
          "1,3,1,1",
          "1,3,2,1",
          "1,3,3,1",
          "1,3,4,1",
          "1,3,5,1",
          "1,3,6,1",
          "1,3,7,1",
          "1,3,8,1",
        ],
        zadanieMaturalneSpecyfikacje: [
          ["95", "350", "b", "maj 2023", "rows4", "7.5"],
        ],
        1: {
          name: "Pojęcie funkcji. Funkcja liczbowa. Sposoby opisywania funkcji",
          liczbaStronTeorii: 1,
        },
        2: {
          name: "Wykres funkcji",
          liczbaStronTeorii: 1,
        },
        3: {
          name: "Dziedzina funkcji liczbowej",
          liczbaStronTeorii: 1,
        },
        4: {
          name: "Zbiór wartości funkcji liczbowej. Najmniejsza i największa wartość funkcji",
          liczbaStronTeorii: 1,
        },
        5: {
          name: "Miejsce zerowe funkcji",
          liczbaStronTeorii: 1,
        },
        6: {
          name: "Monotoniczność funkcji",
          liczbaStronTeorii: 1,
        },
        7: {
          name: "Funkcje różnowartościowe",
          liczbaStronTeorii: 1,
        },
        8: {
          name: "Odczytywanie własności funkcji na podstawie jej wykresu",
          liczbaStronTeorii: 1,
        },
        9: {
          name: "Zastosowanie wiadomości o funkcjach do opisywania, interpretowania i przetwarzania informacji wyrażonych w postaci wykresu funkcji",
          liczbaStronTeorii: 8,

          teoriaCustom: [
            "1,3,1,1",
            "1,3,2,1",
            "1,3,3,1",
            "1,3,4,1",
            "1,3,5,1",
            "1,3,6,1",
            "1,3,7,1",
            "1,3,8,1",
          ],
        },
      },
      4: {
        liczbaStronTeorii: 4,
        teoriaCustom: ["1,4,1,1", "1,4,2,1", "1,4,3,1", "1,4,4,1"],
        zadanieMaturalneSpecyfikacje: [
          ["95", "350", "b", "maj 2023", "rows4", "7.5"],
          ["35", "300", "c", "maj 2023", "rows2"],
          ["35", "200", "a", "czerwiec 2023"],
          ["45", "180", "b", "wrzesień 2022"],
          ["35", "300", "b", "próbna marzec 2022", "rows2"],
        ],
        1: {
          name: "Proporcjonalność prosta",
          liczbaStronTeorii: 1,
        },
        2: {
          name: "Funkcja liniowa. Wykres i miejsce zerowe funkcji liniowej",
          liczbaStronTeorii: 1,
        },
        3: {
          name: "Znaczenie współczynnika kierunkowego we wzorze funkcji liniowej",
          liczbaStronTeorii: 1,
        },
        4: {
          name: "Własności funkcji liniowej - zadania różne",
          liczbaStronTeorii: 1,
        },
      },
      5: {
        liczbaStronTeorii: 5,

        teoriaCustom: ["1,5,1,1", "1,5,2,1", "1,5,3,1", "1,5,4,1", "1,5,5,1"],
        1: {
          name: "Równania pierwszego stopnia z dwiema niewiadomymi",
          liczbaStronTeorii: 1,
        },
        2: {
          name: "Układy równań pierwszego stopnia z dwiema niewiadomymi. Graficzne rozwiązywanie układów równań",
          liczbaStronTeorii: 1,
        },
        3: {
          name: "Rozwiązywanie układów równań pierwszego stopnia z dwiema niewiadomymi metodą podstawiania",
          liczbaStronTeorii: 1,
        },
        4: {
          name: "Rozwiązywanie układów równań pierwszego stopnia z dwiema niewiadomymi metodą przeciwnych współczynników",
          liczbaStronTeorii: 1,
        },
        5: {
          name: "Zastosowanie układów równań do rozwiązywania zadań",
          liczbaStronTeorii: 1,
        },
      },
      6: {
        liczbaStronTeorii: 5,

        teoriaCustom: ["1,6,1,1", "1,6,2,1", "1,6,3,1", "1,6,4,1", "1,6,5,1"],
        1: {
          name: "Funkcja kwadratowa",
          liczbaStronTeorii: 1,
        },
        2: {
          name: "Funkcja kwadratowa - zastosowania",
          liczbaStronTeorii: 1,
        },
        3: {
          name: "Proporcjonalność odwrotna",
          liczbaStronTeorii: 1,
        },
        4: {
          name: "Funkcja wykładnicza",
          liczbaStronTeorii: 1,
        },
        5: {
          name: "Funkcja logarytmiczna",
          liczbaStronTeorii: 1,
        },
      },
      7: {
        liczbaStronTeorii: 10,

        teoriaCustom: [
          "1,7,1,1",
          "1,7,1,2",
          "1,7,2,1",
          "1,7,2,2",
          "1,7,3,1",
          "1,7,4,1",
          "1,7,5,1",
          "1,7,5,2",
          "1,7,6,1",
          "1,7,7,1",
          "1,7,7,2",
          "1,7,7,3",
          "1,7,8,1",
        ],
        zadanieMaturalneSpecyfikacje: [
          ["40", "170", "a", "próbna grudzień 2023"],
        ],
        1: {
          name: "Punkt, prosta, odcinek, półprosta, kąt, figura wypukła, figura ograniczona",
          liczbaStronTeorii: 2,
        },
        2: {
          name: "Wzajemne położenie prostych na płaszczyźnie, odległość punktu od prostej, odległość między prostymi równoległymi, symetralna odcinka, dwusieczna kąta",
          liczbaStronTeorii: 2,
        },
        3: {
          name: "Dwie proste przecięte trzecią prostą. Suma kątów w trójkącie",
          liczbaStronTeorii: 1,
        },
        4: {
          name: "Twierdzenie Talesa",
          liczbaStronTeorii: 1,
        },
        5: {
          name: "Podział trójkątów. Nierówność trójkąta. Odcinek łączący środki boków w trójkącie",
          liczbaStronTeorii: 2,
        },
        6: {
          name: "Twierdzenie Pitagorasa. Twierdzenie odwrotne do twierdzenia Pitagorasa",
          liczbaStronTeorii: 1,
        },
        7: {
          name: "Wysokości w trójkącie. Środkowe w trójkącie",
          liczbaStronTeorii: 3,
        },
        8: {
          name: "Przystawanie trójkątów",
          liczbaStronTeorii: 1,
        },
        9: {
          name: "Podobieństwo trójkątów",
          liczbaStronTeorii: 2,
        },
        10: {
          name: "Podobieństwo trójkątów - zastosowanie w zadaniach",
          liczbaStronTeorii: 2,

          teoriaCustom: ["1,7,9,1", "1,7,9,2"],
        },
      },
      8: {
        1: {
          name: "Określenie sinusa, cosinusa, tangensa i cotangensa w trójkącie prostokątnym",
          liczbaStronTeorii: 1,
        },
        2: {
          name: "Wartości sinusa, cosinusa, tangensa i cotangensa kątów 30°, 45° i 60°",
          liczbaStronTeorii: 1,
        },
        3: {
          name: "Zależności między funkcjami trygonometrycznymi tego samego kąta ostrego",
          liczbaStronTeorii: 1,
        },
      },
    },
    rozszerzenie: {
      1: {
        liczbaStronTeorii: 14,
        teoriaCustom: [
          "1,1,1,1",
          "1,1,1,2",
          "1,1,1,3",
          "1,1,2,1",
          "1,1,2,2",
          "1,1,3,1",
          "1,1,4,1",
          "1,1,5,1",
          "1,1,6,1",
          "1,1,7,1",
          "1,1,8,1",
          "1,1,9,1",
          "1,1,10,1",
          "1,1,11,1",
        ],
        1: {
          name: "Zbiór. Działania na zbiorach",
          liczbaStronTeorii: 3,
        },
        2: {
          name: "Zbiory liczbowe",
          liczbaStronTeorii: 2,
        },
        3: {
          name: "Prawa działań w zbiorze liczb rzeczywistych",
          liczbaStronTeorii: 1,
        },
        4: {
          name: "Przedziały",
          liczbaStronTeorii: 1,
        },
        5: {
          name: "Zbiór liczb naturalnych i zbiór liczb całkowitych",
          liczbaStronTeorii: 1,
        },
        6: {
          name: "Przypomnienie i uzupełnienie wiadomości o równaniach",
          liczbaStronTeorii: 1,
        },
        7: {
          name: "Rozwiązywanie równań metodą równań równoważnych",
          liczbaStronTeorii: 1,
        },
        8: {
          name: "Nierówność z jedną niewiadomą. Rozwiązywanie nierówności metodą nierówności równoważnych",
          liczbaStronTeorii: 1,
        },
        9: {
          name: "Procenty",
          liczbaStronTeorii: 1,
        },
        10: {
          name: "Punkty procentowe",
          liczbaStronTeorii: 1,
        },
        11: {
          name: "Przybliżenia, błąd bezwzględny i błąd względny, szacowanie",
          liczbaStronTeorii: 1,
        },
      },
      2: {
        liczbaStronTeorii: 14,

        teoriaCustom: [
          "1,2,1,1",
          "1,2,2,1",
          "1,2,3,1",
          "1,2,4,1",
          "1,2,5,1",
          "1,2,6,1",
          "1,2,7,1",
          "1,2,8,1",
          "1,2,9,1",
          "1,2,10,1",
          "1,2,11,1",
          "1,2,12,1",
          "1,2,13,1",
          "1,2,14,1",
        ],
        1: {
          name: "Potęga o wykładniku naturalnym",
          liczbaStronTeorii: 1,
        },
        2: {
          name: "Pierwiastek arytmetyczny. Pierwiastek stopnia nieparzystego z liczby ujemnej",
          liczbaStronTeorii: 1,
        },
        3: {
          name: "Działania na wyrażeniach algebraicznych",
          liczbaStronTeorii: 1,
        },
        4: {
          name: "Wzory skróconego mnożenia",
          liczbaStronTeorii: 1,
        },
        5: {
          name: "Potęga o wykładniku całkowitym ujemnym",
          liczbaStronTeorii: 1,
        },
        6: {
          name: "Potęga o wykładniku wymiernym",
          liczbaStronTeorii: 1,
        },
        7: {
          name: "Potęga o wykładniku rzeczywistym",
          liczbaStronTeorii: 1,
        },
        8: {
          name: "Określenie logarytmu",
          liczbaStronTeorii: 1,
        },
        9: {
          name: "Zastosowanie logarytmów",
          liczbaStronTeorii: 1,
        },
        10: {
          name: "Zdanie. Zaprzeczenie zdania",
          liczbaStronTeorii: 1,
        },
        11: {
          name: "Zdania złożone. Zaprzeczenia zdań złożonych",
          liczbaStronTeorii: 1,
        },
        12: {
          name: "Definicja. Twierdzenie. Dowód twierdzenia",
          liczbaStronTeorii: 1,
        },
        13: {
          name: "Przekształcanie wzorów",
          liczbaStronTeorii: 1,
        },
        14: {
          name: "Średnie",
          liczbaStronTeorii: 1,
        },
      },
      3: {
        liczbaStronTeorii: 9,

        teoriaCustom: [
          "1,3,1,1",
          "1,3,2,1",
          "1,3,3,1",
          "1,3,4,1",
          "1,3,5,1",
          "1,3,6,1",
          "1,3,8,1r",
          "1,3,9,1r",
          "1,3,8,1",
        ],
        1: {
          name: "Pojęcie funkcji. Funkcja liczbowa. Sposoby opisywania funkcji",
          liczbaStronTeorii: 1,
        },
        2: {
          name: "Wykres funkcji",
          liczbaStronTeorii: 1,
        },
        3: {
          name: "Dziedzina funkcji liczbowej",
          liczbaStronTeorii: 1,
        },
        4: {
          name: "Zbiór wartości funkcji liczbowej. Najmniejsza i największa wartość funkcji",
          liczbaStronTeorii: 1,
        },
        5: {
          name: "Miejsce zerowe funkcji",
          liczbaStronTeorii: 1,
        },
        6: {
          name: "Funkcje równe",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Monotoniczność funkcji",
          liczbaStronTeorii: 1,

          teoriaCustom: ["1,3,6,1"],
        },
        8: {
          name: "Funkcje różnowartościowe",
          liczbaStronTeorii: 1,

          teoriaCustom: ["1,3,8,1r"],
        },
        9: {
          name: "Funkcje parzyste i funkcje nieparzyste",
          liczbaStronTeorii: 1,

          teoriaCustom: ["1,3,9,1r"],
        },
        10: {
          name: "Odczytywanie własności funkcji na podstawie jej wykresu. Szkicowanie wykresów funkcji o zadanych własnościach",
          liczbaStronTeorii: 1,

          teoriaCustom: ["1,3,8,1"],
        },
        11: {
          name: "Zastosowanie wiadomości o funkcjach do opisywania, interpretowania i przetwarzania informacji wyrażonych w postaci wykresu funkcji",
          liczbaStronTeorii: 9,

          teoriaCustom: [
            "1,3,1,1",
            "1,3,2,1",
            "1,3,3,1",
            "1,3,4,1",
            "1,3,5,1",
            "1,3,6,1",
            "1,3,8,1r",
            "1,3,9,1r",
            "1,3,8,1",
          ],
        },
      },
      4: {
        liczbaStronTeorii: 6,

        teoriaCustom: [
          "1,4,1,1",
          "1,4,2,1",
          "1,4,3,1",
          "1,4,4,1",
          "1,4,5,1",
          "1,4,6,1",
        ],
        1: {
          name: "Proporcjonalność prosta",
          liczbaStronTeorii: 1,
        },
        2: {
          name: "Funkcja liniowa. Wykres i miejsce zerowe funkcji liniowej",
          liczbaStronTeorii: 1,
        },
        3: {
          name: "Znaczenie współczynnika kierunkowego występującego we wzorze funkcji liniowej",
          liczbaStronTeorii: 1,
        },
        4: {
          name: "Własności funkcji liniowej - zadania różne",
          liczbaStronTeorii: 1,
        },
        5: {
          name: "Zastosowanie własności funkcji liniowej w zadaniach praktycznych",
          liczbaStronTeorii: 1,
        },
        6: {
          name: "Wykresy wybranych funkcji",
          liczbaStronTeorii: 1,
        },
      },
      5: {
        liczbaStronTeorii: 5,

        teoriaCustom: ["1,5,1,1", "1,5,2,1", "1,5,3,1", "1,5,4,1", "1,5,5,1"],
        1: {
          name: "Równania pierwszego stopnia z dwiema niewiadomymi",
          liczbaStronTeorii: 1,
        },
        2: {
          name: "Układy równań pierwszego stopnia z dwiema niewiadomymi. Graficzne rozwiązywanie układów równań",
          liczbaStronTeorii: 1,
        },
        3: {
          name: "Rozwiązywanie układów równań pierwszego stopnia z dwiema niewiadomymi metodą podstawiania",
          liczbaStronTeorii: 1,
        },
        4: {
          name: "Rozwiązywanie układów równań pierwszego stopnia z dwiema niewiadomymi metodą przeciwnych współczynników",
          liczbaStronTeorii: 1,
        },
        5: {
          name: "Zastosowanie układów równań do rozwiązywania zadań",
          liczbaStronTeorii: 1,
        },
      },
      6: {
        liczbaStronTeorii: 5,

        teoriaCustom: ["1,6,1,1", "1,6,2,1", "1,6,3,1", "1,6,4,1", "1,6,5,1"],
        1: {
          name: "Funkcja kwadratowa",
          liczbaStronTeorii: 1,
        },
        2: {
          name: "Funkcja kwadratowa - zastosowania",
          liczbaStronTeorii: 1,
        },
        3: {
          name: "Proporcjonalność odwrotna",
          liczbaStronTeorii: 1,
        },
        4: {
          name: "Funkcja wykładnicza",
          liczbaStronTeorii: 1,
        },
        5: {
          name: "Funkcja logarytmiczna",
          liczbaStronTeorii: 1,
        },
      },
      7: {
        liczbaStronTeorii: 18,

        teoriaCustom: [
          "1,7,1,1",
          "1,7,1,2",
          "1,7,2,1",
          "1,7,2,2",
          "1,7,3,1",
          "1,7,4,1r",
          "1,7,4,2r",
          "1,7,4,1",
          "1,7,5,1",
          "1,7,5,2",
          "1,7,6,1",
          "1,7,7,1",
          "1,7,7,2",
          "1,7,7,3",
          "1,7,8,1",
          "1,7,9,1",
          "1,7,9,2",
          "1,7,12,1",
        ],
        1: {
          name: "Punkt, prosta, odcinek, półprosta, kąt, figura wypukła, figura ograniczona",
          liczbaStronTeorii: 2,
        },
        2: {
          name: "Wzajemne położenie prostych na płaszczyźnie, odległość punktu od prostej, odległość między prostymi równoległymi, symetralna odcinka, dwusieczna kąta",
          liczbaStronTeorii: 2,
        },
        3: {
          name: "Dwie proste przecięte trzecią prostą, suma kątów w trójkącie, wielokąt, wielokąt foremny, suma kątów w wielokącie",
          liczbaStronTeorii: 1,
        },
        4: {
          name: "Wielokąt. Wielokąt foremny. Suma kątów w wielokącie",
          liczbaStronTeorii: 2,

          teoriaCustom: ["1,7,4,1r", "1,7,4,2r"],
        },
        5: {
          name: "Twierdzenie Talesa",
          liczbaStronTeorii: 1,

          teoriaCustom: ["1,7,4,1"],
        },
        6: {
          name: "Podział trójkątów. Nierówność trójkąta. Odcinek łączący środki boków w trójkącie",
          liczbaStronTeorii: 2,

          teoriaCustom: ["1,7,5,1", "1,7,5,2"],
        },
        7: {
          name: "Twierdzenie Pitagorasa. Twierdzenie odwrotne do twierdzenia Pitagorasa",
          liczbaStronTeorii: 1,

          teoriaCustom: ["1,7,6,1"],
        },
        8: {
          name: "Wysokości w trójkącie. Środkowe w trójkącie",
          liczbaStronTeorii: 3,

          teoriaCustom: ["1,7,7,1", "1,7,7,2", "1,7,7,3"],
        },
        9: {
          name: "Przystawanie trójkątów",
          liczbaStronTeorii: 1,

          teoriaCustom: ["1,7,8,1"],
        },
        10: {
          name: "Podobieństwo trójkątów",
          liczbaStronTeorii: 2,

          teoriaCustom: ["1,7,9,1", "1,7,9,2"],
        },
        11: {
          name: "Podobieństwo trójkątów - zastosowanie w zadaniach",
          liczbaStronTeorii: 2,

          teoriaCustom: ["1,7,9,1", "1,7,9,2"],
        },
        12: {
          name: "Wektor na płaszczyźnie",
          liczbaStronTeorii: 1,
        },
      },
      8: {
        liczbaStronTeorii: 3,

        teoriaCustom: ["1,8,1,1", "1,8,2,1", "1,8,3,1"],
        1: {
          name: "Określenie sinusa, cosinusa, tangensa i cotangensa w trójkącie prostokątnym",
          liczbaStronTeorii: 1,
        },
        2: {
          name: "Wartości sinusa, cosinusa, tangensa i cotangensa kątów 30°, 45° i 60°",
          liczbaStronTeorii: 1,
        },
        3: {
          name: "Zależności między funkcjami trygonometrycznymi tego samego kąta ostrego",
          liczbaStronTeorii: 1,
        },
      },
    },
  },
  2: {
    podstawa: {
      1: {
        liczbaStronTeorii: 6,
        teoriaCustom: [
          "2,1,1,1",
          "2,1,1,2",
          "2,1,2,1",
          "2,1,3,1",
          "2,1,4,1",
          "2,1,5,1",
        ],
        1: {
          name: "Wektor w układzie współrzędnych - podstawowe informacje",
          liczbaStronTeorii: 2,
        },
        2: {
          name: "Przesunięcie równoległe. Przesunięcie równoległe wzdłuż osi OX",
          liczbaStronTeorii: 1,
        },
        3: {
          name: "Przesunięcie równoległe wzdłuż osi OY",
          liczbaStronTeorii: 1,
        },
        4: {
          name: "Symetria osiowa. Symetria osiowa względem osi OX i OY",
          liczbaStronTeorii: 1,
        },
        5: {
          name: "Symetria środkowa. Symetria środkowa względem punktu (0, 0)",
          liczbaStronTeorii: 1,
        },
        6: {
          name: "Zastosowanie wykresów funkcji do rozwiązywania równań i nierówności",
          liczbaStronTeorii: 6,

          teoriaCustom: [
            "2,1,1,1",
            "2,1,1,2",
            "2,1,2,1",
            "2,1,3,1",
            "2,1,4,1",
            "2,1,5,1",
          ],
        },
      },
      2: {
        liczbaStronTeorii: 3,
        teoriaCustom: ["2,2,1,1", "2,2,2,1", "2,2,3,1"],
        zadanieMaturalneSpecyfikacje: [
          ["35", "350", "b", "maj 2023", "rows2"],
          ["35", "130", "a", "czerwiec 2023"],
          ["45", "280", "c", "wrzesień 2022"],
          ["100", "1200", "a", "próbna marzec 2022", "rows4", "45"],
        ],
        1: {
          name: "Wartość bezwzględna liczby rzeczywistej",
          liczbaStronTeorii: 1,
        },
        2: {
          name: "Odległość między liczbami na osi liczbowej. Geometryczna interpretacja wartości bezwzględnej na osi liczbowej",
          liczbaStronTeorii: 1,
        },
        3: {
          name: "Proste równania z wartością bezwzględną",
          liczbaStronTeorii: 1,
        },
        4: {
          name: "Proste nierówności z wartością bezwzględną",
          liczbaStronTeorii: 1,
        },
      },
      3: {
        zadanieMaturalneSpecyfikacje: [
          ["40", "175", "a", "maj 2023"],
          ["100", "220", "d", "czerwiec 2023"],
          ["40", "155", "b", "próbna grudzień 2023"],
        ],
        1: {
          name: "Przypomnienie wiadomości o funkcji kwadratowej z klasy 1.",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Związek między wzorem funkcji kwadratowej w postaci ogólnej, a wzorem funkcji kwadratowej w postaci kanonicznej",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Miejsce zerowe funkcji kwadratowej. Wzór funkcji kwadratowej w postaci iloczynowej",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Szkicowanie wykresów funkcji kwadratowych. Odczytywanie własności funkcji kwadratowej na podstawie wykresu",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Wyznaczanie wzoru funkcji kwadratowej na podstawie jej własności",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Najmniejsza oraz największa wartość funkcji kwadratowej w przedziale domkniętym",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Badanie funkcji kwadratowej - zadania optymalizacyjne",
          liczbaStronTeorii: 0,
        },
        8: {
          name: "Równania kwadratowe",
          liczbaStronTeorii: 0,
        },
        9: {
          name: "Równania prowadzące do równań kwadratowych",
          liczbaStronTeorii: 0,
        },
        10: {
          name: "Nierówności kwadratowe",
          liczbaStronTeorii: 0,
        },
        11: {
          name: "Zadania prowadzące do równań i nierówności kwadratowych",
          liczbaStronTeorii: 0,
        },
      },
      4: {
        zadanieMaturalneSpecyfikacje: [
          ["35", "160", "b", "maj 2023"],
          ["70", "190", "c", "czerwiec 2023"],
          ["35", "150", "b", "czerwiec 2023"],
          ["45", "150", "a", "próbna grudzień 2023", "rows4", "20"],
          ["40", "210", "a", "próbna grudzień 2023"],
          ["40", "140", "b", "próbna wrzesień 2023"],
          ["40", "140", "a", "próbna marzec 2022"],
          ["35", "140", "d", "próbna marzec 2022"],
        ],
        1: {
          name: "Powtórzenie wiadomości z geometrii z klasy 1.",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Okrąg. Położenie prostej i okręgu",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Wzajemne położenie dwóch okręgów",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Koła i kąty",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Twierdzenie o stycznej i siecznej",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Symetralne boków trójkąta. Okrąg opisany na trójkącie",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Dwusieczne kątów trójkąta. Okrąg wpisany w trójkąt",
          liczbaStronTeorii: 0,
        },
      },
      5: {
        zadanieMaturalneSpecyfikacje: [
          ["80", "200", "d", "maj 2023"],
          ["40", "650", "a", "maj 2023", "rows2"],
          ["40", "230", "a", "czerwiec 2023"],
          ["70", "130", "b", "próbna grudzień 2023"],
          ["40", "150", "b", "wrzesień 2022"],
          ["70", "130", "a", "próbna marzec 2022"],
        ],
        1: {
          name: "Trygonometria kąta ostrego - powtórzenie wiadomości z klasy 1.",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Sinus, cosinus, tangens i cotangens dowolnego kąta płaskiego",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Podstawowe tożsamości trygonometryczne",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Wybrane wzory redukcyjne",
          liczbaStronTeorii: 0,
        },
      },
      6: {
        zadanieMaturalneSpecyfikacje: [
          ["75", "350", "d", "maj 2023", "rows2", "115"],
          ["75", "155", "a", "czerwiec 2023"],
          ["40", "330", "d", "czerwiec 2023", "rows2"],
          ["40", "185", "c", "czerwiec 2023"],
          ["55", "630", "b", "próbna grudzień 2023", "rows4", "10"],
          ["70", "190", "b", "próbna grudzień 2023"],
          ["50", "500", "c", "wrzesień 2022", "rows4", "1"],
          ["45", "450", "a", "próbna marzec 2022", "rows2", "115"],
          ["40", "200", "b", "próbna marzec 2022"],
        ],
        1: {
          name: "Odcinek w układzie współrzędnych",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Równanie kierunkowe prostej",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Równanie ogólne prostej",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Równanie okręgu",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Wyznaczanie w układzie współrzędnych punktów wspólnych prostych, okręgów i parabol",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Zastosowanie układów równań do rozwiązywania zadań z geometrii analitycznej",
          liczbaStronTeorii: 0,
        },
      },
      7: {
        zadanieMaturalneSpecyfikacje: [
          ["35", "165", "b", "maj 2023"],
          ["80", "150", "d", "wrzesień 2023"],
        ],
        1: {
          name: "Twierdzenie sinusów",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Twierdzenie cosinusów",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Zastosowanie twierdzenia sinusów i twierdzenia cosinusów do rozwiązywania zadań",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Pole figury płaskiej",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Pole trójkąta, część 1",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Pole trójkąta, część 2",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Pola trójkątów podobnych",
          liczbaStronTeorii: 0,
        },
        8: {
          name: "Pole koła, pole wycinki koła",
          liczbaStronTeorii: 0,
        },
        9: {
          name: "Zastosowanie pojęcia pola w dowodzeniu twierdzeń",
          liczbaStronTeorii: 0,
        },
      },
      8: {
        zadanieMaturalneSpecyfikacje: [
          ["45", "435", "b", "próbna grudzień 2023", "rows4", "21"],
          ["50", "850", "b", "próbna wrzesień 2022", "rows4", "15"],
          ["35", "120", "b", "próbna marzec 2022"],
        ],
        1: {
          name: "Wielomiany jednej zmiennej rzeczywistej",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Dodawanie, odejmowanie i mnożenie wielomianów",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Równość wielomianów",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Wzory skróconego mnożenia stopnia 3. Wzór na aⁿ - bⁿ",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Zastosowanie wzorów skróconego mnożenia w dowodzeniu",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Podzielność wielomianów",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Dzielenie wielomianu przez dwumian liniowy. Schemat Hornera",
          liczbaStronTeorii: 0,
        },
        8: {
          name: "Pierwiastek wielomianu. Twierdzenie Bezouta",
          liczbaStronTeorii: 0,
        },
        9: {
          name: "Pierwiastki wymierne wielomianu",
          liczbaStronTeorii: 0,
        },
        10: {
          name: "Rozkładanie wielomianów na czynniki",
          liczbaStronTeorii: 0,
        },
        11: {
          name: "Równania wielomianowe",
          liczbaStronTeorii: 0,
        },
        12: {
          name: "Zadania prowadzące do równań wielomianowych",
          liczbaStronTeorii: 0,
        },
      },
    },
    rozszerzenie: {
      1: {
        liczbaStronTeorii: 9,

        teoriaCustom: [
          "2,1,1,1r",
          "2,1,1,1",
          "2,1,1,2",
          "2,1,2,1",
          "2,1,3,1",
          "2,1,4,1",
          "2,1,5,1",
          "2,1,7,1",
          "2,1,8,1",
        ],
        1: {
          name: "Wektor na płaszczyźnie",
          liczbaStronTeorii: 1,

          teoriaCustom: ["2,1,1,1r"],
        },
        2: {
          name: "Wektor w układzie współrzędnych",
          liczbaStronTeorii: 2,

          teoriaCustom: ["2,1,1,1", "2,1,1,2"],
        },
        3: {
          name: "Przesunięcie równoległe. Przesunięcie równoległe wzdłuż osi OX",
          liczbaStronTeorii: 1,

          teoriaCustom: ["2,1,2,1"],
        },
        4: {
          name: "Przesunięcie równoległe wzdłuż osi OY",
          liczbaStronTeorii: 1,

          teoriaCustom: ["2,1,3,1"],
        },
        5: {
          name: "Symetria osiowa. Symetria osiowa względem osi OX i OY",
          liczbaStronTeorii: 1,

          teoriaCustom: ["2,1,4,1"],
        },
        6: {
          name: "Symetria środkowa. Symetria środkowa względem punktu (0, 0)",
          liczbaStronTeorii: 1,

          teoriaCustom: ["2,1,5,1"],
        },
        7: {
          name: "Wykres funkcji y = |f(x)| oraz y = f(|x|)",
          liczbaStronTeorii: 1,
        },
        8: {
          name: "Wykres funkcji y = k * f(x) oraz y = f(k * x), gdzie k ≠ 0",
          liczbaStronTeorii: 1,
        },
        9: {
          name: "Szkicowanie wykresów wybranych funkcji",
          liczbaStronTeorii: 6,

          teoriaCustom: [
            "2,1,2,1",
            "2,1,3,1",
            "2,1,4,2",
            "2,1,5,1",
            "2,1,7,1",
            "2,1,8,1",
          ],
        },
        10: {
          name: "Zastosowanie wykresów funkcji do rozwiązywania równań i nierówności",
          liczbaStronTeorii: 6,

          teoriaCustom: [
            "2,1,2,1",
            "2,1,3,1",
            "2,1,4,2",
            "2,1,5,1",
            "2,1,7,1",
            "2,1,8,1",
          ],
        },
      },
      2: {
        liczbaStronTeorii: 3,
        teoriaCustom: ["2,2,1,1", "2,2,2,1", "2,2,3,1"],
        1: {
          name: "Wartość bezwzględna liczby rzeczywistej",
          liczbaStronTeorii: 1,
        },
        2: {
          name: "Odległość między liczbami na osi liczbowej. Geometryczna interpretacja wartości bezwzględnej na osi liczbowej",
          liczbaStronTeorii: 1,
        },
        3: {
          name: "Proste równania z wartością bezwzględną",
          liczbaStronTeorii: 1,
        },
        4: {
          name: "Proste nierówności z wartością bezwzględną",
          liczbaStronTeorii: 1,
        },
        5: {
          name: "Własności wartości bezwzględnej",
          liczbaStronTeorii: 1,
        },
        6: {
          name: "Równania z wartością bezwzględną",
          liczbaStronTeorii: 2,
          teoriaCustom: ["2,2,4,1", "2,2,5,1"],
        },
        7: {
          name: "Nierówności z wartością bezwzględną",
          liczbaStronTeorii: 2,
          teoriaCustom: ["2,2,4,1", "2,2,5,1"],
        },
        8: {
          name: "Równanie liniowe z parametrem",
          liczbaStronTeorii: 1,
        },
        9: {
          name: "Nierówność liniowa z parametrem",
          liczbaStronTeorii: 1,
        },
        10: {
          name: "Równania liniowe z wartością bezwzględną i z parametrem",
          liczbaStronTeorii: 0,
        },
        11: {
          name: "Układy równań pierwszego stopnia z dwiema niewiadomymi z parametrem",
          liczbaStronTeorii: 1,
        },
      },
      3: {
        1: {
          name: "Przypomnienie wiadomości o funkcji kwadratowej z klasy 1.",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Związek między wzorem funkcji kwadratowej w postaci ogólnej, a wzorem funkcji kwadratowej w postaci kanonicznej",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Miejsce zerowe funkcji kwadratowej. Wzór funkcji kwadratowej w postaci iloczynowej",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Szkicowanie wykresów funkcji kwadratowych. Odczytywanie własności funkcji kwadratowej na podstawie wykresu",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Wyznaczanie wzoru funkcji kwadratowej na podstawie jej własności",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Najmniejsza oraz największa wartość funkcji kwadratowej w przedziale domkniętym",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Badanie funkcji kwadratowej - zadania optymalizacyjne",
          liczbaStronTeorii: 0,
        },
        8: {
          name: "Równania kwadratowe",
          liczbaStronTeorii: 0,
        },
        9: {
          name: "Równania prowadzące do równań kwadratowych",
          liczbaStronTeorii: 0,
        },
        10: {
          name: "Nierówności kwadratowe",
          liczbaStronTeorii: 0,
        },
        11: {
          name: "Zadania prowadzące do równań i nierówności kwadratowych",
          liczbaStronTeorii: 0,
        },
        12: {
          name: "Równania i nierówności, w których niewiadoma występuje pod znakiem pierwiastka kwadratowego",
          liczbaStronTeorii: 0,
        },
        13: {
          name: "Wykres funkcji kwadratowej z wartością bezwzględną",
          liczbaStronTeorii: 0,
        },
        14: {
          name: "Równania i nierówności kwadratowe z wartością bezwzględną",
          liczbaStronTeorii: 0,
        },
        15: {
          name: "Wzory Viete'a",
          liczbaStronTeorii: 0,
        },
        16: {
          name: "Równania i nierówności kwadratowe z parametrem",
          liczbaStronTeorii: 0,
        },
      },
      4: {
        1: {
          name: "Powtórzenie wiadomości z geometrii z klasy 1.",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Okrąg. Położenie prostej i okręgu",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Wzajemne położenie dwóch okręgów",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Koła i kąty",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Twierdzenie o stycznej i siecznej",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Symetralne boków trójkąta. Okrąg opisany na trójkącie",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Dwusieczne kątów trójkąta. Okrąg wpisany w trójkąt",
          liczbaStronTeorii: 0,
        },
      },
      5: {
        1: {
          name: "Trygonometria kąta ostrego - powtórzenie wiadomości z klasy 1.",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Sinus, cosinus, tangens i cotangens dowolnego kąta płaskiego",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Podstawowe tożsamości trygonometryczne",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Wybrane wzory redukcyjne",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Kąt skierowany. Miara łukowa kąta",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Funkcje trygonometryczne zmiennej rzeczywistej",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Wykresy funkcji trygonometrycznych",
          liczbaStronTeorii: 0,
        },
      },
      6: {
        1: {
          name: "Odcinek w układzie współrzędnych",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Równanie kierunkowe prostej",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Równanie ogólne prostej",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Równanie okręgu",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Wyznaczanie w układzie współrzędnych punktów wspólnych prostych, okręgów i parabol",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Zastosowanie układów równań do rozwiązywania zadań z geometrii analitycznej",
          liczbaStronTeorii: 0,
        },
      },
      7: {
        1: {
          name: "Twierdzenie sinusów",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Twierdzenie cosinusów",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Zastosowanie twierdzenia sinusów i twierdzenia cosinusów do rozwiązywania zadań",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Pole figury płaskiej",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Pole trójkąta, część 1",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Pole trójkąta, część 2",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Pola trójkątów podobnych",
          liczbaStronTeorii: 0,
        },
        8: {
          name: "Pole koła, pole wycinki koła",
          liczbaStronTeorii: 0,
        },
        9: {
          name: "Zastosowanie pojęcia pola w dowodzeniu twierdzeń",
          liczbaStronTeorii: 0,
        },
      },
      8: {
        1: {
          name: "Wielomiany jednej zmiennej rzeczywistej",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Dodawanie, odejmowanie i mnożenie wielomianów",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Równość wielomianów",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Wzory skróconego mnożenia stopnia 3. Wzór na aⁿ - bⁿ",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Zastosowanie wzorów skróconego mnożenia w dowodzeniu",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Podzielność wielomianów",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Dzielenie wielomianu przez dwumian liniowy. Schemat Hornera",
          liczbaStronTeorii: 0,
        },
        8: {
          name: "Dzielenie wielomianu przez wielomian stopnia większego od 1",
          liczbaStronTeorii: 0,
        },
        9: {
          name: "Pierwiastek wielomianu. Twierdzenie Bezouta",
          liczbaStronTeorii: 0,
        },
        10: {
          name: "Pierwiastki wymierne wielomianu",
          liczbaStronTeorii: 0,
        },
        11: {
          name: "Pierwiastek wielokrotny",
          liczbaStronTeorii: 0,
        },
        12: {
          name: "Rozkładanie wielomianów na czynniki",
          liczbaStronTeorii: 0,
        },
        13: {
          name: "Równania wielomianowe",
          liczbaStronTeorii: 0,
        },
        14: {
          name: "Zadania prowadzące do równań wielomianowych",
          liczbaStronTeorii: 0,
        },
        15: {
          name: "Równania wielomianowe z parametrem",
          liczbaStronTeorii: 0,
        },
        16: {
          name: "Funkcje wielomianowe",
          liczbaStronTeorii: 0,
        },
        17: {
          name: "Nierówności wielomianowe",
          liczbaStronTeorii: 0,
        },
      },
    },
  },
  3: {
    podstawa: {
      1: {
        zadanieMaturalneSpecyfikacje: [
          ["45", "340", "b", "czerwiec 2023", "rows4", "17.5"],
          ["50", "1000", "a", "próbna marzec 2022", "rows4", "15"],
        ],
        1: {
          name: "Ułamek algebraiczny. Skracanie i rozszerzanie ułamków algebraicznych",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Dodawanie i odejmowanie ułamków algebraicznych",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Mnożenie i dzielenie ułamków algebraicznych",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Działania na ułamkach algebraicznych",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Równania wymierne",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Zadania tekstowe prowadzące do równań wymiernych",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Funkcja homograficzna",
          liczbaStronTeorii: 0,
        },
      },
      2: {
        zadanieMaturalneSpecyfikacje: [
          ["35", "135", "d", "maj 2023"],
          ["35", "100", "c", "maj 2023"],
          ["35", "200", "d", "czerwiec 2023"],
          ["35", "130", "b", "czerwiec 2023"],
          ["40", "210", "a", "czerwiec 2023"],
          ["40", "350", "a", "próbna grudzień 2023", "rows4", "6"],
          ["70", "410", "a", "próbna grudzień 2023", "rows2", "115"],
          ["45", "320", "d", "próbna marzec 2022"],
        ],
        1: {
          name: "Określenie ciągu. Sposoby opisywania ciągów",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Monotoniczność ciągów",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Ciąg arytmetyczny",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Suma początkowych wyrazów ciągu arytmetycznego",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Ciąg geometryczny",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Suma początkowych wyrazów ciągu geometrycznego",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Ciąg arytmetyczny i ciąg geometryczny - zadania różne",
          liczbaStronTeorii: 0,
        },
        8: {
          name: "Lokaty pieniężne i kredyty bankowe",
          liczbaStronTeorii: 0,
        },
      },
      3: {
        zadanieMaturalneSpecyfikacje: [
          ["35", "160", "c", "maj 2023"],
          ["35", "160", "d", "czerwiec 2023"],
          ["30", "300", "b", "próbna grudzień 2023"],
          ["35", "225", "b", "próbna wrzesień 2022"],
          ["40", "140", "c", "próbna marzec 2022"],
        ],
        1: {
          name: "Reguła mnożenia i reguła dodawania",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Wariacje",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Permutacje",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Kombinacje",
          liczbaStronTeorii: 0,
        },
      },
      4: {
        1: {
          name: "Podział czworokątów. Trapezoidy",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Trapezy",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Równoległoboki",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Podobieństwo. Czworokąty podobne",
          liczbaStronTeorii: 0,
        },
      },
      5: {
        zadanieMaturalneSpecyfikacje: [
          ["40", "190", "a", "czerwiec 2023"],
          ["35", "125", "c", "próbna grudzień 2023"],
        ],
        1: {
          name: "Pole prostokąta. Pole kwadratu",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Pole równoległoboku. Pole rombu",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Pole trapezu",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Pole czworokąta - zadania różne",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Pola figur podobnych",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Mapa. Skala mapy",
          liczbaStronTeorii: 0,
        },
      },
      6: {
        zadanieMaturalneSpecyfikacje: [
          ["40", "210", "b", "czerwiec 2023"],
          ["40", "170", "b", "wrzesień 2022"],
        ],
        1: {
          name: "Wektor w układzie współrzędnych. Podział odcinka",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Proste w układzie współrzędnych",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Odległość punktu od prostej",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Odległość między dwiema prostymi równoległymi",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Pole trójką. Pole wielokąta",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Równania okręgu. Wzajemne położenie prostej i okręgu",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Zadania różne z geometrii analitycznej",
          liczbaStronTeorii: 0,
        },
        8: {
          name: "Wybrane przekształcenia geometryczne w układzie współrzędnych",
          liczbaStronTeorii: 0,
        },
      },
    },
    rozszerzenie: {
      1: {
        1: {
          name: "Ułamek algebraiczny. Skracanie i rozszerzanie ułamków algebraicznych",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Dodawanie i odejmowanie ułamków algebraicznych",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Mnożenie i dzielenie ułamków algebraicznych",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Działania na ułamkach algebraicznych",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Równania wymierne",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Zadania tekstowe prowadzące do równań wymiernych",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Nierówności wymierne",
          liczbaStronTeorii: 0,
        },
        8: {
          name: "Zadania na dowodzenie z zastosowaniem średniej arytmetycznej, średniej geometrycznej i średniej kwadratowej kilku liczb",
          liczbaStronTeorii: 0,
        },
        9: {
          name: "Funkcja homograficzna",
          liczbaStronTeorii: 0,
        },
        10: {
          name: "Zastosowanie wiadomości o funkcji homograficznej w zadaniach",
          liczbaStronTeorii: 0,
        },
        11: {
          name: "Funkcje wymierne",
          liczbaStronTeorii: 0,
        },
      },
      2: {
        1: {
          name: "Określenie ciągu. Sposoby opisywania ciągów",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Monotoniczność ciągów",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Ciąg arytmetyczny",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Suma początkowych wyrazów ciągu arytmetycznego",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Ciąg geometryczny",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Suma początkowych wyrazów ciągu geometrycznego",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Ciąg arytmetyczny i ciąg geometryczny - zadania różne",
          liczbaStronTeorii: 0,
        },
        8: {
          name: "Lokaty pieniężne i kredyty bankowe",
          liczbaStronTeorii: 0,
        },
        9: {
          name: "Granica ciągu liczbowego",
          liczbaStronTeorii: 0,
        },
        10: {
          name: "Obliczanie granic ciągów zbieżnych",
          liczbaStronTeorii: 0,
        },
        11: {
          name: "Wybrane własności ciągów zbieżnych",
          liczbaStronTeorii: 0,
        },
        12: {
          name: "Ciągi rozbieżne do nieskończoności",
          liczbaStronTeorii: 0,
        },
        13: {
          name: "Szereg geometryczny",
          liczbaStronTeorii: 0,
        },
      },
      3: {
        1: {
          name: "Wariacje",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Permutacje",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Kombinacje",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Kombinatoryka - zadania różne",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Symbol Newtona. Wzór Newtona. Trójkąt Pascala",
          liczbaStronTeorii: 0,
        },
      },
      4: {
        1: {
          name: "Podział czworokątów. Trapezoidy",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Trapezy",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Równoległoboki",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Okrąg opisany na czworokącie",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Okrąg wpisany w czworokąt",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Okrąg opisany na czworokącie, okrąg wpisany w czworokąt - zadania na dowodzenie",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Podobieństwo. Czworokąty podobne",
          liczbaStronTeorii: 0,
        },
      },
      5: {
        1: {
          name: "Pole prostokąta. Pole kwadratu",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Pole równoległoboku. Pole rombu",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Pole trapezu",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Pole czworokąta - zadania różne",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Pola figur podobnych",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Mapa. Skala mapy",
          liczbaStronTeorii: 0,
        },
      },
      6: {
        1: {
          name: "Granica funkcji w punkcie",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Obliczanie granicy funkcji w punkcie",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Granice jednostronne funkcji w punkcie",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Granica funkcji w nieskończoności",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Granica niewłaściwa funkcji",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Ciągłość funkcji w punkcie",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Ciągłość funkcji w zbiorze",
          liczbaStronTeorii: 0,
        },
        8: {
          name: "Asymptoty wykresu funkcji",
          liczbaStronTeorii: 0,
        },
        9: {
          name: "Pochodna funkcji w punkcie",
          liczbaStronTeorii: 0,
        },
        10: {
          name: "Funkcja pochodna",
          liczbaStronTeorii: 0,
        },
        11: {
          name: "Funkcja złożona. Pochodna funkcji złożonej",
          liczbaStronTeorii: 0,
        },
        12: {
          name: "Styczna do wykresu funkcji",
          liczbaStronTeorii: 0,
        },
        13: {
          name: "Pochodna funkcji a monotoniczność funkcji",
          liczbaStronTeorii: 0,
        },
        14: {
          name: "Ekstrema lokalne funkcji",
          liczbaStronTeorii: 0,
        },
        15: {
          name: "Największa i najmniejsza wartość funkcji w przedziale",
          liczbaStronTeorii: 0,
        },
        16: {
          name: "Zadania optymalizacyjne",
          liczbaStronTeorii: 0,
        },
      },
      7: {
        1: {
          name: "Funkcje trygonometryczne zmiennej rzeczywistej - powtórzenie wiadomości z klasy 2.",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Przekształcenia wykresów funkcji trygonometrycznych",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Równania trygonometryczne, cz. 1.",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Funkcje trygonometryczne sumy i różnicy",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Funkcje trygonometryczne wielokrotności kąta",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Sumy i różnice funkcji trygonometrycznych",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Równania trygonometryczne, cz. 2.",
          liczbaStronTeorii: 0,
        },
        8: {
          name: "Nierówności trygonometryczne",
          liczbaStronTeorii: 0,
        },
        9: {
          name: "Pochodne funkcji trygonometrycznych",
          liczbaStronTeorii: 0,
        },
      },
      8: {
        1: {
          name: "Wektor w układzie współrzędnych. Podział odcinka",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Kąt między niezerowymi wektorami",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Proste w układzie współrzędnych",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Odległość punktu od prostej. Odległość między dwiema prostymi równoległymi",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Pole trójkąta. Pole wielokąta",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Równanie okręgu. Wzajemne położenie prostej i okręgu",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Wzajemne położenie dwóch okręgów",
          liczbaStronTeorii: 0,
        },
        8: {
          name: "Zadania różne z geometrii analitycznej",
          liczbaStronTeorii: 0,
        },
        9: {
          name: "Wybrane przekształcenia geometryczne w układzie współrzędnych",
          liczbaStronTeorii: 0,
        },
        10: {
          name: "Zastosowanie analizy matematycznej w rozwiązywaniu zadań z geometrii analitycznej",
          liczbaStronTeorii: 0,
        },
      },
    },
  },
  4: {
    podstawa: {
      1: {
        1: {
          name: "Potęga o wykładniku rzeczywistym - powtórzenie",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Funkcja wykładnicza i jej właściwości",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Przekształcenia wykresów funkcji wykładniczych",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Równania wykładnicze",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Nierówności wykładnicze",
          liczbaStronTeorii: 0,
        },
      },
      2: {
        1: {
          name: "Logarytm - powtórzenie wiadomości",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Funkcja logarytmiczna - powtórzenie i uzupełnienie wiadomości",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Przekształcenia wykresów funkcji logarytmicznych",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Równania logarytmiczne",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Zastosowanie funkcji wykładniczej i funkcji logarytmicznej do rozwiązywania zadań umieszczonych w kontekście praktycznym",
          liczbaStronTeorii: 0,
        },
      },
      3: {
        1: {
          name: "Sposoby prezentowania danych zebranych w wyniku obserwacji statystycznej",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Średnia z próby",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Mediana z próby i moda z próby. Skala centylowa",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Wariancja i odchylenie standardowe",
          liczbaStronTeorii: 0,
        },
      },
      4: {
        1: {
          name: "Kombinatoryka - powtórzenie wiadomości",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Doświadczenie losowe",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Zdarzenia. Działania na zdarzeniach",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Określenie prawdopodobieństwa",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Prawdopodobieństwo klasyczne",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Doświadczenia losowe wieloetapowe",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Zmienna losowa. Wartość oczekiwana zmiennej losowej",
          liczbaStronTeorii: 0,
        },
      },
      5: {
        1: {
          name: "Płaszczyzny i proste w przestrzeni. Równoległość prostych i płaszczyzn. Proste skośne",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Prostopadłość prostych i płaszczyzn w przestrzeni",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Rzut równoległy na płaszczyznę. Rysowanie figur płaskich w rzucie równoległym na płaszczyznę",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Twierdzenie o trzech prostych prostopadłych",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Kąt między prostą a płaszczyzną. Kąt dwuścienny",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Ostrosłupy",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Siatka wielościanu. Pole powierzchni wielościanu",
          liczbaStronTeorii: 0,
        },
        8: {
          name: "Objętość figury przestrzennej. Objętość wielościanów",
          liczbaStronTeorii: 0,
        },
        9: {
          name: "Przekroje wielościanów - zadania",
          liczbaStronTeorii: 0,
        },
      },
      6: {
        1: {
          name: "Walec",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Stożek",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Kula i sfera",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Bryły obrotowe - różne zadania",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Podobieństwo figur w przestrzeni",
          liczbaStronTeorii: 0,
        },
      },
    },
    rozszerzenie: {
      1: {
        1: {
          name: "Potęga o wykładniku rzeczywistym - powtórzenie",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Funkcja wykładnicza i jej właściwości",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Przekształcenia wykresów funkcji wykładniczych",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Równania wykładnicze",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Nierówności wykładnicze",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Zastosowanie własności funkcji wykładniczej w zadaniach",
          liczbaStronTeorii: 0,
        },
      },
      2: {
        1: {
          name: "Logarytm - powtórzenie wiadomości",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Funkcja logarytmiczna - powtórzenie i uzupełnienie wiadomości",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Przekształcenia wykresów funkcji logarytmicznych",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Równania logarytmiczne",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Nierówności logarytmiczne",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Zastosowanie funkcji wykładniczej i funkcji logarytmicznej do rozwiązywania zadań umieszczonych w kontekście praktycznym",
          liczbaStronTeorii: 0,
        },
      },
      3: {
        1: {
          name: "Sposoby prezentowania danych zebranych w wyniku obserwacji statystycznej",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Średnia z próby",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Mediana z próby i moda z próby. Skala centylowa",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Wariancja i odchylenie standardowe",
          liczbaStronTeorii: 0,
        },
      },
      4: {
        1: {
          name: "Kombinatoryka - powtórzenie wiadomości",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Doświadczenie losowe",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Zdarzenia. Działania na zdarzeniach",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Określenie prawdopodobieństwa",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Obliczanie prawdopodobieństwa",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Doświadczenia losowe wieloetapowe",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Prawdopodobieństwo warunkowe",
          liczbaStronTeorii: 0,
        },
        8: {
          name: "Twierdzenie o prawdopodobieństwie całkowitym. Wzór Bayesa",
          liczbaStronTeorii: 0,
        },
        9: {
          name: "Niezależność zdarzeń",
          liczbaStronTeorii: 0,
        },
        10: {
          name: "Schemat Bernoulliego",
          liczbaStronTeorii: 0,
        },
        11: {
          name: "Zmienna losowa. Wartość oczekiwana zmiennej losowej",
          liczbaStronTeorii: 0,
        },
      },
      5: {
        1: {
          name: "Płaszczyzny i proste w przestrzeni. Równoległość prostych i płaszczyzn. Proste skośne",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Prostopadłość prostych i płaszczyzn w przestrzeni",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Rzut równoległy na płaszczyznę. Rysowanie figur płaskich w rzucie równoległym na płaszczyznę",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Twierdzenie o trzech prostych prostopadłych",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Kąt między prostą a płaszczyzną. Kąt dwuścienny",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Graniastosłupy",
          liczbaStronTeorii: 0,
        },
        7: {
          name: "Ostrosłupy",
          liczbaStronTeorii: 0,
        },
        8: {
          name: "Siatka wielościanu. Pole powierzchni wielościanu",
          liczbaStronTeorii: 0,
        },
        9: {
          name: "Objętość figury przestrzennej. Objętość wielościanów",
          liczbaStronTeorii: 0,
        },
        10: {
          name: "Przekroje wielościanów - konstrukcje",
          liczbaStronTeorii: 0,
        },
        11: {
          name: "Przekroje wielościanów - zadania",
          liczbaStronTeorii: 0,
        },
      },
      6: {
        1: {
          name: "Walec",
          liczbaStronTeorii: 0,
        },
        2: {
          name: "Stożek",
          liczbaStronTeorii: 0,
        },
        3: {
          name: "Kula i sfera",
          liczbaStronTeorii: 0,
        },
        4: {
          name: "Bryły obrotowe - zadania różne",
          liczbaStronTeorii: 0,
        },
        5: {
          name: "Podobieństwo figur w przestrzeni",
          liczbaStronTeorii: 0,
        },
        6: {
          name: "Zastosowanie analizy matematycznej w rozwiązywaniu zadań z geometrii przestrzennej",
          liczbaStronTeorii: 0,
        },
      },
    },
  },
};

export default tematy;
