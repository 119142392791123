import React from "react";
import { useAtomValue } from "jotai";
import "../../component-styles/Matura.scss";
import { ZakresArkusze } from "./Matura.tsx";
function ArkuszeLista() {
  const lista = [
    "MaturaPodstawowaMaj2023Formula2015",
    "MaturaPodstawowaMaj2023Formula2023",
    "oMaturaPodstawowaCzerwiec2023Formula2023",
    "oMaturaPodstawowaSierpien2023Formula2015",
    "oMaturaPodstawowaSierpien2023Formula2023",
    "oMaturaPodstawowaGrudzien2023Formula2023",
    "MaturaPodstawowaMaj2022",
    "oMaturaPodstawowaCzerwiec2022",
    "oMaturaPodstawowaSierpien2022",
    "oMaturaPodstawowaWrzesien2022",
    "oMaturaPodstawowaGrudzien2022",
    "oMaturaPodstawowaMarzec2021",
    "MaturaPodstawowaMaj2021",
    "oMaturaPodstawowaSierpien2021",
    "oMaturaRozszerzonaMaj2023Formula2015",
    "MaturaRozszerzonaMaj2023Formula2023",
    "oMaturaRozszerzonaCzerwiec2023Formula2015",
    "oMaturaRozszerzonaCzerwiec2023Formula2023",
    "oMaturaRozszerzonaMaj2022",
    "oMaturaRozszerzonaCzerwiec2022",
    "oMaturaRozszerzonaGrudzien2022",
    "oMaturaRozszerzonaMarzec2021",
    "oMaturaRozszerzonaMaj2021",
    "oMaturaRozszerzonaCzerwiec2021",
  ];

  const zakresArkusze = useAtomValue(ZakresArkusze);

  const openPDF = (fileName: string) => {
    window.open(require(`./ArkuszePDF/${fileName}.pdf`), "_blank");
  };

  return (
    <div id="arkusze-lista">
      <div className="lista">
        {lista
          .filter((x) =>
            new RegExp(
              zakresArkusze === "podstawa" ? "podstawowa" : "rozszerzona",
              "i"
            ).test(x)
          )
          .map((x) => (
            <div className="lista-div">
              <button
                className="lista-button"
                onClick={() => {
                  openPDF(x.replace(/^o/, ""));
                }}
              >
                {x
                  .replace(/^o/, "")
                  .replace(/([A-Z][a-z]*|\d+)/g, "$1 ")
                  .replace(/Formula/, "Formuła")
                  .replace(/Styczen/, "Styczeń")
                  .replace(/Kwiecien/, "Kwiecień")
                  .replace(/Sierpien/, "Sierpień")
                  .replace(/Wrzesien/, "Wrzesień")
                  .replace(/Pazdziernik/, "Październik")
                  .replace(/Grudzien/, "Grudzień")}
              </button>
              <button
                className={`lista-button ${x[0] === "o" ? "disabled" : ""}`}
                onClick={() => {
                  if (x[0] !== "o") openPDF(x + "Odpowiedzi");
                }}
              >
                {(x + "Odpowiedzi")
                  .replace(/^o/, "")
                  .replace(/([A-Z][a-z]*|\d+)/g, "$1 ")
                  .replace(/Formula/, "Formuła")
                  .replace(/Styczen/, "Styczeń")
                  .replace(/Kwiecien/, "Kwiecień")
                  .replace(/Sierpien/, "Sierpień")
                  .replace(/Wrzesien/, "Wrzesień")
                  .replace(/Pazdziernik/, "Październik")
                  .replace(/Grudzien/, "Grudzień")}
              </button>
            </div>
          ))}
      </div>
    </div>
  );
}
export default ArkuszeLista;
