import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { atom, useAtom } from "jotai";

import "./App.css";
import "./component-styles/About.scss";
import colors from "./colors.ts";
import NavBar from "./app-components/NavBar.tsx";
import HomePage from "./app-components/HomePage.tsx";
import Materialy from "./app-components/Materialy/Materialy.tsx";
import Korepetycje from "./app-components/Korepetytorzy/Korepetycje.tsx";
import Studia from "./app-components/Studia.tsx";
import ArkuszeLista from "./app-components/Matura/ArkuszeLista.tsx";
import Matura from "./app-components/Matura/Matura.tsx";
import ZagadnieniaMaturalne from "./app-components/ZagadnieniaMaturalne.tsx";
import About from "./app-components/About.tsx";
import Kontakt from "./app-components/Kontakt.tsx";
import ONas from "./app-components/ONas.tsx";
import FAQ from "./app-components/FAQ.tsx";
import MetodyEfektywnegoUczeniaSie from "./app-components/Artykuly/MetodyEfektywnegoUczeniaSię.tsx";
import PrzygotowanieDoMatury from "./app-components/Artykuly/PrzygotowanieDoMatury.tsx";
import MotywacjaDoSystematycznejNauki from "./app-components/Artykuly/MotywacjaDoSystematycznejNauki.tsx";

export const MotywStrony = atom<string>("dark");
export const WindowWidth = atom<number>(window.innerWidth);

declare module "react" {
  interface CSSProperties {
    "--purple"?: string;
    "--purpleHovered"?: string;
    "--purpleDarker"?: string;
    "--content"?: string;
    "--blue"?: string;
    "--colorFont"?: string;
    "--contentInverted"?: string;
    "--gray"?: string;
  }
}

function App() {
  const [motyw, setMotyw] = useAtom(MotywStrony);
  const [width, setWidth] = useAtom(WindowWidth);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const updateWindowWidth = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateWindowWidth);
    return () => {
      window.removeEventListener("resize", updateWindowWidth);
    };
  }, [width, setWidth]);

  //useEffect(() => {
  //  const prefersDarkMode =
  //    window.matchMedia &&
  //    window.matchMedia("(prefers-color-scheme: light)").matches;
  //  setMotyw(prefersDarkMode ? "light" : "dark");
  //}, [setMotyw]);

  return (
    <div
      className="website"
      style={{
        "--purple":
          motyw === "light" ? colors.lightMode.purple : colors.darkMode.purple,
        "--purpleHovered":
          motyw === "light"
            ? colors.lightMode.purpleHovered
            : colors.darkMode.purpleHovered,
        "--purpleDarker":
          motyw === "light"
            ? colors.lightMode.purpleDarker
            : colors.darkMode.purpleDarker,
        "--content":
          motyw === "light"
            ? colors.lightMode.content
            : colors.darkMode.content,
        "--blue":
          motyw === "light" ? colors.lightMode.blue : colors.darkMode.blue,
        "--colorFont":
          motyw === "light"
            ? colors.lightMode.colorFont
            : colors.darkMode.colorFont,
        "--contentInverted":
          motyw === "light"
            ? colors.lightMode.contentInverted
            : colors.darkMode.contentInverted,
        "--gray":
          motyw === "light" ? colors.lightMode.gray : colors.darkMode.gray,
      }}
    >
      <NavBar />
      <main id="content">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="materialy" element={<Materialy />} />
          <Route path="korepetycje" element={<Korepetycje />} />
          <Route path="studia" element={<Studia />} />
          <Route path="matura">
            <Route path="" element={<Matura />} />
            <Route path="lista-arkuszy" element={<ArkuszeLista />} />
            <Route
              path="zagadnienia-maturalne"
              element={<ZagadnieniaMaturalne />}
            />
          </Route>
          <Route path="kontakt" element={<Kontakt />} />
          <Route path="o-nas" element={<ONas />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="artykul">
            <Route
              path="metody-efektywnego-uczenia-sie"
              element={<MetodyEfektywnegoUczeniaSie />}
            />
            <Route
              path="przygotowanie-do-matury"
              element={<PrzygotowanieDoMatury />}
            />
            <Route
              path="motywacja-do-systematycznej-nauki"
              element={<MotywacjaDoSystematycznejNauki />}
            />
          </Route>
        </Routes>
        <About />
      </main>
    </div>
  );
}

export default App;
